import { useFormik } from 'formik';
import { useEffect, useState, useRef, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { toasts } from '../../../services/toast.service';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import {
	getColumnsForDataTable,
	profilePic,
	updateColumnsForDataTable,
	updateComplaintDetails,
	updateFilter,
} from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { getComplaintDetails } from '../../../services/student.service';
import Avatar from '../../Avatar';
import Button from '../../bootstrap/Button';
import { showLoader } from '../../../services/loader.services';
import AlertService from '../../../services/AlertService';

function ComplaintList({ type }: any) {

	useEffect(() => {
		getComplaintList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [studentComplaintsData, setStudentComplaintsData] = useState<any>([]);
	const [staffComplaintsData, setStaffComplaintsData] = useState<any>([]);
	const [parentComplaintsData, setParentComplaintsData] = useState<any>([]);
	const [studentAndstaffComplaintsData, setStudentAndstaffComplaintsData] = useState<any>([]);
	const [dataStatus, setDataStatus] = useState<any>(false);
	const [complaintDetailsData, setComplaintDetailsData] = useState<any>(false);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const [studentNoDataMsg, setStudentNoDataMsg] = useState<any>('');
	const [staffNoDataMsg, setStaffNoDataMsg] = useState<any>('');
	const [parentNoDataMsg, setParentNoDataMsg] = useState<any>('');
	const [staffAndStudentNoDataMsg, setStaffAndStudentNoDataMsg] = useState<any>('');


	function getComplaintList() {
		setDataStatus(false);
		getComplaintDetails(0, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.complaintDetails;
					if (data != undefined) {
						setComplaintDetailsData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						let studentComplaints = data.filter((item: any) => item.userTypeId == 9);
						setStudentComplaintsData(studentComplaints);
						setStudentNoDataMsg(studentComplaints == '' ? 'No Complaints' : '');

						let staffComplaints = data.filter(
							(item: any) => item.userTypeId != 9 && item.userTypeId != 10,
						);
						setStaffComplaintsData(staffComplaints);
						setStaffNoDataMsg(staffComplaints == '' ? 'No Complaints' : '');

						let parentComplaints = data.filter((item: any) => item.userTypeId == 10);
						setParentComplaintsData(parentComplaints);
						setParentNoDataMsg(parentComplaints == '' ? 'No Complaints' : '');
						setStudentAndstaffComplaintsData(data);

						setDataStatus(true);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					setComplaintDetailsData([])
					setStudentComplaintsData([])
					setStaffComplaintsData([])
					setParentComplaintsData([])
					setStaffAndStudentNoDataMsg(response.data.message);
					setStudentNoDataMsg(response.data.message);
					setStaffNoDataMsg(response.data.message);
					setParentNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function setEditAnnouncementDetails(complaintDetailsId: any) {
		return {
			complaintDetailsId: complaintDetailsId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId
		};
	}

	function updateComplaintSubmit(complaintDetailsId: any) {
		showLoader(true);
		if (complaintDetailsId > 0) {
			let complaintPostData = setEditAnnouncementDetails(complaintDetailsId);
			updateComplaintDetails(complaintPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getComplaintList();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (complaintDetailsId == 0) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}


	return (
		<>
			{type == 'dashboard' ? (
				<div className='col-lg-12'>
					<Card stretch className='overflow-hidden border border-light rounded-1' shadow='md'>
						<CardHeader borderSize={1}>
							<CardLabel icon='Chat' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Complaints
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody style={{ height: '55vh' }} isScrollable>
							{studentAndstaffComplaintsData != '' ? (
								<>
									{studentAndstaffComplaintsData.map((i: any, index: any) => (
										<div
											className={index > 0 ? 'row g-4 mt-1' : 'row g-4'}
											key={i.complaintDetailsId}>
											<div className='col-12 d-flex'>
												<div className='flex-shrink-0'>
													{i.profilePath != null ? (
														<Avatar src={i.profilePath} size={64} />
													) : (
														<img
															src={profilePic(i.genderId)}
															width='64'
															height='64'
														/>
													)}
												</div>
												<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
													<figure className='mb-0'>
														<div>
															<strong className='text-dark'>
																{i.studentOrStaffName} -{' '}
															</strong>
															{i.studentRollNoOrEmpNumber}
														</div>
														<div className='text-info fw-bold mt-1 text-uppercase'>
															{i.complaintType}
														</div>
														<blockquote className='blockquote mt-1'>
															<div
																className='fs-6'
																dangerouslySetInnerHTML={{
																	__html: i.natureOfComplaint,
																}}
															/>
														</blockquote>
														<figcaption className='blockquote-footer mb-0'>
															{i.complaintDateForView} ({i.userType})
														</figcaption>
													</figure>
												</div>
											</div>
											<div className='col-12 mt-2 border border-light'></div>
										</div>
									))}{' '}
								</>
							) : (
								<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
									{staffAndStudentNoDataMsg}
								</div>
							)}
						</CardBody>
					</Card>
				</div>
			) : (
				<PageWrapper title='Complaint List'>
					<Page>
						<div className='row'>
							<div className='col-lg-6'>
								<Card stretch className='overflow-hidden border border-light' shadow='md'>
									<CardHeader borderSize={1}>
										<CardLabel icon='Chat' iconColor='info'>
											<CardTitle tag='div' className='h5'>
												Student Complaints
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody style={{ height: '55vh' }} isScrollable>
										{studentComplaintsData != '' ? (
											<>
												{studentComplaintsData.map((i: any, index: any) => (
													<div className={`row g-4  ${index > 0 ? 'mt-1' : ''}`}
														key={i.complaintDetailsId}>
														<div className='col-10 d-flex'>
															<div className='flex-shrink-0'>
																{i.profilePath != null ?
																	<Avatar src={i.profilePath} size={64} /> :
																	<img src={profilePic(i.genderId)} width='64' height='64' />
																}
															</div>
															<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
																<figure className='mb-0'>
																	<div>
																		<strong className='text-dark'>{i.studentOrStaffName} - </strong>
																		{i.studentRollNoOrEmpNumber}
																	</div>
																	<div className='text-info fw-bold mt-1 text-uppercase'>{i.complaintType}</div>
																	<blockquote className='blockquote mt-1'>
																		<div className='fs-6' dangerouslySetInnerHTML={{ __html: i.natureOfComplaint }} />
																	</blockquote>
																	<figcaption className='blockquote-footer mb-0'>{i.complaintDateForView}</figcaption>
																</figure>
															</div>
														</div>
														<div className='col-1 mt-5'>
															<Button isLight icon='Check' color='primary' onClick={() => updateComplaintSubmit(i.complaintDetailsId)} className='border border-primary'>
															</Button>
														</div>
														<div className='col-12 mt-2 border border-light'></div>
													</div>
												))}
											</>
										) : (
											<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
												{studentNoDataMsg}
											</div>
										)}
									</CardBody>
								</Card>
							</div>
							<div className='col-lg-6'>
								<Card stretch className='overflow-hidden border border-light' shadow='md'>
									<CardHeader borderSize={1}>
										<CardLabel icon='Chat' iconColor='info'>
											<CardTitle tag='div' className='h5'>
												Staff Complaints
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody style={{ height: '55vh' }} isScrollable>
										{staffComplaintsData != '' ? (
											<>
												{staffComplaintsData.map((i: any, index: any) => (
													<div className={`row g-4  ${index > 0 ? 'mt-1' : ''}`}
														key={i.complaintDetailsId}>
														<div className='col-10 d-flex'>
															<div className='flex-shrink-0'>
																{i.profilePath != null ?
																	<Avatar src={i.profilePath} size={64} /> :
																	<img src={profilePic(i.genderId)} width='64' height='64' />
																}
															</div>
															<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
																<figure className='mb-0'>
																	<div>
																		<strong className='text-dark'>{i.studentOrStaffName} - </strong>
																		{i.studentRollNoOrEmpNumber}
																	</div>
																	<div className='text-info fw-bold mt-1 text-uppercase'>{i.complaintType}</div>
																	<blockquote className='blockquote mt-1'>
																		<div className='fs-6' dangerouslySetInnerHTML={{ __html: i.natureOfComplaint }} />
																	</blockquote>
																	<figcaption className='blockquote-footer mb-0'>{i.complaintDateForView}</figcaption>
																</figure>
															</div>
														</div>
														<div className='col-1 mt-5'>
															<Button isLight icon='Check' color='primary' onClick={() => updateComplaintSubmit(i.complaintDetailsId)} className='border border-primary'>
															</Button>
														</div>
														<div className='col-12 mt-2 border border-light'></div>
													</div>
												))}
											</>
										) : (
											<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>{staffNoDataMsg}</div>
										)}
									</CardBody>
								</Card>
							</div>
							<div className='col-lg-6'>
								<Card stretch className='overflow-hidden border border-light' shadow='md'>
									<CardHeader borderSize={1}>
										<CardLabel icon='Chat' iconColor='info'>
											<CardTitle tag='div' className='h5'>
												Parent Complaints
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody style={{ height: '55vh' }} isScrollable>
										{parentComplaintsData != '' ? (
											<>
												{parentComplaintsData.map((i: any, index: any) => (
													<div className={index > 0 ? 'row g-4 mt-1' : 'row g-4'}
														key={i.complaintDetailsId}>
														<div className='col-10 d-flex'>
															<div className='flex-shrink-0'>
																{i.profilePath != null ?
																	<Avatar src={i.profilePath} size={64} /> :
																	<img src={profilePic(i.genderId)} width='64' height='64' />
																}
															</div>
															<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
																<figure className='mb-0'>
																	<div>
																		<strong className='text-dark'>{i.studentOrStaffName}</strong>
																	</div>
																	<div className='text-info fw-bold mt-1 text-uppercase'>{i.complaintType}</div>
																	<blockquote className='blockquote mt-1'>
																		<div className='fs-6' dangerouslySetInnerHTML={{ __html: i.natureOfComplaint }} />
																	</blockquote>
																	<figcaption className='blockquote-footer mb-0'>{i.complaintDateForView}</figcaption>
																</figure>
															</div>
														</div>
														<div className='col-1 mt-5'>
															<Button isLight icon='Check' color='primary' onClick={() => updateComplaintSubmit(i.complaintDetailsId)} className='border border-primary'>
															</Button>
														</div>
														<div className='col-12 mt-2 border border-light'></div>
													</div>
												))}
											</>
										) : (
											<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>{parentNoDataMsg}</div>
										)}
									</CardBody>
								</Card>
							</div>
						</div>
					</Page>
					<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
				</PageWrapper>
			)}
		</>
	);
}
export default ComplaintList;
