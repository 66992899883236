import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import Page from '../../../layout/Page/Page';
import FormGroup from '../../bootstrap/forms/FormGroup';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Button from '../../bootstrap/Button';
import { toasts } from '../../../services/toast.service';
import { addComplaintDetails, getComplaintType } from '../../../services/complaint.service';
import { showLoader } from '../../../services/loader.services';
import { getLicenseKey } from '../../../services/application.settings';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import AlertService from '../../../services/AlertService';
import { getComplaintDetails, getProfileForStudent } from '../../../services/student.service';
import Quill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Input from '../../bootstrap/forms/Input';
import {
	getColumnsForDataTable,
	profilePic,
	updateColumnsForDataTable,
} from '../../../services/common.service';
import Avatar from '../../Avatar';

const AddComplaint = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getComplaintTypeList();
		if (userTypeId == 9 || userTypeId == 10) {
			getStudentProfile(userAccountId);
		}
		getComplaintList(userTypeId, userAccountId);
	}, []);

	const [complaintType, setComplaintTypeId] = useState<any>('');
	const [complaintTypeData, setComplaintTypeData] = useState<any>([]);
	const [studentDetailsData, setStudentDetailsData] = useState<any>([]);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [natureOfComplaint, setNatureOfComplaint] = useState<any>('');
	const [staffOrStudentDetailsId, setStaffOrStudentDetailsId] = useState<any>('');

	const [complaintDetailsData, setComplaintDetailsData] = useState<any>([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState<any>('');

	const addNatureOfComplaint = (content: any, delta: any, source: any, editor: any) => {
		setNatureOfComplaint(editor.getHTML());
	};

	const complaintTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			natureOfComplaint: '',
			complaintType: '',
		},
		validate: (values) => {
			const errors: {
				natureOfComplaint?: string;
				complaintType?: string;
			} = {};
			if (!natureOfComplaint) {
				errors.natureOfComplaint = 'Required';
			}
			if (isVisible ? !complaintType : !values.complaintType) {
				errors.complaintType = 'Required';
			}

			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			addComplaintSubmit();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => {},
		//validateOnChange: false,
		onSubmit: () => {},
	});

	const SelectComplaint = (e: any) => {
		let complaintType = e;
		setComplaintTypeId(complaintType);
	};

	function getComplaintTypeList() {
		getComplaintType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.complaintType;
					if (data != undefined) {
						setComplaintTypeData(data);
						setIsVisible(true);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setComplaintTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStudentProfile(studentDetailsId: any) {
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile[0];
					if (data != undefined) {
						setStudentDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getComplaintList(userTypeId: any, staffOrStudentDetailsId: any) {
		setDataStatus(false);
		getComplaintDetails(
			userTypeId,
			staffOrStudentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.complaintDetails;
					if (data != undefined) {
						getColumnsForTable('getComplaintDetails', 'get');
						setComplaintDetailsData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						if (userTypeId == 9) {
							let studentComplaints = data.filter(
								(item: any) => item.userTypeId == 9,
							);
							setComplaintDetailsData(studentComplaints);
							setNoDataMsg(studentComplaints == '' ? 'No Complaints' : '');
						} else if (userTypeId == 10) {
							let parentComplaints = data.filter(
								(item: any) => item.userTypeId == 10,
							);
							setComplaintDetailsData(parentComplaints);
							setNoDataMsg(parentComplaints == '' ? 'No Complaints' : '');
						} else {
							let staffComplaints = data.filter(
								(item: any) => item.userTypeId != 9 && item.userTypeId != 10,
							);
							setComplaintDetailsData(staffComplaints);
							setNoDataMsg(staffComplaints == '' ? 'No Complaints' : '');
						}

						let staffComplaints = data.filter(
							(item: any) => item.userTypeId != 9 && item.userTypeId != 10,
						);
						setDataStatus(true);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					//toasts(response.data.message, "Error")
					getColumnsForTable('getComplaintDetails', 'get');
					setComplaintDetailsData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function setComplaintDetails() {
		return {
			studentOrStaffDetailsId: userAccountId,
			standardDetailsId: userTypeId == 9 ? studentDetailsData?.standardDetailsId : 0,
			complaintType: isVisible
				? complaintType?.value
				: complaintTypeForm.values.complaintType,
			natureOfComplaint: natureOfComplaint,
			userTypeId: userTypeId,
			licenseKey: getLicenseKey,
		};
	}

	function addComplaintSubmit() {
		showLoader(true);
		if (complaintTypeForm.isValid) {
			let complaintTypeData = setComplaintDetails();
			addComplaintDetails(
				complaintTypeData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getComplaintTypeList();
						getComplaintList(userTypeId, userAccountId);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (complaintTypeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
							{
								columnVisibilityId: columnVisibilityId,
								isDisplay: isDisplay ? 0 : 1,
							},
						],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getComplaintDetails', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	function closeAndReset() {
		complaintTypeForm.resetForm();
		setComplaintTypeId('');
		setNatureOfComplaint('');
	}

	const [isVisible, setIsVisible] = useState(false);

	const toggleVisibility = () => {
		setIsVisible(!isVisible);
		complaintTypeForm.resetForm();
	};

	return (
		<PageWrapper title=' Add Complaint'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xl-7'>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='Feedback' iconColor='info'>
									<CardTitle>Add Complaint</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row mt-3'>
									<div className='col d-flex align-items-center'>
										<div className=' col-2'>
											<strong> Complaint Type</strong>
										</div>
										<div className=''>
											<strong> : </strong>
										</div>
										<div className='col-8 ms-4 mt-1 justify-content-center'>
											{isVisible ? (
												<FormGroup id='complaintType'>
													<SearchableSelect
														ariaLabel='Complaint Type'
														placeholder='Complaint Type'
														onChange={SelectComplaint}
														value={complaintType}
														onBlur={complaintTypeForm.handleBlur}
														isValid={complaintTypeForm.isValid}
														isTouched={
															complaintTypeForm.touched.complaintType
														}
														invalidFeedback={
															complaintTypeForm.errors.complaintType
														}
														list={complaintTypeData.map(
															(data: any) => ({
																value: data.complaintType,
																label: data.complaintType,
															}),
														)}
													/>
												</FormGroup>
											) : (
												<FormGroup id='complaintType'>
													<Input
														type='text'
														placeholder='Enter Complaint Type'
														onChange={complaintTypeForm.handleChange}
														value={
															complaintTypeForm.values.complaintType
														}
														onBlur={complaintTypeForm.handleBlur}
														isValid={complaintTypeForm.isValid}
														isTouched={
															complaintTypeForm.touched.complaintType
														}
														invalidFeedback={
															complaintTypeForm.errors.complaintType
														}
													/>
												</FormGroup>
											)}
										</div>
										<div className='col-1 ms-1'>
											<FormGroup>
												<Button
													className='mt-1'
													color='primary'
													icon='Add'
													isLight
													hoverShadow='lg'
													size={'sm'}
													onClick={toggleVisibility}
												/>
											</FormGroup>
										</div>
									</div>
								</div>

								<div className='row mt-4'>
									<div className='col d-flex'>
										<div className=' col-2'>
											<strong> Nature Of Complaint</strong>
										</div>
										<div className=''>
											<strong> : </strong>
										</div>
										<div className='col-9 ms-4'>
											<Quill
												style={{ height: '28vh' }}
												value={natureOfComplaint}
												onChange={addNatureOfComplaint}
												placeholder='Nature Of Complaint'
											/>
											{/* <FormGroup
										id='natureOfComplaint'
										label='Enter Complaint'
										isFloating>
										<Textarea
											rows={6}
											placeholder='Enter Complaint'
											onChange={complaintTypeForm.handleChange}
											value={complaintTypeForm.values.natureOfComplaint}
											style={{ height: '200px', width: '105%' }}
											onBlur={complaintTypeForm.handleBlur}
											isValid={complaintTypeForm.isValid}
											isTouched={complaintTypeForm.touched.natureOfComplaint}
											invalidFeedback={
												complaintTypeForm.errors.natureOfComplaint
											}
										/>
									</FormGroup> */}
										</div>
									</div>
								</div>
							</CardBody>
							<CardFooter>
								<div className='col-12 mt-4 d-flex justify-content-end'>
									<Button
										className='mt-4'
										color='info'
										isLight
										isDisable={natureOfComplaint == '' ? true : false}
										onClick={complaintTypeForm.handleSubmit}
										icon='Save'>
										Submit
									</Button>
								</div>
							</CardFooter>
						</Card>
					</div>

					<div className='col-xl-5'>
						<Card stretch className='overflow-hidden border border-light' shadow='md'>
							{' '}
							<CardHeader borderSize={1}>
								<CardLabel icon='Chat' iconColor='info'>
									{userTypeId == 9 ? (
										<CardTitle tag='div' className='h5'>
											Student Complaints
										</CardTitle>
									) : null}

									{userTypeId == 10 ? (
										<CardTitle tag='div' className='h5'>
											Parent Complaints
										</CardTitle>
									) : null}
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable style={{ height: 70 }}>
								{complaintDetailsData != '' ? (
									<>
										{complaintDetailsData.map((i: any, index: any) => (
											<div
												className={index > 0 ? 'row g-4 mt-1' : 'row g-4'}
												key={i.complaintDetailsId}>
												<div className='col-12 d-flex'>
													<div className='flex-shrink-0'>
														{i.profilePath != null ? (
															<Avatar src={i.profilePath} size={64} />
														) : (
															<img
																src={profilePic(i.genderId)}
																width='64'
																height='64'
															/>
														)}
													</div>
													<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
														<figure className='mb-0'>
															<div>
																<strong className='text-dark'>
																	{i.studentOrStaffName} -{' '}
																</strong>
																{i.studentRollNoOrEmpNumber}
															</div>
															<div className='text-info fw-bold mt-1 text-uppercase'>
																{i.complaintType}
															</div>
															<blockquote className='blockquote mt-1'>
																<div
																	className='fs-6'
																	dangerouslySetInnerHTML={{
																		__html: i.natureOfComplaint,
																	}}
																/>
															</blockquote>
															<figcaption className='blockquote-footer mb-0'>
																{i.complaintDateForView}
															</figcaption>
														</figure>
													</div>
												</div>
												<div className='col-12 mt-2 border border-light'></div>
											</div>
										))}
									</>
								) : (
									<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
										{noDataMsg}
									</div>
								)}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default AddComplaint;
