import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import NoDataMsg from "../../../common/components/NoDataMsg";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import SearchableSelect from "../../../common/components/SearchableSelect";
import AuthContext from "../../../contexts/authContext";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getLicenseKey } from "../../../services/application.settings";
import { getCurrentDateAndTime, convertDateToEpoch, updateFilter, getColumnsForDataTable, updateColumnsForDataTable, getStandardDetails, getMedium } from "../../../services/common.service";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../services/ExportService";
import { getFeeType } from "../../../services/fee.service";
import { TableLoader } from "../../../services/loader.services";
import { getBatchMasterDetails, getSectionDetails } from "../../../services/master.service";
import { getFeesCollectionListByDate, getFeeCollectionReportByPaymentType, getDailyFeeCollectionReport, getDailyFeeCollectionDeletedReport, getOverAllPendingFees, getStudentFeeDetailsByFeeTypeId, getStudentPendingFeeReport } from "../../../services/report.service";
import { toasts } from "../../../services/toast.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from "../../bootstrap/Card";
import Collapse from "../../bootstrap/Collapse";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Modal, { ModalHeader, ModalTitle, ModalBody } from "../../bootstrap/Modal";
import { dataPagination } from "../../PaginationButtons";
import Input from "../../bootstrap/forms/Input";
import Icon from "../../icon/Icon";

function OverAllFeeReport() {

    useEffect(() => {
        getBatchList();
        getFeeTypeList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);

    const [isReport, setIsReport] = useState('')
    const { darkModeStatus } = useDarkMode();

    const [feesSummaryData, setFeesSummaryData] = useState<any>([])
    const [paymentTypeData, setPaymentTypeData] = useState<any>([])
    const [dailyFeeCollectionData, setDailyFeeCollectionData] = useState<any>([])
    const [dailyFeeCollectionDeletedData, setDailyFeeCollectionDeletedData] = useState<any>([])
    const [feeTypeData, setFeeTypeData] = useState<any>([])
    const [overAllPendingFeesData, setOverAllPendingFeesData] = useState<any>([])
    const [labCoteDetailsData, setLabCoteDetailsData] = useState<any>([])
    const [pendingFeesData, setPendingFeesData] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(labCoteDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [feeTypeId, setFeeTypeId] = useState<any>('')
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')

    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])
    const [standardDetailsData, setStandardDetailsData] = useState<any>([])

    const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
    const [paymentTypeColumnVisibilityData, setPaymentTypeColumnVisibilityData] = useState<any>([]);
    const [pendingFeeColumnVisibilityData, setPendingFeeColumnVisibilityData] = useState<any>([]);
    const [overAllPendingColumnVisibilityData, setOverAllPendingColumnVisibilityData] = useState<any>([]);
    const [labCoatColumnVisibilityData, setLabCoatColumnVisibilityData] = useState<any>([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const contentRef = useRef(null);
    const modalContent = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    const [noDataMsg, setNoDataMsg] = useState('')

    const [isOpenListCard, setIsOpenListCard] = useState(true)

    const [reportTypeId, setReportTypeId] = useState<any>('')
    const [reportTypeIdValue, setReportTypeIdValue] = useState<any>('')

    const [deletedReportModal, setDeletedReportModal] = useState(false)

    const selectReportType = (e: any) => {
        setIsReport('')
        setDataStatus(false)
        setIsLoader(false)
        tabEmpty()
        let reportTypeId = e
        setReportTypeId(reportTypeId?.value)
        setReportTypeIdValue(reportTypeId)
        setIsOpenListCard(true)
    }

    const feesSummaryForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            fromDate: getCurrentDateAndTime('date'),
            toDate: getCurrentDateAndTime('date'),
            feesCollectedDate: '',
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const dailyReportForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            fromDate: getCurrentDateAndTime('date'),
            toDate: getCurrentDateAndTime('date'),
            feeTypeId: '',
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
                feeTypeId?: any;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            if (!feeTypeId?.value) {
                errors.feeTypeId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewFeeSummaryList() {
        setIsReport('')
        setIsLoader(true);
        let fromDate = feesSummaryForm.values.fromDate
        let toDate = feesSummaryForm.values.toDate
        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)
        getFeesSummaryList(fromDateInMilliSec, toDateInMilliSec)
        console.log(fromDateInMilliSec);

    }

    function viewDailyReportList() {
        setIsReport('')
        setIsLoader(true);

        //let feeTypeId1 = feeTypeId
        let fromDate = dailyReportForm.values.fromDate
        let toDate = dailyReportForm.values.toDate
        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)

        getDailyFeeCollectionReportList(fromDateInMilliSec, toDateInMilliSec, feeTypeId?.value, 0)
        getDailyFeeCollectionDeletedReportList(fromDateInMilliSec, toDateInMilliSec, feeTypeId?.value, 1);
    }

    function viewModal() {
        setDeletedReportModal(true)
    }

    const viewPendingFeesList = () => {
        setIsReport('')
        setIsLoader(true);
        getStudentPendingFeeReportList(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value, feeTypeId?.value);
    }

    const viewOverAllPendingFeesList = () => {
        setIsReport('')
        setIsLoader(true);
        getOverAllPendingFeesList();
    }

    const viewLabCoatDetailsList = () => {
        setIsReport('')
        setIsLoader(true);
        getLabCoatDetails();
    }

    const filteredData = updateFilter(labCoteDetailsData, labCoatColumnVisibilityData, columnVisibilityForm);

    function getFeesSummaryList(fromDate: any, toDate: any) {
        setIsLoader(true);
        getFeesCollectionListByDate(fromDate, toDate,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.feesCollectionListByDate;
                    if (data != undefined) {
                        setIsLoader(false);
                        setFeesSummaryData(data)
                        setIsReport('isFeeSummary')
                        getColumnsForTable('getFeesCollectionListByDate', 'get');
                        setIsOpenListCard(false)
                        getFeeCollectionReportByPaymentTypeWiseList(userAccountId, fromDate, toDate)

                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }

                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    setIsReport('isFeeSummary')

                    getColumnsForTable('getFeesCollectionListByDate', 'get');
                    setIsOpenListCard(false)
                    setFeesSummaryData([]);
                    getFeeCollectionReportByPaymentTypeWiseList(userAccountId, fromDate, toDate)
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getFeeCollectionReportByPaymentTypeWiseList(userAccountId: number, fromDate: any, toDate: any) {
        getFeeCollectionReportByPaymentType(userAccountId, fromDate, toDate,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.feeCollectionReportByPaymentType;
                    if (data != undefined) {
                        setPaymentTypeData(data)
                        getColumnsForPaymentTypeReport('getFeeCollectionReportByPaymentType', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    getColumnsForPaymentTypeReport('getFeeCollectionReportByPaymentType', 'get');
                    setPaymentTypeData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDailyFeeCollectionReportList(fromDate: any, toDate: any, feeTypeId: any, isDeleted: any) {
        setIsLoader(true);
        getDailyFeeCollectionReport(fromDate, toDate, feeTypeId, isDeleted,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dailyFeeCollectionReport;
                    if (data != undefined) {
                        setIsLoader(false);
                        setDailyFeeCollectionData(data);
                        setIsReport('isDailyReport')
                        setIsOpenListCard(false)
                        getColumnsForTable('getDailyFeeCollectionReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }

                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setIsReport('isDailyReport')
                    getColumnsForTable('getDailyFeeCollectionReport', 'get');
                    setIsOpenListCard(false)
                    setDailyFeeCollectionData([]);
                    setNoDataMsg(response.data.message)
                    setIsLoader(false);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getDailyFeeCollectionDeletedReportList(fromDate: any, toDate: any, feeTypeId: any, isDeleted: any) {
        setIsLoader(true);
        getDailyFeeCollectionDeletedReport(fromDate, toDate, feeTypeId, isDeleted,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dailyFeeCollectionReport;
                    if (data != undefined) {
                        setIsLoader(false);
                        setDailyFeeCollectionDeletedData(data);
                        setIsReport('isDailyReport')
                        setIsOpenListCard(false)
                        getColumnsForTable('getDailyFeeCollectionReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    setIsReport('isDailyReport')
                    getColumnsForTable('getDailyFeeCollectionReport', 'get');
                    setIsOpenListCard(false)
                    setDailyFeeCollectionDeletedData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getOverAllPendingFeesList() {
        setDataStatus(false)
        getOverAllPendingFees(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.overAllPendingFees;
                    if (data != undefined) {
                        setIsLoader(false);
                        setDataStatus(true)
                        setOverAllPendingFeesData(data);
                        setIsOpenListCard(false)
                        setIsReport('isOverAllPendingFee')
                        getColumnsForGetOverAllPendingFees("getOverAllPendingFees", 'get')
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    setIsReport('isOverAllPendingFee')
                    getColumnsForGetOverAllPendingFees("getOverAllPendingFees", 'get')
                    setIsOpenListCard(false)
                    setOverAllPendingFeesData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getLabCoatDetails() {
        setDataStatus(false)
        getStudentFeeDetailsByFeeTypeId(
            (response) => {
                if (response.data.success) {
                    setIsLoader(false);
                    let data = response.data.data.studentFeeDetailsByFeeTypeId;
                    if (data != undefined) {
                        setDataStatus(true)
                        setLabCoteDetailsData(data);
                        setIsReport('isLabCoat')
                        setIsOpenListCard(false)
                        getColumnsForLabCoat("getStudentFeeDetailsByFeeTypeId", 'get')
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataStatus(true)
                    // toasts(response.data.message, "Error")
                    setIsReport('isLabCoat')
                    getColumnsForLabCoat("getStudentFeeDetailsByFeeTypeId", 'get')
                    setIsOpenListCard(false)
                    setLabCoteDetailsData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getFeeTypeList() {
        getFeeType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.feeType;
                    if (data != undefined) {
                        const feeTypeData = [{ feeTypeId: 0, feeType: 'Select All' }];
                        data.forEach((obj: { feeTypeId: number; feeType: string }) => {
                            feeTypeData.push(obj);
                        });
                        setFeeTypeData(feeTypeData);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setFeeTypeData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const selectBatch = (e: any) => {
        setStandardDetailsId('')
        setMediumId('')
        setSectionDetailsId('')
        setFeeTypeId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        getMediumList()
    }

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        setFeeTypeId('')
        let mediumId = e
        setMediumId(mediumId)
        getStandardList()
    }

    const selectStandard = (e: any) => {
        setStandardDetailsId('')
        setFeeTypeId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (standardDetailsId?.value != undefined) {
            getSectionList(mediumId?.value, standardDetailsId?.value)
        }
    }

    const selectSection = (e: any) => {
        setFeeTypeId('')
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const selectFeeType = (e: any) => {
        let feeTypeId = e
        setFeeTypeId(feeTypeId)
    }

    function getStudentPendingFeeReportList(courseId: any, batchMasterId: any, semesterDetailsId: any, feeTypeId: any) {
        setIsLoader(true);
        getStudentPendingFeeReport(courseId, batchMasterId, semesterDetailsId, feeTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentPendingFeeReport;
                    if (data != undefined) {
                        setIsLoader(false);
                        setPendingFeesData(data);
                        setIsOpenListCard(false)
                        setIsReport('isPendingFee')
                        getColumnsForPendingReport('getStudentPendingFeeReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    setIsReport('isPendingFee')
                    getColumnsForPendingReport('getStudentPendingFeeReport', 'get');
                    setIsOpenListCard(false)
                    setPendingFeesData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [sectionDetailsData, setSectionDetailsData] = useState([])
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')

    function getSectionList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function getColumnsForPaymentTypeReport(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let paymentTypeColumnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setPaymentTypeColumnVisibilityData(paymentTypeColumnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function getColumnsForPendingReport(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let pendingFeeColumnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setPendingFeeColumnVisibilityData(pendingFeeColumnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function getColumnsForGetOverAllPendingFees(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let overAllPendingColumnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setOverAllPendingColumnVisibilityData(overAllPendingColumnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function getColumnsForLabCoat(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let labCoatColumnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setLabCoatColumnVisibilityData(labCoatColumnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForLabCoat('getStudentFeeDetailsByFeeTypeId', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    // Print
    const handlePrintForFeeSummary = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrintDailyReportModal = useReactToPrint({
        content: () => modalContent.current,
    });

    // Print
    const handlePrintForPaymentType = useReactToPrint({
        content: () => contentRef.current,
    });

    function tabEmpty() {
        feesSummaryForm.resetForm()
        dailyReportForm.resetForm()
        setBatchMasterId('')
        setStandardDetailsId('')
        setBatchMasterId('')
        setSectionDetailsId('')
        setFeeTypeId('')
    }

    function closeAndReset() {
        setDeletedReportModal(false)
    }

    const reportTypeData = [{ reportTypeId: 1, reportType: 'Fee Summary' }, { reportTypeId: 2, reportType: 'Daily Report' }, { reportTypeId: 3, reportType: 'Pending Fees' }, { reportTypeId: 4, reportType: 'Over All Pending Fees' }, { reportTypeId: 5, reportType: 'Lab Coat' }]

    return (
        <PageWrapper title="Over All Fee Report" >
            <Page container='fluid' >
                <Collapse isOpen={isOpenListCard}>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='list' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Over All Fee Report
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody className="d-print-none">
                            <div className="row">
                                <div className="col-lg-3">

                                    <FormGroup id="reportTypeId" label="Report Type" isFloating>
                                        <SearchableSelect placeholder="Report Type" isFloating
                                            ariaLabel='Report Type'
                                            onChange={selectReportType}
                                            value={reportTypeIdValue}
                                            list={reportTypeData.map((data: any) => (
                                                { value: data.reportTypeId, label: data.reportType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>

                                {reportTypeId == '1' ?
                                    <>
                                        <div className="col-3">
                                            <FormGroup id="fromDate" label="From Date" isFloating>
                                                <Input
                                                    type='date'
                                                    ariaLabel='From Date'
                                                    onChange={feesSummaryForm.handleChange}
                                                    onBlur={feesSummaryForm.handleBlur}
                                                    value={feesSummaryForm.values.fromDate}
                                                    isValid={feesSummaryForm.isValid}
                                                    isTouched={feesSummaryForm.touched.fromDate}
                                                    invalidFeedback={feesSummaryForm.errors.fromDate}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-3 ">
                                            <FormGroup id="toDate" label="To Date" isFloating>
                                                <Input
                                                    type='date'
                                                    ariaLabel='To Date'
                                                    onChange={feesSummaryForm.handleChange}
                                                    onBlur={feesSummaryForm.handleBlur}
                                                    value={feesSummaryForm.values.toDate}
                                                    isValid={feesSummaryForm.isValid}
                                                    isTouched={feesSummaryForm.touched.toDate}
                                                    invalidFeedback={feesSummaryForm.errors.toDate}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-3 d-flex align-self-center">
                                            <Button icon='ArrowDownward' color='primary' onClick={viewFeeSummaryList}>View</Button>
                                        </div>
                                    </> : null}
                                {reportTypeId == '2' ?
                                    <>
                                        <div className="col-lg-3">
                                            <FormGroup id="feeTypeId" label="Fee Type" isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Fee Type'
                                                    onChange={selectFeeType}
                                                    value={feeTypeId}
                                                    list={feeTypeData.map((data: any) => (
                                                        { value: data.feeTypeId, label: data.feeType }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-2">
                                            <FormGroup id="fromDate" label="From Date" isFloating>
                                                <Input
                                                    type='date'
                                                    ariaLabel='From Date'
                                                    onChange={dailyReportForm.handleChange}
                                                    onBlur={dailyReportForm.handleBlur}
                                                    value={dailyReportForm.values.fromDate}
                                                    isValid={dailyReportForm.isValid}
                                                    isTouched={dailyReportForm.touched.fromDate}
                                                    invalidFeedback={dailyReportForm.errors.fromDate}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-2">
                                            <FormGroup id="toDate" label="To Date" isFloating>
                                                <Input
                                                    type='date'
                                                    ariaLabel='To Date'
                                                    onChange={dailyReportForm.handleChange}
                                                    onBlur={dailyReportForm.handleBlur}
                                                    value={dailyReportForm.values.toDate}
                                                    isValid={dailyReportForm.isValid}
                                                    isTouched={dailyReportForm.touched.toDate}
                                                    invalidFeedback={dailyReportForm.errors.toDate}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-2 align-self-center mt-1">
                                            <FormGroup >
                                                <Button icon="South" color="primary" isDisable={feeTypeId?.value == undefined ? true : false}
                                                    onClick={viewDailyReportList}>View</Button>
                                            </FormGroup>
                                        </div>
                                    </>
                                    : null}
                                {reportTypeId == '3' ?
                                    <>
                                        <div className='col-3'>
                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Batch'
                                                    onChange={selectBatch}
                                                    value={batchMasterId}
                                                    list={batchMasterData.map((data: any) => (
                                                        { value: data.batchMasterId, label: data.batchName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div>
                                        {batchMasterId ? <div className='col-3'>
                                            <FormGroup id='mediumId' label='Medium' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Medium'
                                                    onChange={selectMedium}
                                                    value={mediumId}
                                                    list={mediumData.map((data: any) => (
                                                        { value: data.mediumId, label: data.medium }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {mediumId ? <div className='col-3'>
                                            <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Standard'
                                                    onChange={selectStandard}
                                                    value={standardDetailsId}
                                                    list={standardDetailsData.map((data: any) => (
                                                        { value: data.standardDetailsId, label: data.standardName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {standardDetailsId ? <div className='col-3 mt-4'>
                                            <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Section'
                                                    onChange={selectSection}
                                                    value={sectionDetailsId}
                                                    list={sectionDetailsData.map((data: any) => (
                                                        { value: data.sectionDetailsId, label: data.sectionName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {sectionDetailsId ? <div className='col-3 mt-4'>
                                            <FormGroup id="feeTypeId" label="Fee Type" isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Fee Type'
                                                    onChange={selectFeeType}
                                                    value={feeTypeId}
                                                    list={feeTypeData.map((data: any) => (
                                                        { value: data.feeTypeId, label: data.feeType }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {feeTypeId ? <div className="col-3 d-flex align-self-center mt-4">
                                            <FormGroup>
                                                <Button icon='ArrowDownward' color='primary' onClick={viewPendingFeesList}>View</Button>
                                            </FormGroup>
                                        </div> : null}
                                    </>
                                    : null}
                                {reportTypeId == '4' ?
                                    <>
                                        <div className="col-lg-3 align-self-center">
                                            <FormGroup >
                                                <Button icon="South" color="primary"
                                                    onClick={viewOverAllPendingFeesList}>View</Button>
                                            </FormGroup>
                                        </div>
                                    </>
                                    : null}
                                {reportTypeId == '5' ?
                                    <>
                                        <div className="col-lg-3 align-self-center">
                                            <FormGroup >
                                                <Button icon="South" color="primary"
                                                    onClick={viewLabCoatDetailsList}>View</Button>
                                            </FormGroup>
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {isReport == 'isFeeSummary' && reportTypeId == '1' && (
                    <>
                        <Card stretch ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>Date Wise Fee Summary List</CardTitle>
                                </CardLabel>

                                <CardActions className="d-print-none">
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('FeeSummary', feesSummaryData, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(feesSummaryData, columnVisibilityData, 'FeeSummary')}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('FeeSummary', feesSummaryData, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrintForFeeSummary}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <>
                                    <table className='table table-modern table-hover text-center  text-nowrap'>
                                        <thead>
                                            <tr className='table-primary' style={{ textAlign: 'center' }}>
                                                <td colSpan={13} style={{
                                                    fontFamily: 'Bookman Old Style',
                                                    color: 'rgb(61, 57, 57)'
                                                }}>
                                                    <h5>Date wise Summary Report At {feesSummaryForm.values.fromDate} to {feesSummaryForm.values.toDate}</h5>
                                                </td>
                                            </tr>
                                            <tr style={{ height: '20px' }}></tr>
                                            <tr className='table-success text-decoration-underline mt-4' >
                                                <td width="5px"><strong>S.NO</strong></td>
                                                <td width="5x"><strong>DATE</strong></td>
                                                <td width="10px"><strong>TUITION</strong></td>
                                                <td width="10px"><strong>TRANSPORT</strong></td>
                                                <td width="10px"><strong>LAB</strong></td>
                                                <td width="10px"><strong>FINE</strong></td>
                                                <td width="10px"><strong>EXAM</strong></td>
                                                <td width="10px"><strong>FORM</strong></td>
                                                {/* <td width="10px"><strong>Application</strong></td> */}
                                                <td width="10px"><strong>OTHER</strong></td>
                                                <td width="10px"><strong>MATRICULATE</strong></td>
                                                <td width="10px"><strong>SEMESTER</strong></td>
                                                <td width="10px"><strong>Total</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {feesSummaryData != "" ?
                                                <>
                                                    {feesSummaryData.map((feesCollection: any, index: any) => (
                                                        <tr key={index}>
                                                            <td>{feesCollection.sno}</td>
                                                            <td>{feesCollection.feesCollectedDate}</td>
                                                            <td>{feesCollection.tutionFees}</td>
                                                            <td>{feesCollection.transportFees}</td>
                                                            <td>{feesCollection.labCoatFees}</td>
                                                            <td>{feesCollection.fineFees}</td>
                                                            <td>{feesCollection.examFees}</td>
                                                            <td>{feesCollection.applicationFees}</td>
                                                            <td>{feesCollection.otherFees}</td>
                                                            <td>{feesCollection.matriculateFees}</td>
                                                            <td>{feesCollection.extraFees}</td>
                                                            <td><strong>{feesCollection.totalAmount}</strong></td>
                                                        </tr>
                                                    ))
                                                    } </>
                                                :
                                                <NoDataMsg columnsCount={12} msg={noDataMsg} />}
                                            <tr className='table-primary'>
                                                <td colSpan={2}>
                                                    <strong style={{ float: 'right' }}>TOTAL OUTSTANDING</strong>
                                                </td>
                                                <td><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.tutionFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                                <td ><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.transportFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                                <td ><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.labCoatFees).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                <td ><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.fineFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                                <td ><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.examFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                                <td ><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.applicationFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                                <td ><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.otherFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                                <td ><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.matriculateFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                                <td ><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.extraFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                                <td ><strong>{feesSummaryData.length > 0 ? feesSummaryData.map((data: any) => data.totalAmount).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6 className="mg-lg-t-10">Date : {getCurrentDateAndTime('dateAndTime')}</h6>

                                </>
                            </CardBody>
                        </Card>

                        <Card stretch ref={contentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>Payment wise Fee Summary List</CardTitle>
                                </CardLabel>

                                <CardActions className="d-print-none">
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("PaymentWiseFeeSummaryList", paymentTypeData, paymentTypeColumnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(paymentTypeData, paymentTypeColumnVisibilityData, "PaymentWiseFeeSummaryList")}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("PaymentWiseFeeSummaryList", paymentTypeData, paymentTypeColumnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrintForPaymentType}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>

                            <CardBody className="table-responsive" >
                                <>
                                    <table className="table table-modern table-hover text-center text-nowrap text-md-nowrap mg-b-0">
                                        <thead>
                                            <tr style={{ textAlign: 'center' }} className='table-primary'>
                                                <td colSpan={13}
                                                    style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)' }}>
                                                    <h5>Payment Wise Summary Report At {feesSummaryForm.values.fromDate} to {feesSummaryForm.values.toDate}</h5>
                                                </td>
                                            </tr>
                                            <tr style={{ height: '20px' }}></tr>
                                            <tr className='table-success text-decoration-underline'>
                                                <td width="5px"><strong>S.No</strong></td>
                                                <td width="5x"><strong>Date</strong></td>
                                                <td width="10px"><strong>Type</strong></td>
                                                <td width="10px"><strong>Amount</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentTypeData != "" ?
                                                <>
                                                    {paymentTypeData.map((paymentData: any, index: any) => (
                                                        <tr key={index}>
                                                            <td>{paymentData.sno}</td>
                                                            <td>{paymentData.receiptDate}</td>
                                                            <td>{paymentData.paymentType}</td>
                                                            <td><strong>{paymentData.totalCollected}</strong></td>
                                                        </tr>
                                                    ))
                                                    } </>
                                                :
                                                <NoDataMsg columnsCount={4} msg={noDataMsg} />}
                                            <tr className='table-primary'>
                                                <td colSpan={3} >
                                                    <strong style={{ float: 'right' }}>TOTAL </strong>
                                                </td>
                                                <td ><strong>{paymentTypeData.map((data: any) => data.totalCollected > 0 ? data.totalCollected : 0).reduce((a: any, b: any) => a + b, 0)}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6 className="mg-lg-t-10">Date : {getCurrentDateAndTime('dateAndTime')}</h6>
                                </>
                            </CardBody>
                        </Card>
                    </>
                )}

                {isReport == 'isDailyReport' && reportTypeId == '2' && (
                    <>
                        <Card stretch ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>Daily Fee Details List</CardTitle>
                                </CardLabel>

                                <CardActions className="d-print-none">
                                    <Button isLight color="success" onClick={viewModal}>
                                        Deleted Report
                                    </Button>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('DailyFeeDetailsList', dailyFeeCollectionData, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(dailyFeeCollectionData, columnVisibilityData, 'DailyFeeDetailsList')}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('DailyFeeDetailsList', dailyFeeCollectionData, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrintForFeeSummary}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <>
                                    <table className='table table-modern table-hover text-center text-nowrap'>
                                        {/* <thead>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <td className="bg-info text-white" colSpan={13}
                                                        style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)' }}>
                                                        <h5> Fee Type : Select All- Date : ( {dailyReportForm.values.fromDate} -
                                                            {dailyReportForm.values.toDate})</h5>
                                                    </td>
                                                </tr>
                                            </thead> */}
                                        <thead>
                                            <tr className='table-primary' style={{ textAlign: 'center' }}>
                                                <td colSpan={13} style={{
                                                    fontFamily: 'Bookman Old Style',
                                                    color: 'rgb(61, 57, 57)'
                                                }}>
                                                    <h5>FEE TYPE  : {feeTypeId?.label} - DATE : ({dailyReportForm.values.fromDate} to {dailyReportForm.values.toDate})</h5>
                                                </td>
                                            </tr>
                                            <tr style={{ height: '20px' }}></tr>
                                            <tr className='table-primary text-decoration-underline'>
                                                <td className="text-center"><strong>S.No</strong></td>
                                                <td className="text-center"><strong>Admission No</strong></td>
                                                <td className="text-center"><strong>Student Name</strong></td>
                                                <td className="text-center"><strong>Receipt<br />Number</strong></td>
                                                <td className="text-center"><strong>Receipt Date</strong></td>
                                                <td className="text-center"><strong>Fee Type</strong></td>
                                                <td className="text-center"><strong>Payment<br />Type</strong></td>
                                                <td className="text-center"><strong>Collected<br />Fees</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dailyFeeCollectionData != "" ?
                                                <>
                                                    {dailyFeeCollectionData.map((feesData: any) => (
                                                        <tr key={feesData.receiptNo}>
                                                            <td>{feesData.sno}</td>
                                                            <td>{feesData.admissionNo}<br />{feesData.uniRegNo}</td>
                                                            <td>{feesData.studentName}</td>
                                                            <td>{feesData.receiptNo}</td>
                                                            <td>{feesData.receiptDate}</td>
                                                            <td>{feesData.feeType}</td>
                                                            <td>{feesData.paymentType}</td>
                                                            <td>{feesData.collectedFees}</td>
                                                        </tr>
                                                    ))
                                                    } </>
                                                :
                                                <NoDataMsg columnsCount={8} msg={noDataMsg} />}
                                            <tr className='table-primary'>
                                                <td colSpan={7} ><strong style={{ float: 'right' }}>TOTAL</strong></td>
                                                <td><strong>{dailyFeeCollectionData.length > 0 ? dailyFeeCollectionData.map((data: any) => data.collectedFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6 className="mg-lg-t-10">Date : {getCurrentDateAndTime('dateAndTime')}</h6>
                                </>
                            </CardBody>
                        </Card>
                    </>
                )}

                {isReport == 'isPendingFee' && reportTypeId == '3' && (
                    <>
                        <Card stretch ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>Pending Fee List</CardTitle>
                                </CardLabel>

                                <CardActions className="d-print-none">
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('PendingFeeList', pendingFeesData, pendingFeeColumnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(pendingFeesData, pendingFeeColumnVisibilityData, 'PendingFeeList')}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('PendingFeeList', pendingFeesData, pendingFeeColumnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrintForFeeSummary}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <>
                                    <table className='table table-modern table-hover text-center text-nowrap'>
                                        <thead>
                                            {/* <tr style={{ textAlign: 'center' }}>
                                                    <td className="bg-info text-white" colSpan={13}
                                                        style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)' }}>
                                                        <h5>Department / Course : {{  }} - {{ courseName }} ( {{ semesterNumber }} )
                                                        </h5>
                                                    </td>
                                                </tr> */}
                                            <tr className='table-primary' style={{ textAlign: 'center' }}>
                                                <td colSpan={13} style={{
                                                    fontFamily: 'Bookman Old Style',
                                                    color: 'rgb(61, 57, 57)'
                                                }}>
                                                    <h5>STANDARD / SECTION : {batchMasterId?.label} - {standardDetailsId?.label} ({sectionDetailsId?.label})</h5>
                                                </td>
                                            </tr>
                                            <tr style={{ height: '20px' }}></tr>
                                            <tr className='table-primary text-decoration-underline'>
                                                <td ><strong>S.No</strong></td>
                                                <td ><strong>Admission No</strong></td>
                                                <td ><strong>Roll No</strong></td>
                                                <td ><strong>Student Name</strong></td>
                                                <td >
                                                    <strong>Term Fees <br />(Total)<br />(Paid)<br /> (Pending)</strong>
                                                </td>
                                                <td >
                                                    <strong>Bus Fees<br />(Total)<br />(Paid)<br /> (Pending)</strong>
                                                </td>
                                                <td >
                                                    <strong>Other Fees<br />(Total)<br />(Paid)<br /> (Pending)</strong>
                                                </td>
                                                <td ><strong>Total<br />Pending<br />Fees</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pendingFeesData != "" ?
                                                <>
                                                    {pendingFeesData.map((student: any, index: any) => (
                                                        <tr key={index}>
                                                            <td>{student.sno}</td>
                                                            <td>{student.admissionNo}</td>
                                                            <td>{student.rollNo}</td>
                                                            <td>{student.studentName}</td>
                                                            <td>
                                                                <strong>{student.termFees_grossPayable}</strong><br />
                                                                {student.termFees_amountPaid + student.termFees_concession}<br />
                                                                <strong>
                                                                    {student.termFees_netPayable === 0 ? <div className="text-success">0</div> : <div className="text-danger">{student.termFees_netPayable}</div>}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>{student.busFees_grossPayable}</strong><br />
                                                                {student.busFees_amountPaid + student.busFees_concession}<br />
                                                                <strong>
                                                                    {student.busFees_netPayable === 0 ?
                                                                        <div className="text-success">0</div>
                                                                        :
                                                                        <div className="text-danger">{student.busFees_netPayable}</div>}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>{student.extraFees_grossPayable}</strong><br />
                                                                {student.extraFees_amountPaid + student.extraFees_concession}<br />
                                                                <strong>
                                                                    {student.extraFees_netPayable === 0 ? <div className="text-success">0</div> : <div className="text-danger">{student.extraFees_netPayable}</div>}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    {student.totalPendingFees === 0 ? <div className="text-success">0</div> : <div className="text-danger">{student.totalPendingFees}</div>}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    } </>
                                                :
                                                <NoDataMsg columnsCount={9} msg={noDataMsg} />}
                                            <tr className='table-primary'>
                                                <td colSpan={4} ><strong style={{ float: 'right' }}>TOTAL OUTSTANDING</strong></td>
                                                <td ><strong>{pendingFeesData.length > 0 ? pendingFeesData.map((data: any) => data.termFees_netPayable).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                <td ><strong>{pendingFeesData.length > 0 ? pendingFeesData.map((data: any) => data.busFees_netPayable).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                <td ><strong>{pendingFeesData.length > 0 ? pendingFeesData.map((data: any) => data.extraFees_netPayable).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                <td ><strong>{pendingFeesData.length > 0 ? pendingFeesData.map((data: any) => data.totalPendingFees).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6 className="mg-lg-t-10">Date : {getCurrentDateAndTime('dateAndTime')}</h6>
                                </>
                            </CardBody>
                        </Card>
                    </>
                )}

                {isReport == 'isOverAllPendingFee' && reportTypeId == '4' && (
                    <>
                        <Card stretch ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>Over All Pending Fee List</CardTitle>
                                </CardLabel>

                                <CardActions className="d-print-none">
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('OverAllPendingFeeList', overAllPendingFeesData, overAllPendingColumnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(overAllPendingFeesData, overAllPendingColumnVisibilityData, 'OverAllPendingFeeList')}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('OverAllPendingFeeList', overAllPendingFeesData, overAllPendingColumnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrintForFeeSummary}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                {!dataStatus ?
                                    <TableLoader /> :
                                    <>
                                        <table className='table table-modern table-hover text-center text-nowrap'>
                                            <thead>
                                                <tr className='table-primary text-decoration-underline'>
                                                    <td ><strong>S.No</strong></td>
                                                    <td ><strong>Standard</strong></td>
                                                    <td ><strong>Tuition Fees</strong></td>
                                                    <td ><strong>Other Fees</strong></td>
                                                    <td ><strong>Matriculate <br /> Fees</strong></td>
                                                    <td ><strong>Lab Coat</strong></td>
                                                    <td ><strong>Transport <br /> Fees</strong></td>
                                                    <td ><strong>Extra <br /> Fees</strong></td>
                                                    <td ><strong>Over All Pending<br />Fees</strong></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {overAllPendingFeesData != "" ?
                                                    <>
                                                        {overAllPendingFeesData.map((overAllPendingFees: any) => (
                                                            <tr key={overAllPendingFees.standardDetailsId}>
                                                                <td>{overAllPendingFees.sno}</td>
                                                                <td>{overAllPendingFees.standardName}</td>
                                                                <td>{overAllPendingFees.balanceTutionFees}</td>
                                                                <td>{overAllPendingFees.balanceOtherFees}</td>
                                                                <td>{overAllPendingFees.balanceMatriculateFees}</td>
                                                                <td>{overAllPendingFees.balanceLabCoatFees}</td>
                                                                <td>{overAllPendingFees.balanceTransportFees}</td>
                                                                <td>{overAllPendingFees.balanceExtraFees}</td>
                                                                <td>{overAllPendingFees.overAllPendingFees}</td>
                                                            </tr>
                                                        ))
                                                        } </>
                                                    :
                                                    <NoDataMsg columnsCount={9} msg={noDataMsg} />}
                                                <tr className='table-primary'>
                                                    <td colSpan={2} className="text-center"><strong>TOTAL </strong></td>
                                                    <td ><strong>{overAllPendingFeesData.length > 0 ? overAllPendingFeesData.map((data: any) => data.balanceTutionFees).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                    <td ><strong>{overAllPendingFeesData.length > 0 ? overAllPendingFeesData.map((data: any) => data.balanceOtherFees).reduce((a: number, b: number) => a + b, 0) : 0}</strong></td>
                                                    <td ><strong>{overAllPendingFeesData.length > 0 ? overAllPendingFeesData.map((data: any) => data.balanceMatriculateFees).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                    <td ><strong>{overAllPendingFeesData.length > 0 ? overAllPendingFeesData.map((data: any) => data.balanceLabCoatFees).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                    <td ><strong>{overAllPendingFeesData.length > 0 ? overAllPendingFeesData.map((data: any) => data.balanceTransportFees).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                    <td ><strong>{overAllPendingFeesData.length > 0 ? overAllPendingFeesData.map((data: any) => data.balanceExtraFees).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                    <td ><strong>{overAllPendingFeesData.length > 0 ? overAllPendingFeesData.map((data: any) => data.overAllPendingFees).reduce((a: any, b: any) => a + b) : 0}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table><h6 className="mg-lg-t-10">Date : {getCurrentDateAndTime('dateAndTime')}</h6>
                                    </>
                                }

                            </CardBody>
                        </Card>
                    </>
                )}

                {isReport == 'isLabCoat' && reportTypeId == '5' && (
                    <>
                        <Card stretch ref={contentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>Lab Coat Fee Collected Report</CardTitle>
                                </CardLabel>&nbsp;&nbsp;

                                <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                </ButtonGroup>

                                <CardActions className="d-print-none">
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForLabCoat('getStudentFeeDetailsByFeeTypeId', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("LabCoatFeeDetails", labCoteDetailsData, labCoatColumnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(labCoteDetailsData, labCoatColumnVisibilityData, "LabCoatFeeDetails")}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("LabCoatFeeDetails", labCoteDetailsData, labCoatColumnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrintForPaymentType}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive' isScrollable>
                                {!dataStatus ?
                                    <TableLoader /> :
                                    <table className='table table-modern table-hover text-center'>
                                        <thead>
                                            <tr className='table-primary'>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                {labCoatColumnVisibilityData.map((column: any) => (
                                                    <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                        className='cursor-pointer text-decoration-underline'>
                                                        {column.columnName}
                                                        <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData != "" ?
                                                <>
                                                    {filteredData.map((rowData: any) => (
                                                        <tr key={rowData.studentFeeCollectionId}>
                                                            <td>{rowData.sno}</td>
                                                            {labCoatColumnVisibilityData.map((column: any) => (
                                                                (column.isDisplay && rowData[column.keyName]) ?
                                                                    <td key={`${rowData.studentFeeCollectionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                            ))}
                                                        </tr>
                                                    ))
                                                    } </>
                                                :
                                                <NoDataMsg columnsCount={columnVisibilityData.length + 7} msg={noDataMsg} />}
                                        </tbody>
                                    </table>
                                }
                            </CardBody>
                            {/* <PaginationButtons
                                className='d-print-none'
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            /> */}
                        </Card>
                    </>
                )}
            </Page>

            <Modal
                titleId='deletedReportModal'
                isOpen={deletedReportModal}
                setIsOpen={setDeletedReportModal}
                isStaticBackdrop={true}
                isCentered
                isScrollable
                size='xl' >
                <ModalHeader setIsOpen={setDeletedReportModal} onClick={closeAndReset}>
                    <ModalTitle id='deletedReportModal' className="flex-grow-1">Deleted Receipt</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Card shadow='none' ref={modalContent}>
                        <CardTitle tag='div' className='h5 print-only'>Deleted Receipt</CardTitle>

                        <CardActions className="d-print-none ms-auto">
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('Daily Fee Details List', dailyFeeCollectionData, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(dailyFeeCollectionData, columnVisibilityData, 'Daily Fee Details List')}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('Daily Fee Details List', dailyFeeCollectionData, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrintDailyReportModal}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardActions>
                        <CardBody>
                            <table className='table table-modern table-hover text-center text-nowrap'>
                                <thead>
                                    <tr className='table-primary' style={{ textAlign: 'center' }}>
                                        <td colSpan={13} style={{
                                            fontFamily: 'Bookman Old Style',
                                            color: 'rgb(61, 57, 57)'
                                        }}>
                                            <h5>FEE TYPE  : {feeTypeId?.label} - DATE : ({dailyReportForm.values.fromDate} to {dailyReportForm.values.toDate})</h5>
                                        </td>
                                    </tr>
                                    <tr style={{ height: '20px' }}></tr>
                                    <tr className='table-primary text-decoration-underline'>
                                        <td className="text-center"><strong>S.No</strong></td>
                                        <td className="text-center"><strong>Admission No</strong></td>
                                        <td className="text-center"><strong>Student Name</strong></td>
                                        <td className="text-center"><strong>Receipt<br />Number</strong></td>
                                        <td className="text-center"><strong>Receipt Date</strong></td>
                                        <td className="text-center"><strong>Fee Type</strong></td>
                                        <td className="text-center"><strong>Payment<br />Type</strong></td>
                                        <td className="text-center"><strong>Collected<br />Fees</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dailyFeeCollectionDeletedData != "" ?
                                        <>
                                            {dailyFeeCollectionDeletedData.map((feesData: any) => (
                                                <tr key={feesData.receiptNo}>
                                                    <td>{feesData.sno}</td>
                                                    <td>{feesData.admissionNo}<br />{feesData.uniRegNo}</td>
                                                    <td>{feesData.studentName}</td>
                                                    <td>{feesData.receiptNo}</td>
                                                    <td>{feesData.receiptDate}</td>
                                                    <td>{feesData.feeType}</td>
                                                    <td>{feesData.paymentType}</td>
                                                    <td>{feesData.collectedFees}</td>
                                                </tr>
                                            ))
                                            } </>
                                        :
                                        <NoDataMsg columnsCount={8} msg={noDataMsg} />}
                                    <tr className='table-primary'>
                                        <td colSpan={7} ><strong style={{ float: 'right' }}>TOTAL</strong></td>
                                        <td><strong>{dailyFeeCollectionDeletedData.length > 0 ? dailyFeeCollectionDeletedData.map((data: any) => data.collectedFees).reduce((a: any, b: any) => a + b, 0) : 0}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                            <h6 className="mg-lg-t-10">Date : {getCurrentDateAndTime('dateAndTime')}</h6>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>

        </PageWrapper>
    )
}
export default OverAllFeeReport;