import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()
export const getBookAuthors = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBookAuthors/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getBookAuthors - " + error.message)
            console.log('error caught in service : getBookAuthors')
        },
    );

export const getBookGenres = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBookGenres/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getBookGenres - " + error.message)
            console.log('error caught in service : getBookGenres')
        },
    );

export const getBookDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBookDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getBookDetails - " + error.message)
            console.log('error caught in service : getBookDetails')
        },
    );

    export const addBookDetails = (libraryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    
        api.post(getAPIURL() + `addBookDetails`, libraryPostData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : addBookDetails')
            }
        );

    export const updateBookDetails = (updateLibraryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.post(getAPIURL() + `updateBookDetails`, updateLibraryPostData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : updateBookDetails')
            }
        );

    export const deleteBookDetails = (updateLibraryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.post(getAPIURL() + `deleteBookDetails`, updateLibraryPostData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : deleteBookDetails')
            }
        );

    export const getLibraryMember = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.get(getAPIURL() + `getLibraryMember/${getLicenseKey}`,
            response => {
                success(response)
            },
            error => {
                failure("getLibraryMember - " + error.message)
                console.log('error caught in service : getLibraryMember')
            },
        );
    
    export const addLibraryMember = (libraryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    
        api.post(getAPIURL() + `addLibraryMember`, libraryPostData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : addLibraryMember')
            }
        );

    export const updateLibraryMember = (updateLibraryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.post(getAPIURL() + `updateLibraryMember`, updateLibraryPostData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : updateLibraryMember')
            }
        );

    export const deleteLibraryMember = (updateLibraryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.post(getAPIURL() + `deleteLibraryMember`, updateLibraryPostData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : deleteLibraryMember')
            }
        );

    export const getBookTransaction = (userTypeId:any, studentOrStaffDetailsId:any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.get(getAPIURL() + `getBookTransaction/${getLicenseKey}/${userTypeId}/${studentOrStaffDetailsId}`,
            response => {
                success(response)
            },
            error => {
                failure("getBookTransaction - " + error.message)
                console.log('error caught in service : getBookTransaction')
            },
        );
    
        export const addBookTransaction = (libraryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
        
            api.post(getAPIURL() + `addBookTransaction`, libraryPostData,
                response => {
                    success(response)
                },
                error => {
                    failure(error.message)
                    console.log('error caught in service : addBookTransaction')
                }
            );
    
        export const updateBookTransaction = (updateLibraryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    
            api.post(getAPIURL() + `updateBookTransaction`, updateLibraryPostData,
                response => {
                    success(response)
                },
                error => {
                    failure(error.message)
                    console.log('error caught in service : updateBookTransaction')
                }
            );
        export const getNotReturnBookList = (userTypeId:any, studentOrStaffDetailsId:any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

            api.get(getAPIURL() + `getNotReturnBookList/${getLicenseKey}${userTypeId}/${studentOrStaffDetailsId}`,
                response => {
                    success(response)
                },
                error => {
                    failure("getNotReturnBookList - " + error.message)
                    console.log('error caught in service : getNotReturnBookList')
                },
            );
        export const addStaffFineFee = (libraryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    
            api.post(getAPIURL() + `addStaffFineFee`, libraryPostData,
                response => {
                    success(response)
                },
                error => {
                    failure(error.message)
                    console.log('error caught in service : addStaffFineFee')
                }
            );
    
        
    