import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import HumansWebp from '../../../assets/img/scene2.webp';
import Humans from '../../../assets/img/scene2.webp';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { getCurrentDateAndTime, convertDateToEpoch } from '../../../services/common.service';
import { getScheduleDetailsByDate } from '../../../services/timeTableService';
import { toasts } from '../../../services/toast.service';
import Card, {
	CardLabel,
	CardTitle,
	CardBody,
	CardHeader,
	CardActions,
	CardSubTitle,
} from '../../bootstrap/Card';
import Button from '../../bootstrap/Button';
import { useFormik } from 'formik';
import Icon from '../../icon/Icon';
import Popovers from '../../bootstrap/Popovers';
import { Calendar as DatePicker } from 'react-date-range';
import { getLabel } from '../../extras/calendarHelper';
import { View as TView } from 'react-big-calendar';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { TableLoader } from '../../../services/loader.services';

const StudentSchedule = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [date, setDate] = useState(new Date());
	const [viewMode, setViewMode] = useState<TView>('month');
	const calendarDateLabel = getLabel(date, viewMode);
	const [day, setDay] = useState<any>('');
	const [transactionDate, setTransactionDate] = useState<any>('');

	useEffect(() => {
		let dateInMilliSec = convertDateToEpoch(getCurrentDateAndTime('date'));
		getScheduleDetailsList(userTypeId, userAccountId, dateInMilliSec);
		setTransactionDate(getCurrentDateAndTime('date'));

		const day = date.getDate().toString().padStart(2, '0');
		setDay(day + ' ' + calendarDateLabel);
	}, []);

	const { themeStatus, darkModeStatus } = useDarkMode();

	const [standardDetailsId, setStandardId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);
	const [dayOrderTypeId, setDayOrderTypeId] = useState<any>('');

	const [scheduleData, setScheduleData] = useState<any>([]);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(true);
	const [dataSuccess, setDataSuccess] = useState(false);

	const staffScheduleForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			date: getCurrentDateAndTime('date'),
		},
		validate: (values) => {
			const errors: {
				date?: string;
			} = {};

			if (!values.date) {
				errors.date = 'Required';
			}
			return errors;
		},

		//validateOnChange: false,
		onSubmit: () => {},
	});

	const [dayOrder, setDayOrder] = useState<any>('');
	const [dayId, setDayId] = useState<any>('');

	function getScheduleDetailsList(userTypeId: any, userAccountId: any, date: any) {
		setIsLoader(true);

		getScheduleDetailsByDate(
			userTypeId,
			userAccountId,
			date,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.scheduleDetailsByDate[0].period;
					if (data != undefined) {
						setIsLoader(false);
						setScheduleData(data);
						setDayId(response.data.data.scheduleDetailsByDate[0].dayId);
						setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
						setDataSuccess(true);
						for (let i = 0; i < data.length; i++) {
							data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
						}
					} else {
						setIsLoader(false);
						setDataSuccess(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setIsOpenListCard(false);
					setDataSuccess(true);
					// toasts(response.data.message, "Error")
					setScheduleData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function selectScheduleDate(e: any) {
		setDate(e);
		const calendarDateLabel = getLabel(e, 'month');
		const day = e.getDate().toString().padStart(2, '0');
		setDay(day + ' ' + calendarDateLabel);
		const formattedDate = `${e.getFullYear()}-${String(e.getMonth() + 1).padStart(2, '0')}-${String(e.getDate()).padStart(2, '0')}`;

		let dateInMilliSec = convertDateToEpoch(formattedDate);
		getScheduleDetailsList(userTypeId, userAccountId, dateInMilliSec);
		setTransactionDate(formattedDate);
		setScheduleData([]);
	}

	return (
		<PageWrapper title='Schedule'>
			<Page container='fluid'>
				<Card className='mt-2'>
					<CardHeader borderSize={1}>
						<CardLabel icon='Schedule' iconColor='info'>
							<CardTitle tag='div' className='h5'>
								Schedule
							</CardTitle>
							<CardSubTitle>
								{dataSuccess && userTypeId == 1 ? dayOrderTypeId?.label : dayOrder}
							</CardSubTitle>
						</CardLabel>
						<Popovers
							desc={
								<DatePicker
									onChange={selectScheduleDate}
									date={date}
									color={process.env.REACT_APP_PRIMARY_COLOR}
								/>
							}
							placement='bottom-end'
							className='mw-100'
							trigger='click'>
							<Button color={darkModeStatus ? 'light' : 'dark'} isLight>
								{day}
							</Button>
						</Popovers>
					</CardHeader>

					<CardBody>
						{!isLoader ? (
							<>
								{dataSuccess ? (
									<>
										{scheduleData != '' && dayId > 0 && dayId != 7 ? (
											<>
												<div className='col-12'>
													<div className='row'>
														{scheduleData.map((period: any) => (
															<div
																className='col-3'
																key={period.periodDetailsId}>
																<Card
																	stretch
																	shadow={'md'}
																	className='border border-light'>
																	<CardBody>
																		<div className='row g-3 align-items-center'>
																			<div className='col d-flex align-items-center'>
																				<div className='flex-shrink-0'>
																					<div
																						className='ratio ratio-1x1'
																						style={{
																							width: 65,
																						}}>
																						<div
																							className={classNames(
																								'rounded-2',
																								'd-flex align-items-center justify-content-center',
																								{
																									'bg-l10-info':
																										!darkModeStatus,
																									'bg-lo25-info':
																										darkModeStatus,
																								},
																							)}>
																							<span className='fw-bold'>
																								<span className='ms-1 text-info fs-2'>
																									{
																										period.periodCount
																									}
																								</span>
																								<br />
																								{userTypeId ==
																								1 ? (
																									<small className='text-dark fs-6'>
																										{
																											standardDetailsId?.label
																										}{' '}
																										-{' '}
																										{
																											sectionDetailsId?.label
																										}
																									</small>
																								) : null}
																							</span>
																						</div>
																					</div>
																				</div>
																				<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
																					<div>
																						<small className='fw-bold'>
																							{
																								period.paperName
																							}
																						</small>
																						<div className=' text-info fs-6 mb-0'>
																							{
																								period.staffName
																							}
																						</div>
																						<div className='text-info'>
																							<small>
																								<span className='text-black-50 fw-bold'>
																									{
																										period.startTime
																									}

																									-
																									{
																										period.endTime
																									}
																								</span>
																							</small>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</CardBody>
																</Card>
															</div>
														))}{' '}
													</div>
												</div>
											</>
										) : (
											<div className='row'>
												<div className='col-12 d-flex justify-content-center'>
													{scheduleData != '' &&
													(dayId == 0 || dayId == 7) ? (
														<Icon
															style={{ height: '30vh' }}
															icon='CalendarToday'
															size={'8x'}
														/>
													) : (
														<img
															srcSet={HumansWebp}
															src={Humans}
															alt='Humans'
															style={{ height: '50vh' }}
														/>
													)}
												</div>
												<div className='fs-4 fw-bold d-flex justify-content-center text-center text-uppercase text-danger'>
													{scheduleData != '' &&
													(dayId == 0 || dayId == 7)
														? 'Holiday'
														: 'Schedule Not Allocated'}
												</div>
											</div>
										)}
									</>
								) : null}
							</>
						) : (
							<div className='row'>
								<div className='col-12 d-flex justify-content-center'>
									<TableLoader />
								</div>
							</div>
						)}
					</CardBody>
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default StudentSchedule;
