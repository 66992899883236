import React, { useContext, useRef, useState } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import Page from '../../../layout/Page/Page';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Icon from '../../icon/Icon';
import Collapse from '../../bootstrap/Collapse';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../../services/toast.service';
import Input from '../../bootstrap/forms/Input';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import { useFormik } from 'formik';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { useReactToPrint } from 'react-to-print';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import { convertDateToEpoch, getColumnsForDataTable, getCurrentDateAndTime, updateColumnsForDataTable, updateFilter } from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import NoDataMsg from '../../../common/components/NoDataMsg';
import { getBookTransactionReport } from '../../../services/report.service';

function BookTransactionReport() {

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [eventDetailsData, setEventDetailsData] = useState<any>([]);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
    const [columnVisibilityDataForExport, setColumnVisibilityDataForExport] = useState<any>([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(eventDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [isLoader, setIsLoader] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('');

    const eventReportForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            fromDate: getCurrentDateAndTime('date'),
            toDate: getCurrentDateAndTime('date'),
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
                staffTypeId?: any;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(
        onCurrentPageData,
        columnVisibilityData,
        columnVisibilityForm,
    );

    function viewEventReportList() {
        setIsLoader(true);

        //let feeTypeId1 = feeTypeId
        let fromDate = eventReportForm.values.fromDate;
        let toDate = eventReportForm.values.toDate;
        let fromDateInMilliSec = convertDateToEpoch(fromDate);
        let toDateInMilliSec = convertDateToEpoch(toDate);

        getBookTransactionReportList(fromDateInMilliSec, toDateInMilliSec);
    }

    function getBookTransactionReportList(fromDate: any, toDate: any) {
        setIsLoader(true);
        getBookTransactionReport(fromDate, toDate,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.bookTransactionReport;
                    if (data != undefined) {
                        setIsLoader(false);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        setEventDetailsData(data);
                        getColumnsForTable('getBookTransaction', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(true);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setIsLoader(false);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setEventDetailsData([]);
                    getColumnsForTable('getBookTransaction', 'get');
                    setNoDataMsg(response.data.message);
                } else {
                    setIsLoader(false);
                    setDataSuccess(true);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                setDataSuccess(true);
                toasts(error, 'Error');
            },
        );
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(
            userTypeId,
            apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData);
                        let columnVisibilityData = allColumnsData.filter(
                            (item: any) => item.isDisplay == 1,
                        );

                        const last = [{ columnVisibilityId: 0, columnName: 'Status', keyName: 'statusName', isActive: true, isDisplay: true, isDefault: true }]

                        setColumnVisibilityData(columnVisibilityData);
                        setColumnVisibilityDataForExport([...columnVisibilityData, ...last]);

                        console.log([...columnVisibilityData, ...last]);


                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        } else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter(
                                (item: any) => item.isDefault == false,
                            );

                            for (let i = 0; i < nonDefaultValue.length; i++) {
                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
                                };
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all);
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
        if (columnVisibilityForm.isValid) {
            setColumnDataById(isDisplay);
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type
                    ? type
                    : [
                        {
                            columnVisibilityId: columnVisibilityId,
                            isDisplay: isDisplay ? 0 : 1,
                        },
                    ],
            };

            updateColumnsForDataTable(
                columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getBookTransaction', 'get');
                    } else if (data.success == false) {
                        // toasts(data.success, "Error")
                    } else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, 'Error');
                    }
                },
                (error) => {
                    toasts(error, 'Error');
                },
            );
        } else if (columnVisibilityForm.isValid == false) {
            toasts('Please fill all the details!', 'Error');
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title='Event Report'>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='list' iconColor='primary'>
                                    <CardTitle>Book Transaction Report</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <FormGroup id='fromDate' label='From Date' isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='From Date'
                                                onChange={eventReportForm.handleChange}
                                                onBlur={eventReportForm.handleBlur}
                                                value={eventReportForm.values.fromDate}
                                                isValid={eventReportForm.isValid}
                                                isTouched={eventReportForm.touched.fromDate}
                                                invalidFeedback={eventReportForm.errors.fromDate}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-2'>
                                        <FormGroup id='toDate' label='To Date' isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='To Date'
                                                onChange={eventReportForm.handleChange}
                                                onBlur={eventReportForm.handleBlur}
                                                value={eventReportForm.values.toDate}
                                                isValid={eventReportForm.isValid}
                                                isTouched={eventReportForm.touched.toDate}
                                                invalidFeedback={eventReportForm.errors.toDate}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-2 align-self-center mt-1'>
                                        <FormGroup>
                                            <Button
                                                icon='South'
                                                color='primary'
                                                onClick={viewEventReportList}>
                                                View
                                            </Button>
                                        </FormGroup>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent
                        isOpenListCard={isOpenListCard}
                        setIsOpenListCard={setIsOpenListCard}
                        isLoader={isLoader}
                    />

                    {dataSuccess ? (
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                    <CardTitle tag='div' className='h5'>
                                        Book Transaction Report List
                                    </CardTitle>
                                </CardLabel>
                                &nbsp;&nbsp;
                                <ButtonGroup
                                    className='col-lg-3 justify-content-end d-print-none'
                                    color='primary'>
                                    <Icon
                                        className='mt-1'
                                        icon='Search'
                                        size='2x'
                                        color='primary'
                                    />
                                    <Input
                                        id='searchInput'
                                        type='search'
                                        placeholder='Search...'
                                        onChange={columnVisibilityForm.handleChange}
                                        value={columnVisibilityForm.values.searchInput}
                                    />
                                </ButtonGroup>
                                <CardActions className='d-print-none'>
                                    <Dropdown
                                        isOpen={columnVisibilityMenu}
                                        setIsOpen={setColumnVisibilityMenu}
                                        isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>
                                                Filter
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu
                                            isAlignmentEnd
                                            size='lg'
                                            isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks
                                                                    id='available'
                                                                    type='switch'
                                                                    label='Select All Columns'
                                                                    onChange={() =>
                                                                        getColumnsForTable(
                                                                            'getBookTransaction',
                                                                            'post',
                                                                        )
                                                                    }
                                                                    checked={isChecked}
                                                                    ariaLabel='Available status'
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map(
                                                                        (i: any) => (
                                                                            <Checks
                                                                                key={i.columnVisibilityId}
                                                                                id='{i.columnVisibilityId}'
                                                                                label={i.columnName}
                                                                                onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')}
                                                                                checked={i.isDisplay}
                                                                                disabled={i.isDefault == true}
                                                                            />
                                                                        ),
                                                                    )}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'>
                                                Export
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() =>
                                                        downloadExcel(
                                                            `BookTransactionReport(${eventReportForm.values.fromDate + ' to ' + eventReportForm.values.toDate})`,
                                                            eventDetailsData,
                                                            columnVisibilityDataForExport,
                                                        )
                                                    }>
                                                    Excel
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='PictureAsPdf'
                                                    onClick={() =>
                                                        convertJsonToPdf(
                                                            eventDetailsData,
                                                            columnVisibilityDataForExport,
                                                            `BookTransactionReport(${eventReportForm.values.fromDate + ' to ' + eventReportForm.values.toDate})`,
                                                        )
                                                    }>
                                                    PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() =>
                                                        downloadFile(
                                                            `BookTransactionReport(${eventReportForm.values.fromDate + ' to ' + eventReportForm.values.toDate})`,
                                                            eventDetailsData,
                                                            columnVisibilityDataForExport,
                                                        )
                                                    }>
                                                    CSV
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='Print'
                                                    onClick={handlePrint}>
                                                    Print
                                                </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>
                                                S.No{' '}
                                                <Icon
                                                    size='lg'
                                                    className={getClassNamesFor('sno')}
                                                    icon='FilterList'
                                                />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th
                                                    key={column.keyName}
                                                    scope='col'
                                                    onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon
                                                        size='lg'
                                                        className={getClassNamesFor(column.keyName)}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                            ))}
                                            <th scope='col' onClick={() => requestSort('statusName')} className='cursor-pointer'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != '' ? (
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.eventDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) =>
                                                            column.isDisplay &&
                                                                rowData[column.keyName] ? (
                                                                <td
                                                                    key={`${rowData.eventDetailsId}-${column.keyName}`}>
                                                                    {rowData[column.keyName]}
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`empty-${column.keyName}`}
                                                                />
                                                            ),
                                                        )}
                                                        <td>
                                                            <Button isLink color={rowData.statusId == 1 ? 'success' : 'danger'} icon='Circle' className='text-nowrap'>
                                                                {rowData.statusName}
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <NoDataMsg
                                                columnsCount={columnVisibilityData.length + 1}
                                                msg={noDataMsg}
                                            />
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className='d-print-none'
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>
                    ) : null}
                </Page>
            </PageWrapper>
        </>
    );
}

export default BookTransactionReport;
