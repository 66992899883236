import React, { useContext, useEffect, useRef, useState } from 'react';
import { toasts } from '../../../services/toast.service';
import { useReactToPrint } from 'react-to-print';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import Button from '../../bootstrap/Button';
import Card, { CardHeader, CardActions, CardBody, CardLabel, CardSubTitle, CardTitle } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Icon from '../../icon/Icon';
import {
	getAllStudent,
	getProfileForStudent,
	getStudentDetailsBySectionDetailsId,
} from '../../../services/student.service';
import {
	getMedium,
	getStandardDetails,
	pictNotLoading,
	profilePic,
} from '../../../services/common.service';
import { getBatchMasterDetails, getSectionDetails } from '../../../services/master.service';
import { useNavigate, useParams } from 'react-router-dom';
import { TableLoader } from '../../../services/loader.services';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import CollegeLogo from '../../../assets/logos/logo.jpg';
import { useFormik } from 'formik';
import { addStudentCertificateIssue } from '../../../services/certificate.service';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import dayjs from 'dayjs';
import SearchableSelect from '../../../common/components/SearchableSelect';
import { getSchoolDetails } from '../../../services/school.service';
import AttendanceStatus from '../../charts/AttendanceStatus';
import ExamMarkStatus from '../../charts/ExamMarkStatus';
import Spinner from '../../bootstrap/Spinner';

const AttendanceAndExamStatus = () => {
	const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const navigate = useNavigate();
	const { navigatedStudentDetailsId } = useParams();
	const [isNavigatedStudent, setIsNavigatedStudent] = useState(false);

	useEffect(() => {
		getAllStudentDetails();
		getSchool();
		if (navigatedStudentDetailsId != undefined) {
			setIsNavigatedStudent(true);
			getStudentProfile(navigatedStudentDetailsId);
			setStudentDetailsId(navigatedStudentDetailsId);
		}
	}, []);

	const [mediumId, setMediumId] = useState<any>('');
	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);

	const componentRef = useRef(null);
	const [mediumData, setMediumData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [sectionDetailsData, setSectionDetailsData] = useState([]);
	const [studentDetailsData, setStudentDetailsData] = useState<any>([]);
	const [allStudentData, setAllStudentData] = useState([]);
	const [schoolDetailsData, setSchoolDetailsData] = useState<any>([]);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [batchMasterId, setBatchMasterId] = useState<any>('');
	const [batchMasterData, setBatchMasterData] = useState<any>([]);
	const [show, setShow] = useState<any>('attendance');

	const selectBatch = (e: any) => {
		setDataSuccess(false);
		setStudentDetailsId('');
		setStandardDetailsId('');
		setSectionDetailsId('');
		setMediumId('');
		let batchMasterId = e;
		setBatchMasterId(batchMasterId);
		getMediumList();
	};

	const selectMedium = (e: any) => {
		setDataSuccess(false);
		setStudentDetailsId('');
		setStandardDetailsId('');
		setSectionDetailsId('');
		let mediumId = e;
		setMediumId(mediumId);
		getStandardList();
	};

	const selectStandard = (e: any) => {
		setDataSuccess(false);
		setStudentDetailsId('');
		setSectionDetailsId('');
		let standardDetailsId = e;
		setStandardDetailsId(standardDetailsId);
		if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
			getSectionList(mediumId?.value, standardDetailsId?.value);
		}
	};

	const selectSection = (e: any) => {
		setDataSuccess(false);
		setStudentDetailsId('');
		let sectionDetailsId = e;
		setSectionDetailsId(sectionDetailsId);
		if (batchMasterId?.value != undefined && sectionDetailsId?.value != undefined) {
			getStudentListBySectionId(batchMasterId?.value, sectionDetailsId?.value);
		}
	};

	const selectStudent = (e: any) => {
    setShow('attendance')
		setDataSuccess(false);
		let studentDetailsId = e;
		setStudentDetailsId(studentDetailsId);
	};

	function viewParticularStudent() {
    setShow('attendance')
		setDataSuccess(false);
		setIsLoader(true);
		getStudentProfile(studentDetailsId?.value);
	}

	function viewStudentClassWiseList() {
    setShow('attendance');
		setDataSuccess(false);
		setIsLoader(true);
		getStudentProfile(studentDetailsId?.value);
	}

	const [studentDetailsDataByDropdown, setStudentDetailsDataByDropdown] = useState([]);

	function getStudentListBySectionId(batchMasterId: any, sectionDetailsId: number) {
		getStudentDetailsBySectionDetailsId(
			batchMasterId,
			sectionDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetailsBySectionDetailsId;
					if (data != undefined) {
						setStudentDetailsDataByDropdown(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
					setStudentDetailsDataByDropdown([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStudentProfile(studentDetailsId: any) {
		setIsLoader(true);
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile;
					let studentProfile = response.data.data.studentProfile[0];
					if (data != undefined) {
						setIsLoader(false);
						setStudentDetailsData(data);
						setDataSuccess(true);
						setIsOpenListCard(false);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						toasts('Undefined Data', 'Error');
						setIsLoader(false);
						setDataSuccess(false);
						setIsOpenListCard(true);
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
					setIsLoader(false);
					setDataSuccess(false);
					setIsOpenListCard(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSectionList(mediumId: any, standardDetailsId: any) {
		getSectionDetails(
			mediumId,
			standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sectionDetails;
					if (data != undefined) {
						setSectionDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSectionDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getAllStudentDetails() {
		getAllStudent(
			userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetails;
					if (data != undefined) {
						setAllStudentData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAllStudentData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSchool() {
		getSchoolDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.schoolDetails;
					if (data != undefined) {
						setSchoolDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function selectStudentCard() {
		if (isStudentOrClassWise) {
			getBatchList();
		}
		setIsStudentOrClassWise(!isStudentOrClassWise);
		setDataSuccess(false);
		setSectionDetailsId('');
		setStudentDetailsId('');
		setMediumId('');
		setStandardDetailsId('');
		setBatchMasterId('');
	}

	function selectAttendanceOrExamMark(type: any) {
		setShow(type);
		if (type == 'attendance') {
		} else if (type == 'examMark') {
		}
	}

	return (
		<PageWrapper title='Attendance And Exam Status'>
			{isNavigatedStudent ? (
				<SubHeader className='d-print-none'>
					<SubHeaderLeft>
						<Button
							color='info'
							isLink
							icon='ArrowBack'
							onClick={() => navigate(`../academic/studentList`)}>
							Back to List
						</Button>
						<SubheaderSeparator />
						{studentDetailsData.map((student: any) => (
							<div key={student.studentDetailsId}>
								<img
									style={{ borderRadius: '50%' }}
									src={
										student.profilePath != null
											? student.profilePath
											: profilePic(student.genderId)
									}
									height='40'
									width='40'
									onError={(e: any) => pictNotLoading(e, student.genderId)}
								/>
								<span>
									<strong className='ms-3'>{student.studentName} </strong>
								</span>
								<span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
									{student.admissionNo}
								</span>
							</div>
						))}
					</SubHeaderLeft>
					<SubHeaderRight>
						<Button
							icon='Print'
							isLight
							color='primary'
							onClick={() => {
								handlePrint();
							}}>
							Print
						</Button>
					</SubHeaderRight>
				</SubHeader>
			) : (
				<></>
			)}
			<Page container='lg'>
				{!isNavigatedStudent ? (
					<>
						<Collapse isOpen={isOpenListCard}>
							<Card>
								<CardHeader borderSize={1}>
									<CardActions>
										<Button
											icon='PersonSearch'
											color='info'
											isLight={isStudentOrClassWise ? false : true}
											onClick={selectStudentCard}>
											Student
										</Button>

										<Button
											icon='PeopleAlt'
											color='info'
											isLight={isStudentOrClassWise ? true : false}
											onClick={selectStudentCard}>
											Class Wise
										</Button>
									</CardActions>
								</CardHeader>
								{isStudentOrClassWise ? (
									<CardBody>
										<div className='row'>
											<div className='col-4 mt-3'>
												<FormGroup
													id='studentDetailsId'
													label='Student'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Student'
														onChange={selectStudent}
														value={studentDetailsId}
														list={allStudentData.map((data: any) => ({
															value: data.studentDetailsId,
															label: data.studentNameAndAdmissionNo,
														}))}
													/>
												</FormGroup>
											</div>
											<div className='col-3 mt-4'>
												<Button
													icon='ArrowDownward'
													color='primary'
													onClick={viewParticularStudent}
													isDisable={
														studentDetailsId?.value == undefined
															? true
															: false
													}>
													View
												</Button>
											</div>
										</div>
									</CardBody>
								) : (
									<CardBody>
										<div className='row'>
											<div className='col-md-3'>
												<FormGroup
													id='batchMasterId'
													label='Batch'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Batch'
														placeholder='Batch'
														onChange={selectBatch}
														value={batchMasterId}
														list={batchMasterData.map((data: any) => ({
															value: data.batchMasterId,
															label: data.batchName,
														}))}
														required
													/>
												</FormGroup>
											</div>

											{batchMasterId?.value != undefined ? (
												<div className='col-3'>
													<FormGroup
														id='mediumId'
														label='Medium'
														isFloating>
														<SearchableSelect
															isFloating
															ariaLabel='Medium'
															onChange={selectMedium}
															value={mediumId}
															list={mediumData.map((data: any) => ({
																value: data.mediumId,
																label: data.medium,
															}))}
														/>
													</FormGroup>
												</div>
											) : null}

											{mediumId?.value != undefined ? (
												<div className='col-3'>
													<FormGroup
														id='standardDetailsId'
														label='Standard'
														isFloating>
														<SearchableSelect
															isFloating
															ariaLabel='Standard'
															onChange={selectStandard}
															value={standardDetailsId}
															list={standardData.map((data: any) => ({
																value: data.standardDetailsId,
																label: data.standardName,
															}))}
														/>
													</FormGroup>
												</div>
											) : null}

											{standardDetailsId?.value != undefined ? (
												<div className='col-3'>
													<FormGroup
														id='sectionDetailsId'
														label='Section'
														isFloating>
														<SearchableSelect
															isFloating
															ariaLabel='Section'
															onChange={selectSection}
															value={sectionDetailsId}
															list={sectionDetailsData.map(
																(data: any) => ({
																	value: data.sectionDetailsId,
																	label: data.sectionName,
																}),
															)}
														/>
													</FormGroup>
												</div>
											) : null}

											{sectionDetailsId?.value != undefined ? (
												<div className='col-3 mt-3'>
													<FormGroup
														id='studentDetailsId'
														label='Student'
														isFloating>
														<SearchableSelect
															isFloating
															ariaLabel='Student'
															onChange={selectStudent}
															value={studentDetailsId}
															list={studentDetailsDataByDropdown.map(
																(data: any) => ({
																	value: data.studentDetailsId,
																	label: data.studentNameAndAdmissionNo,
																}),
															)}
														/>
													</FormGroup>
												</div>
											) : null}

											{studentDetailsId ? (
												<div className='col-2 mt-4'>
													<Button
														icon='ArrowDownward'
														color='primary'
														onClick={viewStudentClassWiseList}>
														View
													</Button>
												</div>
											) : null}
										</div>
									</CardBody>
								)}
							</Card>
						</Collapse>
						<OpenCardComponent
							isOpenListCard={isOpenListCard}
							setIsOpenListCard={setIsOpenListCard}
							isLoader={isLoader}
						/>
					</>
				) : null}

				{dataSuccess ? (
					<>
						<div className='row'>
							<div className='col-lg-4  '>
							<Card stretch>
                                    <CardHeader borderSize={1}>
                                        <CardLabel icon='Person' iconColor='info'>
                                            <CardTitle tag='div' className='h5'>
                                                Account Details
                                            </CardTitle>
                                            <CardSubTitle tag='div' className='h6'>
                                                Personal Information - {studentDetailsData[0].isActive ? <span className="text-success align-self-center mt-2 border border-success border-2 px-2 py-1 rounded sm">{studentDetailsData[0].activeStatus}</span> : <span className="text-danger align-self-center mt-2 border border-danger border-2 px-2 py-1 rounded sm">{studentDetailsData[0].activeStatus}</span>}
                                            </CardSubTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-3'>
                                            <div className='col-12'>
                                                {studentDetailsData.map((student: any, index: any) => (
                                                    <div className="row" key={index}>
                                                        <div className="col-4 mt-3">
                                                            {student.profilePath != null ?
                                                                <img style={{ borderRadius: '50%' }} src={student.profilePath}
                                                                    width="100" height="100" onError={(e: any) => pictNotLoading(e, student.gender == 'Male' ? 1 : 2)} />
                                                                : <img className="rounded-0" src={profilePic(student.genderId)}
                                                                    width="100" height="100" />}

                                                        </div>
                                                        <div className="col-8">
                                                            <h5 className='fs-5 fw-bold me-2 text-uppercase mt-3'>{student.studentName}</h5>
                                                            <div><span className="text-success">{student.admissionNo} </span>( {student.studentRollNo != null ? student.studentRollNo : '-'} )</div>
                                                            <div>{student.standardName} - {student.sectionName} ( {student.medium} )</div>
                                                            <div>{student.batchName}</div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='PendingActions'
                                                    color='info'
                                                    className='w-100 p-3'
													isLink={show !== 'attendance'}
													isLight={show === 'attendance'}
                                                    onClick={() =>
														selectAttendanceOrExamMark(
															'attendance',
														)
													}>
                                                    Attendance
                                                </Button>
                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='ReceiptLong'
                                                    color='info'
                                                    className='w-100 p-3'
													isLink={show !== 'examMark'}
													isLight={show === 'examMark'}
                                                    onClick={() =>
														selectAttendanceOrExamMark(
															'examMark',
														)
													}>
                                                   Exam Mark
                                                </Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
								
								{/* <Card stretch className='rounded-1 border border-light'>
									<CardBody>
										{studentDetailsData.map((student: any) => (
											<div className='row g-3' key={student.studentDetailsId}>
												<div className='col-12 d-flex justify-content-center'>
													{student.profilePath != null ? (
														<img
															style={{ borderRadius: '50%' }}
															src={student.profilePath}
															width='100'
															height='100'
															onError={(e: any) =>
																pictNotLoading(e, student.genderId)
															}
														/>
													) : (
														<img
															src={profilePic(student.genderId)}
															width='100'
															height='100'
														/>
													)}
												</div>
												<div className='col-12'>
													<div className='row g-2'>
														<span className='fs-4 fw-bold me-2  d-flex justify-content-center text-center text-uppercase'>{`${student.studentName}`}</span>
														<div className='h5 text-muted text-lowercase opacity-50 d-flex justify-content-center text-uppercase'>
															{student.admissionNo}
														</div>
														<div className='col-6'>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='HowToReg'
																		size='3x'
																		color='info'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold mb-0 '>
																		{student.studentRollNo ? (
																			student.studentRollNo
																		) : (
																			<Icon
																				icon='Remove'
																				className='mb-0 text-dark fw-bold h2'
																			/>
																		)}{' '}
																	</div>
																	<div className='text-muted'>
																		Roll No
																	</div>
																</div>
															</div>
														</div>
														<div className='col-6'>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon={
																			student.genderId == 1
																				? 'Male'
																				: 'Female'
																		}
																		size='3x'
																		color='info'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold  mb-0'>
																		{student.gender ? (
																			student.gender
																		) : (
																			<Icon
																				icon='Remove'
																				className='mb-0 text-dark fw-bold h2'
																			/>
																		)}
																	</div>
																	<div className='text-muted'>
																		Gender
																	</div>
																</div>
															</div>
														</div>
														<div className='col-6 mt-4'>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='PhoneIphone'
																		size='3x'
																		color='info'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold  mb-0'>
																		{student.smsToBeSentNumber ? (
																			student.smsToBeSentNumber
																		) : (
																			<Icon
																				icon='Remove'
																				className='mb-0 text-dark fw-bold h2'
																			/>
																		)}
																	</div>
																	<div className='text-muted'>
																		Mobile
																	</div>
																</div>
															</div>
														</div>
														<div className='col-6 mt-4'>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='DateRange'
																		size='3x'
																		color='info'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold  mb-0'>
																		{student.dateOfBirthForView ? (
																			student.dateOfBirthForView
																		) : (
																			<Icon
																				icon='Remove'
																				className='mb-0 text-dark fw-bold h2'
																			/>
																		)}
																	</div>
																	<div className='text-muted'>
																		Date of Birth
																	</div>
																</div>
															</div>
														</div>
														<div className='col-6 mt-4'>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='Mail'
																		size='3x'
																		color='info'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold  mb-0'>
																		{student.email ? (
																			student.email
																		) : (
																			<Icon
																				icon='Remove'
																				className='mb-0 text-dark fw-bold h4'
																			/>
																		)}
																	</div>
																	<div className='text-muted'>
																		Email Address
																	</div>
																</div>
															</div>
														</div>
														<div className='row top-90 m-0'>
															<div className='col-6 position-absolute bottom-0 start-0 p-3'>
																<Button
																	color='info'
																	className='w-100'
																	onClick={() =>
																		selectAttendanceOrExamMark(
																			'attendance',
																		)
																	}
																	isLink={show !== 'attendance'}
																	isLight={show === 'attendance'}>
																	Attendance
																</Button>
															</div>
															<div className='col-6 position-absolute bottom-0 end-0 p-3'>
																<Button
																	color='info'
																	className='w-100'
																	onClick={() =>
																		selectAttendanceOrExamMark(
																			'examMark',
																		)
																	}
																	isLink={show !== 'examMark'}
																	isLight={show === 'examMark'}>
																	Exam Marks
																</Button>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</CardBody>
								</Card> */}
							</div>
							{show == 'attendance' ? (
								<>
									<div className='col-8'>
										<AttendanceStatus
											studentDetailsId={studentDetailsId?.value}
											height={300}
										/>
									</div>
								</>
							) : show == 'examMark' ? (
								<div className='col-8'>
									<ExamMarkStatus data={studentDetailsData[0]} />
								</div>
							) : 
              null}
						</div>
					</>
				) : (
					<>{isNavigatedStudent ? <TableLoader /> : null}</>
				)}

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</Page>
		</PageWrapper>
	);
};

export default AttendanceAndExamStatus;
