import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getStudentDetailsByAcademicPeriodId = (academicPeriodId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsByAcademicPeriodId/${getLicenseKey}/${academicPeriodId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsByAcademicPeriodId - " + error.message)
            console.log('error caught in service : getStudentDetailsByAcademicPeriodId')
        },
    );

export const getStudentDetailsByYear = (batchMasterId: number, semesterDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsByYear/${getLicenseKey}/${batchMasterId}/${semesterDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsByYear - " + error.message)
            console.log('error caught in service : getStudentDetailsByYear')
        },
    );

export const getStudentStandardList = (batchMasterId: number, standardDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentList/${getLicenseKey}/${batchMasterId}/${standardDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsByYear - " + error.message)
            console.log('error caught in service : getStudentDetailsByYear')
        },
    );

export const getStudentSectionList = (batchMasterId: number, standardDetailsId: number, sectionDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentList/${getLicenseKey}/${batchMasterId}/${standardDetailsId}/${sectionDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsByYear - " + error.message)
            console.log('error caught in service : getStudentDetailsByYear')
        },
    );

export const getStaffListByUserTypeId = (staffTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffListByUserTypeId/${getLicenseKey}/${staffTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListByUserTypeId - " + error.message)
            console.log('error caught in service : getStaffListByUserTypeId')
        },
    );

    export const sendSMS = (messageData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.post(getAPIURL() + `sendSMS`, messageData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : sendSMS')
            }
        );
        export const getSmsMessageType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

            api.get(getAPIURL() + `getSmsMessageType/${getLicenseKey}`,
                response => {
                    success(response)
                },
                error => {
                    failure("getSmsMessageType - " + error.message)
                    console.log('error caught in service : getSmsMessageType')
                },
            );