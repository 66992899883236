import React, { useContext, useEffect, useRef, useState } from 'react'
import { convertDateToEpoch, getColumnsForDataTable, getCurrentDateAndTime, getUserType, pictNotLoading, profilePic } from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody, CardSubTitle } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { getActivityLog, getProfileForStaff, getStaffListByUserTypeId } from '../../../services/staff.service';
import AuthContext from '../../../contexts/authContext';
import Input from '../../bootstrap/forms/Input';
import { useFormik } from 'formik';
import Timeline, { TimelineItem } from '../../extras/Timeline';
import NoDataMsg from '../../../common/components/NoDataMsg';
import Icon from '../../icon/Icon';
import { NotListedLocation } from '../../icon/material-icons';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import { useReactToPrint } from 'react-to-print';

const StaffActivityReport = () => {

    useEffect(() => {
        getUserTypeList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [userTypeData, setUserTypeData] = useState<any>([])
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [staffTypeId, setStaffTypeId] = useState<any>([])
    const [staffDetailsData, setStaffDetailsData] = useState<any>([])
    const [staffDetailsId, setStaffDetailsId] = useState<any>([])
    const [activityData, setActivityData] = useState<any>([]);
    const [noDataMsg, setNoDataMsg] = useState('')

    const staffDetailsForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            fromDate: '',
            toDate: '',
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewFeeSummaryList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = staffDetailsForm.values.fromDate
        let toDate = staffDetailsForm.values.toDate
        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)
        getActivityLogList(staffDetailsId?.value, fromDateInMilliSec, toDateInMilliSec);
        getStaffProfile(staffDetailsId?.value)
    }

    const selectUserType = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        setStaffDetailsId('')
        staffDetailsForm.resetForm()
        let staffTypeId = e
        setStaffTypeId(staffTypeId)
        if (staffTypeId?.value != undefined) {
            getStaffListByUserType(staffTypeId?.value)
        }

    }

    const selectStaff = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        staffDetailsForm.resetForm()
        let staffDetailsId = e
        setStaffDetailsId(staffDetailsId)
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {

                        setUserTypeData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffListByUserType(staffTypeId: any) {
        getStaffListByUserTypeId(userAccountId, staffTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setStaffDetailsData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const colors = ['info', 'success', 'warning', 'secondary', 'primary'];
    const [fromDate, setFromDate] = useState<any>('')
    const [toDate, setToDate] = useState<any>('')

    function getActivityLogList(staffDetailsId: any, fromDateInMilliSec: any, toDateInMilliSec: any) {
        setIsLoader(true);
        getActivityLog(staffDetailsId, fromDateInMilliSec, toDateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.activityLog;
                    let fromDate = response.data.data.activityLog[0].fromDateForView;
                    let toDate = response.data.data.activityLog[0].toDateForView;
                    if (data != undefined) {
                        setIsLoader(false);
                        setIsOpenListCard(false)
                        setDataSuccess(true)
                        setFromDate(fromDate)
                        setToDate(toDate)
                        for (let i = 0; i < data.length; i++) {
                            let activities = data[i].activities;
                            for (let j = 0; j < activities.length; j++) {
                                activities[j].color = colors[Math.floor(Math.random() * colors.length)];
                                activities[j].empNumber = data[i].empNumber;
                                activities[j].staffName = data[i].staffName;
                            }
                        }
                        setActivityData(data);
                        getColumnsForTable('getStaffActivityLog')
                    } else {
                        setIsLoader(false);
                        setDataSuccess(true)
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false)
                    setDataSuccess(true)
                    setNoDataMsg(response.data.message)
                    setActivityData([]);
                    getColumnsForTable('getStaffActivityLog')
                    // toasts(response.data.message, 'Error');
                } else {
                    setIsLoader(false);
                    setDataSuccess(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                setDataSuccess(true)
                toasts(error, 'Error');
            },
        );
    }

    const [profileForStaffData, setProfileForStaffData] = useState<any>([])

    function getStaffProfile(staffDetailsId: any) {
        getProfileForStaff(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff;
                    if (data != undefined) {
                        setProfileForStaffData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);

    function getColumnsForTable(apiName: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    return (

        <PageWrapper title='Staff Activity Report'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle>
                                    Staff Activity Report
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-3'>
                                    <FormGroup id='staffTypeId' label='User Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Type'
                                            onChange={selectUserType}
                                            value={staffTypeId}
                                            list={userTypeData.map((data: any) => (
                                                { value: data.userTypeId, label: data.userType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>

                                {staffTypeId?.value != undefined ?
                                    <div className='col-md-3'>
                                        <FormGroup id='staffDetailsId' label='Staff' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Type'
                                                onChange={selectStaff}
                                                value={staffDetailsId}
                                                list={staffDetailsData.map((data: any) => (
                                                    { value: data.staffDetailsId, label: data.staffName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }

                                {staffDetailsId?.value != undefined ?
                                    <div className="col-2">
                                        <FormGroup id="fromDate" label="From Date" isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='From Date'
                                                onChange={staffDetailsForm.handleChange}
                                                onBlur={staffDetailsForm.handleBlur}
                                                value={staffDetailsForm.values.fromDate}
                                                isValid={staffDetailsForm.isValid}
                                                isTouched={staffDetailsForm.touched.fromDate}
                                                invalidFeedback={staffDetailsForm.errors.fromDate}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }

                                {staffDetailsForm.values.fromDate != '' ?
                                    <div className="col-2 ">
                                        <FormGroup id="toDate" label="To Date" isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='To Date'
                                                onChange={staffDetailsForm.handleChange}
                                                onBlur={staffDetailsForm.handleBlur}
                                                value={staffDetailsForm.values.toDate}
                                                isValid={staffDetailsForm.isValid}
                                                isTouched={staffDetailsForm.touched.toDate}
                                                invalidFeedback={staffDetailsForm.errors.toDate}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }
                                {staffDetailsForm.values.toDate != '' ?
                                    <div className='col-md-2'>
                                        <Button className='mt-2' icon='ArrowDownward' color='primary' onClick={viewFeeSummaryList}>View</Button>
                                    </div> : null
                                }

                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <div className='row h-100 pb-3'>
                        <div className='col-lg-4'>
                            <Card stretch className='rounded-1 border border-light'>
                                <CardBody>
                                    {profileForStaffData.map((staff: any) => (
                                        <div className='row g-3' key={staff.staffDetailsId}>
                                            <div className='col-12 d-flex justify-content-center'>
                                                {staff.profilePath != null ?
                                                    <img style={{ borderRadius: '50%' }} src={staff.profilePath}
                                                        width="100" height="100" onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                                    : <img src={profilePic(staff.genderId)}
                                                        width="100" height="100" />}
                                            </div>
                                            <div className='col-12'>
                                                <div className='row g-2'>
                                                    <span className='fs-4 fw-bold me-2  d-flex justify-content-center text-center text-uppercase'>{`${staff.fullname}`}</span>
                                                    <div className='h6 text-muted text-lowercase opacity-50 d-flex justify-content-center text-uppercase'>
                                                        {staff.userType}
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='HowToReg' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0 '>
                                                                    {staff.empNumber ? staff.empNumber : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}</div>
                                                                <div className='text-muted'>
                                                                    Employee No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon={staff.genderId == 1 ? 'Male' : 'Female'} size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {staff.gender ? staff.gender : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Gender
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mt-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='PhoneIphone' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {staff.mobile ? staff.mobile : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Mobile
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mt-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='DateRange' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {staff.dateOfBirthForView ? staff.dateOfBirthForView : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Date of Birth
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mt-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='Mail' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {staff.email ? staff.email : <Icon icon='Remove' className='mb-0 text-dark fw-bold h4' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Email Address
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-lg-8'>
                            <Card ref={componentRef} stretch className='rounded-1 border border-light'>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='NotificationsActive' iconColor='warning'>
                                        <CardTitle tag='div' className='h5'>
                                            Recent Activities
                                        </CardTitle>
                                        <CardSubTitle tag='div' className='h6'>
                                            {fromDate} - {toDate}
                                        </CardSubTitle>
                                    </CardLabel>
                                    <CardActions className="d-print-none">
                                        <Dropdown isButtonGroup>
                                            <DropdownToggle>
                                                <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("StaffActivity", activityData[0].activities, columnVisibilityData)}> Excel </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(activityData[0].activities, columnVisibilityData, "StaffActivity")}> PDF
                                                    </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("StaffActivity", activityData[0].activities, columnVisibilityData)}> CSV </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </CardActions>
                                </CardHeader>
                                <CardBody isScrollable>
                                    {activityData != '' ?
                                        <Timeline>
                                            {activityData.map((item: any) => (
                                                <div key={item.staffDetailsId}>
                                                    {item.activities.map((i: any) => (
                                                        <TimelineItem
                                                            key={i.activityLogsId}
                                                            label={i.duration}
                                                            color={i.color}>
                                                            {i.activity}
                                                        </TimelineItem>
                                                    ))}

                                                </div>
                                            ))}
                                        </Timeline> :
                                        <div className='row'>
                                            <div className="col-12" style={{ height: '5vh' }}></div>
                                            <div className='col-12 d-flex justify-content-center'>
                                                <Icon icon='DoNotDisturb' size={'10x'} />
                                            </div>
                                            <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                                {noDataMsg}
                                            </div>
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </div>
                    </div> : null
                }
            </Page>
        </PageWrapper>
    )
}

export default StaffActivityReport