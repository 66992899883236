import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../bootstrap/Card';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from '../../bootstrap/OffCanvas';
import Textarea from '../../bootstrap/forms/Textarea';
import { toasts } from '../../../services/toast.service';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import { showLoader } from '../../../services/loader.services';
import DeleteComponents from '../../../common/components/DeleteComponents';
import AlertService from '../../../services/AlertService';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Alert, { AlertHeading } from '../../bootstrap/Alert';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { getUserType } from '../../../services/common.service';
import { addAnnouncementDetails, deleteAnnouncementDetails, getAnnouncementDetails, updateAnnouncementDetails } from '../../../services/announcement.service';
import Input from '../../bootstrap/forms/Input';

const AddAnnouncement = () => {

	
	useEffect(() => {
		getAnnouncement(userTypeId);
		getUserTypeList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [addAddAnnouncementOffCanvas, setAddAnnouncementOffCanvas] = useState(false);
	const [editAddAnnouncementOffCanvas, setEditAnnouncementOffCanvas] = useState(false);
	const [staffTypeId, setStaffTypeId] = useState<any>('');
	const [AnnouncementData, setAnnouncementData] = useState<any>([]);
	const colors = ['info', 'success', 'warning', 'secondary', 'primary'];
	const [studentAnnouncementData, setStudentAnnouncementData] = useState<any>([]);
	const [staffAnnouncementData, setStaffAnnouncementData] = useState<any>([]);
	const [parentAnnouncementData, setParentAnnouncementData] = useState<any>([]);
	const [type, setType] = useState('');

	const [userTypeData, setUserTypeData] = useState<any>([]);

	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [studentNoDataMsg, setStudentNoDataMsg] = useState<any>('');
	const [staffNoDataMsg, setStaffNoDataMsg] = useState<any>('');
	const [parentNoDataMsg, setParentNoDataMsg] = useState<any>('');

	const announcementForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: '',
			fromDate: '',
			toDate: '',
			staffDetailsId: '',
		},
		validate: (values) => {
			const errors: {
				title?: string;
				fromDate?: string;
				toDate?: string;
				staffDetailsId?: string;
			} = {};
			if (!values.title) {
				errors.title = 'Required';
			}
			if (!values.fromDate) {
				errors.fromDate = 'Required';
			}
			if (!values.toDate) {
				errors.toDate = 'Required';
			}
			return errors;
		},
		validateOnChange: true,
		onSubmit: () => {
			addAnnouncementSubmit();
		},
	});

	const editAnnouncementForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: '',
			fromDate: '',
			toDate: '',
			announcementDetailsId: '',
		},
		validate: (values) => {
			const errors: {
				title?: string;
				fromDate?: string;
				toDate?: string;
				announcementDetailsId?: number;
			} = {};
			if (!values.title) {
				errors.title = 'Required';
			}
			if (!values.fromDate) {
				errors.fromDate = 'Required';
			}
			if (!values.toDate) {
				errors.toDate = 'Required';
			}
			return errors;
		},
		validateOnChange: true,
		onSubmit: () => {
			updateAnnouncementSubmit();
		},
	});

	const [selectedOption, setSelectedOption] = useState<any>([])

	const selectUserType = (e: any) => {
		let staffTypeId = e;

		let allStaff = staffTypeId.filter((item: any) => item.value == 0)
		let particularStaff = staffTypeId.filter((item: any) => item.value > 0)

		if (allStaff != '') {
			setSelectedOption(allStaff)
			let staffTypeIdArray = allStaff.map((item: any) => item.value)
			setStaffTypeId(staffTypeIdArray);
		}
		else if (particularStaff != '' || allStaff == '') {
			setSelectedOption(particularStaff)
			let staffTypeIdArray = particularStaff.map((item: any) => item.value)
			setStaffTypeId(staffTypeIdArray);
		}
	};

	const selectUserTypeForEdit = (e: any) => {
		let staffTypeId = e;
		setStaffTypeId(staffTypeId);
	};

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						const userTypeData = [{ userTypeId: 0, userType: 'All Staff' }];
						data.forEach((obj: { userTypeId: number; userType: string }) => {
							let userType = userTypeData.push(obj);
						});
						setUserTypeData(userTypeData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getAnnouncement(userTypeId:any) {
		getAnnouncementDetails(0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.announcementDetails;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							data[i].colors = colors[Math.floor(Math.random() * colors.length)];
						}

						let studentAnnouncement = data.filter((item: any) => item.userTypeId == 9);
						setStudentAnnouncementData(studentAnnouncement);
						setStudentNoDataMsg(studentAnnouncement == '' ? 'No Data Found' : '');

						let staffAnnouncement = data.filter(
							(item: any) => item.userTypeId != 9 && item.userTypeId != 10,
						);
						setStaffAnnouncementData(staffAnnouncement);
						setStaffNoDataMsg(staffAnnouncement == '' ? 'No Data Found' : '');

						let parentAnnouncement = data.filter((item: any) => item.userTypeId == 10);
						setParentAnnouncementData(parentAnnouncement);
						setParentNoDataMsg(parentAnnouncement == '' ? 'No Data Found' : '');
						setAnnouncementData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAnnouncementData([]);
					setStudentAnnouncementData([])
					setStaffAnnouncementData([])
					setParentAnnouncementData([])
					setStudentNoDataMsg(response.data.message)
					setStaffNoDataMsg(response.data.message)
					setParentNoDataMsg(response.data.message)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedAnnouncement = i;
			setEditAnnouncementOffCanvas(true);
			if (selectedAnnouncement.userTypeId == 0) {
				setStaffTypeId({ value: 0, label: 'All Staff' })
			} else {
				setStaffTypeId({ value: selectedAnnouncement.userTypeId, label: selectedAnnouncement.userType })
			}

			editAnnouncementForm.setValues({
				title: selectedAnnouncement.title,
				fromDate: selectedAnnouncement.fromDate,
				toDate: selectedAnnouncement.toDate,
				announcementDetailsId: selectedAnnouncement.announcementDetailsId,
			});
		}
	}

	function setAnnouncementDetails() {
		return {
			title: announcementForm.values.title,
			fromDate: announcementForm.values.fromDate,
			toDate: announcementForm.values.toDate,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			userTypeId: type == 'student' ? [9] : type == 'parent' ? [10] : staffTypeId,
		};
	}

	function setEditAnnouncementDetails() {
		return {
			announcementDetailsId: editAnnouncementForm.values.announcementDetailsId,
			title: editAnnouncementForm.values.title,
			fromDate: editAnnouncementForm.values.fromDate,
			toDate: editAnnouncementForm.values.toDate,
			staffDetailsId: 1,
			licenseKey: 5566,
			userTypeId: type == 'student' ? 9 : type == 'parent' ? 10 : staffTypeId?.value,
		};
	}

	function addAnnouncementSubmit() {
		showLoader(true);
		if (announcementForm.isValid) {
			let AnnouncementTypePostData = setAnnouncementDetails();
			addAnnouncementDetails(
				AnnouncementTypePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getAnnouncement(userTypeId?.value);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (announcementForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function updateAnnouncementSubmit() {
		showLoader(true);
		if (editAnnouncementForm.isValid) {
			let updateAnnouncementPost = setEditAnnouncementDetails();
			updateAnnouncementDetails(
				updateAnnouncementPost,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getAnnouncement(userTypeId?.value);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editAnnouncementForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteAnnouncementSubmit() {
		showLoader(true);
		let updateAnnouncementPost = setEditAnnouncementDetails();
		deleteAnnouncementDetails(updateAnnouncementPost,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'success' });
					setIsOpen(true);
					closeAndReset();
					getAnnouncement(userTypeId?.value);
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'error' });
					setIsOpen(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					showLoader(false);
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
	}

	function closeAndReset() {
		setAddAnnouncementOffCanvas(false);
		setEditAnnouncementOffCanvas(false);
		setIsDeleteOpen(false);
		announcementForm.resetForm();
		editAnnouncementForm.resetForm();
		setStaffTypeId([]);
		setSelectedOption([])
	}

	return (
		<PageWrapper title='Announcement'>
			<Page>
				<div className='row'>
					<div className='col-lg-6' >
						<Card
							stretch
							className='overflow-hidden border border-light'
							shadow='md'
							tag='form'
							noValidate
							style={{ height: '66vh' }}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Chat' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Staff Announcement
									</CardTitle>
								</CardLabel>
								<CardActions className='d-print-none'>
									<Button
										color='primary'
										icon='Add'
										isLight
										onClick={() => {
											setAddAnnouncementOffCanvas(true);
											setType('staff');
										}}>
										Add New
									</Button>
								</CardActions>
							</CardHeader>

							<CardBody isScrollable>
								{staffAnnouncementData != '' ? (
									<div className='row'>
										{staffAnnouncementData.map((i: any) => (
											<div className='row' key={i.announcementDetailsId}>
												<div className='col-11'>
													<Alert color={i.colors} isLight icon='VerifiedUser' className='flex-nowrap'>
														<AlertHeading>{i.userTypeId > 0 ? i.userType : 'All Staff'} !</AlertHeading>
														<p>{i.title}</p>
													</Alert>
												</div>
												<div className='d-print-none col-1 align-self-center'>
													<Button
														color='dark'
														isLight
														icon='Edit'
														aria-label='Edit'
														onClick={() => {
															onActivate(i);
															setType('staff');
														}}
													/>
												</div>
											</div>
										))}
									</div>
								) : (
									<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
										{staffNoDataMsg}
									</div>
								)}
							</CardBody>
						</Card>
					</div>
					<div className='col-lg-6'>
						<Card
							stretch
							className='overflow-hidden border border-light'
							shadow='md'
							tag='form'
							noValidate
							style={{ height: '66vh' }}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Chat' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Student Announcement
									</CardTitle>
								</CardLabel>
								<CardActions className='d-print-none'>
									<Button
										color='primary'
										icon='Add'
										isLight
										onClick={() => {
											setAddAnnouncementOffCanvas(true);
											setType('student');
										}}>
										Add New
									</Button>
								</CardActions>
							</CardHeader>

							<CardBody isScrollable>
								{studentAnnouncementData != '' ? (
									<div className='row'>
										{studentAnnouncementData.map((i: any) => (
											<div className='row' key={i.announcementDetailsId} >
												<div className='col-11'>
													<Alert color={i.colors} isLight icon='VerifiedUser' className='flex-nowrap'>
														<AlertHeading>Announcement !</AlertHeading>
														<p>{i.title}</p>
													</Alert>
												</div>
												<div className='d-print-none col-1 align-self-center'>
													<Button
														color='dark'
														isLight
														icon='Edit'
														aria-label='Edit'
														onClick={() => {
															onActivate(i);
															setType('student');
														}}
													/>
												</div>
											</div>
										))}
									</div>
								) : (
									<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
										{studentNoDataMsg}
									</div>
								)}
							</CardBody>
						</Card>
					</div>
					<div className='col-lg-6'>
						<Card
							stretch
							className='overflow-hidden border border-light'
							shadow='md'
							tag='form'
							noValidate
							style={{ height: '350px' }}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Chat' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Parent Announcement
									</CardTitle>
								</CardLabel>
								<CardActions className='d-print-none'>
									<Button
										color='primary'
										icon='Add'
										isLight
										onClick={() => {
											setAddAnnouncementOffCanvas(true);
											setType('parent');
										}}>
										Add New
									</Button>
								</CardActions>
							</CardHeader>

							<CardBody isScrollable>
								{parentAnnouncementData != '' ? (
									<div className='row'>
										{parentAnnouncementData.map((i: any) => (
											<div className='row' key={i.announcementDetailsId}>
												<div className='col-11'>
													<Alert color={i.colors} isLight icon='VerifiedUser' className='flex-nowrap'>
														<AlertHeading>Announcement !</AlertHeading>
														<p>{i.title}</p>
													</Alert>
												</div>
												<div className='d-print-none col-1 align-self-center'>
													<Button
														color='dark'
														isLight
														icon='Edit'
														aria-label='Edit'
														onClick={() => {
															onActivate(i);
															setType('parent');
														}}
													/>
												</div>
											</div>
										))}
									</div>
								) : (
									<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
										{parentNoDataMsg}
									</div>
								)}
							</CardBody>
						</Card>
					</div>
				</div>

				<OffCanvas
					setOpen={setAddAnnouncementOffCanvas}
					isOpen={addAddAnnouncementOffCanvas}
					titleId='addAddAnnouncementOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={announcementForm.handleSubmit}>
					<OffCanvasHeader setOpen={setAddAnnouncementOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addAddAnnouncementOffCanvas'>Announcement Creation</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							{type == 'staff' ? (
								<div className='col-12'>
									<FormGroup id='staffTypeId' label='User Type'>
										<SearchableSelect
											ariaLabel='Type'
											onChange={selectUserType}
											value={selectedOption}
											multiple
											list={userTypeData.map((data: any) => ({
												value: data.userTypeId,
												label: data.userType,
											}))}
										/>
									</FormGroup>
								</div>
							) : null}
							<div className='col-12'>
								<FormGroup id='title' label='Title'>
									<Textarea
										autoComplete=''
										placeholder='Enter Title'
										onChange={announcementForm.handleChange}
										onBlur={announcementForm.handleBlur}
										value={announcementForm.values.title}
										isValid={announcementForm.isValid}
										isTouched={announcementForm.touched.title}
										invalidFeedback={announcementForm.errors.title}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='fromDate' label='From Date'>
									<Input
										onChange={announcementForm.handleChange}
										value={announcementForm.values.fromDate}
										onBlur={announcementForm.handleBlur}
										isValid={announcementForm.isValid}
										isTouched={announcementForm.touched.fromDate}
										invalidFeedback={announcementForm.errors.fromDate}
										type='datetime-local'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='toDate' label='To Date'>
									<Input
										onChange={announcementForm.handleChange}
										value={announcementForm.values.toDate}
										onBlur={announcementForm.handleBlur}
										isValid={announcementForm.isValid}
										isTouched={announcementForm.touched.toDate}
										invalidFeedback={announcementForm.errors.toDate}
										type='datetime-local'
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row m-0'>
						<div className='col-12 p-3'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								isDisable={!announcementForm.isValid && !!announcementForm.submitCount}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<OffCanvas
					setOpen={setEditAnnouncementOffCanvas}
					isOpen={editAddAnnouncementOffCanvas}
					titleId='editAddAnnouncementOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={editAnnouncementForm.handleSubmit}>
					<OffCanvasHeader setOpen={setEditAnnouncementOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='editAddAnnouncementOffCanvas'>Update Announcement</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							{type == 'staff' ? (
								<div className='col-12'>
									<FormGroup id='staffTypeId' label='User Type'>
										<SearchableSelect
											ariaLabel='Type'
											onChange={selectUserTypeForEdit}
											value={staffTypeId}
											disabled
											list={userTypeData.map((data: any) => ({
												value: data.userTypeId,
												label: data.userType,
											}))}
										/>
									</FormGroup>
								</div>
							) : null
							}
							<div className='col-12'>
								<FormGroup id='title' label='Title'>
									<Textarea
										placeholder='Enter Title'
										autoComplete=''
										onChange={editAnnouncementForm.handleChange}
										onBlur={editAnnouncementForm.handleBlur}
										value={editAnnouncementForm.values.title}
										isValid={editAnnouncementForm.isValid}
										isTouched={editAnnouncementForm.touched.title}
										invalidFeedback={editAnnouncementForm.errors.title}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='fromDate' label='From Date'>
									<Input
										onChange={editAnnouncementForm.handleChange}
										value={editAnnouncementForm.values.fromDate}
										onBlur={editAnnouncementForm.handleBlur}
										isValid={editAnnouncementForm.isValid}
										isTouched={editAnnouncementForm.touched.fromDate}
										invalidFeedback={editAnnouncementForm.errors.fromDate}
										type='datetime-local'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='toDate' label='To Date'>
									<Input
										onChange={editAnnouncementForm.handleChange}
										value={editAnnouncementForm.values.toDate}
										onBlur={editAnnouncementForm.handleBlur}
										isValid={editAnnouncementForm.isValid}
										isTouched={editAnnouncementForm.touched.toDate}
										invalidFeedback={editAnnouncementForm.errors.toDate}
										type='datetime-local'
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>

					<div className='row'>
						<div className='d-flex justify-content-center position-absolute top-65 start-50 translate-middle'>
							<DeleteComponents
								isDeleteOpen={isDeleteOpen}
								setIsDeleteOpen={setIsDeleteOpen}
								deleteFunction={deleteAnnouncementSubmit}
							/>
						</div>
						<div className='col-6 position-absolute bottom-0 start-0 p-3'>
							<Button
								color='danger'
								className='w-100'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
						</div>
						<div className='col-6 position-absolute bottom-0 end-0 p-3'>
							<Button
								color='info'
								icon='Save'
								type='submit'
								className='w-100'
								isDisable={
									!editAnnouncementForm.isValid && !!editAnnouncementForm.submitCount
								}>
								{' '}
								Update
							</Button>
						</div>
					</div>
				</OffCanvas>
			</Page>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default AddAnnouncement;
