import React, { useContext, useEffect, useState } from 'react'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card'
import Page from '../../../layout/Page/Page'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Input from '../../bootstrap/forms/Input'
import Button from '../../bootstrap/Button'
import { useFormik } from 'formik'
import { toasts } from '../../../services/toast.service'
import useDarkMode from '../../../hooks/useDarkMode'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import { getCity, getStates, onlyAllowNumber } from '../../../services/common.service'
import { addRouteMaster, addRouteTraveller, getRoutePointNumber, getTransportAreaMaster } from '../../../services/transport.service'
import { showLoader } from '../../../services/loader.services'
import { getLicenseKey } from '../../../services/application.settings'
import AuthContext from '../../../contexts/authContext'
import AlertService from '../../../services/AlertService'
import Icon from '../../icon/Icon'
import useSortableData from '../../../hooks/useSortableData'
import { dataPagination } from '../../PaginationButtons'
import useSelectTable from '../../../hooks/useSelectTable'
import Checks from '../../bootstrap/forms/Checks'
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader'
import { useNavigate } from 'react-router'
import SearchableSelect from '../../../common/components/SearchableSelect'
import NoDataMsg from '../../../common/components/NoDataMsg'

const RouteMaster = () => {

    const navigate = useNavigate();

    useEffect(() => {
        getTransportArea();
        getStatesList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [routeNumberData, setRouteNumberData] = useState<any>([
        { pointNumber: 1 },
        { pointNumber: 2 },
        { pointNumber: 3 },
        { pointNumber: 4 },
        { pointNumber: 5 }
    ]);
    const [transportAreaData, setTransportAreaData] = useState<any>([]);
    const [routeTravellerListByRouteMasterData, setRouteTravellerListByRouteMasterData] = useState<any>([]);
    const [routeMasterId, setRouteMasterId] = useState('');

    const [stateId, setStateId] = useState<any>('')
    const [cityData, setCityData] = useState<any>([])
    const [cityId, setCityId] = useState<any>('')
    const [statesData, setStatesData] = useState<any>([])
    const [routeAreaArray, setRouteAreaArray] = useState<any>([])
    const [checkArray, setCheckArray] = useState<any>([])
    const [routeTraveller, setRouteTraveller] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [data, setData] = useState<any>([])

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);

    // Table States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(routeTravellerListByRouteMasterData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

    const routeMasterForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            pointNumber: "",
            routeNumber: "",
            routeName: '',
            stateId: '',
            cityId: '',
        },
        validate: (values) => {
            const errors: {
                routeNumber?: string;
                routeName?: string;
                stateId?: string;
                cityId?: string;
            } = {};
            if (!values.routeNumber) {
                errors.routeNumber = 'Required';
            }
            if (!values.routeName) {
                errors.routeName = 'Required';
            }
            if (!stateId) {
                errors.stateId = 'Required';
            }
            if (!cityId) {
                errors.cityId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { routeMasterSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name
            i.travellerId.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.travellerName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.userType.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.areaName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.address.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
    );

    const selectState = (e: any) => {
        let stateId = e
        setStateId(stateId)
        if (stateId?.value != undefined) {
            getCityList(stateId?.value)
        }
    }

    const selectCity = (e: any) => {
        let cityId = e
        setCityId(cityId)
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // function getRoutePoint() {
    //     getRoutePointNumber(
    //         (response) => {
    //             if (response.data.success) {
    //                 let data = response.data.data.routePointNumber;
    //                 if (data != undefined) {
    //                     setRouteNumberData(data);
    //                 } else {
    //                     toasts("Undefined Data", "Error")
    //                 }
    //             }
    //             else if (response.data.success === false) {
    //                 toasts(response.data.message, "Error")
    //             } else {
    //                 let errorCode = response.data.error[0].error_code;
    //                 let errorDescription = response.data.error[0].error_description;
    //                 toasts(errorDescription, "Error")
    //             }
    //         }, error => {
    //             toasts(error, "Error")
    //         }
    //     )
    // }

    function getTransportArea() {
        getTransportAreaMaster(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transportAreaMaster;
                    if (data != undefined) {
                        setTransportAreaData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setTransportAreaData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function updateRouteArea(pointNumber: number, e: any) {
        const newData: any = [];

        data.forEach((item: any, index: any) => {
            if (item['pointNumber'] == pointNumber) {
                item['transportAreaMasterId'] = e != null ? e : null
            }
            newData.push(item)
        });

        setData(newData)

        if (routeAreaArray.length == 0) {
            var obj
            obj = {
                pointNumber: pointNumber,
                transportAreaMasterId: e != null ? e?.value : null,
            };
            routeAreaArray.push(obj);
        } else {
            let selectedItem: any
            routeAreaArray.forEach((item: any, index: number) => {
                if (item['pointNumber'] == pointNumber) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['transportAreaMasterId'] = e != null ? e?.value : null
            } else {
                var obj
                obj = {
                    pointNumber: pointNumber,
                    transportAreaMasterId: e != null ? e?.value : null,
                };
                routeAreaArray.push(obj);
            }
        }
    }

    function setRouteMasterDetails() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            routeNumber: routeMasterForm.values.routeNumber,
            routeName: routeMasterForm.values.routeName,
            stateId: stateId?.value,
            cityId: cityId?.value,
            routeArea: routeAreaArray,

        })
    }

    function routeMasterSubmit() {
        showLoader(true)
        if (routeMasterForm.isValid) {
            let routeMasterPostData = setRouteMasterDetails()
            addRouteMaster(routeMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(null)

                        let routeMasterId = data.data.routeMasterId;
                        setRouteMasterId(routeMasterId)
                        getRouteTravellerListByRouteMaster(routeMasterId)
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (routeMasterForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function getRouteTravellerListByRouteMaster(routeMasterId: any) {
        // getRouteTravellerListByRouteMasterId(routeMasterId,
        //     (response) => {
        //         if (response.data.success) {
        //             let data = response.data.data.routeTravellerListByRouteMasterId;
        //             if (data != undefined) {
        //                 setRouteTravellerListByRouteMasterData(data);
        //                 setData(data)
        //                 setDataSuccess(true)
        //                 for (let i = 0; i < data.length; i++) {
        //                     data[i].sno = i + 1;
        //                     data[i].id = {
        //                         studentOrStaffDetailsId: data[i].travellerDetailsId.toString(),
        //                         transportAreaMasterId: data[i].transportAreaMasterId.toString(),
        //                         userTypeId: data[i].userTypeId.toString(),
        //                     }
        //                     data[i].isChecked = false
        //                 }
        //             } else {
        //                 toasts("Undefined Data", "Error")
        //             }
        //         }
        //         else if (response.data.success === false) {
        //             toasts(response.data.message, "Error")
        //         } else {
        //             let errorCode = response.data.error[0].error_code;
        //             let errorDescription = response.data.error[0].error_description;
        //             toasts(errorDescription, "Error")
        //         }
        //     }, error => {
        //         toasts(error, "Error")
        //     }
        // )
    }

    function setRouteTravellerDetails() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            routeMasterId: routeMasterId,
            routeTraveller: routeTraveller

        })
    }

    function routeTravellerSubmit() {
        showLoader(true)
        if (routeMasterForm.isValid) {
            let routeTravellerPostData = setRouteTravellerDetails()
            addRouteTraveller(routeTravellerPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        // setIsNavigate(`../management/routeMaster`)
                        setIsNavigate(null)
                        setDataSuccess(false);
                        setRouteMasterId('');
                        setRouteTraveller([]);
                        setRouteNumberData([]);
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (routeMasterForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }


    function closeAndReset() {
        routeMasterForm.resetForm()
        setStateId('')
        setCityId('')
        setRouteAreaArray([])


        const newData: any = [];

        data.forEach((item: any, index: any) => {
            item['transportAreaMasterId'] = null
            newData.push(item)
        });

        setData(newData)
    }


    function onSelect(data1: any, isChecked: any) {

        let newData: any = []
        data.forEach((item: any, index: any) => {
            if (item['travellerDetailsId'] == data1.studentOrStaffDetailsId) {
                item['isChecked'] = isChecked
            }
            newData.push(item)
        });
        setData(newData)

        let checkedArray = data.filter((item: any) => item.isChecked).length
        let totalLength = data.length

        if (checkedArray == totalLength) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }


        if (isChecked == true) {
            const obj = {
                studentOrStaffDetailsId: data1.studentOrStaffDetailsId,
                transportAreaMasterId: data1.transportAreaMasterId,
                userTypeId: data1.userTypeId
            };
            routeTraveller.push(obj);
        }
        else {
            let selectedItem: any;
            routeTraveller.forEach((item: any, index: any) => {
                if (item['studentOrStaffDetailsId'] == data1.studentOrStaffDetailsId) {
                    selectedItem = item
                    routeTraveller.splice(routeTraveller.indexOf(selectedItem), 1);
                }
            });
        }
    }

    function SelectAll(e: any) {
        setIsChecked(e.target.checked)

        const newData: any = [];
        routeTravellerListByRouteMasterData.forEach((item: any, index: any) => {
            item['isChecked'] = e.target.checked
            newData.push(item)
        });
        setData(newData)

        if (e.target.checked == true) {
            routeTravellerListByRouteMasterData.forEach((item: any, index: any) => {

                let selectedItem: any;
                routeTraveller.forEach((travellerItem: any, index: any) => {
                    if (travellerItem['studentOrStaffDetailsId'] == item.studentOrStaffDetailsId) {
                        selectedItem = travellerItem
                        routeTraveller.splice(routeTraveller.indexOf(selectedItem), 1);
                    }
                });
                routeTraveller.push(item.id);
            });

        } else if (e.target.checked == false) {

            routeTravellerListByRouteMasterData.forEach((item: any, index: any) => {

                let selectedItem: any;
                routeTraveller.forEach((travellerItem: any, index: any) => {
                    if (travellerItem['studentOrStaffDetailsId'] == item.studentOrStaffDetailsId) {
                        selectedItem = travellerItem
                        routeTraveller.splice(routeTraveller.indexOf(selectedItem), 1);
                    }
                });
            });
        }
    }

    const [noOfPoint, setNooOfPoint] = useState<any>(5)

    function selectPointNumber(e: any) {

        let newData: any = [];

        for (let i = 0; i < e.target.value; i++) {
            newData.push({ pointNumber: i + 1 })
        }
        setNooOfPoint(e.target.value)
        setRouteNumberData(newData);

    }

    return (
        <PageWrapper title='Route Master'>
            <Page container='fluid'>
                <form noValidate onSubmit={routeMasterForm.handleSubmit}>
                    <div className='row px-4'>
                        <div className='col-xl-4 '>
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='list' iconColor='primary'>
                                        <CardTitle>
                                            Route Master
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>
                                        <div className='col-12'>
                                            <FormGroup id='routeNumber' label='Route Number' isFloating>
                                                <Input
                                                    placeholder='Route Number'
                                                    onBlur={routeMasterForm.handleBlur}
                                                    onChange={routeMasterForm.handleChange}
                                                    value={routeMasterForm.values.routeNumber}
                                                    isValid={routeMasterForm.isValid}
                                                    isTouched={routeMasterForm.touched.routeNumber}
                                                    invalidFeedback={routeMasterForm.errors.routeNumber}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='routeName' label='Route Name' isFloating >
                                                <Input
                                                    placeholder='Route Name'
                                                    onBlur={routeMasterForm.handleBlur}
                                                    onChange={routeMasterForm.handleChange}
                                                    value={routeMasterForm.values.routeName}
                                                    isValid={routeMasterForm.isValid}
                                                    isTouched={routeMasterForm.touched.routeName}
                                                    invalidFeedback={routeMasterForm.errors.routeName}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='stateId' label='State' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='State'
                                                    onChange={selectState}
                                                    onBlur={routeMasterForm.handleBlur}
                                                    value={stateId}
                                                    list={statesData.map((data: any) => (
                                                        { value: data.stateId, label: data.stateName }
                                                    ))}
                                                    isValid={routeMasterForm.isValid}
                                                    isTouched={routeMasterForm.touched.stateId}
                                                    invalidFeedback={routeMasterForm.errors.stateId}
                                                    required />
                                            </FormGroup>
                                        </div>

                                        <div className='col-12'>
                                            <FormGroup id='cityId' label='City' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='City'
                                                    onChange={selectCity}
                                                    onBlur={routeMasterForm.handleBlur}
                                                    value={cityId}
                                                    list={cityData.map((data: any) => (
                                                        { value: data.cityId, label: data.cityName }
                                                    ))}
                                                    isValid={routeMasterForm.isValid}
                                                    isTouched={routeMasterForm.touched.cityId}
                                                    invalidFeedback={routeMasterForm.errors.cityId}
                                                    required />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='noOfPoint' label='No Of Point' isFloating >
                                                <Input
                                                    placeholder='No Of Point'
                                                    onChange={selectPointNumber}
                                                    value={noOfPoint}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-xl-8'>
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='AddLocationAlt' iconColor='primary'>
                                        <CardTitle>
                                            Area Allocation
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-10">
                                            <table className="table table-modern table-hover text-nowrap text-md-nowrap mg-b-0">
                                                <thead>
                                                    <tr className='text-center table-primary fs-6'>
                                                        <th>Point Number</th>
                                                        <th>Area</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {routeNumberData != '' || routeNumberData > 0 ? <>
                                                        {routeNumberData.map((routeNumber: any) => (
                                                            <tr key={routeNumber.pointNumber}>
                                                                <td className='text-center'>{routeNumber.pointNumber}</td>
                                                                <td>
                                                                    <div className='col-12'>
                                                                        <FormGroup id="transportAreaMasterId">
                                                                            <SearchableSelect
                                                                                ariaLabel=''
                                                                                placeholder='Select'
                                                                                onChange={(e) => updateRouteArea(routeNumber.pointNumber, e)}
                                                                                value={routeNumber.transportAreaMasterId}
                                                                                list={transportAreaData.map((data: any) => (
                                                                                    { value: data.transportAreaMasterId, label: data.areaName }
                                                                                ))}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))} </> : <NoDataMsg columnsCount={2} msg={'No data dound'} />
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {routeNumberData != '' || routeNumberData > 0 ?
                                            <div className="col-lg-12 d-flex justify-content-center">
                                                <FormGroup>
                                                    <Button icon="Save" color="info" type="submit" isDisable={!routeMasterForm.isValid && !!routeMasterForm.submitCount && routeAreaArray.length == 0}>
                                                        Save
                                                    </Button>
                                                </FormGroup>
                                            </div> : null
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </form>
                {dataSuccess ?
                    <>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <div className='d-flex col-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>Traveller List</CardTitle>
                                    </CardLabel>
                                </div>
                            </CardHeader>
                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col'>
                                                <Checks
                                                    id='selectAll'
                                                    onChange={SelectAll}
                                                    checked={isChecked}
                                                    ariaLabel='Select All'
                                                />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('travellerId')}
                                                className='cursor-pointer text-decoration-underline'>Traveller Id{' '}
                                                <Icon size='lg' className={getClassNamesFor('travellerId')} icon='FilterList' />
                                            </th>

                                            <th scope='col' onClick={() => requestSort('travellerName')}
                                                className='cursor-pointer text-decoration-underline'>Traveller Name{' '}
                                                <Icon size='lg' className={getClassNamesFor('travellerName')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('userType')}
                                                className='cursor-pointer text-decoration-underline'>Type{' '}
                                                <Icon size='lg' className={getClassNamesFor('userType')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('areaName')}
                                                className='cursor-pointer text-decoration-underline'>Area Name{' '}
                                                <Icon size='lg' className={getClassNamesFor('areaName')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('address')}
                                                className='cursor-pointer text-decoration-underline'>Address{' '}
                                                <Icon size='lg' className={getClassNamesFor('address')} icon='FilterList' />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map((i: any) => (
                                            <tr key={i.travellerDetailsId}>

                                                <td>
                                                    <Checks
                                                        value={i.travellerDetailsId}
                                                        onChange={(e: any) => onSelect(i.id, e.target.checked)}
                                                        checked={i.isChecked}
                                                    />
                                                </td>
                                                <td>{i.sno}</td>
                                                <td>{i.travellerId}</td>
                                                <td>{i.travellerName}</td>
                                                <td>{i.userType}</td>
                                                <td>{i.areaName}</td>
                                                <td className='text-wrap'>{i.address}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                            {/* <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        /> */}
                        </Card>
                        <SubHeader>
                            <SubHeaderLeft><></></SubHeaderLeft>
                            <SubHeaderRight>
                                <SubheaderSeparator />
                                <Button icon='Save' color='info' isDisable={routeTraveller.length == 0} onClick={routeTravellerSubmit}>Submit</Button>
                            </SubHeaderRight>
                        </SubHeader>
                    </>
                    : null
                }
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

        </PageWrapper>
    );
}

export default RouteMaster