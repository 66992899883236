import React, { useContext, useEffect, useState } from 'react';
import Card, { CardLabel, CardTitle, CardBody } from '../../bootstrap/Card';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { toasts } from '../../../services/toast.service';
import { getGalleryImages } from '../../../services/complaint.service';
import Modal, { ModalHeader, ModalTitle, ModalBody } from '../../bootstrap/Modal';
import Popovers from '../../bootstrap/Popovers';
import SubHeader, { SubHeaderLeft } from '../../../layout/SubHeader/SubHeader';
import AuthContext from '../../../contexts/authContext';

const GalleryList = () => {

	useEffect(() => {
		getGalleryImagesList();
	}, []);

	const [galleryData, setGalleryData] = useState<any>([]);
	const [selectedImage, setSelectedImage] = useState<any>(undefined);
	const [showImage, setShowImage] = useState<any>(false);
	const [dataStatus, setDataStatus] = useState<any>(false);
	const [noDataMsg, setNoDataMsg] = useState<any>(false);

	function getGalleryImagesList() {
		getGalleryImages(0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.galleryImages;
					if (data != undefined) {
						setDataStatus(true);
						for (let i = 0; i < data.length; i++) {
							let imageData = data[i].image;
							for (let j = 0; j < imageData.length; j++) {
								imageData[j].src = imageData[j].imagePath;
							}
						}
						const allImagePaths = data.flatMap((item: any) =>
							item.image.map((img: any) => ({ galleryType: item.galleryType, imagePath: img.imagePath })),
						);
						setGalleryData(allImagePaths);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					//toasts(response.data.message, 'Error');
					setGalleryData([]);
					setNoDataMsg(response.data.message);
				} else {
					setDataStatus(true);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	return (
		<PageWrapper title='Gallery'>
			<SubHeader>
				<SubHeaderLeft>
					<CardLabel icon='PhotoAlbum' iconColor='primary'>
						<CardTitle tag='div' className='h5 text-uppercase'>
							Gallery
						</CardTitle>
					</CardLabel>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row g-4'>
					{galleryData.map((imageData: any, i: any) => (
						<div key={imageData.imagePath} className='col-3'>
							<Popovers trigger='hover' desc={<b>Preview</b>}>
								<Card stretch shadow={'md'} className='border border-light rounder-0' onClick={() => { setSelectedImage(imageData); setShowImage(true) }}>
									<CardBody>
										<img
											src={imageData.imagePath}
											alt={imageData.imagePath}
											width={220}
											height={200}
										/>
										<span className='d-flex justify-content-center mt-3 text-uppercase fw-bold text-info'>{imageData.galleryType}</span>
									</CardBody>

								</Card>
							</Popovers>
						</div>

					))}
				</div>

				<Modal setIsOpen={setShowImage} isOpen={showImage} isCentered>
					<ModalHeader setIsOpen={setShowImage}>
						<ModalTitle id='preview'>{selectedImage?.galleryType}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<img src={selectedImage?.imagePath} alt={selectedImage?.imagePath} width='100%' height='auto' className='object-fit-contain p-4' />
					</ModalBody>
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default GalleryList;
