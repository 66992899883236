import React, { useContext, useEffect, useRef, useState } from 'react';
import useSortableData from '../../../hooks/useSortableData';
import useDarkMode from '../../../hooks/useDarkMode';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { useFormik } from 'formik';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import { toasts } from '../../../services/toast.service';
import Page from '../../../layout/Page/Page';
import Collapse from '../../bootstrap/Collapse';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import { useReactToPrint } from 'react-to-print';
import Icon from '../../icon/Icon';
import Input from '../../bootstrap/forms/Input';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService';
import AlertService from '../../../services/AlertService';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../bootstrap/OffCanvas';
import { useNavigate } from 'react-router-dom';
import Textarea from '../../bootstrap/forms/Textarea';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import {
	addStudentTransportDetails,
	deleteStudentDetails,
	deleteStudentHobbieDetails,
	deleteStudentSkills,
	getAllStudent,
	getProfileForStudent,
	getStudentDetailsBySectionDetailsId,
	getStudentSkills,
} from '../../../services/student.service';
import {
	addHobbieType,
	addSkillType,
	addStudentHobbieDetails,
	addStudentSkills,
	getColumnsForDataTable,
	getHobbieType,
	getMedium,
	getSkillType,
	getStandardDetails,
	getStudentHobbieDetails,
	pictNotLoading,
	profilePic,
	updateColumnsForDataTable,
	updateFilter,
} from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { getTransportAreaMaster } from '../../../services/transport.service';
import {
	addStudentScholarshipDetails,
	getScholarshipDetailsByStudentDetailsId,
	getScholarshipType,
} from '../../../services/scholarship.service';
import Badge from '../../bootstrap/Badge';
import { showLoader } from '../../../services/loader.services';
import Select from '../../bootstrap/forms/Select';
import NoDataMsg from '../../../common/components/NoDataMsg';
import dayjs from 'dayjs';
import SearchableSelect from '../../../common/components/SearchableSelect';
import { getBatchMasterDetails, getSectionDetails } from '../../../services/master.service';
import Alert from '../../bootstrap/Alert';
import InputGroup from '../../bootstrap/forms/InputGroup';
import Modal, { ModalHeader, ModalBody } from '../../bootstrap/Modal';

const StudentList = () => {

	useEffect(() => {
		getBatchList();
		getAllStudentDetails();
		getTransportAreaMasterList();
		getScholarshipTypeList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);
	const [transportAreaMasterId, setTransportAreaMasterId] = useState<any>(Number);

	const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);
	const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);
	const [transportAreaMasterData, setTransportAreaMasterData] = useState<any>([]);
	const [sectionDetailsData, setSectionDetailsData] = useState<any>([]);
	const [studentDetailsData, setStudentDetailsData] = useState<any>([]);
	const [hobbieDetailsData, setHobbieDetailsData] = useState<any>([]);
	const [studentDetailsData1, setStudentDetailsData1] = useState<any>([]);
	const [allStudentData, setAllStudentData] = useState<any>([]);
	const [skillTypeData, setSkillTypeData] = useState<any>([]);
	const [hobbieTypeData, setHobbieTypeData] = useState<any>([]);
	const [scholarshipTypeData, setScholarshipTypeData] = useState<any>([]);
	const [studentScholarshipData, setStudentScholarshipData] = useState([]);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [dataSuccess, setDataSuccess] = useState(false);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [addUpdateTransportCanvas, setUpdateTransportCanvas] = useState(false);
	const [addAssignScholarshipCanvas, setAssignScholarshipCanvas] = useState(false);
	const [addSkillTypeOffCanvas, setAddSkillTypeOffCanvas] = useState(false);
	const [addHobbieTypeOffCanvas, setAddHobbieTypeOffCanvas] = useState(false);
	const [deleteStudentListCanvas, setDeleteStudentListCanvas] = useState(false);
	const [isRemove, setIsRemove] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [scholarshipTypeIdArray, setStudentScholarshipIdArray] = useState<any>([]);
	const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([]);
	const [studentTransportDetailsData, setStudentTransportDetailsData] = useState<any>([]);
	const navigate = useNavigate();

	const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [ifRemarksShow, setIfRemarksShow] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [mediumId, setMediumId] = useState<any>('');
	const [batchMasterId, setBatchMasterId] = useState<any>('');
	const [standardData, setStandardData] = useState<any>([]);
	const [mediumData, setMediumData] = useState<any>([]);
	const [batchMasterData, setBatchMasterData] = useState<any>([]);
	const [selectedOption, setSelectedOption] = useState<any>([]);
	const [skillTypeId, setSkillTypeId] = useState<any>('');
	const [hobbieTypeId, setHobbieTypeId] = useState<any>('');
	const colors = ['info', 'success', 'warning', 'secondary', 'primary', 'dark'];

	const skillTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			skillTypeId: '',
		},
		validate: (values) => {
			const errors: {
				skillTypeId?: string;
			} = {};
			if (!skillTypeId) {
				errors.skillTypeId = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: (values) => {
			addSkillTypeSubmit();
		},
	});

	const hobbieTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			hobbieTypeId: '',
		},
		validate: (values) => {
			const errors: {
				hobbieTypeId?: string;
			} = {};
			if (!hobbieTypeId) {
				errors.hobbieTypeId = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: (values) => {
			addHobbieTypeSubmit();
		},
	});

	const updateStudentTransportAreaForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			studentDetailsId: '',
			transportAreaMasterId: '',
			modeOfWays: '',
			isRemove: 0,
			remarks: '',
		},
		validate: (values) => {
			const errors: {
				transportAreaMasterId?: string;
				modeOfWays?: string;
				remarks?: string;
			} = {};
			if (!transportAreaMasterId) {
				errors.transportAreaMasterId = 'Required';
			}
			if (!values.modeOfWays) {
				errors.modeOfWays = 'Required';
			}
			if (ifRemarksShow) {
				if (!values.remarks) {
					errors.remarks = 'Required';
				}
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: (values) => {
			addStudentTransportDetailsSubmit();
		},
	});

	const deleteStudentForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			remarks: '',
			lastDateOfAttendance: '',
		},
		validate: (values) => {
			const errors: {
				lastDateOfAttendance?: string;
			} = {};
			if (!values.lastDateOfAttendance) {
				errors.lastDateOfAttendance = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			setIsDeleteOpen(!isDeleteOpen);
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedStudent = i;
			setStudentDetailsId({
				value: selectedStudent.studentDetailsId,
				label: selectedStudent.studentNameAndAdmissionNo,
			});
			getStudentProfile1(selectedStudent.studentDetailsId);
		}
	}

	const selectBatch = (e: any) => {
		setStandardDetailsId('');
		setSectionDetailsId('');
		setMediumId('');
		let batchMasterId = e;
		setBatchMasterId(batchMasterId);
		getMediumList();
	};

	const selectMedium = (e: any) => {
		setStandardDetailsId('');
		setSectionDetailsId('');
		let mediumId = e;
		setMediumId(mediumId);
		getStandardList();
	};

	const selectStandard = (e: any) => {
		setSectionDetailsId('');
		let standardDetailsId = e;
		setStandardDetailsId(standardDetailsId);
		if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
			getSectionDetailsList(mediumId?.value, standardDetailsId?.value);
		}
	};

	const selectSection = (e: any) => {
		setStudentDetailsId('');
		let sectionDetailsId = e;
		setSectionDetailsId(sectionDetailsId);
	};

	const selectStudent = (e: any) => {
		setDataSuccess(false);
		setIsOpenListCard(true);
		let studentDetailsId = e;
		setStudentDetailsId(studentDetailsId);
	};

	function viewParticularStudent() {
		setDataSuccess(false);
		setIsLoader(true);
		columnVisibilityForm.resetForm();
		if (studentDetailsId?.value != undefined) {
			getStudentProfile(studentDetailsId?.value);
			getHobbieDetails(studentDetailsId?.value);
			getStudentSkillsList(studentDetailsId?.value);
		}
	}

	function viewStudentClassWiseList() {
		setDataSuccess(false);
		setIsLoader(true);
		columnVisibilityForm.resetForm();
		if (sectionDetailsId?.value != undefined) {
			getStudentList(batchMasterId?.value, sectionDetailsId?.value);
		}
	}

	const [transportDetails, setTransportDetails] = useState<any>([]);

	function updateStudentTransport(i: any) {
		setUpdateTransportCanvas(true);
		getStudentProfile1(i.studentDetailsId);
		setTransportDetails(i.transportDetails);
	}

	function assignScholarshipForStudent() {
		setAssignScholarshipCanvas(true);
		getScholarshipDetailsByStudentDetails(studentDetailsId?.value);
	}

	function removeTransport(e: any) {
		setIsRemove(e.target.checked);
	}

	function selectTransportArea(e: any) {
		let transportAreaMasterId = e;
		setTransportAreaMasterId(transportAreaMasterId);
	}

	function selectScholarship(e: any, scholarshipTypeId: any) {
		const newData: any = [];
		scholarshipTypeData.forEach((item: any, index: any) => {
			if (item['scholarshipTypeId'] == scholarshipTypeId) {
				item['isChecked'] = e.target.checked;
			}
			newData.push(item);
		});
		setFilteredDataToRefresh(newData);
		if (e.target.checked == true) {
			scholarshipTypeIdArray.push(scholarshipTypeId);
		} else if (e.target.checked == false) {
			let unCheckArrayId = scholarshipTypeIdArray.indexOf(Number(scholarshipTypeId));

			scholarshipTypeIdArray.splice(unCheckArrayId, 1);
			const newData: any = [];
			scholarshipTypeData.forEach((item: any, index: any) => {
				if (item['scholarshipTypeId'] == scholarshipTypeId) {
					item['isChecked'] = e.target.checked;
				}
				newData.push(item);
			});
		}
	}

	function setSkillTypeDetails() {
		return {
			skillTypeId: skillTypeId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			studentDetailsId: studentDetailsId?.value,
		};
	}

	function addSkillTypeSubmit() {
		showLoader(true);
		if (skillTypeForm.isValid) {
			let skillTypePostData = setSkillTypeDetails();
			addStudentSkills(
				skillTypePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getSkillTypeDetails()
						getStudentSkillsList(studentDetailsId?.value);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (skillTypeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function setHobbiTypeDetails() {
		return {
			hobbieTypeId: hobbieTypeId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			studentDetailsId: studentDetailsId?.value,
		};
		1;
	}

	function addHobbieTypeSubmit() {
		showLoader(true);
		if (hobbieTypeForm.isValid) {
			let hobbieTypePostData = setHobbiTypeDetails();
			addStudentHobbieDetails(
				hobbieTypePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getHobbieTypeList()
						getHobbieDetails(studentDetailsId?.value);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (hobbieTypeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	const [studentHobbieDetailsId, setStudentHobbieDetailsId] = useState<any>('');

	function setDeleteStudentHobbies() {
		return {
			studentHobbieDetailsId: studentHobbieDetailsId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
		};
	}

	function deleteStudentHobbieSubmit() {
		showLoader(true);
		if (studentHobbieDetailsId > 0) {
			let StudentHobbyPostData = setDeleteStudentHobbies();
			deleteStudentHobbieDetails(StudentHobbyPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setIsDeleteOpen(false);
						getHobbieDetails(studentDetailsId?.value);
						setStudentHobbieDetailsId('');
						getHobbieTypeList()
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (studentHobbieDetailsId == 0 || studentHobbieDetailsId == undefined) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function setAddStudentScholarship() {
		return {
			studentDetailsId: studentDetailsId?.value,
			licenseKey: getLicenseKey,
			scholarshipTypeId: scholarshipTypeIdArray,
			assignedBy: userAccountId,
		};
	}

	function setStudentTransportDetails() {
		return {
			studentDetailsId: studentDetailsId?.value,
			transportAreaMasterId: transportAreaMasterId?.value,
			modeOfWays: updateStudentTransportAreaForm.values.modeOfWays,
			remarks: updateStudentTransportAreaForm.values.remarks,
			isRemove: isRemove ? 1 : 0,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function setDeleteStudentDetails() {
		return {
			studentDetailsId: studentDetailsId?.value,
			remarks: deleteStudentForm.values.remarks,
			lastDateOfAttendance: deleteStudentForm.values.lastDateOfAttendance,
			userAccountId: userAccountId,
			licenseKey: getLicenseKey,
		};
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSectionDetailsList(mediumId: any, standardDetailsId: any) {
		getSectionDetails(
			mediumId,
			standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sectionDetails;
					if (data != undefined) {
						setSectionDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setSectionDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStudentProfile(studentDetailsId: number) {
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile;
					if (data != undefined) {
						setIsLoader(false);
						getColumnsForTable('getStudentDetails', 'get');
						setStudentDetailsData(data);
						setDataSuccess(true);
						setIsOpenListCard(false);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					getColumnsForTable('getStudentDetails', 'get');
					setStudentDetailsData([]);
					setNoDataMsg(response.data.message);
					//toasts(response.data.message, "Error")
					setDataSuccess(false);
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	const [studentSkillsData, setStudentSkillsData] = useState<any>([]);
	const [skillNoDataMsg, setSkillNoDataMsg] = useState<any>('');
	const [studentSkillsId, setStudentSkillsId] = useState<any>('');

	function getStudentSkillsList(studentDetailsId: any) {
		getStudentSkills(studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentSkills[0].skills;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							data[i].color = colors[Math.floor(Math.random() * colors.length)];
						}
						setStudentSkillsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setStudentSkillsData([]);
					setSkillNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [hobbieNoDataMsg, setHobbieNoDataMsg] = useState<any>('');

	function getHobbieDetails(studentDetailsId: number) {
		getStudentHobbieDetails(studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentHobbieDetails[0].hobbies;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							data[i].color = colors[Math.floor(Math.random() * colors.length)];
						}
						setHobbieDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setHobbieDetailsData([]);
					setHobbieNoDataMsg(response.data.message);
					//toasts(response.data.message, "Error")
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}
	function getStudentProfile1(studentDetailsId: any) {
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile;
					if (data != undefined) {
						setStudentDetailsData1(data);
						setIfRemarksShow(
							data[0].transportDetails?.studentTransportDetailsId != null
								? true
								: false,
						);
						setTransportAreaMasterId(
							data[0].transportDetails?.studentTransportDetailsId != null
								? {
									value: data[0].transportDetails?.transportAreaMasterId,
									label: data[0].transportDetails?.areaName,
								}
								: null,
						);
						updateStudentTransportAreaForm.setValues({
							studentDetailsId: data[0].studentDetailsId,
							transportAreaMasterId: data[0].transportDetails?.transportAreaMasterId,
							modeOfWays: data[0].transportDetails?.modeOfWays,
							isRemove: 0,
							remarks: '',
						});
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					setStudentDetailsData1([]);
					setNoDataMsg(response.data.message);
					setIfRemarksShow(false);
					//toasts(response.data.message, "Error")
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getStudentList(batchMasterId: any, sectionDetailsId: number) {
		getStudentDetailsBySectionDetailsId(
			batchMasterId,
			sectionDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetailsBySectionDetailsId;
					if (data != undefined) {
						setIsLoader(false);
						setIsOpenListCard(false);
						getColumnsForTable('getStudentDetails', 'get');
						setStudentDetailsData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataSuccess(true);
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
						setIsOpenListCard(true);
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					getColumnsForTable('getStudentDetails', 'get');
					setStudentDetailsData([]);
					setNoDataMsg(response.data.message);
					//toasts(response.data.message, "Error")
					setDataSuccess(true);
					setIsOpenListCard(false);
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
					setIsOpenListCard(true);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getTransportAreaMasterList() {
		getTransportAreaMaster(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.transportAreaMaster;
					if (data != undefined) {
						setTransportAreaMasterData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setTransportAreaMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getScholarshipTypeList() {
		getScholarshipType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.scholarshipType;
					if (data != undefined) {
						setScholarshipTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getScholarshipDetailsByStudentDetails(studentDetailsId: any) {
		getScholarshipDetailsByStudentDetailsId(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.scholarshipDetailsByStudentDetailsId;
					if (data != undefined) {
						setStudentScholarshipData(data);
						data.forEach((scholarshipType: any) => {
							scholarshipTypeData.forEach((item: any) => {
								if (item.scholarshipTypeId == scholarshipType.scholarshipTypeId) {
									item['isChecked'] = true;
									item['dateStamp'] = scholarshipType.dateStamp;
									scholarshipTypeIdArray.push(item['scholarshipTypeId']);
								}
							});
						});
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getAllStudentDetails() {
		getAllStudent(userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetails;
					if (data != undefined) {
						setAllStudentData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAllStudentData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getHobbieTypeList() {
		getHobbieType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.hobbieType;
					if (data != undefined) {
						let studentHobbies = hobbieDetailsData.map((item: any) => item.hobbieTypeId)
						let data1 = data.filter((item: any) => !studentHobbies.includes(item.hobbieTypeId));
						setHobbieTypeData(data1);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setHobbieTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSkillTypeDetails() {
		getSkillType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.skillType;
					if (data != undefined) {
						let studentSkills = studentSkillsData.map((item: any) => item.skillTypeId)
						let data1 = data.filter((item1: any) => !studentSkills.includes(item1.skillTypeId));
						setSkillTypeData(data1);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSkillTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function addStudentScholarshipSubmit() {
		showLoader(true);
		if (scholarshipTypeIdArray.length > 0) {
			let studentScholarshipPostData = setAddStudentScholarship();
			addStudentScholarshipDetails(
				studentScholarshipPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getScholarshipDetailsByStudentDetails(studentDetailsId?.value);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (scholarshipTypeIdArray.length == 0) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function addStudentTransportDetailsSubmit() {
		showLoader(true);
		if (updateStudentTransportAreaForm.isValid) {
			let addStudentTransportDetailsPostData = setStudentTransportDetails();
			addStudentTransportDetails(
				addStudentTransportDetailsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						if (isStudentOrClassWise) {
							getStudentProfile(studentDetailsId?.value);
							getStudentProfile1(studentDetailsId?.value);
						} else {
							getStudentList(batchMasterId?.value, sectionDetailsId?.value);
						}

						closeAndReset();
						setUpdateTransportCanvas(false);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (updateStudentTransportAreaForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteStudentSubmit() {
		showLoader(true);
		if (deleteStudentForm.isValid) {
			let deleteStudentPostData = setDeleteStudentDetails();
			deleteStudentDetails(
				deleteStudentPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setDataSuccess(false);
						setIsOpenListCard(true);
						selectStudentCardForDelete();
						getAllStudentDetails();
						closeAndReset();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (deleteStudentForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	const [deleteOpen, setDeleteOpen] = useState(false);

	function setDeleteStudentSkills() {
		return {
			studentSkillsId: studentSkillsId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
		};
	}

	function deleteStudentSkillsSubmit() {
		showLoader(true);
		if (studentSkillsId > 0) {
			let leavePostData = setDeleteStudentSkills();
			deleteStudentSkills(
				leavePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setDeleteOpen(false);
						getStudentSkillsList(studentDetailsId?.value);
						setStudentSkillsId('');
						getSkillTypeDetails()
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (studentSkillsId == 0 || studentSkillsId == undefined) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStudentDetails', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function closeAndReset() {
		setSelectedOption([])
		setSkillTypeId([])
		setHobbieTypeId([])
		deleteStudentForm.resetForm();
		updateStudentTransportAreaForm.resetForm();
		setStudentScholarshipIdArray([]);
		setFilteredDataToRefresh([]);
		setIsRemove(false);
		setTransportAreaMasterId('');
		setIsDeleteOpen(false);
		setDeleteOpen(false);
		const newData: any = [];
		scholarshipTypeData.forEach((item: any, index: any) => {
			item['isChecked'] = false;
			newData.push(item);
		});
		setFilteredDataToRefresh(newData);
		setDeleteStudentListCanvas(false);
		skillTypeForm.resetForm();
		hobbieTypeForm.resetForm();
	}

	function selectStudentCard() {
		setIsStudentOrClassWise(!isStudentOrClassWise);
		setDataSuccess(false);
		setStandardDetailsId('');
		setMediumId('');
		setSectionDetailsId('');
		setStudentDetailsId('');
	}

	function selectStudentCardForDelete() {
		setDataSuccess(false);
		setStandardDetailsId('');
		setMediumId('');
		setSectionDetailsId('');
		setStudentDetailsId('');
	}

	function selectedSkills(e: any) {
		let skillTypeId = e;
		setSelectedOption(skillTypeId.value);
		const skillsArrayId = skillTypeId.map((e: any) => e.value);
		setSkillTypeId(skillsArrayId);
	}

	function selectedHobbie(e: any) {
		let hobbieTypeId = e;
		setSelectedOption(hobbieTypeId.value);
		const hobbiesArrayId = hobbieTypeId.map((e: any) => e.value);
		setHobbieTypeId(hobbiesArrayId);
	}

	return (
		<PageWrapper title='Student List'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-6'>
								<CardTitle tag='div' className='h5'>
									Student List
								</CardTitle>
							</CardLabel>
							<CardActions>
								<Button
									icon='PersonSearch'
									color='info'
									isLight={isStudentOrClassWise ? false : true}
									onClick={selectStudentCard}>
									Student
								</Button>

								<Button
									icon='PeopleAlt'
									color='info'
									isLight={isStudentOrClassWise ? true : false}
									onClick={selectStudentCard}>
									Class Wise
								</Button>
								{userTypeId == 1 ? (
									<Button
										className='float-end'
										icon='Add'
										color='primary'
										isLight
										tag='a'
										to={`../academic/addStudent`}>
										Add New
									</Button>
								) : null}
							</CardActions>
						</CardHeader>
						{isStudentOrClassWise ? (
							<CardBody>
								<div className='row'>
									<div className='col-4'>
										<FormGroup id='studentDetailsId' label='Student' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Student'
												onChange={selectStudent}
												value={studentDetailsId}
												list={allStudentData.map((data: any) => ({
													value: data.studentDetailsId,
													label: data.studentNameAndRollNo,
												}))}
												required
											/>
										</FormGroup>
									</div>
									<div className='col-3'>
										<Button
											className='mt-2'
											icon='ArrowDownward'
											color='primary'
											onClick={viewParticularStudent}
											isDisable={studentDetailsId?.value == undefined}>
											View
										</Button>
									</div>
								</div>
							</CardBody>
						) : (
							<CardBody>
								<div className='row'>
									<div className='col-md-2'>
										<FormGroup id='batchMasterId' label='Batch' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Batch'
												placeholder='Batch'
												onChange={selectBatch}
												value={batchMasterId}
												list={batchMasterData.map((data: any) => ({
													value: data.batchMasterId,
													label: data.batchName,
												}))}
												required
											/>
										</FormGroup>
									</div>
									{batchMasterId?.value != undefined ? (
										<div className='col-md-2'>
											<FormGroup id='mediumId' label='Medium' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Medium'
													placeholder='Medium'
													onChange={selectMedium}
													value={mediumId}
													list={mediumData.map((data: any) => ({
														value: data.mediumId,
														label: data.medium,
													}))}
													required
												/>
											</FormGroup>
										</div>
									) : null}
									{mediumId?.value != undefined ? (
										<div className='col-md-2'>
											<FormGroup
												id='standardDetailsId'
												label='Standard'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Standard'
													placeholder='Standard'
													onChange={selectStandard}
													value={standardDetailsId}
													list={standardData.map((data: any) => ({
														value: data.standardDetailsId,
														label: data.standardName,
													}))}
													required
												/>
											</FormGroup>
										</div>
									) : null}
									{standardDetailsId?.value != undefined ? (
										<div className='col-md-2'>
											<FormGroup
												id='sectionDetailsId'
												label='Section'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Section'
													onChange={selectSection}
													value={sectionDetailsId}
													list={sectionDetailsData.map((data: any) => ({
														value: data.sectionDetailsId,
														label: data.sectionName,
													}))}
												/>
											</FormGroup>
										</div>
									) : null}

									{sectionDetailsId?.value != undefined ? (
										<div className='col-3'>
											<Button
												className='mt-2'
												icon='ArrowDownward'
												color='primary'
												isDisable={sectionDetailsId?.value == undefined}
												onClick={viewStudentClassWiseList}>
												View
											</Button>
										</div>
									) : null}
								</div>
							</CardBody>
						)}
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? (
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-6'>
								<CardTitle tag='div' className='h5'>
									Student List
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForTable(
																		'getStudentDetails',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div
														className='col-12'
														style={{
															maxHeight: '200px',
															overflowY: 'scroll',
														}}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		id='{i.columnVisibilityId}'
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={
																			i.isDefault == true
																		}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'Student List',
														studentDetailsData,
														columnVisibilityData,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														studentDetailsData,
														columnVisibilityData,
														'Student List',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'Student List',
														studentDetailsData,
														columnVisibilityData,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={handlePrint}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</CardActions>
						</CardHeader>

						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr>
										<th
											scope='col'
											onClick={() => requestSort('sno')}
											className='cursor-pointer text-decoration-underline'>
											S.No{' '}
											<Icon
												size='lg'
												className={getClassNamesFor('sno')}
												icon='FilterList'
											/>
										</th>
										{columnVisibilityData.map((column: any) => (
											<th
												key={column.keyName}
												scope='col'
												onClick={() => requestSort(column.keyName)}
												className='cursor-pointer text-decoration-underline'>
												{column.columnName}
												<Icon
													size='lg'
													className={getClassNamesFor(column.keyName)}
													icon='FilterList'
												/>
											</th>
										))}
										<td className='d-print-none' />
									</tr>
								</thead>
								<tbody>
									{filteredData != '' ? (
										<>
											{filteredData.map((rowData: any) => (
												<tr key={rowData.studentDetailsId}>
													<td>{rowData.sno}</td>
													{columnVisibilityData.map((column: any) =>
														column.isDisplay &&
															rowData[column.keyName] ? (
															<td
																key={`${rowData.studentDetailsId}-${column.keyName}`}>
																{rowData[column.keyName]}
															</td>
														) : (
															<td key={`empty-${column.keyName}`} />
														),
													)}
													<td
														className='d-print-none'
														onClick={() => { onActivate(rowData) }}>
														{userTypeId == 2 || userTypeId == 3 || userTypeId == 4 || userTypeId == 5 ? (
															<Button
																icon='PersonPin'
																isLight
																color='info'
																onClick={() =>
																	navigate(
																		`../academic/studentProfile/${rowData.studentDetailsId}`,
																	)
																}>
																Profile
															</Button>
														) : (
															<Dropdown isButtonGroup>
																<DropdownToggle>
																	<Button
																		className='text-nowrap'
																		color='dark'
																		isLight
																		icon='MoreHoriz'
																		aria-label='MoreHoriz'>
																		More
																	</Button>
																</DropdownToggle>
																<DropdownMenu
																	isAlignmentEnd
																	className='card-body-scrollable'
																	size='lg'>
																	<DropdownItem>
																		<Button
																			icon='PersonPin'
																			isLight
																			color='info'
																			onClick={() =>
																				navigate(
																					`../academic/studentProfile/${studentDetailsId?.value}`,
																				)
																			}>
																			Student Profile
																		</Button>
																	</DropdownItem>
																	{userTypeId == 1 || userTypeId == 12 ?
																		<DropdownItem>
																			<Button
																				icon='Update'
																				isLight
																				color='success'
																				onClick={() =>
																					navigate(
																						`../academic/updateStudent/${studentDetailsId?.value}`,
																					)
																				}>
																				Update student Details
																			</Button>
																		</DropdownItem> : null
																	}
																	{userTypeId == 1 || userTypeId == 6 || userTypeId == 8 ?
																		<DropdownItem>
																			<Button
																				icon='Description'
																				isLight
																				color='primary'
																				onClick={() => { setAddSkillTypeOffCanvas(true); getSkillTypeDetails() }}>
																				Add Skills
																			</Button>
																		</DropdownItem> : null
																	}
																	{userTypeId == 1 || userTypeId == 6 || userTypeId == 8 ?
																		<DropdownItem>
																			<Button
																				icon='AddReaction'
																				isLight
																				color='primary'
																				onClick={() => { setAddHobbieTypeOffCanvas(true); getHobbieTypeList() }
																				}>
																				Add Hobbie
																			</Button>
																		</DropdownItem> : null
																	}
																	{userTypeId == 1 || userTypeId == 12 ?
																		<DropdownItem>
																			<Button
																				icon='DirectionsBus'
																				isLight
																				color='primary'
																				onClick={() =>
																					updateStudentTransport(
																						rowData,
																					)
																				}>
																				Update Transport
																			</Button>
																		</DropdownItem> : null
																	}
																	{userTypeId == 1 || userTypeId == 6 || userTypeId == 11 || userTypeId == 12 ?
																		<DropdownItem>
																			<Button
																				icon='RequestQuote'
																				isLight
																				color='primary'
																				onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId?.value}`)
																				}>
																				Fee Details
																			</Button>
																		</DropdownItem> : null
																	}
																	{userTypeId == 1 || userTypeId == 12 ?
																		<DropdownItem>
																			<Button
																				icon='EditLocation'
																				isLight
																				color='primary'
																				onClick={() => navigate(`../academic/studentCertificateTracker/${studentDetailsId?.value}`)}>
																				Certificate Tracker
																			</Button>
																		</DropdownItem> : null
																	}
																	{userTypeId == 1 || userTypeId == 12 ?
																		<DropdownItem>
																			<Button
																				icon='UploadFile'
																				isLight
																				color='primary'
																				onClick={assignScholarshipForStudent}>
																				Assign Scholarship
																			</Button>
																		</DropdownItem> : null
																	}
																	{userTypeId == 1 || userTypeId == 12 ?
																		<DropdownItem>
																			<Button
																				icon='Description'
																				isLight
																				color='primary'
																				onClick={() => navigate(`../academic/bonafideCertificateType1/${studentDetailsId?.value}`)}>
																				Bonafide Certificate 1{' '}
																			</Button>
																		</DropdownItem> : null
																	}
																	{userTypeId == 1 || userTypeId == 12 ?
																		<DropdownItem>
																			<Button
																				icon='Description'
																				isLight
																				color='primary'
																				onClick={() => navigate(`../academic/bonafideCertificateType2/${studentDetailsId?.value}`)}>
																				Bonafide Certificate 2{' '}
																			</Button>
																		</DropdownItem> : null
																	}
																	{userTypeId == 1 || userTypeId == 12 ?
																		<DropdownItem>
																			<Button
																				icon='Delete'
																				isLight
																				color='primary'
																				onClick={() => setDeleteStudentListCanvas(true)}>
																				Discontinue
																			</Button>
																		</DropdownItem> : null
																	}
																</DropdownMenu>
															</Dropdown>
														)}
														{/* <Dropdown isButtonGroup>
                                            <DropdownToggle>
                                                <Button className="text-nowrap" color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd className='card-body-scrollable' size='lg'>
                                                <DropdownItem>
                                                    <Button icon="PersonPin" isLight color="info" onClick={() => navigate(`../academic/studentProfile/${studentDetailsId?.value}`)}>Student Profile</Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button icon="Update" isLight color="success" onClick={() => navigate(`../academic/updateStudent/${studentDetailsId?.value}`)}>Update student Details</Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button icon="DirectionsBus" isLight color="primary" onClick={updateStudentTransport}>Update Transport
                                                    </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button icon="RequestQuote" isLight color="primary" onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId?.value}`)}>Fee Details</Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button icon="EditLocation" isLight color="primary" onClick={() => navigate(`../academic/studentCertificateTracker/${studentDetailsId?.value}`)}>Certificate Tracker</Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button icon="UploadFile" isLight color="primary" onClick={assignScholarshipForStudent}>Assign Scholarship</Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button icon="Description" isLight color="primary" onClick={() => navigate(`../academic/bonafideCertificateType1/${studentDetailsId?.value}`)}>Bonafide Certificate 1 </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button icon="Description" isLight color="primary" onClick={() => navigate(`../academic/bonafideCertificateType2/${studentDetailsId?.value}`)}>Bonafide Certificate 2 </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button icon="Delete" isLight color="primary" onClick={() => { setDeleteStudentListCanvas(true) }}>Discontinue</Button>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown> */}
													</td>
												</tr>
											))}
										</>
									) : (
										<NoDataMsg
											columnsCount={columnVisibilityData.length + 2}
											msg={noDataMsg}
										/>
									)}
								</tbody>
							</table>
						</CardBody>
						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				) : null}

				<OffCanvas
					setOpen={setUpdateTransportCanvas}
					isOpen={addUpdateTransportCanvas}
					titleId='addUpdateTransportCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={updateStudentTransportAreaForm.handleSubmit}>
					<OffCanvasHeader setOpen={setUpdateTransportCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addUpdateTransportCanvas'>
							Update Transport
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row'>
							<div className='col-12'>
								<Card
									className={`bg-l${darkModeStatus ? 'o25' : '25'}-info bg-l${darkModeStatus ? 'o50' : '10'
										}-info-hover transition-base rounded-2 mb-4`}
									shadow='sm'>
									<CardBody>
										<div className='d-flex align-items-center pb-3'>
											<div className='flex-shrink-0 ms-2'>
												<Icon
													size='3x'
													icon='DirectionsBusFilled'
													color='primary'
												/>
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-4 text-dark mb-0'>
													{transportDetails?.routeName != null ? (
														transportDetails?.routeName
													) : (
														<Icon
															icon='Remove'
															className='mb-0 text-dark fw-bold h2'
														/>
													)}
												</div>
												<div className='text-muted'>
													(Point Number - {transportDetails?.routeNumber}{' '}
													)
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className='col-12'>
								<FormGroup id='transportAreaMasterId' label='Area of Student'>
									<SearchableSelect
										ariaLabel='Area of Student'
										placeholder='Select Area of Student'
										onChange={selectTransportArea}
										value={transportAreaMasterId}
										onBlur={updateStudentTransportAreaForm.handleBlur}
										isValid={updateStudentTransportAreaForm.isValid}
										isTouched={
											updateStudentTransportAreaForm.touched
												.transportAreaMasterId
										}
										invalidFeedback={
											updateStudentTransportAreaForm.errors
												.transportAreaMasterId
										}
										list={transportAreaMasterData.map((data: any) => ({
											value: data.transportAreaMasterId,
											label: data.areaName,
										}))}
									/>
								</FormGroup>
							</div>

							<div className='col-12 mt-3'>
								<FormGroup id='modeOfWays' label='Ways'>
									<Select
										ariaLabel='Ways'
										placeholder='Ways'
										onChange={updateStudentTransportAreaForm.handleChange}
										value={updateStudentTransportAreaForm.values.modeOfWays}
										onBlur={updateStudentTransportAreaForm.handleBlur}
										isValid={updateStudentTransportAreaForm.isValid}
										isTouched={
											updateStudentTransportAreaForm.touched.modeOfWays
										}
										invalidFeedback={
											updateStudentTransportAreaForm.errors.modeOfWays
										}>
										<option value='1'>One Ways</option>
										<option value='2'>Two Ways</option>
									</Select>
								</FormGroup>
							</div>
							{ifRemarksShow ? (
								<>
									<div className='col-12 mt-3'>
										<FormGroup id='remarks' label='Remarks'>
											<Textarea
												placeholder='Enter Remarks'
												onChange={
													updateStudentTransportAreaForm.handleChange
												}
												value={
													updateStudentTransportAreaForm.values.remarks
												}
												onBlur={updateStudentTransportAreaForm.handleBlur}
												isValid={updateStudentTransportAreaForm.isValid}
												isTouched={
													updateStudentTransportAreaForm.touched.remarks
												}
												invalidFeedback={
													updateStudentTransportAreaForm.errors.remarks
												}
											/>
										</FormGroup>
									</div>
									<div className='fs-5 col-12 mt-4'>
										<ButtonGroup>
											<Checks onChange={removeTransport} checked={isRemove} />
											<h6 className='fs-5 mt-1'>
												<Badge isLight color='danger'>
													Remove
												</Badge>
											</h6>
										</ButtonGroup>
									</div>
								</>
							) : null}
						</div>
						<div className='row m-0'>
							<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
								<Button
									color='info'
									icon='Save'
									className='w-100'
									type='submit'
									isDisable={
										!updateStudentTransportAreaForm.isValid &&
										!!updateStudentTransportAreaForm.submitCount
									}>
									Save
								</Button>
							</div>
						</div>
					</OffCanvasBody>
				</OffCanvas>

				<OffCanvas
					setOpen={setAssignScholarshipCanvas}
					isOpen={addAssignScholarshipCanvas}
					titleId='addAssignScholarshipCanvas'
					isBodyScroll
					placement='end'>
					<OffCanvasHeader setOpen={setAssignScholarshipCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addAssignScholarshipCanvas'>
							Assign Scholarship
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							<FormGroup>
								<ChecksGroup className='fs-5'>
									{scholarshipTypeData.map((i: any) => (
										<div className='row' key={i.scholarshipTypeId}>
											<div className='col-lg-12'>
												<Checks
													label={i.scholarshipType}
													checked={i.isChecked}
													onChange={(e) =>
														selectScholarship(e, i.scholarshipTypeId)
													}
												/>
												{i.isChecked ? (
													<div className='ms-4 text-muted'>
														<small>
															Assigned:{' '}
															<span className='text-info fw-bold'>
																{dayjs(i.dateStamp).format(
																	'D MMMM, YYYY h:mm A',
																)}
															</span>
														</small>
													</div>
												) : null}{' '}
												<br />
											</div>
										</div>
									))}
								</ChecksGroup>
							</FormGroup>
						</div>
					</OffCanvasBody>
					<div className='row m-0'>
						<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								onClick={() => {
									addStudentScholarshipSubmit();
								}}
								isDisable={scholarshipTypeIdArray.length == 0}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<OffCanvas
					setOpen={setAddSkillTypeOffCanvas}
					isOpen={addSkillTypeOffCanvas}
					titleId='addSkillTypeOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={skillTypeForm.handleSubmit}>
					<OffCanvasHeader setOpen={setAddSkillTypeOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addSkillTypeOffCanvas'>Add Skills</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							{studentSkillsData != '' ? (
								<Card className='border border-light'>
									<CardBody>
										{studentSkillsData != '' ? (
											<div className='row g-2'>
												{studentSkillsData.map((item: any) => (
													<div className='col-auto'>
														{userTypeId == 9 || userTypeId == 10 ? (
															<Badge
																className={`border border-${item.color} rounded-1 px-3 py-2`}
																isLight
																color={item.color}>
																<Icon
																	icon={'AccessibilityNew'}
																	size='lg'
																	className='me-1'
																/>
																{item.skillType}
															</Badge>
														) : (
															<InputGroup>
																<Badge
																	className={`border border-${item.color} rounded-0 px-3 py-2`}
																	isLight
																	color={item.color}>
																	<Icon
																		icon={'AccessibilityNew'}
																		size='lg'
																		className='me-1'
																	/>
																	{item.skillType}
																</Badge>
																<Button
																	onClick={() => {
																		setDeleteOpen(true);
																		setStudentSkillsId(
																			item.studentSkillsId,
																		);
																	}}
																	isOutline
																	color={'danger'}
																	icon='Delete'></Button>
															</InputGroup>
														)}
													</div>
												))}
											</div>
										) : (
											<div className='row'>
												<div className='col'>
													<Alert
														color='warning'
														isLight
														icon='Report'
														className='mb-0'>
														{skillNoDataMsg}
													</Alert>
												</div>
											</div>
										)}
									</CardBody>
								</Card>
							) : null}
							<div className='col-12'>
								<FormGroup id='skillTypeId' label='Skill Type'>
									<SearchableSelect
										ariaLabel='Skill Type'
										onChange={selectedSkills}
										value={selectedOption}
										multiple
										list={skillTypeData.map((data: any) => ({
											value: data.skillTypeId,
											label: data.skillType,
										}))}
										placeholder='Select Skills'
										isValid={skillTypeForm.isValid}
										onBlur={skillTypeForm.handleBlur}
										isTouched={skillTypeForm.touched.skillTypeId}
										invalidFeedback={skillTypeForm.errors.skillTypeId}
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row m-0'>
						<div className='col-12 p-3'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								isDisable={!skillTypeForm.isValid && !!skillTypeForm.submitCount}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<OffCanvas
					setOpen={setAddHobbieTypeOffCanvas}
					isOpen={addHobbieTypeOffCanvas}
					titleId='addHobbieTypeOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={hobbieTypeForm.handleSubmit}>
					<OffCanvasHeader setOpen={setAddHobbieTypeOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addHobbieTypeOffCanvas'>Add Hobbie</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							{hobbieDetailsData != '' ? (
								<Card className='border border-light'>
									<CardBody>
										{hobbieDetailsData != '' ? (
											<div className='row g-2'>
												{hobbieDetailsData.map((item: any) => (
													<div className='col-auto'>
														{userTypeId == 9 || userTypeId == 10 ? (
															<Badge
																className={`border border-${item.color} rounded-1 px-3 py-2`}
																isLight
																color={item.color}>
																<Icon
																	icon={'AccessibilityNew'}
																	size='lg'
																	className='me-1'
																/>
																{item.hobbieType}
															</Badge>
														) : (
															<InputGroup>
																<Badge
																	className={`border border-${item.color} rounded-0 px-3 py-2`}
																	isLight
																	color={item.color}>
																	<Icon
																		icon={'AccessibilityNew'}
																		size='lg'
																		className='me-1'
																	/>
																	{item.hobbieType}
																</Badge>
																<Button
																	onClick={() => { setIsDeleteOpen(true); setStudentHobbieDetailsId(item.studentHobbieDetailsId) }}
																	isOutline
																	color={'danger'}
																	icon='Delete'></Button>
															</InputGroup>
														)}
													</div>
												))}
											</div>
										) : (
											<div className='row'>
												<div className='col'>
													<Alert
														color='warning'
														isLight
														icon='Report'
														className='mb-0'>
														{hobbieNoDataMsg}
													</Alert>
												</div>
											</div>
										)}
									</CardBody>
								</Card>
							) : null}
							<div className='col-12'>
								<FormGroup id='hobbieTypeId' label='Hobbie Type'>
									<SearchableSelect
										ariaLabel='Hobbie Type'
										onChange={selectedHobbie}
										value={selectedOption}
										multiple
										list={hobbieTypeData.map((data: any) => ({
											value: data.hobbieTypeId,
											label: data.hobbieType,
										}))}
										placeholder='Select Hobbie'
										isValid={hobbieTypeForm.isValid}
										onBlur={hobbieTypeForm.handleBlur}
										isTouched={hobbieTypeForm.touched.hobbieTypeId}
										invalidFeedback={hobbieTypeForm.errors.hobbieTypeId}
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row m-0'>
						<div className='col-12 p-3'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								isDisable={!hobbieTypeForm.isValid && !!hobbieTypeForm.submitCount}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>
				<OffCanvas
					setOpen={setDeleteStudentListCanvas}
					isOpen={deleteStudentListCanvas}
					titleId='deleteStudentListCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={deleteStudentForm.handleSubmit}>
					<OffCanvasHeader setOpen={setDeleteStudentListCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='deleteStudentListCanvas'>
							Discontinue Student
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row'>
							{studentDetailsData1.map((student: any) => (
								<div className='col-12' key={student.studentDetailsId}>
									<Card
										className={`bg-l${darkModeStatus ? 'o25' : '25'}-info bg-l${darkModeStatus ? 'o50' : '10'
											}-info-hover transition-base rounded-2 mb-4`}
										shadow='sm'>
										<CardBody>
											<div className='d-flex align-items-center pb-3'>
												<div className='flex-shrink-0 ms-2'>
													<img
														style={{ borderRadius: '50%' }}
														src={
															student.profilePath != null
																? student.profilePath
																: profilePic(student.genderId)
														}
														height={65}
														width={65}
														onError={(e: any) =>
															pictNotLoading(e, student.genderId)
														}
													/>
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='fw-bold fs-4 text-dark mb-0'>
														{student.studentName}
													</div>
													<div className='text-muted'>
														( {student.rollNo} )
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							))}
							<div className='col-12 mt-3'>
								<FormGroup id='remarks' label='Remarks'>
									<Textarea
										placeholder='Enter Remarks'
										onChange={deleteStudentForm.handleChange}
										value={deleteStudentForm.values.remarks}
										onBlur={deleteStudentForm.handleBlur}
									// isValid={deleteStudentForm.isValid}
									// isTouched={deleteStudentForm.touched.remarks}
									// invalidFeedback={deleteStudentForm.errors.remarks}
									/>
								</FormGroup>
							</div>
							<div className='col-12 mt-3'>
								<FormGroup
									id='lastDateOfAttendance'
									label='Last Date Of Attendance'>
									<Input
										type='date'
										placeholder='Enter Last Date Of Attendance'
										onChange={deleteStudentForm.handleChange}
										value={deleteStudentForm.values.lastDateOfAttendance}
										onBlur={deleteStudentForm.handleBlur}
										isValid={deleteStudentForm.isValid}
										isTouched={deleteStudentForm.touched.lastDateOfAttendance}
										invalidFeedback={
											deleteStudentForm.errors.lastDateOfAttendance
										}
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row'>
						<div className='d-flex justify-content-center position-absolute top-65 start-50 translate-middle'>
							<Collapse isOpen={isDeleteOpen} className='dropdown-menu'>
								<div className='row g-4 mt-2'>
									<div className='col-12 d-flex justify-content-center'>
										<h5>
											<strong>Are you sure want to discontinue ?</strong>
										</h5>
									</div>
									<div className='col-10'>
										<div className='d-flex gap-2 justify-content-end'>
											<Button
												color='danger'
												size={'sm'}
												onClick={deleteStudentSubmit}>
												Yes
											</Button>

											<Button
												color='info'
												size={'sm'}
												onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
												No
											</Button>
										</div>
									</div>
									<div className='col-10'></div>
								</div>
							</Collapse>
						</div>
						<div className='col-12 position-absolute bottom-0 end-0 p-3'>
							<Button
								color='danger'
								icon='Delete'
								className='w-100'
								type='submit'
								isDisable={
									!deleteStudentForm.isValid && !!deleteStudentForm.submitCount
								}>
								Discontinue
							</Button>
						</div>
					</div>
				</OffCanvas>

				<Modal
					setIsOpen={setIsDeleteOpen}
					isOpen={isDeleteOpen && studentHobbieDetailsId > 0}
					titleId='deleteHobbie'
					isStaticBackdrop
					size='sm'>
					<ModalHeader>
						<></>
					</ModalHeader>
					<ModalBody>
						<div className='row g-2'>
							<div className='col-12 d-flex justify-content-center fs-4'>
								<h5>
									<strong>Are you sure want to delete ?</strong>
								</h5>
							</div>
							<div className='col-10'>
								<div className='d-flex gap-2 justify-content-end'>
									<Button
										color='danger'
										size={'sm'}
										onClick={deleteStudentHobbieSubmit}>
										Yes
									</Button>
									<Button
										color='info'
										size={'sm'}
										onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
										No
									</Button>
								</div>
							</div>
							<div className='col-10'></div>
						</div>
					</ModalBody>
				</Modal>

				<Modal
					setIsOpen={setDeleteOpen}
					isOpen={deleteOpen}
					titleId='deleteHobbie'
					isStaticBackdrop
					size='sm'>
					<ModalHeader>
						<></>
					</ModalHeader>
					<ModalBody>
						<div className='row g-2'>
							<div className='col-12 d-flex justify-content-center fs-4'>
								<h5>
									<strong>Are you sure want to delete ?</strong>
								</h5>
							</div>
							<div className='col-10'>
								<div className='d-flex gap-2 justify-content-end'>
									<Button
										color='danger'
										size={'sm'}
										onClick={deleteStudentSkillsSubmit}>
										Yes
									</Button>
									<Button
										color='info'
										size={'sm'}
										onClick={() => setDeleteOpen(!deleteOpen)}>
										No
									</Button>
								</div>
							</div>
							<div className='col-10'></div>
						</div>
					</ModalBody>
				</Modal>
			</Page>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default StudentList;
