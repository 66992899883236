import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import NoDataMsg from '../../../../common/components/NoDataMsg'
import OpenCardComponent from '../../../../common/components/OpenCardComponent'
import useSortableData from '../../../../hooks/useSortableData'
import Page from '../../../../layout/Page/Page'
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper'
import { convertDateToEpoch } from '../../../../services/common.service'
import { getNotSubmittedStudentAttendance, getStudentAbsenteesList } from '../../../../services/report.service'
import { toasts } from '../../../../services/toast.service'
import Button from '../../../bootstrap/Button'
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../../bootstrap/Card'
import Collapse from '../../../bootstrap/Collapse'
import FormGroup from '../../../bootstrap/forms/FormGroup'
import PaginationButtons, { dataPagination } from '../../../PaginationButtons'
import Input from '../../../bootstrap/forms/Input'
import Icon from '../../../icon/Icon'
import AuthContext from '../../../../contexts/authContext'

const TodayAbsenteesReport = () => {

  const { userAccountId, userTypeId } = useContext(AuthContext);
  const [todayAbsenteesList, setTodayAbsenteesList] = useState<any>([])
  const [notSubmittedData, setNotSubmittedData] = useState<any>([])
  const [dataSuccess, setDataSuccess] = useState(false)
  const [submittedDataSuccess, setSubmittedDataSuccess] = useState('')
  const [isOpenListCard, setIsOpenListCard] = useState(true)

  const [currentPage1, setCurrentPage1] = useState(1);
  const [perPage1, setPerPage1] = useState(10);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [perPage2, setPerPage2] = useState(10);
  const { items: items1, requestSort: requestSort1, getClassNamesFor: getClassNamesFor1 } = useSortableData(todayAbsenteesList);
  const onCurrentPageData1 = dataPagination(items1, currentPage1, perPage1);
  const [isLoader, setIsLoader] = useState(false)

  const [noDataMsg, setNoDataMsg] = useState('')

  // const { items: items2, requestSort: requestSort2, getClassNamesFor: getClassNamesFor2 } = useSortableData(notSubmittedData);
  // const onCurrentPageData2 = dataPagination(items2, currentPage2, perPage2);
  const studentAttendanceForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateOfAttendance: dayjs().format('YYYY-MM-DD'),
    },
    validate: (values) => {
      const errors: {
        dateOfAttendance?: string;
      } = {};

      if (!values.dateOfAttendance) {
        errors.dateOfAttendance = "Required"
      }
      return errors;
    },

    //validateOnChange: false,
    onSubmit: () => { },
  });

  const columnVisibilityForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchInput: '',
      available: false,
    },
    validate: (values) => { },

    //validateOnChange: false,
    onSubmit: () => { },
  });

  const filteredData = onCurrentPageData1.filter(
    (i) =>
      // Name
      i.admissionNo !== null && i.admissionNo.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
      i.studentName !== null && i.studentName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
      i.departmentName !== null && i.departmentName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
      i.courseName !== null && i.courseName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
      i.semesterNumber !== null && i.semesterNumber.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
      i.smsToBeSentNumber !== null && i.smsToBeSentNumber.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
      i.isSmsSend !== null && i.isSmsSend.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
  );
  // const filteredData1 = items2.filter(
  //   (id) =>
  //     // Name
  //     id.departmentName !== null && id.departmentName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
  //     id.courseName !== null && id.courseName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
  //     id.semesterNumber !== null && id.semesterNumber.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
  // );

  function view() {
    setNotSubmittedData([]);
    setDataSuccess(false)
    setIsLoader(true);
    let dateOfAttendance = studentAttendanceForm.values.dateOfAttendance
    let dateInMilleSec = convertDateToEpoch(dateOfAttendance)
    getStudentAbsenteesDetails(dateInMilleSec)
  }

  function notSubmitted() {
    setDataSuccess(false)
    setIsLoader(true);
    let dateOfAttendance = studentAttendanceForm.values.dateOfAttendance
    let dateInMilleSec = convertDateToEpoch(dateOfAttendance)
    getNotSubmittedStudentAttendanceReport(dateInMilleSec)

  }

  function getStudentAbsenteesDetails(dateInMilleSec: number) {
    setIsLoader(true);
    getStudentAbsenteesList(userAccountId, dateInMilleSec,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.studentAbsenteesList;
          if (data != undefined) {
            setIsLoader(false);
            setTodayAbsenteesList(data);
            setDataSuccess(true);
            setIsOpenListCard(false);
            setSubmittedDataSuccess('TodayAbsentees')
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            setIsLoader(false);
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setIsLoader(false);
          // toasts(response.data.message, "Error")
          setTodayAbsenteesList([]);
          setIsOpenListCard(false);
          setNoDataMsg(response.data.message)
          setDataSuccess(true);
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          setIsLoader(false);
          toasts(errorDescription, "Error")
        }
      }, error => {
        setIsLoader(false);
        toasts(error, "Error")
      }
    )
  }

  function getNotSubmittedStudentAttendanceReport(dateInMilleSec: number) {
    setIsLoader(true);
    getNotSubmittedStudentAttendance(dateInMilleSec,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.notSubmittedStudentAttendanceReport;
          if (data != undefined) {
            setIsLoader(false);
            setNotSubmittedData(data);
            setDataSuccess(true);
            setIsOpenListCard(false);
            setSubmittedDataSuccess('NotSubmitted')
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            setIsLoader(false);
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setIsLoader(false);
          //toasts(response.data.message, "Error")
          setNotSubmittedData([]);
          setNoDataMsg(response.data.message)
          setDataSuccess(true);
          setIsOpenListCard(false);
        } else {
          setIsLoader(false);
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        setIsLoader(false);
        toasts(error, "Error")
      }
    )
  }

  return (
    <>
      <PageWrapper title='Today Absentees Report'>
        <Page container='fluid'>
          <Collapse isOpen={isOpenListCard}>
            <Card tag='form' noValidate onSubmit={studentAttendanceForm.handleSubmit}>
              <CardHeader borderSize={1}>
                <CardLabel icon='list' iconColor='primary'>
                  <CardTitle>
                    Today Absentees List
                  </CardTitle>
                </CardLabel>
              </CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-4'>
                    <FormGroup id='dateOfAttendance' label='Date' isFloating>
                      <Input onChange={studentAttendanceForm.handleChange}
                        value={studentAttendanceForm.values.dateOfAttendance}
                        //defaultValue={Date}
                        onBlur={studentAttendanceForm.handleBlur}
                        isValid={studentAttendanceForm.isValid}
                        isTouched={studentAttendanceForm.touched.dateOfAttendance}
                        invalidFeedback={studentAttendanceForm.errors.dateOfAttendance}
                        type='date' />
                    </FormGroup>
                  </div>
                  <div className='col-6 mt-2'>
                    <Button icon='ArrowDownward' color='primary' onClick={view}>View</Button> &nbsp;
                    <Button icon='ArrowDownward' color='primary' onClick={notSubmitted}>Not Submitted</Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Collapse>

          <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

          {dataSuccess ? <> {submittedDataSuccess == 'TodayAbsentees' ?
            <Card stretch data-tour='list'>
              <CardHeader borderSize={1}>
                <div className='d-flex col-5 justify-content-start'>
                  <CardLabel icon='List' iconColor='info'>
                    <CardTitle tag='div' className='h5'>Student List</CardTitle>
                  </CardLabel>
                </div>
                <div className='d-flex col-7 justify-content-end'>
                  <div className='d-flex col-5 justify-content-end' data-tour='search'>
                    <label className='hide-in-pdf border-0 bg-transparent cursor-pointer mt-2'
                      htmlFor='searchInput'>
                      <Icon icon='Search' size='2x' color='primary' />
                    </label>
                    <Input
                      id='searchInput'
                      type='search'
                      placeholder='Search...'
                      onChange={columnVisibilityForm.handleChange}
                      value={columnVisibilityForm.values.searchInput}
                    />
                  </div>
                </div>
              </CardHeader>

              <CardBody className='table-responsive' isScrollable >
                <table className='table table-modern table-hover text-nowrap'>
                  <thead>
                    <tr>
                      <th scope='col' onClick={() => requestSort1('sno')}
                        className='cursor-pointer text-decoration-underline'>S.No{' '}
                        <Icon size='lg' className={getClassNamesFor1('sno')} icon='FilterList' />
                      </th>
                      <th scope='col' onClick={() => requestSort1('admissionNo')}
                        className='cursor-pointer text-decoration-underline'>
                        Admission No{''}
                        <Icon size='lg' className={getClassNamesFor1('admissionNo')}
                          icon='FilterList'
                        />
                      </th>
                      <th scope='col' onClick={() => requestSort1('rollNo')}
                        className='cursor-pointer text-decoration-underline'>
                        Roll No{''}
                        <Icon size='lg' className={getClassNamesFor1('rollNo')}
                          icon='FilterList'
                        />
                      </th>
                      <th scope='col' onClick={() => requestSort1('studentName')}
                        className='cursor-pointer text-decoration-underline'>
                        Student  Name{''}
                        <Icon size='lg' className={getClassNamesFor1('studentName')}
                          icon='FilterList'
                        />
                      </th>
                      <th scope='col' onClick={() => requestSort1('departmentName')}
                        className='cursor-pointer text-decoration-underline'>
                        Medium{''}
                        <Icon size='lg' className={getClassNamesFor1('departmentName')}
                          icon='FilterList'
                        />
                      </th>
                      <th scope='col' onClick={() => requestSort1('courseName')}
                        className='cursor-pointer text-decoration-underline'>
                        Standard{''}
                        <Icon size='lg' className={getClassNamesFor1('courseName')}
                          icon='FilterList'
                        />
                      </th>
                      <th scope='col' onClick={() => requestSort1('semesterNumber')}
                        className='cursor-pointer text-decoration-underline'>
                        Section{''}
                        <Icon size='lg' className={getClassNamesFor1('semesterNumber')}
                          icon='FilterList'
                        />
                      </th>
                      <th scope='col' onClick={() => requestSort1('smsToBeSentNumber')}
                        className='cursor-pointer text-decoration-underline'>
                        Sms To Be Sent Number{''}
                        <Icon size='lg' className={getClassNamesFor1('smsToBeSentNumber')}
                          icon='FilterList'
                        />
                      </th>
                      <th scope='col' onClick={() => requestSort1('isSmsSend')}
                        className='cursor-pointer text-decoration-underline'>
                        Is Sms Send{''}
                        <Icon size='lg' className={getClassNamesFor1('isSmsSend')}
                          icon='FilterList'
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {todayAbsenteesList != '' ?
                      <>
                        {filteredData.map((i: any) => (
                          <tr key={i.studentAttendanceId}>
                            <td>{i.sno}</td>
                            <td>{i.admissionNo} </td>
                            <td>{i.rollNo} </td>
                            <td>{i.studentName}</td>
                            <td>{i.medium}</td>
                            <td>{i.standardName}</td>
                            <td>{i.sectionName}</td>
                            <td>{i.smsToBeSentNumber}</td>
                            {i.isSmsSend == 1 ? <td>
                              <Button
                                isLink
                                icon='Circle'
                                className='text-nowrap'
                                color={i.isSmsSend == 1 ? 'success' : 'danger'}
                              >Success
                              </Button></td> : <td>
                              <Button
                                isLink
                                icon='Circle'
                                className='text-nowrap'
                                color={i.isSmsSend == 0 ? 'danger' : 'success'}
                              >Pending
                              </Button></td>}
                          </tr>
                        ))
                        }</>
                      :
                      <NoDataMsg columnsCount={8} msg={noDataMsg} />}
                  </tbody>
                </table>
              </CardBody>
              <PaginationButtons
                data={items1}
                label='items'
                setCurrentPage={setCurrentPage1}
                currentPage={currentPage1}
                perPage={perPage1}
                setPerPage={setPerPage1}
              />
            </Card>
            :
            <Card stretch data-tour='list'>
              <CardHeader borderSize={1}>
                <div className='d-flex col-5 justify-content-start'>
                  <CardLabel icon='List' iconColor='info'>
                    <CardTitle tag='div' className='h5'>Attendance Not Submitted</CardTitle>
                  </CardLabel>
                </div>
                <div className='d-flex col-7 justify-content-end'>
                  <div className='d-flex col-5 justify-content-end' data-tour='search'>
                    <label className='hide-in-pdf border-0 bg-transparent cursor-pointer mt-2'
                      htmlFor='searchInput'>
                      <Icon icon='Search' size='2x' color='primary' />
                    </label>
                    <Input
                      id='searchInput'
                      type='search'
                      placeholder='Search...'
                      onChange={columnVisibilityForm.handleChange}
                      value={columnVisibilityForm.values.searchInput}
                    />
                  </div>
                </div>
              </CardHeader>

              <CardBody className='table-responsive' isScrollable >
                <table className='table table-modern table-hover text-nowrap'>
                  <thead>
                    <tr>
                      <th scope='col'
                        className='cursor-pointer text-decoration-underline'>S.No{' '}
                      </th>
                      <th scope='col'
                        className='cursor-pointer text-decoration-underline'>
                        Standard{''}
                      </th>
                      <th scope='col'
                        className='cursor-pointer text-decoration-underline'>
                        Section{''}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {notSubmittedData != '' ?
                      <>
                        {notSubmittedData.map((i: any) => (
                          <tr key={i.sectionDetailsId}>
                            <td>{i.sno}</td>
                            <td>{i.standardName}</td>
                            <td>{i.sectionName}</td>
                          </tr>
                        ))
                        }</> :
                      <NoDataMsg columnsCount={4} msg={noDataMsg} />}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          }</>
            : null}
        </Page>
      </PageWrapper>
    </>
  )
}

export default TodayAbsenteesReport