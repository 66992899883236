import React, { useContext, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { toasts } from '../../../services/toast.service';
import {
	getProfileForStudent,
	getStudentDetailsBySectionDetailsId,
} from '../../../services/student.service';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import useDarkMode from '../../../hooks/useDarkMode';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../bootstrap/OffCanvas';
import classNames from 'classnames';
import Icon from '../../icon/Icon';
import {
	getBatchMasterDetails,
	getSectionDetails,
} from '../../../services/master.service';
import {
	getMedium,
	getStandardDetails,
	pictNotLoading,
	profilePic,
} from '../../../services/common.service';
import { getExamDetails, getStudentExamMarkDetails } from '../../../services/exam.service';
import AuthContext from '../../../contexts/authContext';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Badge from '../../bootstrap/Badge';
import { TableLoader } from '../../../services/loader.services';

const StudentMarkSheet = () => {

	const { userAccountId, userTypeId, userData } = useContext(AuthContext);
	const [isStudentMarkDetails, setIsStudentMarkDetails] = useState<any>(false);

	useEffect(() => {
		getBatchList();
		if (userTypeId == 9 || userTypeId == 10) {
			setIsStudentMarkDetails(true)
			getStudentProfile(userAccountId);
		}
		else if (userTypeId == 6 && userData.classInchargeStandardDetailsId > 0) {
			getStudentListBySectionId(userData.batchMasterId, userData.classInchargeSectionDetailsId);
		}
	}, []);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [mediumId, setMediumId] = useState<any>('');
	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);

	const [mediumData, setMediumData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [sectionDetailsData, setSectionDetailsData] = useState([]);
	const [studentMarkDetailsData, setStudentMarkDetailsData] = useState<any>([]);
	const [studentDetailsDataByDropdown, setStudentDetailsDataByDropdown] = useState<any>([]);
	const [studentData, setStudentData] = useState<any>([]);

	const [isLoader, setIsLoader] = useState(false);
	const { darkModeStatus } = useDarkMode();
	const [noDataMsg, setNoDataMsg] = useState('');

	const [viewOffCanvas, setViewOffCanvas] = useState(false);

	const [batchMasterId, setBatchMasterId] = useState<any>('');
	const [batchMasterData, setBatchMasterData] = useState<any>([]);

	const selectBatch = (e: any) => {
		setStudentDetailsId('');
		setStandardDetailsId('');
		setSectionDetailsId('');
		setMediumId('');
		let batchMasterId = e;
		setBatchMasterId(batchMasterId);
		getMediumList();
	};

	const selectMedium = (e: any) => {
		setStudentMarkDetailsData([]);
		setDataSuccess(false);
		setStandardDetailsId('');
		setSectionDetailsId('');
		setStudentDetailsId('');
		let mediumId = e;
		setMediumId(mediumId);
		getStandardList();
	};

	const selectStandard = (e: any) => {
		setStudentMarkDetailsData([]);
		setDataSuccess(false);
		setSectionDetailsId('');
		setStudentDetailsId('');
		setSectionDetailsId('');
		let standardDetailsId = e;
		setStandardDetailsId(standardDetailsId);
		if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
			getSectionList(mediumId?.value, standardDetailsId?.value);
		}
	};

	const selectSection = (e: any) => {
		setStudentMarkDetailsData([]);
		setDataSuccess(false);
		setStudentDetailsId('');
		let sectionDetailsId = e;
		setSectionDetailsId(sectionDetailsId);
		if (batchMasterId?.value != undefined && sectionDetailsId?.value != undefined) {
			getStudentListBySectionId(batchMasterId?.value, sectionDetailsId?.value);
		}
	};

	const selectStudent = (e: any) => {
		setStudentMarkDetailsData([]);
		setDataSuccess(false);
		setIsStudentMarkDetails(false)
		let studentDetailsId = e;
		setStudentDetailsId(studentDetailsId);
	};

	function ViewExam() {
		getStudentProfile(studentDetailsId?.value);
		getStudentExamMarkDetailsList(standardDetailsId?.value, sectionDetailsId?.value, studentDetailsId?.value, 0);
	}

	function ViewStudentMarkByClassIncharge(i: any) {
		setStudentMarkDetailsData([])
		setStudentData([])
		getStudentProfile(i.studentDetailsId);
		getStudentExamMarkDetailsList(i.standardDetailsId, i.sectionDetailsId, i.studentDetailsId, 0);
		setIsStudentMarkDetails(true);
	}

	const [markDetailsData, setMarkDetailsData] = useState<any>([]);
	const [examType, setExamType] = useState<any>('');

	function ViewMark(i: any) {
		setViewOffCanvas(true);
		setMarkDetailsData([i]);
		setExamType(i.examTypeName);
	}

	const [studentProfileData, setStudentProfileData] = useState<any>([]);

	function getStudentProfile(studentDetailsId: number) {
		setIsLoader(true)
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile;
					if (data != undefined) {
						setStudentData(data);
						setStudentProfileData(data[0]);
						if (userTypeId == 9 || userTypeId == 10) {
							setStandardDetailsId(data[0]?.standardDetailsId);
							setSectionDetailsId(data[0]?.sectionDetailsId);
							setStudentDetailsId(data[0]?.studentDetailsId);
							getStudentExamMarkDetailsList(data[0]?.standardDetailsId, data[0]?.sectionDetailsId, data[0]?.studentDetailsId, 0);
						}
						if (userTypeId == 6) {
							setSectionDetailsId(data[0]?.sectionDetailsId);
							setBatchMasterId(data[0]?.batchMasterId);
							getStudentListBySectionId(data[0]?.batchMasterId, data[0]?.sectionDetailsId);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setStudentData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}
	const [standard, setStandard] = useState<any>('');
	const [section, setSection] = useState<any>('');
	const [isLoaderByClassIncharge, setIsLoaderByClassIncharge] = useState<any>(false);

	function getStudentListBySectionId(batchMasterId: any, sectionDetailsId: any) {
		setIsLoaderByClassIncharge(true)
		getStudentDetailsBySectionDetailsId(batchMasterId, sectionDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetailsBySectionDetailsId;
					if (data != undefined) {
						setIsLoaderByClassIncharge(false)
						setStudentDetailsDataByDropdown(data);
						setStandard(data[0].standardName);
						setSection(data[0].sectionName);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
					setStudentDetailsDataByDropdown([]);
					setIsLoaderByClassIncharge(false)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setIsLoaderByClassIncharge(false)
				}
			},
			(error) => {
				toasts(error, 'Error');
				setIsLoaderByClassIncharge(false)
			},
		);
	}

	function getSectionList(mediumId: any, standardDetailsId: any) {
		getSectionDetails(
			mediumId,
			standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sectionDetails;
					if (data != undefined) {
						setSectionDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSectionDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStudentExamMarkDetailsList(StandardDetailsId: any, SectionDetailsId: any, StudentDetailsId: any, examDetailsId: any) {
		setIsLoader(true);
		setIsStudentMarkDetails(true)
		getStudentExamMarkDetails(StandardDetailsId, SectionDetailsId, StudentDetailsId, examDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentExamMarkDetails;
					if (data != undefined) {
						setIsLoader(false);
						setIsOpenListCard(false);
						setStudentMarkDetailsData(data);
						setDataSuccess(true);
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
						setIsOpenListCard(true);
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setStudentMarkDetailsData([]);
					setNoDataMsg(response.data.message);
					setDataSuccess(true);
					setIsOpenListCard(false);
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
					setIsOpenListCard(true);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function closeAndReset() {
		setViewOffCanvas(false);
	}
	return (
		<PageWrapper title='Student Wise Mark List'>
			{userTypeId == 9 || userTypeId == 10 || userData.classInchargeStandardDetailsId > 0 ?
				<SubHeader>
					<SubHeaderLeft>
						{isStudentMarkDetails && userTypeId != 9 && userTypeId != 10 ? (
							<>
								<Button
									color='info'
									isLight
									icon='ArrowBack'
									onClick={() => setIsStudentMarkDetails(false)}>
									Back to List
								</Button>
								<SubheaderSeparator />
								{studentData.map((student: any, index: any) => (
									<div key={index}>
										<img
											style={{ borderRadius: '50%' }}
											src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)}
											height='40'
											width='40'
											onError={(e: any) => pictNotLoading(e, student.genderId)}
										/>
										<span><strong className='ms-3'>{student.studentName}</strong></span>&nbsp;&nbsp;
										<span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
											{student.rollNo}
										</span>
									</div>
								))}
							</>
						) : (
							<CardLabel icon='EditNote' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Exam Mark List
									{standard != '' ?
										<Badge
											className={`border border-success rounded-1 px-3 py-2 ms-3`}
											isLight
											color={'success'}>
											{standard} - {section}
										</Badge> : null
									}
								</CardTitle>
							</CardLabel>
						)}
					</SubHeaderLeft>
				</SubHeader>
				: <></>
			}

			<Page container='fluid'>
				{userData.classInchargeStandardDetailsId == 0 && userTypeId != 9 && userTypeId != 10 ? (
					<>
						<Collapse isOpen={isOpenListCard}>
							<Card stretch data-tour='list' tag='form' noValidate>
								<CardHeader borderSize={1}>
									<CardLabel icon='List' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Student Wise Mark List
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row'>
										<div className='col-md-3'>
											<FormGroup id='batchMasterId' label='Batch' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Batch'
													placeholder='Batch'
													onChange={selectBatch}
													value={batchMasterId}
													list={batchMasterData.map((data: any) => ({
														value: data.batchMasterId,
														label: data.batchName,
													}))}
													required
												/>
											</FormGroup>
										</div>

										{batchMasterId?.value != undefined ? (
											<div className='col-3'>
												<FormGroup id='mediumId' label='Medium' isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Medium'
														onChange={selectMedium}
														value={mediumId}
														list={mediumData.map((data: any) => ({
															value: data.mediumId,
															label: data.medium,
														}))}
													/>
												</FormGroup>
											</div>
										) : null}

										{mediumId?.value != undefined ? (
											<div className='col-3'>
												<FormGroup
													id='standardDetailsId'
													label='Standard'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Standard'
														onChange={selectStandard}
														value={standardDetailsId}
														list={standardData.map((data: any) => ({
															value: data.standardDetailsId,
															label: data.standardName,
														}))}
													/>
												</FormGroup>
											</div>
										) : null}

										{standardDetailsId?.value != undefined ? (
											<div className='col-3'>
												<FormGroup
													id='sectionDetailsId'
													label='Section'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Section'
														onChange={selectSection}
														value={sectionDetailsId}
														list={sectionDetailsData.map(
															(data: any) => ({
																value: data.sectionDetailsId,
																label: data.sectionName,
															}),
														)}
													/>
												</FormGroup>
											</div>
										) : null}

										{sectionDetailsId?.value != undefined ? (
											<div className='col-3 mt-3'>
												<FormGroup
													id='studentDetailsId'
													label='Student'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Student'
														onChange={selectStudent}
														value={studentDetailsId}
														list={studentDetailsDataByDropdown.map(
															(data: any) => ({
																value: data.studentDetailsId,
																label: data.studentNameAndAdmissionNo,
															}),
														)}
													/>
												</FormGroup>
											</div>
										) : null}

										{studentDetailsId?.value != undefined ? (
											<div
												className={
													userTypeId == 9 || userTypeId == 10
														? 'col-2 mt-2'
														: 'col-2 mt-3'
												}>
												<Button
													className='mt-2'
													icon='ArrowDownward'
													color='primary'
													onClick={ViewExam}>
													View
												</Button>
											</div>
										) : null}
									</div>
								</CardBody>
							</Card>
						</Collapse>
						<OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
					</>
				) : null}

				{userData.classInchargeStandardDetailsId > 0 && !isStudentMarkDetails ? (
					<>
						{studentDetailsDataByDropdown != '' ?
							<>
								<div className='row row-cols-xxl-3 row-cols-lg-3 row-cols-md-2'>
									{studentDetailsDataByDropdown.map((i: any) => (
										<div key={i.studentDetailsId} className='col'>
											<Card stretch className='border border-light rounded-1'>
												<CardBody>
													<div className='row g-3 mb-0'>
														<div className='col d-flex'>
															<div className='flex-shrink-0'>
																<div className='position-relative'>
																	<div
																		className='ratio ratio-1x1'
																		style={{width: 100}}>
																		<div
																			className={classNames(`bg-l25-${i.color}`,'rounded-2','d-flex align-items-center justify-content-center')}>
																			{i.profilePath != null && i.profilePath != '' ? (
																				<img
																					style={{
																						borderRadius:
																							'50%',
																					}}
																					src={
																						i.profilePath
																					}
																					width='90'
																					height='90'
																					onError={(
																						e: any,
																					) =>
																						pictNotLoading(
																							e,
																							i.genderId,
																						)
																					}
																				/>
																			) : (
																				<img
																					// className='rounded-0'
																					src={profilePic(
																						i.genderId,
																					)}
																					width='90'
																					height='90'
																				/>
																			)}
																		</div>
																	</div>
																</div>
															</div>
															<div className='flex-grow-1 ms-3 d-flex justify-content-between'>
																<div className='w-100'>
																	<div className='row'>
																		<div className='col'>
																			<div className='d-flex align-items-center'>
																				<div
																					className='fw-bold  me-2 text-uppercase'
																					style={{
																						fontSize:
																							'14px',
																					}}>
																					{i.studentName}
																				</div>
																			</div>

																			<div className='mt-2 text-muted'>
																				{i.studentRollNo}
																			</div>
																			<div className='text-muted mt-2'>
																				<Badge
																					className={`border border-primary rounded-0 px-3 py-2`}
																					isLight
																					color={
																						'primary'
																					}>
																					{i.admissionNo}
																				</Badge>
																			</div>
																		</div>
																		<div className='col-auto'>
																			<Button
																				icon='Info'
																				color='dark'
																				isLight
																				onClick={() =>
																					ViewStudentMarkByClassIncharge(
																						i,
																					)
																				}
																				aria-label='More info'
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
									))}
								</div>
							</>
							: isLoaderByClassIncharge ? <TableLoader /> :
								<div className='row'>
									<div className='col-12' style={{ height: '20vh' }}></div>
									<div className='col-12 d-flex justify-content-center'>
										<Icon icon='DoNotDisturb' size={'10x'} />
									</div>
									<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
										{noDataMsg}
									</div>
								</div>
						}
					</>
				) : null}


				{isStudentMarkDetails ? (
					<>
						{studentMarkDetailsData != '' ?
							<>
								<div className='row'>
									{studentMarkDetailsData.map((i: any, index: any) => (
										<div className='col-3' key={index}>
											<Card stretch shadow={'sm'} className='border border-light'>
												<CardHeader
													className={
														i.totalScored ? `bg-l25-info` : `bg-l25-danger`
													}
													style={{ height: '50px' }}>
													<CardLabel>
														<CardTitle
															className={
																i.totalScored
																	? `text-info text-uppercase`
																	: `text-danger text-uppercase`
															}>
															{i.examTypeName}{' '}
															{i.totalScored ? null : (
																<span className='fs-6 text-dark'>
																	( Absent )
																</span>
															)}
														</CardTitle>
													</CardLabel>
													{i.totalScored ? (
														<CardActions>
															<Button
																icon='ArrowForwardIos'
																aria-label='Read More'
																color={
																	darkModeStatus ? 'info' : undefined
																}
																onClick={() => ViewMark(i)}
															/>
														</CardActions>
													) : null}
												</CardHeader>
												<CardBody>
													<div className='col-12'>
														<div className='row'>
															<div className='col d-flex align-items-center'>
																<div className='flex-grow-1 col-5'>
																	<strong>Total Mark </strong>
																</div>
																<div className='col-3 text-center'>
																	<strong> : </strong>
																</div>
																<div className='col-4 text-end'>
																	{i.totalScored ? (
																		<strong>
																			<span className='text-primary'>
																				{i.totalScored}
																			</span>{' '}
																			/ {i.totalMark}
																		</strong>
																	) : (
																		<strong className='text-dark'>
																			-
																		</strong>
																	)}
																</div>
															</div>
														</div>
														<div className='row mt-3'>
															<div className='col d-flex align-items-center'>
																<div className='flex-grow-1 col-5'>
																	<strong>Percentage </strong>
																</div>
																<div className='col-3 text-center'>
																	<strong> : </strong>
																</div>
																<div className='col-4 text-end'>
																	{i.percentage ? (
																		<strong>{i.percentage}</strong>
																	) : (
																		<strong className='text-dark'>
																			-
																		</strong>
																	)}
																</div>
															</div>
														</div>
														<div className='row mt-3'>
															<div className='col d-flex align-items-center'>
																<div className='flex-grow-1 col-5'>
																	<strong>Rank </strong>
																</div>
																<div className='col-3 text-center'>
																	<strong> : </strong>
																</div>
																<div className='col-4 text-end'>
																	<strong>{i.classRank}</strong>
																</div>
															</div>
														</div>
														<div className='row mt-3'>
															<div className='col d-flex align-items-center'>
																<div className='flex-grow-1 col-5'>
																	<strong>Result </strong>
																</div>
																<div className='col-3 text-center'>
																	<strong> : </strong>
																</div>
																<div className='col-4 text-end'>
																	<strong
																		className={
																			i.result == 'Fail'
																				? 'text-danger'
																				: 'text-success'
																		}>
																		<Icon
																			icon={
																				i.result == 'Fail'
																					? 'Dangerous'
																					: 'TaskAlt'
																			}
																			className='fs-4'
																		/>{' '}
																		{i.result}
																	</strong>
																</div>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
									))}
								</div>
							</>
							: isLoader && userTypeId != 1 ? <TableLoader /> : isLoader && userTypeId == 1 ? null :
								<div className='row'>
									<div className='col-12' style={{ height: '20vh' }}></div>
									<div className='col-12 d-flex justify-content-center'>
										<Icon icon='DoNotDisturb' size={'10x'} />
									</div>
									<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
										{noDataMsg}
									</div>
								</div>
						}
					</>
				) : null}
			</Page>

			<OffCanvas
				setOpen={setViewOffCanvas}
				isOpen={viewOffCanvas}
				titleId=''
				isBodyScroll
				placement='end'
				tag='form'
				noValidate>
				<OffCanvasHeader setOpen={setViewOffCanvas} onClick={closeAndReset}>
					<OffCanvasTitle id='markList' className='text-uppercase'>
						{examType}{' '}
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					{markDetailsData != '' ? (
						<>
							<div className='col-12'>
								<Card
									className={`bg-l${darkModeStatus ? 'o25' : '25'}-info bg-l${darkModeStatus ? 'o50' : '10'
										}-info-hover transition-base rounded-2`}
									shadow='sm'
									style={{ height: '95px' }}>
									<CardBody>
										<div className='d-flex align-items-center pb-3'>
											<div className='flex-shrink-0 ms-2'>
												<img
													style={{ borderRadius: '50%' }}
													src={
														studentProfileData.profilePath != null
															? studentProfileData.profilePath
															: profilePic(
																studentProfileData.genderId,
															)
													}
													height={60}
													width={60}
													onError={(e: any) =>
														pictNotLoading(
															e,
															studentProfileData.genderId,
														)
													}
												/>
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-5 text-dark mb-0'>
													{studentProfileData.studentName}
												</div>
												<div className='text-muted'>
													( {studentProfileData.rollNo} )
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>

							<div className='col-12'>
								{markDetailsData.map((i: any) => (
									<div className='row g-4'>
										<div className='col-md-4'>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-success bg-l${darkModeStatus ? 'o50' : '10'
													}-success-hover transition-base rounded-2 mb-0`}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-1'>
														<div className='flex-grow-1 '>
															<div className='fw-bold fs-3 text-success mb-0  d-flex justify-content-center '>
																{i.totalScored}
															</div>
															<div className='text-muted  d-flex justify-content-center'>
																<b>Score</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
										<div className='col-md-4'>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-warning bg-l${darkModeStatus ? 'o50' : '10'
													}-warning-hover transition-base rounded-2 mb-0`}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-1'>
														<div className='flex-grow-1 '>
															<div className='fw-bold fs-3 text-warning mb-0  d-flex justify-content-center'>
																{i.percentage}
															</div>
															<div className='text-muted  d-flex justify-content-center '>
																<b>Percentage</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
										<div className='col-md-4'>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-danger bg-l${darkModeStatus ? 'o50' : '10'
													}-danger-hover transition-base rounded-2 mb-0 `}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-1'>
														<div className='flex-grow-1 '>
															<div className='fw-bold fs-3 text-danger mb-0  d-flex justify-content-center '>
																{i.classRank}
															</div>
															<div className='text-muted  d-flex justify-content-center '>
																<b> Rank</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
									</div>
								))}
							</div>
						</>
					) : null}

					<br />

					<table className='table table-modern'>
						<thead>
							<tr>
								<th className='bg-l25-info text-uppercase fs-6'>Subject</th>
								<th className='bg-l25-info text-uppercase fs-6'>Total</th>
								<th className='bg-l25-info text-uppercase fs-6'>Score</th>
							</tr>
						</thead>
						<tbody>
							{markDetailsData.map((item: any) => (
								<>
									{item.markDetails.map((i: any) => (
										<tr key={i.subjectPaperDetailsId}>
											<td>{i.paperName}</td>
											<td>{i.totalMark}</td>
											<td>{i.totalScoredMark}</td>
										</tr>
									))}
									<tr className='table-danger'>
										<td className='text-dark fw-bold'>Total</td>
										<td className='text-dark fw-bold'>{item.totalMark}</td>
										<td className='text-dark fw-bold'>{item.totalScored}</td>
									</tr>
								</>
							))}
						</tbody>
					</table>
				</OffCanvasBody>
			</OffCanvas>
		</PageWrapper>
	);
};

export default StudentMarkSheet;
