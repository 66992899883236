import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';
import AuthContext from '../../../contexts/authContext';
import useSortableData from '../../../hooks/useSortableData';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import { getLicenseKey } from '../../../services/application.settings';
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable } from '../../../services/common.service';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import { addBookDetails, updateBookDetails, deleteBookDetails, getBookDetails, getBookAuthors, getBookGenres } from '../../../services/library.service';
import { showLoader, TableLoader } from '../../../services/loader.services';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from '../../bootstrap/Card';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Icon from '../../icon/Icon';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Input from '../../bootstrap/forms/Input';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from '../../bootstrap/OffCanvas';
import DeleteComponents from '../../../common/components/DeleteComponents';

function BookList() {

	useEffect(() => {
		getBookDetailsList();
		getBookAuthorsList();
		getBookGenresList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [addLibraryBookOffCanvas, setAddLibraryBookOffCanvas] = useState(false);
	const [editLibraryBookOffCanvas, setEditLibraryBookOffCanvas] = useState(false);
	const [bookDetailsData, setBookDetailsData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const componentRef = useRef(null);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(bookDetailsData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);

	const [noDataMsg, setNoDataMsg] = useState('');

	const [bookAuthorsId, setBookAuthorsId] = useState<any>('');
	const [bookGenresId, setBookGenresId] = useState<any>('');
	const [bookAuthorsData, setBookAuthorsData] = useState<any>([]);
	const [bookGenresData, setBookGenresData] = useState<any>([]);

	const addLibraryBookForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: '',
			bookAuthorsId: '',
			bookGenresId: '',
			isbn: '',
			publishedYear: '',
			totalQty: '',
		},
		validate: (values) => {
			const errors: {
				title?: string;
				bookAuthorsId?: string;
				bookGenresId?: string;
				isbn?: string;
				publishedYear?: string;
				totalQty?: string;
			} = {};
			if (!values.title) {
				errors.title = 'Required';
			}
			if (!bookAuthorsId?.value) {
				errors.bookAuthorsId = 'Required';
			}
			if (!bookGenresId?.value) {
				errors.bookGenresId = 'Required';
			}
			if (!values.isbn) {
				errors.isbn = 'Required';
			}
			if (!values.publishedYear) {
				errors.publishedYear = 'Required';
			}
			if (!values.totalQty) {
				errors.totalQty = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			addLibrarySubmit();
		},
	});

	const editLibraryBook = useFormik({
		enableReinitialize: true,
		initialValues: {
			bookDetailsId: '',
			title: '',
			bookAuthorsId: '',
			bookGenresId: '',
			availabilityStatusId: '',
			isbn: '',
			publishedYear: '',
			totalQty: '',
		},
		validate: (values) => {
			const errors: {
				title?: string;
				bookAuthorsId?: string;
				bookGenresId?: string;
				isbn?: string;
				publishedYear?: string;
				totalQty?: string;
			} = {};
			if (!values.title) {
				errors.title = 'Required';
			}
			if (!bookAuthorsId?.value) {
				errors.bookAuthorsId = 'Required';
			}
			if (!bookGenresId?.value) {
				errors.bookGenresId = 'Required';
			}
			if (!values.isbn) {
				errors.isbn = 'Required';
			}
			if (!values.publishedYear) {
				errors.publishedYear = 'Required';
			}
			if (!values.totalQty) {
				errors.totalQty = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			updateBookDetailsSubmit();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedLibraryBook = i;
			setEditLibraryBookOffCanvas(true);
			setBookAuthorsId({ value: selectedLibraryBook.bookAuthorsId, label: selectedLibraryBook.authorName });
			setBookGenresId({ value: selectedLibraryBook.bookGenresId, label: selectedLibraryBook.genreName });
			editLibraryBook.setValues({
				bookDetailsId: selectedLibraryBook.bookDetailsId,
				title: selectedLibraryBook.title,
				bookAuthorsId: selectedLibraryBook.bookAuthorsId,
				bookGenresId: selectedLibraryBook.bookGenresId,
				availabilityStatusId: selectedLibraryBook.availabilityStatusId,
				isbn: selectedLibraryBook.isbn,
				publishedYear: selectedLibraryBook.publishedYear,
				totalQty: selectedLibraryBook.totalQty
			});
		}
	}

	function setLibraryDetails() {
		return {
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			title: addLibraryBookForm.values.title,
			bookAuthorsId: bookAuthorsId?.value,
			bookGenresId: bookGenresId?.value,
			isbn: addLibraryBookForm.values.isbn,
			publishedYear: addLibraryBookForm.values.publishedYear,
			totalQty: addLibraryBookForm.values.totalQty,
		};
	}

	function setEditLibraryDetails() {
		return {
			bookDetailsId: editLibraryBook.values.bookDetailsId,
			title: editLibraryBook.values.title,
			bookAuthorsId: bookAuthorsId?.value,
			bookGenresId: bookGenresId?.value,
			isbn: editLibraryBook.values.isbn,
			publishedYear: editLibraryBook.values.publishedYear,
			totalQty: editLibraryBook.values.totalQty,
			availabilityStatusId: editLibraryBook.values.availabilityStatusId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
		};
	}

	function setDeleteLibraryDetails() {
		return {
			bookDetailsId: editLibraryBook.values.bookDetailsId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
		};
	}

	function addLibrarySubmit() {
		showLoader(true);
		if (addLibraryBookForm.isValid) {
			let libraryPostData = setLibraryDetails();
			addBookDetails(
				libraryPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getBookDetailsList();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addLibraryBookForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	const updateBookDetailsSubmit = () => {
		showLoader(true);
		if (editLibraryBook.isValid) {
			let updateLibraryPostData = setEditLibraryDetails();
			updateBookDetails(
				updateLibraryPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getBookDetailsList();
						closeAndReset();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editLibraryBook.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
			closeAndReset();
		}
	};

	const deleteBookDetailsSubmit = () => {
		showLoader(true);
		if (editLibraryBook.isValid) {
			let updateLibraryPostData = setDeleteLibraryDetails();
			deleteBookDetails(
				updateLibraryPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setIsDeleteOpen(false);
						closeAndReset();
						getBookDetailsList();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editLibraryBook.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	};

	function getBookDetailsList() {
		setDataStatus(false);
		getBookDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.bookDetails;
					if (data != undefined) {
						getColumnsForTable('getBookDetails', 'get');
						setBookDetailsData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataStatus(true);
					} else {
						toasts('Undefined Data', 'Error');
						setDataStatus(true);
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					getColumnsForTable('getBookDetails', 'get');
					setBookDetailsData([]);
					setNoDataMsg(response.data.message);
					setDataStatus(true);
				} else {
					setDataStatus(true);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	const [columnVisibilityDataForExport, setColumnVisibilityDataForExport] = useState<any>([])

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						const last = [{ columnVisibilityId: 0, columnName: 'Status', keyName: 'statusName', isActive: true, isDisplay: true, isDefault: true }]

						setColumnVisibilityData(columnVisibilityData);
						setColumnVisibilityDataForExport([...columnVisibilityData, ...last]);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getBookDetails', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function getBookAuthorsList() {
		getBookAuthors(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.bookAuthors;
					if (data != undefined) {
						setBookAuthorsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setBookAuthorsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBookGenresList() {
		getBookGenres(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.bookGenres;
					if (data != undefined) {
						setBookGenresData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setBookGenresData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const selectBookAuthor = (e: any) => {
		let bookAuthorsId = e;
		setBookAuthorsId(bookAuthorsId);
	};

	const selectBookGenres = (e: any) => {
		let bookGenresId = e;
		setBookGenresId(bookGenresId);
	};

	function closeAndReset() {
		setEditLibraryBookOffCanvas(false);
		setAddLibraryBookOffCanvas(false);
		setIsDeleteOpen(false);
		addLibraryBookForm.resetForm();
		editLibraryBook.resetForm()
		setBookGenresId('');
		setBookAuthorsId('');
	}

	return (
		<>
			<PageWrapper title={`library Book Details`}>
				<Page container='fluid'>
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-5'>
								<CardTitle tag='div' className='h5'>
									Book List
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForTable(
																		'getBookDetails',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div
														className='col-12'
														style={{
															maxHeight: '200px',
															overflowY: 'scroll',
														}}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		id='{i.columnVisibilityId}'
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={
																			i.isDefault == true
																		}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'library Book Details',
														bookDetailsData,
														columnVisibilityDataForExport,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														bookDetailsData,
														columnVisibilityDataForExport,
														'library Book Details',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'library Book Details',
														bookDetailsData,
														columnVisibilityDataForExport,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={handlePrint}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Button
									color='primary'
									icon='Add'
									isLight
									onClick={() => setAddLibraryBookOffCanvas(true)}>
									Add New
								</Button>
							</CardActions>
						</CardHeader>

						<CardBody className='table-responsive' isScrollable>
							{!dataStatus ? (
								<TableLoader />
							) : (
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No{' '}
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											{columnVisibilityData.map((column: any) => (
												<th
													key={column.keyName}
													scope='col'
													onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon
														size='lg'
														className={getClassNamesFor(column.keyName)}
														icon='FilterList'
													/>
												</th>
											))}
											<th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>
												Status <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
											</th>
											<th scope='col' className='d-print-none'>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ? (
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.bookDetailsId}>
														<td>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) =>
															column.isDisplay &&
																rowData[column.keyName] ? (
																<td
																	key={`${rowData.bookDetailsId}-${column.keyName}`}>
																	{rowData[column.keyName]}
																</td>
															) : (
																<td
																	key={`empty-${column.keyName}`}
																/>
															),
														)}
														<td>
															<Button isLink color={rowData.availabilityStatusId == 0 ? 'success' : 'danger'} icon='Circle' className='text-nowrap'>
																{rowData.availabilityStatusName}
															</Button>
														</td>
														<td className='d-print-none'>
															<Button
																color='dark'
																isLight
																icon='Edit'
																aria-label='Edit'
																onClick={() => {
																	onActivate(rowData);
																}}
															/>
														</td>
													</tr>
												))}
											</>
										) : (
											<NoDataMsg
												columnsCount={columnVisibilityData.length + 2}
												msg={noDataMsg}
											/>
										)}
									</tbody>
								</table>
							)}
						</CardBody>
						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				</Page>

				<OffCanvas
					setOpen={setAddLibraryBookOffCanvas}
					isOpen={addLibraryBookOffCanvas}
					titleId='addLibraryBookOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={addLibraryBookForm.handleSubmit}>
					<OffCanvasHeader setOpen={setAddLibraryBookOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addLibraryBookOffCanvas'>
							Add Book
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							<div className='col-12'>
								<FormGroup id='title' label='Title'>
									<Input
										onChange={addLibraryBookForm.handleChange}
										value={addLibraryBookForm.values.title}
										onBlur={addLibraryBookForm.handleBlur}
										isValid={addLibraryBookForm.isValid}
										isTouched={addLibraryBookForm.touched.title}
										invalidFeedback={addLibraryBookForm.errors.title}
										type='text'
										placeholder='Enter title'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='bookAuthorsId' label='Book Author'>
									<SearchableSelect
										ariaLabel='Book Author'
										onChange={selectBookAuthor}
										onBlur={addLibraryBookForm.handleBlur}
										value={bookAuthorsId}
										list={bookAuthorsData.map((data: any) => ({
											value: data.bookAuthorsId,
											label: data.authorName,
										}))}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='bookGenresId' label='Book Genres'>
									<SearchableSelect
										ariaLabel='Book Genres'
										onChange={selectBookGenres}
										onBlur={addLibraryBookForm.handleBlur}
										value={bookGenresId}
										list={bookGenresData.map((data: any) => ({
											value: data.bookGenresId,
											label: data.genreName,
										}))}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='isbn' label='ISBN'>
									<Input
										onChange={addLibraryBookForm.handleChange}
										value={addLibraryBookForm.values.isbn}
										onBlur={addLibraryBookForm.handleBlur}
										isValid={addLibraryBookForm.isValid}
										isTouched={addLibraryBookForm.touched.isbn}
										invalidFeedback={addLibraryBookForm.errors.isbn}
										type='text'
										placeholder='Enter isbn code'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='publishedYear' label='Published Year'>
									<Input
										onChange={addLibraryBookForm.handleChange}
										value={addLibraryBookForm.values.publishedYear}
										onBlur={addLibraryBookForm.handleBlur}
										isValid={addLibraryBookForm.isValid}
										isTouched={addLibraryBookForm.touched.publishedYear}
										invalidFeedback={addLibraryBookForm.errors.publishedYear}
										type='number'
										placeholder='Enter Published Year'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='totalQty' label='Total Qty'>
									<Input
										onChange={addLibraryBookForm.handleChange}
										value={addLibraryBookForm.values.totalQty}
										onBlur={addLibraryBookForm.handleBlur}
										isValid={addLibraryBookForm.isValid}
										isTouched={addLibraryBookForm.touched.totalQty}
										invalidFeedback={addLibraryBookForm.errors.totalQty}
										type='number'
										placeholder='Enter Total Qty'
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>

					<div className='row m-0'>
						<div className='col-12 p-3'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								isDisable={
									!addLibraryBookForm.isValid && !!addLibraryBookForm.submitCount
								}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<OffCanvas
					setOpen={setEditLibraryBookOffCanvas}
					isOpen={editLibraryBookOffCanvas}
					titleId='editLibraryBookOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={editLibraryBook.handleSubmit}>
					<OffCanvasHeader setOpen={setEditLibraryBookOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='editLibraryBookOffCanvas'>Update Book</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							<div className='col-12'>
								<FormGroup id='title' label='Title'>
									<Input
										onChange={editLibraryBook.handleChange}
										value={editLibraryBook.values.title}
										onBlur={editLibraryBook.handleBlur}
										isValid={editLibraryBook.isValid}
										isTouched={editLibraryBook.touched.title}
										invalidFeedback={editLibraryBook.errors.title}
										type='text'
										placeholder='Enter title'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='bookAuthorsId' label='Book Author'>
									<SearchableSelect
										ariaLabel='Book Author'
										onChange={selectBookAuthor}
										onBlur={editLibraryBook.handleBlur}
										isTouched={editLibraryBook.touched.bookAuthorsId}
										invalidFeedback={editLibraryBook.errors.bookAuthorsId}
										value={bookAuthorsId}
										list={bookAuthorsData.map((data: any) => ({
											value: data.bookAuthorsId,
											label: data.authorName,
										}))}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='bookGenresId' label='Book Genres'>
									<SearchableSelect
										ariaLabel='Book Genres'
										onChange={selectBookGenres}
										onBlur={editLibraryBook.handleBlur}
										isTouched={editLibraryBook.touched.bookGenresId}
										invalidFeedback={editLibraryBook.errors.bookGenresId}
										value={bookGenresId}
										list={bookGenresData.map((data: any) => ({
											value: data.bookGenresId,
											label: data.genreName,
										}))}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='isbn' label='ISBN'>
									<Input
										onChange={editLibraryBook.handleChange}
										value={editLibraryBook.values.isbn}
										onBlur={editLibraryBook.handleBlur}
										isValid={editLibraryBook.isValid}
										isTouched={editLibraryBook.touched.isbn}
										invalidFeedback={editLibraryBook.errors.isbn}
										type='text'
										placeholder='Enter isbn code'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='publishedYear' label='Published Year'>
									<Input
										onChange={editLibraryBook.handleChange}
										value={editLibraryBook.values.publishedYear}
										onBlur={editLibraryBook.handleBlur}
										isValid={editLibraryBook.isValid}
										isTouched={editLibraryBook.touched.publishedYear}
										invalidFeedback={editLibraryBook.errors.publishedYear}
										type='number'
										placeholder='Enter Published Year'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='totalQty' label='Total Qty'>
									<Input
										onChange={editLibraryBook.handleChange}
										value={editLibraryBook.values.totalQty}
										onBlur={editLibraryBook.handleBlur}
										isValid={editLibraryBook.isValid}
										isTouched={editLibraryBook.touched.totalQty}
										invalidFeedback={editLibraryBook.errors.totalQty}
										type='number'
										placeholder='Enter Total Qty'
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row'>
						<div className='d-flex justify-content-center position-absolute top-65 start-50 translate-middle'>
							<DeleteComponents
								isDeleteOpen={isDeleteOpen}
								setIsDeleteOpen={setIsDeleteOpen}
								deleteFunction={deleteBookDetailsSubmit}
							/>
						</div>
						<div className='col-6 position-absolute bottom-0 start-0 p-3'>
							<Button
								color='danger'
								className='w-100'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
						</div>
						<div className='col-6 position-absolute bottom-0 end-0 p-3'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								isDisable={
									!editLibraryBook.isValid && !!editLibraryBook.submitCount
								}>
								Update{' '}
							</Button>
						</div>
					</div>
				</OffCanvas>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
}
export default BookList;
