import { useContext, useEffect, useRef, useState } from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Collapse from "../../../bootstrap/Collapse";
import Icon from "../../../icon/Icon";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTabItem, CardTitle } from "../../../bootstrap/Card";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import useDarkMode from "../../../../hooks/useDarkMode";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import { useNavigate, useParams } from "react-router-dom";
import { toasts } from "../../../../services/toast.service";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import { useFormik } from "formik";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Input from "../../../bootstrap/forms/Input";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import PayFees from "./PayFees";
import { downloadExcel, downloadPdfDocument } from "../../../../services/ExportService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import { getAllStudent, getProfileForStudent, getStudentDetailsBySectionDetailsId } from "../../../../services/student.service";
import { getColumnsForDataTable, getMedium, getStandardDetails, pictNotLoading, profilePic, updateFilter } from "../../../../services/common.service";
import { deleteReceiptDetails, getFeeCollectionDetailsByFeeCollectionId, getStudentFeeCollection, getStudentTotalFeeCollection, updReceiptDetailsByAdmin, } from "../../../../services/fee.service";
import AuthContext from "../../../../contexts/authContext";
import { useReactToPrint } from "react-to-print";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas";
import AlertService from "../../../../services/AlertService";
import { getAPIURL, getLicenseKey } from "../../../../services/application.settings";
import Textarea from "../../../bootstrap/forms/Textarea";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../../layout/SubHeader/SubHeader";
import { TableLoader, showLoader } from "../../../../services/loader.services";
import dayjs from "dayjs";
import CollegeLogo from '../../../../assets/logos/logo.jpg';
import './receipt.css'
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import { getBatchMasterDetails, getSectionDetails } from "../../../../services/master.service";
import { getStudentFeeConcession } from "../../../../services/concession.service";
import { getSchoolDetails } from "../../../../services/school.service";
import Page from "../../../../layout/Page/Page";

const TermFeesCollection = () => {

    const { navigatedStudentDetailsId } = useParams();
    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number)
    const [navStudent, setNavStudent] = useState(false)
    const tableFontSize = 'fs-4';

    useEffect(() => {
        getBatchList();
        getSchool();
        getAllStudentDetails();

        if (navigatedStudentDetailsId != undefined) {
            setNavStudent(true)
            viewParticularStudentForNav(navigatedStudentDetailsId)
        }
    }, [])

    const TABS = {
        FEE_DETAILS: 'Fees Details',
        PAID: 'Paid Bills',
        CONCESSION: 'Concession Details',
    };
    const [activeTab, setActiveTab] = useState(TABS.FEE_DETAILS);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true)
    const { darkModeStatus } = useDarkMode();
    const navigate = useNavigate();

    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [columnVisibilityDataForFeeCollection, setColumnVisibilityDataForFeeCollection] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [dataSuccess, setDataSuccess] = useState(false)

    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [studentProfileData, setStudentProfileData] = useState<any>([])
    const [allStudentData, setAllStudentData] = useState<any>([])
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentFeeCollectionData, setStudentFeeCollectionData] = useState<any>([])
    const [studentFeeConcessionData, setStudentFeeConcessionData] = useState<any>([])
    const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([])
    // const [allStudentData, setAllStudentData] = useState([])
    const [showPayFees, setShowPayFees] = useState("")
    const [receiptOpenModal, setReceiptOpenModal] = useState(false)
    const [updateReceiptOpenModal, setUpdateReceiptOpenModal] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    // const { items, requestSort, getClassNamesFor } = useSortableData(studentFeeCollectionData);
    // const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const { items: items1, requestSort: requestSort1, getClassNamesFor: getClassNamesFor1 } = useSortableData(studentFeeConcessionData);
    const { items: items, requestSort: requestSort, getClassNamesFor: getClassNamesFor } = useSortableData(studentFeeCollectionData);

    const onCurrentPageData1 = dataPagination(items1, currentPage, perPage);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);


    const componentRef = useRef(null);
    const [schoolDetailsData, setSchoolDetailsData] = useState<any>([])
    const [studentFeeReceiptData, setStudentFeeReceiptData] = useState<any>([])
    const [deleteReceiptCanvas, setDeleteReceiptCanvas] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [pdfFileName, setPdfFileName] = useState('')
    const [concessionNoDataMsg, setConcessionNoDataMsg] = useState('')

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')
    const [nodataForPaidBills, setNodataForPaidBills] = useState('')
    const [feeReceiptUpdateStudentDetailsId, setFeeReceiptUpdateStudentDetailsId] = useState<any>('')

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const updateStudentFeeReceiptForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentFeeCollectionId: '',
            studentDetailsId: '',
        },
        validate: (values) => {
            const errors: {
                studentDetailsId?: string;
            } = {};
            if (feeReceiptUpdateStudentDetailsId?.value == undefined) {
                errors.studentDetailsId = 'Required';
            }
            return errors;
        },
        onSubmit: () => { updateReceiptSubmit() },
    })

    const deleteStudentFeeReceiptForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentFeeCollectionId: '',
            remarks: '',
        },
        validate: (values) => {
            const errors: {
                remarks?: string;
            } = {};
            if (!values.remarks) {
                errors.remarks = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { deleteReceiptSubmit() },
    })
    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityDataForFeeCollection, columnVisibilityForm);
    // Filter
    const filteredData1 = updateFilter(onCurrentPageData1, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any, text: any) {
        if (i != undefined) {
            let selectedStudent = i
            getFeeReceipt(selectedStudent.studentFeeCollectionId);
            setPdfFileName(selectedStudent.receiptNo)

            updateStudentFeeReceiptForm.setValues({
                studentFeeCollectionId: selectedStudent.studentFeeCollectionId,
                studentDetailsId: '',

            })

            deleteStudentFeeReceiptForm.setValues({
                studentFeeCollectionId: selectedStudent.studentFeeCollectionId,
                remarks: '',
            })

            if (text == 'updateReceipt') {
                setUpdateReceiptOpenModal(true)
            }

        }
    }

    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [batchMasterData, setBatchMasterData] = useState<any>([])

    const selectBatch = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        setMediumId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        getMediumList();
    }

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        let mediumId = e
        setMediumId(mediumId)
        getStandardList();
    }

    const selectStandard = (e: any) => {
        setSectionDetailsId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
            getSectionDetailsList(mediumId?.value, standardDetailsId?.value)
        }
    }

    const selectSection = (e: any) => {
        setStudentDetailsId('')
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        if (batchMasterId?.value != undefined && sectionDetailsId?.value != undefined) {
            getStudentList(batchMasterId?.value, sectionDetailsId?.value)
        }
    }

    const selectStudent = (e: any) => {
        setIsOpenListCard(true)
        setDataSuccess(false);
        let studentDetailsId = e
        setStudentDetailsId(studentDetailsId)
    }

    function viewParticularStudent() {
        setDataSuccess(false);
        setIsLoader(true);
        setShowPayFees("");
        setActiveTab(TABS.FEE_DETAILS);
        getStudentProfile(studentDetailsId?.value);
    }

    function viewParticularStudentForNav(studentDetailsId: any) {
        setDataSuccess(false);
        setIsLoader(true);
        setShowPayFees("");
        setStudentDetailsId({ value: studentDetailsId, label: '' });
        getStudentProfile(studentDetailsId);
    }

    function refreshAfterFeeCollection(msg: any) {
        if (msg != '') {
            viewParticularStudent()
            setAlertStatus({ message: msg, type: "success" });
            setIsOpen(true);
        } else {
            viewParticularStudent()
        }
    }

    function getStudentProfile(studentDetailsId: number) {
        setIsLoader(true);
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentProfileData(data);
                        // setDataSuccess(true)
                        setIsOpenListCard(false);
                        getStudentFeeCollectionList(studentDetailsId);
                        getStudentTotalFeeCollectionList(studentDetailsId);
                        getStudentFeeConcessionList(studentDetailsId);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        // toasts("Undefined Data", "Error")
                        // setDataSuccess(false)
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    // toasts(response.data.message, "Error")
                    // setDataSuccess(false)
                    setIsOpenListCard(true);
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);

                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    // toasts(errorDescription, "Error")
                    // setDataSuccess(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                setIsLoader(false);
                // toasts(error, "Error")
                setDataSuccess(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionDetailsList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentList(batchMasterId: any, sectionDetailsId: number) {
        getStudentDetailsBySectionDetailsId(batchMasterId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySectionDetailsId;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setStudentDetailsData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAllStudentDetails() {
        getAllStudent(userAccountId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentFeeCollectionList(studentDetailsId: number) {
        getStudentFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeCollection;
                    if (data != undefined) {
                        setStudentFeeCollectionData(data);
                        getColumnsForTableStudentFeeCollection('getStudentFeeCollection', 'get')
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts('Fees Not Collected Yet.', "Error")
                    getColumnsForTableStudentFeeCollection('getStudentFeeCollection', 'get')
                    setStudentFeeCollectionData([]);
                    setNodataForPaidBills('Fees Not Collected Yet.')
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentTotalFeeCollectionList(studentDetailsId: number) {
        getStudentTotalFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTotalFeeCollection;
                    if (data != undefined) {
                        setStudentTotalFeeCollectionData(data);
                        setDataSuccess(true)
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    setStudentTotalFeeCollectionData([])
                    //toasts(response.data.message, "Error")
                    setDataSuccess(true)
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    function getStudentFeeConcessionList(studentDetailsId: number) {
        getStudentFeeConcession(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeConcessionListByStudentDetailsId;
                    if (data != undefined) {
                        setStudentFeeConcessionData(data);
                        getColumnsForTable('getStudentFeeConcessionListByStudentDetailsId', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentFeeConcessionData([]);
                    getColumnsForTable('getStudentFeeConcessionListByStudentDetailsId', 'get');
                    setNoDataMsg(response.data.message)
                    //setConcessionNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            // setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function payFees() {
        setShowPayFees("payFees")
    }

    function selectStudentCard() {
        setIsStudentOrClassWise(!isStudentOrClassWise)
        setDataSuccess(false)
        setMediumId('');
        setStandardDetailsId('');
        setSectionDetailsId('')
        setStudentDetailsId('')
        setBatchMasterId('')
        setStudentDetailsData([])
    }

    function getSchool() {
        getSchoolDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.schoolDetails;
                    if (data != undefined) {
                        setSchoolDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getFeeReceipt(studentFeeCollectionId: number) {
        getFeeCollectionDetailsByFeeCollectionId(studentFeeCollectionId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.feeCollectionDetailsByFeeCollectionId;
                    if (data != undefined) {
                        setStudentFeeReceiptData(data);

                        for (let i = 0; i < data.length; i++) {
                            let feeCollectionDetails = data[i].feeCollectionDetails
                            for (let i = 0; i < feeCollectionDetails.length; i++) {
                                feeCollectionDetails[i].sno = i + 1;
                            }
                        }

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setDeleteStudentFeeReceipt() {
        return {
            studentFeeCollectionId: deleteStudentFeeReceiptForm.values.studentFeeCollectionId,
            remarks: deleteStudentFeeReceiptForm.values.remarks,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function setUpdateStudentFeeReceipt() {
        return {
            studentDetailsId: feeReceiptUpdateStudentDetailsId?.value,
            studentFeeCollectionId: updateStudentFeeReceiptForm.values.studentFeeCollectionId,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function updateReceiptSubmit() {
        showLoader(true)
        if (updateStudentFeeReceiptForm.isValid) {
            let updateFeeReceiptPostData = setUpdateStudentFeeReceipt()
            updReceiptDetailsByAdmin(updateFeeReceiptPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewParticularStudent()
                        setUpdateReceiptOpenModal(false)
                        updateStudentFeeReceiptForm.resetForm();
                        setFeeReceiptUpdateStudentDetailsId('')
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateStudentFeeReceiptForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteReceiptSubmit() {
        showLoader(true)
        if (deleteStudentFeeReceiptForm.isValid) {
            let feeReceiptPostData = setDeleteStudentFeeReceipt()
            deleteReceiptDetails(feeReceiptPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setDeleteReceiptCanvas(false)
                        viewParticularStudent();
                        deleteStudentFeeReceiptForm.resetForm()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (deleteStudentFeeReceiptForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTableStudentFeeCollection(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityDataForFeeCollection = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityDataForFeeCollection(columnVisibilityDataForFeeCollection)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            // setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handlePDF() {
        downloadPdfDocument(componentRef.current, pdfFileName)
    }

    function closeAndReset() {
        updateStudentFeeReceiptForm.resetForm()
        setFeeReceiptUpdateStudentDetailsId('')
        setUpdateReceiptOpenModal(false)
    }

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    function receiptPdf(i: any) {
        if (i != undefined) {
            let selectedData = i
            window.open(selectedData.pdfPath);
        }
    }

    return (
        <PageWrapper title={`Fee Collection`}>
            <>
                {navStudent ?
                    <>
                        {showPayFees == "" ?
                            <SubHeader>
                                <SubHeaderLeft>
                                    <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                                        Back to List
                                    </Button>
                                    <SubheaderSeparator />
                                </SubHeaderLeft>
                            </SubHeader>
                            : null}
                    </>
                    : null}
            </>
            <Page>
                <>
                    {navStudent ? null :
                        <>
                            {showPayFees == "" ?
                                <>
                                    <Collapse isOpen={isOpenListCard}>
                                        <Card>
                                            <CardHeader borderSize={1}>
                                                <CardLabel icon='List' iconColor='info' className="col-lg-9">
                                                    <CardTitle tag='div' className='h5'>Term Fees Collection</CardTitle>
                                                </CardLabel>
                                                <div className='col-lg-3'>
                                                    <CardActions>
                                                        <Button
                                                            icon='Contacts'
                                                            color='info'
                                                            isLight={isStudentOrClassWise ? false : true}
                                                            onClick={selectStudentCard}>
                                                            Student
                                                        </Button>

                                                        <Button
                                                            icon='LocalPolice'
                                                            color='info'
                                                            isLight={isStudentOrClassWise ? true : false}
                                                            onClick={selectStudentCard}>
                                                            Class Wise
                                                        </Button>
                                                    </CardActions>
                                                </div>
                                            </CardHeader>
                                            {isStudentOrClassWise ?
                                                <CardBody>
                                                    <div className='row'>
                                                        <div className="col-4 mt-3">
                                                            <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                                <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                                    list={allStudentData.map((data: any) => (
                                                                        { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                                    ))}
                                                                />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-3 mt-4">
                                                            <Button icon='ArrowDownward' color='primary' isDisable={studentDetailsId?.value == undefined} onClick={viewParticularStudent}>View</Button>
                                                        </div>
                                                    </div>
                                                </CardBody> :
                                                <CardBody>
                                                    <div className='row'>
                                                        <div className='col-md-2'>
                                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                                <SearchableSelect isFloating ariaLabel='Batch' placeholder="Batch"
                                                                    onChange={selectBatch}
                                                                    value={batchMasterId}
                                                                    list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} required />
                                                            </FormGroup>
                                                        </div>
                                                        {batchMasterId?.value != undefined ?
                                                            <div className='col-2'>
                                                                <FormGroup id='mediumId' label='Medium' isFloating>
                                                                    <SearchableSelect isFloating ariaLabel='Medium' placeholder="Select Standard"
                                                                        onChange={selectMedium}
                                                                        value={mediumId}
                                                                        list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} required />

                                                                </FormGroup>
                                                            </div> : null
                                                        }
                                                        {mediumId?.value != undefined ? <div className='col-2'>
                                                            <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                                <SearchableSelect isFloating ariaLabel='Standard' placeholder="Select Standard"
                                                                    onChange={selectStandard}
                                                                    value={standardDetailsId}
                                                                    list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />

                                                            </FormGroup>
                                                        </div> : null}
                                                        {standardDetailsId?.value != undefined ? <div className='col-2'>
                                                            <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                                <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                                                    list={sectionDetailsData.map((data: any) => (
                                                                        { value: data.sectionDetailsId, label: data.sectionName }
                                                                    ))}
                                                                />
                                                            </FormGroup>
                                                        </div> : null}

                                                        {sectionDetailsId?.value != undefined ? <div className="col-3">
                                                            <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                                <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                                    list={studentDetailsData.map((data: any) => (
                                                                        { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                                    ))}
                                                                />
                                                            </FormGroup>
                                                        </div> : null}

                                                        {studentDetailsId?.value != undefined ? <div className="col-1 mt-2">
                                                            <Button icon='ArrowDownward' color='primary' isDisable={studentDetailsId?.value == undefined} onClick={viewParticularStudent}>View</Button>
                                                        </div> : null}
                                                    </div>
                                                </CardBody>}
                                        </Card>
                                    </Collapse>

                                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
                                </>
                                : null}

                        </>}
                    {dataSuccess ?
                        <> {showPayFees == "" ?
                            <div className='row h-100 pb-3'>
                                <div className='col-lg-4 col-md-6'>
                                    <Card stretch>
                                        <CardHeader borderSize={1}>
                                            <CardLabel icon='Person' iconColor='info'>
                                                <CardTitle tag='div' className='h5'>
                                                    Account Details
                                                </CardTitle>
                                                <CardSubTitle tag='div' className='h6'>
                                                    Personal Information - {studentProfileData[0].isActive ? <span className="text-success align-self-center mt-2 border border-success border-2 px-2 py-1 rounded sm">{studentProfileData[0].activeStatus}</span> : <span className="text-danger align-self-center mt-2 border border-danger border-2 px-2 py-1 rounded sm">{studentProfileData[0].activeStatus}</span>}
                                                </CardSubTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='row g-3'>
                                                <div className='col-12'>
                                                    {studentProfileData.map((student: any, index: any) => (
                                                        <div className="row" key={index}>
                                                            <div className="col-4 mt-3">
                                                                {student.profilePath != null ?
                                                                    <img style={{ borderRadius: '50%' }} src={student.profilePath}
                                                                        width="100" height="100" onError={(e: any) => pictNotLoading(e, student.gender == 'Male' ? 1 : 2)} />
                                                                    : <img className="rounded-0" src={profilePic(student.genderId)}
                                                                        width="100" height="100" />}

                                                            </div>
                                                            <div className="col-8">
                                                                <h5 className='fs-5 fw-bold me-2 text-uppercase mt-3'>{student.studentName}</h5>
                                                                <div><span className="text-success">{student.admissionNo} </span>( {student.studentRollNo != null ? student.studentRollNo : '-'} )</div>
                                                                <div>{student.standardName} - {student.sectionName} ( {student.medium} )</div>
                                                                <div>{student.batchName}</div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                                <div className='col-12'>
                                                    <Button
                                                        icon='LocalPolice'
                                                        color='info'
                                                        className='w-100 p-3'
                                                        isLight={TABS.FEE_DETAILS !== activeTab}
                                                        onClick={() => setActiveTab(TABS.FEE_DETAILS)}>
                                                        {TABS.FEE_DETAILS}
                                                    </Button>
                                                </div>
                                                <div className='col-12'>
                                                    <Button
                                                        icon='PendingActions'
                                                        color='info'
                                                        className='w-100 p-3'
                                                        isLight={TABS.PAID !== activeTab}
                                                        onClick={() => setActiveTab(TABS.PAID)}>
                                                        {TABS.PAID}
                                                    </Button>
                                                </div>
                                                <div className='col-12'>
                                                    <Button
                                                        icon='ReceiptLong'
                                                        color='info'
                                                        className='w-100 p-3'
                                                        isLight={TABS.CONCESSION !== activeTab}
                                                        onClick={() => setActiveTab(TABS.CONCESSION)}>
                                                        {TABS.CONCESSION}
                                                    </Button>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-lg-8 col-md-6'>
                                    {TABS.FEE_DETAILS === activeTab && (
                                        <Card stretch>
                                            <CardHeader>
                                                <CardLabel icon='Task' iconColor='primary'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Fee Details
                                                    </CardTitle>
                                                </CardLabel>
                                                <CardActions>
                                                    <Button color='success' icon='Payment' isLight onClick={payFees}
                                                    >
                                                        {userTypeId != 6 && userTypeId != 12 ? 'Pay Fees' : 'Fee Details'}
                                                    </Button>
                                                    <Button color='info' icon='Payments' isLight onClick={() => setShowPayFees("payFineFees")}>
                                                        {userTypeId != 6 && userTypeId != 12 ? 'Pay Fine' : 'Fine Report'}
                                                    </Button>
                                                </CardActions>
                                            </CardHeader>
                                            <CardBody className='table-responsive'>
                                                {studentTotalFeeCollectionData ?
                                                    <div className="table-responsive">
                                                        <table className="table table-modern table-hover" >
                                                            <thead className="text-center text-uppercase align-middle">
                                                                <tr>
                                                                    <th className='table-info'></th>
                                                                    <th className='table-info'>Tution Fees</th>
                                                                    {studentProfileData[0].standardDetailsId > 1 ? <th className='table-info'>Previous <br />Tution Fees</th> : null}
                                                                    <th className='table-info'>Fine Fees</th>
                                                                    <th className='table-info'>Bus Fees</th>
                                                                    {studentProfileData[0].standardDetailsId > 1 ? <th className='table-info'>Previous <br />Bus Fees</th> : null}
                                                                    <th className='table-info'>Extra Fees</th>
                                                                </tr>
                                                            </thead>

                                                            {studentTotalFeeCollectionData.map((studentTotalFeeCollectionData: any) => (
                                                                <tbody className="text-center align-middle" key={studentTotalFeeCollectionData.studentDetailsId}>
                                                                    <tr>
                                                                        <th>GROSS PAYABLE</th>
                                                                        <td><strong>{studentTotalFeeCollectionData.termFees_grossPayable}</strong></td>
                                                                        {studentProfileData[0].standardDetailsId > 1 ? <td><strong>{studentTotalFeeCollectionData.previous_termFees_grossPayable}</strong></td> : null}
                                                                        <td><strong>{studentTotalFeeCollectionData.totalFineAmount}</strong></td>
                                                                        <td><strong>{studentTotalFeeCollectionData.busFees_grossPayable}</strong></td>
                                                                        {studentProfileData[0].standardDetailsId > 1 ? <td><strong>{studentTotalFeeCollectionData.previous_busFees_grossPayable}</strong></td> : null}
                                                                        <td><strong>{studentTotalFeeCollectionData.extraFees_grossPayable}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>AMOUNT PAID</th>
                                                                        <td><strong>{studentTotalFeeCollectionData.termFees_amountPaid}</strong></td>
                                                                        {studentProfileData[0].standardDetailsId > 1 ? <td><strong>{studentTotalFeeCollectionData.previous_termFees_amountPaid}</strong></td> : null}
                                                                        <td><strong>{studentTotalFeeCollectionData.totalFineAmount}</strong></td>
                                                                        <td><strong>{studentTotalFeeCollectionData.busFees_amountPaid}</strong></td>
                                                                        {studentProfileData[0].standardDetailsId > 1 ? <td><strong>{studentTotalFeeCollectionData.previous_busFees_amountPaid}</strong></td> : null}
                                                                        <td><strong>{studentTotalFeeCollectionData.extraFees_amountPaid}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>CONCESSION</th>
                                                                        <td><strong>{studentTotalFeeCollectionData.termFees_concession}</strong></td>
                                                                        {studentProfileData[0].standardDetailsId > 1 ? <td><strong>{studentTotalFeeCollectionData.previous_termFees_concession}</strong></td> : null}
                                                                        <td><strong>0</strong></td>
                                                                        <td><strong>{studentTotalFeeCollectionData.busFees_concession}</strong></td>
                                                                        {studentProfileData[0].standardDetailsId > 1 ? <td><strong>{studentTotalFeeCollectionData.previous_busFees_concession}</strong></td> : null}
                                                                        <td><strong>{studentTotalFeeCollectionData.extraFees_concession}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>NET PAYABLE</th>
                                                                        <td>
                                                                            {studentTotalFeeCollectionData.termFees_netPayable === 0 ?
                                                                                <strong className="text-success">{studentTotalFeeCollectionData.termFees_netPayable}</strong> :
                                                                                <strong className="text-danger">{studentTotalFeeCollectionData.termFees_netPayable}</strong>
                                                                            }
                                                                        </td>
                                                                        {studentProfileData[0].standardDetailsId > 1 ? <td>
                                                                            {studentTotalFeeCollectionData.previous_termFees_netPayable === 0 ?
                                                                                <strong className="text-success">{studentTotalFeeCollectionData.previous_termFees_netPayable}</strong> :
                                                                                <strong className="text-danger">{studentTotalFeeCollectionData.previous_termFees_netPayable}</strong>
                                                                            }
                                                                        </td> : null}
                                                                        <td><strong>0</strong></td>
                                                                        <td>
                                                                            {studentTotalFeeCollectionData.busFees_netPayable === 0 ?
                                                                                <strong className="text-success">{studentTotalFeeCollectionData.busFees_netPayable}</strong> :
                                                                                <strong className="text-danger">{studentTotalFeeCollectionData.busFees_netPayable}</strong>
                                                                            }
                                                                        </td>
                                                                        {studentProfileData[0].standardDetailsId > 1 ? <td>
                                                                            {studentTotalFeeCollectionData.previous_busFees_netPayable === 0 ?
                                                                                <strong className="text-success">{studentTotalFeeCollectionData.previous_busFees_netPayable}</strong> :
                                                                                <strong className="text-danger">{studentTotalFeeCollectionData.previous_busFees_netPayable}</strong>
                                                                            }
                                                                        </td> : null}
                                                                        <td>
                                                                            {studentTotalFeeCollectionData.extraFees_netPayable === 0 ?
                                                                                <strong className="text-success">{studentTotalFeeCollectionData.extraFees_netPayable}</strong> :
                                                                                <strong className="text-danger">{studentTotalFeeCollectionData.extraFees_netPayable}</strong>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ))}
                                                        </table>
                                                        <br />

                                                        {studentTotalFeeCollectionData.map((studentTotalFeeCollectionData: any) => (
                                                            <div className="col-12 d-flex justify-content-end" key={studentTotalFeeCollectionData.studentDetailsId}>
                                                                <p className='fw-bold fs-5 mb-0'> Balance : </p>
                                                                <strong className={studentTotalFeeCollectionData.total_netPayable == 0 ? "text-success" : "text-danger"}   >
                                                                    <p className='fw-bold fs-5 mb-0 ms-2'>
                                                                        {studentTotalFeeCollectionData.total_netPayable}
                                                                    </p>
                                                                </strong>
                                                            </div>
                                                        ))}
                                                    </div> :
                                                    <div className='text-danger col-12 d-flex justify-content-center mt-5'>
                                                        <p className='fw-bold fs-5 mb-0 mt-5'>Fees Not Collected Yet . </p>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    )}
                                    {
                                        TABS.PAID === activeTab && (
                                            <Card stretch>
                                                <CardHeader>
                                                    <CardLabel icon='PendingActions' iconColor='primary'>
                                                        <CardTitle tag='div' className='h5'>
                                                            Paid Bill Details
                                                        </CardTitle>
                                                        <CardSubTitle tag='div' className='h6'>
                                                            Total Bills:{' '}
                                                            <small className='ms-2'>
                                                                {filteredData.length}
                                                            </small>
                                                        </CardSubTitle>
                                                    </CardLabel>
                                                    {studentFeeCollectionData != "" ?
                                                        <CardActions>
                                                            <ButtonGroup className="col-lg-12 d-print-none" color='primary'>
                                                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                                            </ButtonGroup>
                                                        </CardActions> : null}
                                                </CardHeader>
                                                <CardBody className='table-responsive' isScrollable>
                                                    <table className='table table-modern table-hover text-nowrap'>
                                                        <thead>
                                                            <tr>
                                                                <th scope='col' onClick={() => requestSort('sno')}
                                                                    className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                                </th>
                                                                {columnVisibilityDataForFeeCollection.map((column: any) => (
                                                                    <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                                        className='cursor-pointer text-decoration-underline'>
                                                                        {column.columnName}
                                                                        <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                                    </th>
                                                                ))}
                                                                <td className="d-print-none" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.studentFeeCollectionId}><td>{rowData.sno}</td>{columnVisibilityDataForFeeCollection.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentFeeCollectionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}{userTypeId == 1 ? <td className="d-print-none" onClick={() => { onActivate(rowData, '') }}><Dropdown isButtonGroup><DropdownToggle><Button color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button></DropdownToggle><DropdownMenu isAlignmentEnd><DropdownItem><Button icon="Print" isLight color="info" onClick={() => receiptPdf(rowData)}>Print</Button></DropdownItem><DropdownItem><Button icon="Delete" isLight color="primary" onClick={() => { setDeleteReceiptCanvas(true) }}>Delete</Button></DropdownItem></DropdownMenu></Dropdown></td> : <td className="d-print-none" onClick={() => { onActivate(rowData, '') }}><Button icon="Print" isLight color="info" onClick={() => receiptPdf(rowData)}>Print</Button></td>}</tr>))}</> : <NoDataMsg columnsCount={columnVisibilityDataForFeeCollection.length + 2} msg={nodataForPaidBills} />}</tbody></table>

                                                    {/* <DropdownItem><Button icon="Receipt" isLight color="success" onClick={() => { onActivate(rowData, 'updateReceipt') }}>Update</Button></DropdownItem> */}
                                                </CardBody>
                                                {studentFeeCollectionData != "" ? <PaginationButtons
                                                    data={items}
                                                    label='items'
                                                    setCurrentPage={setCurrentPage}
                                                    currentPage={currentPage}
                                                    perPage={perPage}
                                                    setPerPage={setPerPage}
                                                /> : null}
                                            </Card>
                                        )
                                    }
                                    {
                                        TABS.CONCESSION === activeTab && (
                                            <Card stretch>
                                                <CardHeader>
                                                    <CardLabel icon='ReceiptLong' iconColor='primary'>
                                                        <CardTitle tag='div' className='h5'>
                                                            Given Concession
                                                        </CardTitle>
                                                        <CardSubTitle tag='div' className='h6'>
                                                            Total Concession:{''}
                                                            <small className='ms-2'>
                                                                {studentFeeConcessionData.length}
                                                            </small>
                                                        </CardSubTitle>
                                                    </CardLabel>
                                                    {studentFeeConcessionData != "" ?
                                                        <>
                                                            <CardActions>
                                                                <Button color='info' icon='CloudDownload' isLight onClick={() => downloadExcel("ConcessionList", studentFeeConcessionData, columnVisibilityData)}>
                                                                    Excel
                                                                </Button>
                                                            </CardActions> </> : null}
                                                </CardHeader>
                                                <CardBody className='table-responsive' isScrollable>
                                                    <table className='table table-modern mb-0 text-center'>
                                                        <thead>
                                                            <tr>
                                                                <th scope='col' onClick={() => requestSort1('sno')}
                                                                    className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                                    <Icon size='lg' className={getClassNamesFor1('sno')} icon='FilterList' />
                                                                </th>
                                                                {columnVisibilityData.map((column: any) => (
                                                                    <th key={column.keyName} scope='col' onClick={() => requestSort1(column.keyName)}
                                                                        className='cursor-pointer text-decoration-underline'>
                                                                        {column.columnName}
                                                                        <Icon size='lg' className={getClassNamesFor1(column.keyName)} icon='FilterList' />
                                                                    </th>
                                                                ))}
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{filteredData1 != "" ? <>{filteredData1.map((rowData: any) => (<tr key={rowData.studentFeeConcessionId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentFeeConcessionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td><Button isLink color={rowData.concessionStatusId == 1 ? 'success' : rowData.concessionStatusId == 2 ? 'danger' : 'warning'} icon='Circle' className='text-nowrap'>{rowData.concessionStatus}</Button></td></tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody></table>
                                                </CardBody>
                                                {studentFeeConcessionData != "" ? <PaginationButtons
                                                    data={items1}
                                                    label='items'
                                                    setCurrentPage={setCurrentPage}
                                                    currentPage={currentPage}
                                                    perPage={perPage}
                                                    setPerPage={setPerPage}
                                                /> : null}
                                            </Card>
                                        )
                                    }
                                </div>
                            </div> :
                            <PayFees studentDetailsId={studentDetailsId?.value} setShowPayFees={setShowPayFees} showPayFees={showPayFees} refreshAfterFeeCollection={refreshAfterFeeCollection} />}
                        </> : <>{navStudent ? <TableLoader /> : null}</>}
                </>

                <style type="text/css" media="print">
                    {"@page { size: A4 landscape; margin-left: 0.5in; margin-right: 0.5in; margin-top: 0.5in; margin-bottom: 0.5in; }\ }"}
                </style>

                <Modal setIsOpen={setReceiptOpenModal} isOpen={receiptOpenModal} titleId='addCourseCapacity' isStaticBackdrop size='xl'>
                    <ModalHeader className="'modal-header'" setIsOpen={() => { setReceiptOpenModal(false) }}>
                        <div className='d-flex col-4'>
                            <Button size='sm' icon="Downloading" isLight color="success" onClick={handlePDF}>Download</Button>

                            <Button className="ms-2" size='sm' icon="Print" isLight color="primary" onClick={handlePrint}>Print</Button>
                        </div>
                    </ModalHeader>

                    <ModalBody ref={componentRef}>
                        <></>
                    </ModalBody>
                </Modal>

                <OffCanvas
                    setOpen={setDeleteReceiptCanvas}
                    isOpen={deleteReceiptCanvas}
                    titleId='deleteReceiptCanvas'
                    isBodyScroll
                    placement='end'
                    tag="form" noValidate onSubmit={deleteStudentFeeReceiptForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setDeleteReceiptCanvas}>
                        <OffCanvasTitle id="deleteReceiptCanvas">Delete Receipt</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row">
                            <div className='col-lg-12 mt-3'>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-warning bg-l${darkModeStatus ? 'o50' : '10'
                                    }-warning-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0'>
                                                <Icon icon='Delete' size='3x' color='danger' />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-6 text-danger mb-0'>
                                                    Are you sure you want to Delete ?
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-12 mt-3">
                                <FormGroup id='remarks' label="Remarks" >
                                    <Textarea
                                        placeholder="Enter Remarks"
                                        onChange={deleteStudentFeeReceiptForm.handleChange}
                                        value={deleteStudentFeeReceiptForm.values.remarks}
                                        onBlur={deleteStudentFeeReceiptForm.handleBlur}
                                        isValid={deleteStudentFeeReceiptForm.isValid}
                                        isTouched={deleteStudentFeeReceiptForm.touched.remarks}
                                        invalidFeedback={deleteStudentFeeReceiptForm.errors.remarks}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='row m-0'>
                            <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <Button
                                    color='danger'
                                    icon='Delete'
                                    className='w-100' type="submit" isDisable={!deleteStudentFeeReceiptForm.isValid && !!deleteStudentFeeReceiptForm.submitCount}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </OffCanvasBody>
                </OffCanvas>

                <Modal setIsOpen={setUpdateReceiptOpenModal} isOpen={updateReceiptOpenModal} titleId="updateReceiptOpenModal" isStaticBackdrop
                    size='lg'  >
                    <ModalHeader setIsOpen={setUpdateReceiptOpenModal} onClick={closeAndReset}>
                        <ModalTitle id="updateReceiptOpenModal">Update Receipt</ModalTitle>
                    </ModalHeader>
                    <form noValidate onSubmit={updateStudentFeeReceiptForm.handleSubmit}>
                        <ModalBody className='px-4'>
                            {studentFeeReceiptData.map((student: any) => (
                                <div className='row g-4' key={student.studentFeeCollectionId}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className='col-lg-6'>
                                                <div className="row g-4 justify-content-center">
                                                    <div className='col-lg-7'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='person' size='2x' color='primary' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0'>{student.studentName}</div>
                                                                <div className='text-muted'>
                                                                    Student Name
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-5'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='HowToReg' size='2x' color='primary' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0'>{student.studentRollNo}</div>
                                                                <div className='text-muted'>
                                                                    Admission No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="row g-4 justify-content-center">
                                                    <div className='col-lg-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='ListAlt' size='2x' color='primary' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0'>{student.receiptNo}</div>
                                                                <div className='text-muted'>
                                                                    Receipt No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='CalendarToday' size='2x' color='primary' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0'>{student.receiptDate}</div>
                                                                <div className='text-muted'>
                                                                    Receipt Date
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <Card stretch className='rounded-1 mb-2'>
                                            <CardHeader>
                                                <CardLabel icon='ReceiptLong'>
                                                    <CardTitle>Paid Bill Details</CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <table className='table table-modern table-hover text-nowrap'>
                                                    <thead className='table-primary'>
                                                        <tr>
                                                            <th>
                                                                S.No
                                                            </th>
                                                            <th>
                                                                Particulars
                                                            </th>
                                                            <th>
                                                                Amount
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {student.feeCollectionDetails.map((feeData: any) => (
                                                            <tr key={feeData.feeTypeId}>
                                                                <td >
                                                                    {feeData.sno}
                                                                </td>
                                                                <td>
                                                                    {feeData.feeType} / {feeData.termName}
                                                                </td>
                                                                <td>
                                                                    <b>{feeData.totalAmount}</b>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className='col-md-6'>
                                        <Card stretch className='rounded-1 mb-2'>
                                            <CardHeader>
                                                <CardLabel icon='Update'>
                                                    <CardTitle>To Be Updated</CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="row g-4">
                                                    <div className="col-lg-12">
                                                        <FormGroup id='studentDetailsId' label="Select Student" isFloating>
                                                            <SearchableSelect isFloating ariaLabel="Select Student"
                                                                onChange={(e: any) => setFeeReceiptUpdateStudentDetailsId(e)}
                                                                value={feeReceiptUpdateStudentDetailsId}
                                                                list={allStudentData.map((data: any) => (
                                                                    { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                                ))}
                                                                isValid={updateStudentFeeReceiptForm.isValid}
                                                                isTouched={updateStudentFeeReceiptForm.touched.studentDetailsId}
                                                                invalidFeedback={updateStudentFeeReceiptForm.errors.studentDetailsId} />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            ))}
                        </ModalBody>
                        <ModalFooter>
                            <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                            <Button color='info' icon='Save' type="submit" isDisable={feeReceiptUpdateStudentDetailsId?.value == undefined}>Update </Button>
                        </ModalFooter>
                    </form>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper>
    )
}
export default TermFeesCollection