import React, { useContext, useEffect, useRef, useState } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Page from '../../layout/Page/Page';
import FormGroup from '../bootstrap/forms/FormGroup';
import Button, { ButtonGroup } from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Collapse from '../bootstrap/Collapse';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../services/toast.service';
import Input from '../bootstrap/forms/Input';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../services/ExportService';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import { useFormik } from 'formik';
import useSortableData from '../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import { useReactToPrint } from 'react-to-print';
import OpenCardComponent from '../../common/components/OpenCardComponent';
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from '../../services/common.service';
import AuthContext from '../../contexts/authContext';
import { getLicenseKey } from '../../services/application.settings';
import NoDataMsg from '../../common/components/NoDataMsg';
import SearchableSelect from '../../common/components/SearchableSelect';
import { assignPlayersPosition, getSportsDetails, getSportsPlayers, getSportsPosition, removeSportsPlayers } from '../../services/sportsManagement.service';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import { showLoader } from '../../services/loader.services';
import AlertService from '../../services/AlertService';
import { useNavigate, useParams } from 'react-router-dom';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';

function SportsPlayers() {

    const { sportsDetailsIdForNavigate } = useParams();
    const [sportsDetailsId, setSportsDetailsId] = useState<any>('')

    useEffect(() => {
        getSportsList()
    }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [sportsPositionId, setSportsPositionId] = useState<any>('')
    const [sportsDetailsData, setSportsDetailsData] = useState<any>([])
    const [sportsPositionData, setSportsPositionData] = useState<any>([])
    const [sportsPlayersData, setSportsPlayersData] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(sportsPlayersData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [isLoader, setIsLoader] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('');

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const assignPositionForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            sportsPositionId: '',
        },
        validate: (values) => {
            const errors: {
                sportsPositionId?: string;
            } = {};
            if (!sportsPositionId?.value) {
                errors.sportsPositionId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { assignPlayersPositionSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function viewSportsList() {
        setIsLoader(true);
        getSportsPlayersList(sportsDetailsId?.value)
    }

    const selectSports = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        let sportsDetailsId = e
        setSportsDetailsId(sportsDetailsId)
    }

    const selectSportsPosition = (e: any) => {
        let sportsPositionId = e
        setSportsPositionId(sportsPositionId)
    }

    function getSportsList() {
        getSportsDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sportsDetails;
                    if (data != undefined) {
                        setSportsDetailsData(data);

                        if (sportsDetailsIdForNavigate != undefined && sportsDetailsIdForNavigate != '') {

                            let filData = data.filter((item: any) => item.sportsDetailsId == sportsDetailsIdForNavigate)

                            setSportsDetailsId({ value: filData[0].sportsDetailsId, label: filData[0].sportsName })
                            getSportsPlayersList(filData[0].sportsDetailsId)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSportsDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSportsPositionList(sportsDetailsId: any) {
        getSportsPosition(sportsDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sportsPosition[0].position;
                    if (data != undefined) {
                        setSportsPositionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSportsPositionData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSportsPlayersList(sportsDetailsId: any) {
        setIsLoader(true);
        getSportsPlayers(sportsDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sportsPlayers[0].studentDetails;
                    if (data != undefined) {
                        setIsLoader(false);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        setSportsPlayersData(data);
                        getColumnsForTable('getSportsPlayers', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(true);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setIsLoader(false);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setSportsPlayersData([]);
                    getColumnsForTable('getSportsPlayers', 'get');
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    setDataSuccess(true);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                setDataSuccess(true);
                toasts(error, 'Error');
            },
        );
    }

    const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [sportsPlayersId, setSportsPlayersId] = useState('');

    function setPositionDetails() {
        return {
            licenseKey: getLicenseKey,
            staffDetailsId: userAccountId,
            sportsPositionId: sportsPositionId?.value,
            sportsPlayersId: sportsPlayersId,
        };
    }

    function assignPlayersPositionSubmit() {
        showLoader(true);
        if (assignPositionForm.isValid) {
            let positionPostData = setPositionDetails();
            assignPlayersPosition(positionPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'success' });
                        setIsOpen(true);
                        getSportsPlayersList(sportsDetailsId?.value);
                        closeAndFormReset();
                    } else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'error' });
                        setIsOpen(true);
                    } else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: 'error' });
                        setIsOpen(true);
                    }
                },
                (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: 'error' });
                    setIsOpen(true);
                },
            );
        } else if (assignPositionForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
            setIsOpen(true);
        }
    }

    function removeSportsPlayersSubmit() {
        showLoader(true);
        if (assignPositionForm.isValid) {
            let positionPostData = setPositionDetails();
            removeSportsPlayers(positionPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'success' });
                        setIsOpen(true);
                        getSportsPlayersList(sportsDetailsId?.value);
                        closeAndFormReset();
                    } else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'error' });
                        setIsOpen(true);
                    } else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: 'error' });
                        setIsOpen(true);
                    }
                },
                (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: 'error' });
                    setIsOpen(true);
                },
            );
        } else if (assignPositionForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(
            userTypeId,
            apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData);
                        let columnVisibilityData = allColumnsData.filter(
                            (item: any) => item.isDisplay == 1,
                        );
                        setColumnVisibilityData(columnVisibilityData);

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        } else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter(
                                (item: any) => item.isDefault == false,
                            );

                            for (let i = 0; i < nonDefaultValue.length; i++) {
                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
                                };
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all);
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
        if (columnVisibilityForm.isValid) {
            setColumnDataById(isDisplay);
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [{ columnVisibilityId: columnVisibilityId, isDisplay: isDisplay ? 0 : 1 }],
            };

            updateColumnsForDataTable(
                columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getSportsPlayers', 'get');
                    } else if (data.success == false) {
                        // toasts(data.success, "Error")
                    } else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, 'Error');
                    }
                },
                (error) => {
                    toasts(error, 'Error');
                },
            );
        } else if (columnVisibilityForm.isValid == false) {
            toasts('Please fill all the details!', 'Error');
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [assignPositionOffCanvas, setAssignPositionOffCanvas] = useState(false)

    function onActivate(i: any) {
        if (i != undefined) {
            getSportsPositionList(sportsDetailsId?.value)
            setSportsPlayersId(i.sportsPlayersId)
            setAssignPositionOffCanvas(true)
            if (i.sportsPositionId > 0) {
                setSportsPositionId({ value: i.sportsPositionId, label: i.position })
            } else {
                setSportsPositionId([])
            }
        }
    }

    function closeAndFormReset() {
        assignPositionForm.resetForm()
        setAssignPositionOffCanvas(false)
    }

    const navigate = useNavigate();    

    return (
        <>
            <PageWrapper title='Players List'>
                {sportsDetailsIdForNavigate != undefined && sportsDetailsIdForNavigate != '' ?
                    <SubHeader>
                        <SubHeaderLeft>
                            <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`../sportsManagement/addSports`)}>
                                Back to List
                            </Button>
                            <SubheaderSeparator />
                        </SubHeaderLeft>
                    </SubHeader> : <></>
                }
                <Page container='fluid'>

                    {sportsDetailsIdForNavigate == undefined || sportsDetailsIdForNavigate == '' ? <>
                        <Collapse isOpen={isOpenListCard}>
                            <Card>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='list' iconColor='primary'>
                                        <CardTitle>Players List</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row'>
                                        <div className="col-lg-3">
                                            <FormGroup id='sportsDetailsId' label='Sports' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Type'
                                                    onChange={selectSports}
                                                    value={sportsDetailsId}
                                                    list={sportsDetailsData.map((data: any) => (
                                                        { value: data.sportsDetailsId, label: data.sportsName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-2 align-self-center mt-1">
                                            <FormGroup >
                                                <Button icon="South" color="primary" isDisable={sportsDetailsId?.value == undefined ? true : false}
                                                    onClick={viewSportsList}>View</Button>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Collapse>

                        <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    </> : null
                    }

                    {dataSuccess ? (
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                    <CardTitle tag='div' className='h5'>
                                        {sportsDetailsId?.label}
                                    </CardTitle>
                                </CardLabel>
                                &nbsp;&nbsp;
                                <ButtonGroup
                                    className='col-lg-3 justify-content-end d-print-none'
                                    color='primary'>
                                    <Icon
                                        className='mt-1'
                                        icon='Search'
                                        size='2x'
                                        color='primary'
                                    />
                                    <Input
                                        id='searchInput'
                                        type='search'
                                        placeholder='Search...'
                                        onChange={columnVisibilityForm.handleChange}
                                        value={columnVisibilityForm.values.searchInput}
                                    />
                                </ButtonGroup>
                                <CardActions className='d-print-none'>
                                    <Dropdown
                                        isOpen={columnVisibilityMenu}
                                        setIsOpen={setColumnVisibilityMenu}
                                        isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>
                                                Filter
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu
                                            isAlignmentEnd
                                            size='lg'
                                            isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks
                                                                    id='available'
                                                                    type='switch'
                                                                    label='Select All Columns'
                                                                    onChange={() => getColumnsForTable('getSportsPlayers', 'post')}
                                                                    checked={isChecked}
                                                                    ariaLabel='Available status'
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map(
                                                                        (i: any) => (
                                                                            <Checks
                                                                                key={i.columnVisibilityId}
                                                                                id='{i.columnVisibilityId}'
                                                                                label={i.columnName}
                                                                                onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')}
                                                                                checked={i.isDisplay}
                                                                                disabled={i.isDefault == true}
                                                                            />
                                                                        ),
                                                                    )}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'>
                                                Export
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() => downloadExcel(`${sportsDetailsId?.label} Players List`, sportsPlayersData, columnVisibilityData)}>
                                                    Excel
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='PictureAsPdf'
                                                    onClick={() => convertJsonToPdf(sportsPlayersData, columnVisibilityData, `${sportsDetailsId?.label} Players List`)
                                                    }>
                                                    PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() => downloadFile(`${sportsDetailsId?.label} Players List`, sportsPlayersData, columnVisibilityData)
                                                    }>
                                                    CSV
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='Print'
                                                    onClick={handlePrint}>
                                                    Print
                                                </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>
                                                S.No
                                                <Icon
                                                    size='lg'
                                                    className={getClassNamesFor('sno')}
                                                    icon='FilterList'
                                                />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th
                                                    key={column.keyName}
                                                    scope='col'
                                                    onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon
                                                        size='lg'
                                                        className={getClassNamesFor(column.keyName)}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                            ))}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != '' ? (
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.sportsPlayersId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) =>
                                                            column.isDisplay &&
                                                                rowData[column.keyName] ? (
                                                                <td className='text-wrap'
                                                                    key={`${rowData.referredId}-${column.keyName}`}>
                                                                    {rowData[column.keyName]}
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`empty-${column.keyName}`}
                                                                />
                                                            ),
                                                        )}
                                                        <td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                    </tr>

                                                ))}

                                            </>
                                        ) : (
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons className='d-print-none' data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                        </Card>
                    ) : null}
                </Page>

                <OffCanvas
                    setOpen={setAssignPositionOffCanvas}
                    isOpen={assignPositionOffCanvas}
                    titleId='assignPositionOffCanvas'
                    isBodyScroll
                >
                    <OffCanvasHeader setOpen={setAssignPositionOffCanvas} onClick={closeAndFormReset}>
                        <OffCanvasTitle id='assignPositionOffCanvas'>Assign Position</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <form noValidate onSubmit={assignPositionForm.handleSubmit}>
                            <div className="row">
                                <div className='col-md-12'>
                                    <FormGroup id='sportsPositionId' label='Position'>
                                        <SearchableSelect
                                            ariaLabel='Position'
                                            onChange={selectSportsPosition}
                                            value={sportsPositionId}
                                            isValid={assignPositionForm.isValid}
                                            onBlur={assignPositionForm.handleBlur}
                                            isTouched={assignPositionForm.touched.sportsPositionId}
                                            invalidFeedback={assignPositionForm.errors.sportsPositionId}
                                            list={sportsPositionData.map((data: any) => (
                                                { value: data.sportsPositionId, label: data.position }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='d-flex justify-content-center position-absolute top-65 start-50 translate-middle'>
                                    <Collapse isOpen={isDeleteOpen} className="dropdown-menu">
                                        <div className="row g-4 mt-2">
                                            <div className="col-12 d-flex justify-content-center">
                                                <h5><strong>Are you sure want to Remove ?</strong></h5>
                                            </div>
                                            <div className='col-10'>
                                                <div className='d-flex gap-2 justify-content-end'>
                                                    <Button color='danger' size={'sm'} onClick={removeSportsPlayersSubmit}>Yes</Button>
                                                    <Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>No</Button>
                                                </div>
                                            </div>
                                            <div className='col-10'></div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>

                            <div className='row top-90 m-0'>
                                <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                                    <Button
                                        color='danger'
                                        className='w-100'
                                        icon='PersonRemove'
                                        onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        Remove
                                    </Button>
                                </div>
                                <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                                    <Button
                                        color='info'
                                        icon='Save'
                                        className='w-100'
                                        type='submit'
                                        isDisable={
                                            !assignPositionForm.isValid &&
                                            !!assignPositionForm.submitCount
                                        }>
                                        Update
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </OffCanvasBody>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

            </PageWrapper>
        </>
    );
}

export default SportsPlayers;
