import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import NoDataMsg from '../../../common/components/NoDataMsg';
import AuthContext from '../../../contexts/authContext';
import useSortableData from '../../../hooks/useSortableData';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import { getLicenseKey } from '../../../services/application.settings';
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable, onlyAllowNumber } from '../../../services/common.service';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import { addStaffFineFee, getNotReturnBookList } from '../../../services/library.service';
import { showLoader, TableLoader } from '../../../services/loader.services';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from '../../bootstrap/Card';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Icon from '../../icon/Icon';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Input from '../../bootstrap/forms/Input';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas';
import { addExtraFeeDetails, getExtraFeeType, getFeeType, getFineFeeType, getStudentFeeDetails, getStudentTotalFeeCollection } from '../../../services/fee.service';
import SearchableSelect from '../../../common/components/SearchableSelect';

function NotReturnBookList() {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getNotReturnBookDetails();
		getFineFeeTypeList();
	}, []);

	const [feeTypeId, setFeeTypeId] = useState<any>({ value: 22, label: 'Library Fine' });
	const [fineTypeId, setFineTypeId] = useState<any>({ value: 10, label: 'Library Fine' });
	const [extraFeeTypeId, setExtraFeeTypeId] = useState<any>('');
	const [studentFeeDetails, setStudentFeeDetails] = useState<any>([]);
	const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([]);
	const [notReturnBookData, setNotReturnBookData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [feesTypeData, setFeesTypeData] = useState<any>([]);
	const [fineTypeData, setFineTypeData] = useState<any>([]);
	const [extraFeeTypeData, setExtraFeeTypeData] = useState<any>([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const componentRef = useRef(null);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(notReturnBookData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [dataStatus, setDataStatus] = useState(false);
	const [addExtraFeeOffCanvas, setAddExtraFeeOffCanvas] = useState(false);

	const [noDataMsg, setNoDataMsg] = useState('');
	const [hideLoader, setHideLoader] = useState(false);
	const [noDataMsgForStudentFeeDetails, setNoDataMsgForStudentFeeDetails] = useState<any>('');

	const addExtraFeesForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			feeTypeId: '',
			amount: '',
			extraFeeTypeId: '',
		},
		validate: (values) => {
			const errors: {
				feeTypeId?: string;
				amount?: string;
				extraFeeTypeId?: string;
			} = {};
			if (!values.amount) {
				errors.amount = 'Required';
			}
			if (feeTypeId?.value == 14) {
				if (!extraFeeTypeId) {
					errors.extraFeeTypeId = 'Required';
				}
			}

			return errors;
		},
		//validateOnChange: false,
		onSubmit: (values) => {
			staffUserTypeId == 9 ? addExtraFeeSubmit() : addStaffFineFeeSubmit();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function getNotReturnBookDetails() {
		setDataStatus(false);
		getNotReturnBookList(0, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.notReturnBookList;
					if (data != undefined) {
						getColumnsForTable('getNotReturnBookList', 'get');
						setNotReturnBookData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataStatus(true);
					} else {
						toasts('Undefined Data', 'Error');
						setDataStatus(true);
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					getColumnsForTable('getNotReturnBookList', 'get');
					setNotReturnBookData([]);
					setNoDataMsg(response.data.message);
					setDataStatus(true);
				} else {
					setDataStatus(true);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getFeeTypeList() {
		getFeeType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.feeType;
					if (data != undefined) {
						setFeesTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setFeesTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getFineFeeTypeList() {
		getFineFeeType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.fineFeeType;
					if (data != undefined) {
						setFineTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setFineTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getExtraFeeTypeList() {
		getExtraFeeType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.extraFeeType;
					if (data != undefined) {
						setExtraFeeTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setExtraFeeTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const selectFeeType = (e: any) => {
		setExtraFeeTypeId('');
		let feeTypeId = e;
		setFeeTypeId(feeTypeId);
	};

	const selectFineType = (e: any) => {
		let fineTypeId = e;
		setFineTypeId(fineTypeId);
	};

	const [studentOrStaffDetailsId, setStudentOrStaffDetailsId] = useState<any>('');
	const [staffUserTypeId, setStaffUserTypeId] = useState<any>('');
	const [bookTransactionId, setBookTransactionId] = useState<any>('');

	function setExtraFeeDetails() {
		return {
			feeTypeId: feeTypeId?.value,
			extraFeeTypeId: extraFeeTypeId?.value != '' ? extraFeeTypeId?.value : null,
			amount: addExtraFeesForm.values.amount,
			studentDetailsId: studentOrStaffDetailsId,
			bookTransactionId: bookTransactionId,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function setAddFineDetails() {
		return {
			fineTypeId: fineTypeId?.value,
			addedBy: userAccountId,
			amount: addExtraFeesForm.values.amount,
			staffDetailsId: studentOrStaffDetailsId,
			bookTransactionId: bookTransactionId,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function addExtraFeeSubmit() {
		showLoader(true);
		if (addExtraFeesForm.isValid) {
			let extraFeePostData = setExtraFeeDetails();
			addExtraFeeDetails(
				extraFeePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getStudentFeeList(userTypeId, studentOrStaffDetailsId);
						getStudentTotalFeeCollectionList(studentOrStaffDetailsId);
						addExtraFeesForm.resetForm();
						setFeeTypeId('');
						getNotReturnBookDetails()
						setExtraFeeTypeId('');
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addExtraFeesForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function addStaffFineFeeSubmit() {
		showLoader(true);
		if (addExtraFeesForm.isValid) {
			let libraryPostData = setAddFineDetails();
			addStaffFineFee(
				libraryPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getStudentFeeList(userTypeId, studentOrStaffDetailsId);
						getStudentTotalFeeCollectionList(studentOrStaffDetailsId);
						addExtraFeesForm.resetForm();
						setFeeTypeId('');
						getNotReturnBookDetails()
						setExtraFeeTypeId('');
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addExtraFeesForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function selectExtraFee() {
		getExtraFeeTypeList();
		getFeeTypeList();
	}

	function getStudentFeeList(userTypeId: number, studentOrStaffDetailsId: number) {
		setHideLoader(true);
		getStudentFeeDetails(
			userTypeId,
			studentOrStaffDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentFeeDetails[0]['feeType'];
					if (data != undefined) {
						setStudentFeeDetails(data);
						setHideLoader(false);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setNoDataMsgForStudentFeeDetails(response.data.message);
					setHideLoader(false);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setHideLoader(false);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setHideLoader(false);
			},
		);
	}

	function getStudentTotalFeeCollectionList(studentOrStaffDetailsId: number) {
		getStudentTotalFeeCollection(
			studentOrStaffDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentTotalFeeCollection;
					if (data != undefined) {
						setStudentTotalFeeCollectionData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getNotReturnBookList', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function closeAndReset() {
		addExtraFeesForm.resetForm();
	}

	return (
		<>
			<PageWrapper title={`Not Return Book List`}>
				<Page container='fluid'>
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-6'>
								<CardTitle tag='div' className='h5'>
									Not Return Book List
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForTable(
																		'getNotReturnBookList',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div
														className='col-12'
														style={{
															maxHeight: '200px',
															overflowY: 'scroll',
														}}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		id='{i.columnVisibilityId}'
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={
																			i.isDefault == true
																		}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'not Return Book List',
														notReturnBookData,
														columnVisibilityData,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														notReturnBookData,
														columnVisibilityData,
														'not Return Book List',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'not Return Book List',
														notReturnBookData,
														columnVisibilityData,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={handlePrint}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</CardActions>
						</CardHeader>

						<CardBody className='table-responsive' isScrollable>
							{!dataStatus ? (
								<TableLoader />
							) : (
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No{' '}
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											{columnVisibilityData.map((column: any) => (
												<th
													key={column.keyName}
													scope='col'
													onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon
														size='lg'
														className={getClassNamesFor(column.keyName)}
														icon='FilterList'
													/>
												</th>
											))}
											<th scope='col' className='d-print-none'>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Action
											</th>
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ? (
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.bookTransactionId}>
														<td>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) =>
															column.isDisplay &&
																rowData[column.keyName] ? (
																<td
																	key={`${rowData.bookTransactionId}-${column.keyName}`}>
																	{rowData[column.keyName]}
																</td>
															) : (
																<td
																	key={`empty-${column.keyName}`}
																/>
															),
														)}
														<td className='d-print-none'>
															{rowData.libraryFineFeeId == 0 ?
																<Button
																	className='mt-2 ms-3'
																	isOutline
																	color='primary'
																	size={'sm'}
																	icon='Payments'
																	onClick={() => {
																		setStaffUserTypeId(rowData.userTypeId);
																		setAddExtraFeeOffCanvas(true);
																		selectExtraFee();
																		setStudentOrStaffDetailsId(rowData.studentOrStaffDetailsId);
																		setBookTransactionId(rowData.bookTransactionId);
																	}}>
																	Add Fine
																</Button> :
																<td>
																	<Button isLink color={'success'} icon='Circle' className='text-nowrap'>
																		Fees Added
																	</Button>
																</td>
															}
														</td>
													</tr>
												))}
											</>
										) : (
											<NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />)
										}
									</tbody>
								</table>
							)}
						</CardBody>
						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				</Page>

				<OffCanvas
					setOpen={setAddExtraFeeOffCanvas}
					isOpen={addExtraFeeOffCanvas}
					titleId='addExtraFeeOffCanvas'
					placement='end'
					tag='form'
					noValidate
					onSubmit={addExtraFeesForm.handleSubmit}>
					<OffCanvasHeader setOpen={setAddExtraFeeOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addExtraFeeOffCanvas'>Add Fine</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							<div className='col-12'>
								{staffUserTypeId == 9 ? (
									<FormGroup
										id='feeTypeId'
										label='Fee Type'
										onBlur={addExtraFeesForm.handleBlur}>
										<SearchableSelect
											disabled
											placeholder='Select Fee Type'
											ariaLabel='Fee Type'
											onChange={selectFeeType}
											value={feeTypeId}
											onBlur={addExtraFeesForm.handleBlur}
											isValid={addExtraFeesForm.isValid}
											isTouched={addExtraFeesForm.touched.feeTypeId}
											invalidFeedback={addExtraFeesForm.errors.feeTypeId}
											list={feesTypeData.map((data: any) => ({
												value: data.feeTypeId,
												label: data.feeType,
											}))}
										/>
									</FormGroup>
								) : (
									<FormGroup
										id='fineTypeId'
										label='Fine Type'
										onBlur={addExtraFeesForm.handleBlur}>
										<SearchableSelect
											disabled
											placeholder='Select Fee Type'
											ariaLabel='Fee Type'
											onChange={selectFineType}
											value={fineTypeId}
											list={fineTypeData.map((data: any) => ({
												value: data.fineTypeId,
												label: data.fineType,
											}))}
										/>
									</FormGroup>
								)}
							</div>
							{feeTypeId?.value == 14 ? (
								<div className='col-12'>
									<FormGroup
										id='extraFeeTypeId'
										label='Fine Type'
										onBlur={addExtraFeesForm.handleBlur}>
										<SearchableSelect
											placeholder='Select Fine Type'
											ariaLabel='Select Fine Type'
											onChange={(e: any) => setExtraFeeTypeId(e)}
											value={extraFeeTypeId}
											onBlur={addExtraFeesForm.handleBlur}
											isValid={addExtraFeesForm.isValid}
											isTouched={addExtraFeesForm.touched.extraFeeTypeId}
											invalidFeedback={addExtraFeesForm.errors.extraFeeTypeId}
											list={extraFeeTypeData.map((data: any) => ({
												value: data.extraFeeTypeId,
												label: data.feeType,
											}))}
										/>
									</FormGroup>
								</div>
							) : null}

							<div className='col-12'>
								<FormGroup id='amount' label='Amount'>
									<Input
										placeholder='Enter Amount'
										onChange={addExtraFeesForm.handleChange}
										value={addExtraFeesForm.values.amount}
										onBlur={addExtraFeesForm.handleBlur}
										isValid={addExtraFeesForm.isValid}
										isTouched={addExtraFeesForm.touched.amount}
										invalidFeedback={addExtraFeesForm.errors.amount}
										onKeyDown={(e: any) => onlyAllowNumber(e)}
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row m-0'>
						<div className='col-12 p-3'>
							<Button
								color='info'
								className='w-100'
								type='submit'
								icon='Save'
								isDisable={
									!addExtraFeesForm.isValid && !!addExtraFeesForm.submitCount
								}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
}
export default NotReturnBookList;
