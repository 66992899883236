import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, {
	CardHeader,
	CardLabel,
	CardTitle,
	CardBody,
	CardActions,
	CardSubTitle,
} from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { getAcademicPeriod } from '../../../services/master.service';
import { toasts } from '../../../services/toast.service';
import {
	getColumnsForDataTable,
	profilePic,
	updateColumnsForDataTable,
} from '../../../services/common.service';
import Avatar from '../../Avatar';
import {
	deleteAwardDetails,
	getAwardDetails,
	updateAwardDetails,
} from '../../../services/administration.service';
import NoDataMsg from '../../../common/components/NoDataMsg';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { useFormik } from 'formik';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Icon from '../../icon/Icon';
import { useReactToPrint } from 'react-to-print';
import Input from '../../bootstrap/forms/Input';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import DeleteComponents from '../../../common/components/DeleteComponents';
import OffCanvas, {
	OffCanvasHeader,
	OffCanvasTitle,
	OffCanvasBody,
} from '../../bootstrap/OffCanvas';
import { showLoader } from '../../../services/loader.services';
import { getStaffDetails } from '../../../services/staff.service';
import AlertService from '../../../services/AlertService';
import Modal, { ModalHeader, ModalBody } from '../../bootstrap/Modal';

const AwardList = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getAcademicPeriodList();
		getAllStaff();
	}, []);

	const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
	const [awardDetailsId, setAwardDetailsId] = useState<any>('');
	const [studentOrStaffDetailsId, setStudentOrStaffDetailsId] = useState<any>('');
	const [academicPeriodData, setAcademicPeriodData] = useState<any>([]);
	const [awardTitleData, setAwardTitleData] = useState<any>([]);
	const [awardPrizeData, setAwardPrizeData] = useState<any>([]);
	const [allStaffData, setAllStaffData] = useState<any>([]);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [title, setTitle] = useState<any>('');

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isChecked, setIsChecked] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(awardPrizeData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const componentRef = useRef(null);

	const [editAwardOffCanvas, setEditAwardOffCanvas] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenForDelete, setIsOpenForDelete] = useState(true);

	const editAwardForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			awardWinnersId: '',
			awardDetailsId: '',
			studentOrStaffDetailsId: '',
			prize: '',
		},
		validate: (values) => {
			const errors: {
				studentOrStaffDetailsId?: string;
				prize?: string;
			} = {};
			if (!values.studentOrStaffDetailsId) {
				errors.studentOrStaffDetailsId = 'Required';
			}
			if (!values.prize) {
				errors.prize = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			updateCasteSubmit();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => {},
		//validateOnChange: false,
		onSubmit: () => {},
	});

	const selectAcademicPeriod = (e: any) => {
		setAwardDetailsId('');
		let academicPeriodId = e;
		setAcademicPeriodId(academicPeriodId);
		if (academicPeriodId?.value != undefined) {
			getAwardList(academicPeriodId?.value);
		}
	};

	const selectTitle = (e: any) => {
		let awardDetailsId = e;
		setAwardDetailsId(awardDetailsId);
	};

	const selectStaff = (e: any) => {
		let studentOrStaffDetailsId = e;
		setStudentOrStaffDetailsId(studentOrStaffDetailsId);
	};

	function View() {
		setIsLoader(true);
		setDataSuccess(false);
		getAwardWinner(academicPeriodId?.value, awardDetailsId?.value);
	}

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedAward = i;
			setEditAwardOffCanvas(true);
			setStudentOrStaffDetailsId({
				value: selectedAward.studentOrStaffDetailsId,
				label: selectedAward.studentOrStaffName,
			});

			editAwardForm.setValues({
				awardWinnersId: selectedAward.awardWinnersId,
				awardDetailsId: selectedAward.awardDetailsId,
				prize: selectedAward.prizeCount,
				studentOrStaffDetailsId: selectedAward.studentOrStaffDetailsId,
			});
		}
	}

	const filteredData = onCurrentPageData.filter(
		(i) =>
			(i.studentOrStaffName !== null &&
				i.studentOrStaffName
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())) ||
			(i.empOrAdmissionNumber !== null &&
				i.empOrAdmissionNumber
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())) ||
			(i.prize !== null &&
				i.prize
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())),
	);

	function getAcademicPeriodList() {
		getAcademicPeriod(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.academicPeriod;
					if (data != undefined) {
						setAcademicPeriodData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setAcademicPeriodData([]);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getAwardList(academicPeriodId: any) {
		getAwardDetails(
			academicPeriodId,
			0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.awardDetails;
					if (data != undefined) {
						setAwardTitleData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAwardTitleData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [userType, setUserType] = useState<any>('');
	const [userTypeIdForApi, setUserTypeIdForApi] = useState<any>('');

	function getAwardWinner(academicPeriodId: any, awardDetailsId: any) {
		getAwardDetails(
			academicPeriodId,
			awardDetailsId,
			(response) => {
				if (response.data.success) {
					let awardDetails = response.data.data.awardDetails;
					if (awardDetails != undefined) {
						let data = awardDetails[0].awaredWinners;
						let userTypeId = awardDetails[0].userTypeId;
						let userType = awardDetails[0].userType;
						let title = awardDetails[0].title;
						if (userTypeId == 9) {
							getColumnsForTable('getAwardDetailsForStudent', 'get');
						} else {
							getColumnsForTable('getAwardDetailsForStaff', 'get');
						}

						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setIsLoader(false);
						setAwardPrizeData(data);
						setIsOpenListCard(false);
						setDataSuccess(true);
						setTitle(title);
						setUserType(userType);
						setUserTypeIdForApi(userTypeId);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setIsOpenListCard(false);
					setDataSuccess(true);
					if (userTypeIdForApi == 9) {
						getColumnsForTable('getAwardDetailsForStudent', 'get');
					} else {
						getColumnsForTable('getAwardDetailsForStaff', 'get');
					}
					setAwardPrizeData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsOpenListCard(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				setIsOpenListCard(true);
				setDataSuccess(false);
				toasts(error, 'Error');
			},
		);
	}

	function getAllStaff() {
		getStaffDetails(
			0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffDetails;
					if (data != undefined) {
						setAllStaffData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setAllStaffData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function setEditAwardDetails() {
		return {
			staffDetailsId: userAccountId,
			studentOrStaffDetailsId: studentOrStaffDetailsId?.value,
			awardWinnersId: editAwardForm.values.awardWinnersId,
			prize: editAwardForm.values.prize,
			licenseKey: getLicenseKey,
		};
	}

	function setDeleteAwardDetails() {
		return {
			staffDetailsId: userAccountId,
			awardDetailsId: awardDetailsId?.value,
			licenseKey: getLicenseKey,
		};
	}

	function getOrdinal(index: any) {
		const j = index % 10;
		const k = index % 100;
		if (j === 1 && k !== 11) {
			return `${index}st`;
		}
		if (j === 2 && k !== 12) {
			return `${index}nd`;
		}
		if (j === 3 && k !== 13) {
			return `${index}rd`;
		}
		return `${index}th`;
	}
	function updateCasteSubmit() {
		showLoader(true);
		if (editAwardForm.isValid) {
			let awardPostData = setEditAwardDetails();
			updateAwardDetails(
				awardPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getAwardWinner(academicPeriodId?.value, awardDetailsId?.value);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editAwardForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteAwardSubmit() {
		showLoader(true);
		// if (editAwardForm.isValid) {
		let awardPostData = setDeleteAwardDetails();
		deleteAwardDetails(
			awardPostData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'success' });
					setIsOpen(true);
					closeAndReset();
					getAwardWinner(academicPeriodId?.value, awardDetailsId?.value);
					getAwardList(academicPeriodId?.value);
					setIsOpenForDelete(false);
					setAwardDetailsId('');
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'error' });
					setIsOpen(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					showLoader(false);
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
		// } else if (editAwardForm.isValid == false) {
		//     showLoader(false)
		//     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
		//     setIsOpen(true);
		// }
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
							{
								columnVisibilityId: columnVisibilityId,
								isDisplay: isDisplay ? 0 : 1,
							},
						],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						if (userTypeIdForApi == 9) {
							getColumnsForTable('getAwardDetailsForStudent', 'get');
						} else {
							getColumnsForTable('getAwardDetailsForStaff', 'get');
						}
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	function closeAndReset() {
		editAwardForm.resetForm();
		setStudentOrStaffDetailsId('');
		setAcademicPeriodId('');
		setIsDeleteOpen(false);
		setEditAwardOffCanvas(false);
		setDataSuccess(false);
		setIsOpenListCard(false);
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper>
			<Page>
				<Collapse isOpen={isOpenListCard || !isOpenForDelete}>
					<Card>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Award List
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-3'>
									<FormGroup
										id='academicPeriodId'
										label='Academic Period'
										isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Academic Period'
											placeholder='Academic Period'
											value={academicPeriodId}
											onChange={selectAcademicPeriod}
											list={academicPeriodData.map((data: any) => ({
												value: data.academicPeriodId,
												label: data.period,
											}))}
											required
										/>
									</FormGroup>
								</div>

								{academicPeriodId?.value != undefined ? (
									<div className='col-4'>
										<FormGroup id='awardDetailsId' label='Title' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Title'
												placeholder='Title'
												value={awardDetailsId}
												onChange={selectTitle}
												list={awardTitleData.map((data: any) => ({
													value: data.awardDetailsId,
													label: data.title,
												}))}
												required
											/>
										</FormGroup>
									</div>
								) : null}

								{awardDetailsId?.value != undefined ? (
									<div className='col-2 mt-2'>
										<Button icon='ArrowDownward' color='primary' onClick={View}>
											View
										</Button>
									</div>
								) : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess && isOpenForDelete ? (
					<div className='col-12'>
						<Card stretch ref={componentRef}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Task' iconColor='primary' className='col-lg-6'>
									<CardTitle tag='div' className='h5'>
										Award List - {userType}
									</CardTitle>
									<CardSubTitle>{title}</CardSubTitle>
								</CardLabel>
								<ButtonGroup
									className='col-lg-3 ms-4 d-print-none d-flex content-justify-end'
									color='primary'>
									<Icon
										className='mt-1'
										icon='Search'
										size='2x'
										color='primary'
									/>
									<Input
										id='searchInput'
										type='search'
										placeholder='Search...'
										onChange={columnVisibilityForm.handleChange}
										value={columnVisibilityForm.values.searchInput}
									/>
								</ButtonGroup>
								<CardActions className='d-print-none'>
									<Dropdown isButtonGroup>
										<DropdownToggle>
											<Button color='primary' isLight icon='CloudDownload'>
												{' '}
												Export{' '}
											</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadExcel(
															`${title}-${userType}`,
															awardPrizeData,
															columnVisibilityData,
														)
													}>
													Excel
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='PictureAsPdf'
													onClick={() =>
														convertJsonToPdf(
															awardPrizeData,
															columnVisibilityData,
															`${title}-${userType}`,
														)
													}>
													PDF
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadFile(
															`${title}-${userType}`,
															awardPrizeData,
															columnVisibilityData,
														)
													}>
													CSV
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='Print'
													onClick={handlePrint}>
													Print
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									
							<Button
								color='danger'
								className='w-40'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
					
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive'>
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th>S.no</th>
											<th>
												{userTypeIdForApi == 9 ? 'Admission No' : 'Emp No'}
											</th>
											<th>
												{userTypeIdForApi == 9
													? 'Student Name'
													: 'Staff Name'}
											</th>
											<th>Prize</th>
											<th scope='col' className='d-print-none'>
												Action
											</th>
										</tr>
									</thead>

									<tbody className='col-12'>
										{awardPrizeData != '' ? (
											<>
												{filteredData.map((i: any) => (
													<tr key={i.awaredWinnersId}>
														<td>{i.sno}</td>
														<td>{i.empOrAdmissionNumber}</td>
														<td>
															<div className='d-flex'>
																<div className='flex-shrink-0'>
																	{i.profilePath != null ? (
																		<Avatar
																			src={i.profilePath}
																			size={36}
																		/>
																	) : (
																		<img
																			src={profilePic(
																				i.genderId,
																			)}
																			width='36'
																			height='36'
																		/>
																	)}
																</div>
																<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
																	{i.studentOrStaffName}
																</div>
															</div>
														</td>
														<td>{i.prize}</td>
														<td className='d-print-none'>
															<Button
																color='dark'
																isLight
																icon='Edit'
																aria-label='Edit'
																onClick={() => {
																	onActivate(i);
																}}
															/>
														</td>
													</tr>
												))}
											</>
										) : (
											<NoDataMsg
												columnsCount={awardPrizeData.length + 5}
												msg={noDataMsg}
											/>
										)}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								className='d-print-none'
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</Card>
					</div>
				) : null}

				<OffCanvas
					setOpen={setEditAwardOffCanvas}
					isOpen={editAwardOffCanvas}
					titleId='editAwardOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={editAwardForm.handleSubmit}>
					<OffCanvasHeader setOpen={setEditAwardOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='editAwardOffCanvas'>Update Award</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							<div className='col-12'>
								<FormGroup id='staffDetailsId' label='Staff'>
									<SearchableSelect
										ariaLabel='Staff'
										onChange={selectStaff}
										value={studentOrStaffDetailsId}
										list={allStaffData.map((data: any) => ({
											value: data.staffDetailsId,
											label: data.staffName,
										}))}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='prize' label='Prize'>
									<Input
										onChange={editAwardForm.handleChange}
										value={editAwardForm.values.prize}
										isValid={editAwardForm.isValid}
										onBlur={editAwardForm.handleBlur}
										isTouched={editAwardForm.touched.prize}
										invalidFeedback={editAwardForm.errors.prize}
										placeholder='Enter Prize'
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>

					<div className='row'>
						<div className='col-12 position-absolute bottom-0 start-0 p-3'>
							<Button
								color='danger'
								className='w-100'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
						</div>
						<div className='col-12 position-absolute bottom-0 end-0 p-3'>
							<Button
								color='info'
								icon='Save'
								type='submit'
								className='w-100'
								isDisable={!editAwardForm.isValid && !!editAwardForm.submitCount}>
								{' '}
								Update
							</Button>
						</div>
					</div>
				</OffCanvas>
				<Modal
                    setIsOpen={setIsDeleteOpen}
                    isOpen={isDeleteOpen}
                    titleId='deleteBatchMaster'
                    isStaticBackdrop
                    size='sm'>
                    <ModalHeader><></></ModalHeader>
                    <ModalBody>
                        <div className="row g-2">
                            <div className="col-12 d-flex justify-content-center">
                                <h5><strong>Are you sure want to delete ?</strong></h5>
                            </div>
                            <div className='col-10'>
                                <div className='d-flex gap-2 justify-content-end'>
                                    <Button color='danger' size={'sm'} onClick={deleteAwardSubmit}>
                                        Yes
                                    </Button>

                                    <Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        No
                                    </Button>
                                </div>
                            </div>
                            <div className='col-10'></div>
                        </div>
                    </ModalBody>
                </Modal>
				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</Page>
		</PageWrapper>
	);
};

export default AwardList;
