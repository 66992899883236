import React, { useContext, useEffect, useState } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardSubTitle, CardActions, CardBody } from '../bootstrap/Card'
import Carousel from '../bootstrap/Carousel'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import { deleteEventImages, getEventList } from '../../services/sportsManagement.service'
import { toasts } from '../../services/toast.service'
import Icon from '../icon/Icon'
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../layout/SubHeader/SubHeader'
import Button from '../bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/authContext'
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal'
import { showLoader } from '../../services/loader.services'
import AlertService from '../../services/AlertService'

const EventList = () => {

    useEffect(() => {
        getEventDetails()
        showLoader(false);
    }, [])


    const navigate = useNavigate();
    const { userAccountId, userTypeId } = useContext(AuthContext);

    const [eventData, setEventData] = useState<any>([])
    const [dataStatus, setDataStatus] = useState<any>(false)
    const [noDataMsg, setNoDataMsg] = useState<any>('')
    const [eventName, setEventName] = useState<any>('');

    const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);
    const [images, setImages] = useState<any>([])

    function getEventDetails() {
        setDataStatus(false)
        getEventList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.eventList;                   
                    if (data != undefined) {
                        setDataStatus(true)

                        for (let i = 0; i < data.length; i++) {

                            let imageData = data[i].image

                            for (let j = 0; j < imageData.length; j++) {
                                imageData[j].src = imageData[j].imagePath;
                            }
                        }
                        setEventData(data);
                    } else {
                        setDataStatus(true)
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, 'Error');                   
                    setEventData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setDataStatus(true)
                toasts(error, 'Error');
            },
        );
    }

    const [isOpenModal, setIsOpenModal] = useState<any>(false)
    const [eventDateForView, setEventDateForView] = useState<any>('')

    function openModal(i: any) {
        setIsOpenModal(true)
        setEventName(i.eventName)
        setEventDateForView(i.dateOfEventForView)
        setImages(i.image);
    }

    function closeModal() {
        setIsOpenModal(false)
    }

    const [isDeleteOpen, setIsDeleteOpen] = useState<any>(false)
    const [eventImageDetailsId, setEventImageDetailsId] = useState<any>('')
    const [filePath, setFilePath] = useState<any>('')

    function deleteModal(i: any) {
        setIsOpenModal(false)
        setIsDeleteOpen(true)
        setEventImageDetailsId(i.eventImageDetailsId)
        setFilePath(i.filePath)
    }

    function setDeleteEventDetails() {
        return {
            licenseKey: 5566,
            eventImageDetailsId: eventImageDetailsId,
            imagePath: filePath,
            staffDetailsId: userAccountId,
        };
    }

    function deleteEventImagesSubmit() {
        showLoader(true);
        let eventPostData = setDeleteEventDetails();
        deleteEventImages(eventPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: 'success' });
                    setIsOpen(true);
                    setIsDeleteOpen(false);
                    closeModal();
                    getEventDetails();
                } else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: 'error' });
                    setIsOpen(true);
                } else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: 'error' });
                    setIsOpen(true);
                }
            },
            (error) => {
                setAlertStatus({ message: error, type: 'error' });
                setIsOpen(true);
            },
        );
    }

    return (
        <PageWrapper title='Event List'>
            <SubHeader>
                <SubHeaderLeft>
                    <CardLabel icon='Image' iconColor='primary'>
                        <CardTitle tag='div' className='h5 text-uppercase'>
                            Event
                        </CardTitle>
                    </CardLabel>
                </SubHeaderLeft>
                {userTypeId != 9 && userTypeId != 10 ?
                    <SubHeaderRight>
                        <SubheaderSeparator />
                        <Button color='info' isLight icon='Add' onClick={() => navigate(`../management/addEventDetails`)}>
                            Add
                        </Button>
                    </SubHeaderRight> : null
                }
            </SubHeader>
            <Page>
                {dataStatus ? <>
                    {eventData != '' ?
                        <div className="row">
                            {eventData.map((item: any) => (
                                <div className='col-4'>
                                    <Card stretch className='border border-light' shadow={'md'} >
                                        <CardHeader borderSize={1}>
                                            <CardLabel icon='EventNote' iconColor='info'>
                                                <CardTitle>{item.eventName}</CardTitle>
                                                <CardSubTitle>{item.dateOfEventForView}</CardSubTitle>
                                            </CardLabel>
                                            <CardActions className='d-print-none'>
                                                <Button
                                                    color='dark'
                                                    icon='Edit'
                                                    isLight
                                                    onClick={() => openModal(item)}>
                                                </Button>
                                            </CardActions>
                                        </CardHeader>
                                        <CardBody>
                                            <Carousel
                                                isManual
                                                width={200}
                                                height={200}
                                                items={item.image}
                                                rounded={1}
                                                isRide={true}
                                            />
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div> :
                        <div className="row">
                            <div className='col-12' style={{ height: '20vh' }}></div>
                            <div className='col-12 d-flex justify-content-center'>
                                <Icon icon='DoNotDisturb' size={'10x'} />
                            </div>
                            <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                {noDataMsg}
                            </div>
                        </div>
                    } </> : null
                }

                <Modal
                    setIsOpen={setIsOpenModal}
                    isOpen={isOpenModal}
                    titleId='addBatchMaster'
                    isStaticBackdrop
                    size='xl'>
                    <ModalHeader
                        className="'modal-header'"
                        setIsOpen={() => { setIsOpenModal(false) }}
                        onClick={closeModal}>
                        <CardLabel icon='EventNote' iconColor='info'>
                            <CardTitle>{eventName}</CardTitle>
                            <CardSubTitle>{eventDateForView}</CardSubTitle>
                        </CardLabel>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            {images != '' ? <>
                                {images.map((item: any) => (
                                    <div className='col-3'>
                                        <Card stretch className='border border-light rounded-1'>
                                            <CardBody>
                                                <div className='row'>
                                                    <div className='col d-flex'>
                                                        <img
                                                            src={item.imagePath}
                                                            alt=''
                                                            width={200}
                                                            height={200}
                                                            className='rounded'
                                                        />
                                                    </div>
                                                    <div className='d-flex mt-3 justify-content-center'>
                                                        <Button
                                                            isOutline
                                                            color='danger'
                                                            icon='Delete'
                                                            size={'sm'}
                                                            onClick={() => deleteModal(item)}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                            </> : null
                            }
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    setIsOpen={setIsDeleteOpen}
                    isOpen={isDeleteOpen}
                    titleId='deleteGalleryImage'
                    isStaticBackdrop
                    size='sm'>
                    <ModalHeader>
                        <></>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row g-2'>
                            <div className='col-12 d-flex justify-content-center'>
                                <h5>
                                    <strong>Are you sure want to delete ?</strong>
                                </h5>
                            </div>
                            <div className='col-10'>
                                <div className='d-flex gap-2 justify-content-end'>
                                    <Button
                                        color='danger'
                                        size={'sm'}
                                        onClick={deleteEventImagesSubmit}>
                                        Yes
                                    </Button>
                                    <Button
                                        color='info'
                                        size={'sm'}
                                        onClick={() => {
                                            setIsDeleteOpen(!isDeleteOpen);
                                            setIsOpenModal(true);
                                        }}>
                                        No
                                    </Button>
                                </div>
                            </div>
                            <div className='col-10'></div>
                        </div>
                    </ModalBody>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

            </Page>
        </PageWrapper >
    )
}

export default EventList