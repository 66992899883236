import { useContext, useEffect, useState } from 'react';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../services/toast.service';
import Button from '../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Checks from '../bootstrap/forms/Checks';
import Label from '../bootstrap/forms/Label';
import {
	addSportsPlayers,
	getSportsDetails,
	getSportsPlayers,
	getSportsType,
} from '../../services/sportsManagement.service';
import AlertService from '../../services/AlertService';
import { getLicenseKey } from '../../services/application.settings';
import AuthContext from '../../contexts/authContext';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '../icon/Icon';
import { showLoader } from '../../services/loader.services';
import SearchableSelect from '../../common/components/SearchableSelect';
import { getAllStudent } from '../../services/student.service';
import { useFormik } from 'formik';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';

const AddPlayers = () => {

	const { sportsDetailsId } = useParams();

	useEffect(() => {
		getAllStudentDetails();
		getSportsTypeList();
		selectPlayerCount(5);
		getSports();
		getSportsPlayersList(sportsDetailsId)
	}, []);

	const [sportsTypeData, setSportsTypeData] = useState([]);
	const [allStudentData, setAllStudentData] = useState<any>([]);
	const [noOfPlayerData, setNoOfPlayerData] = useState<any>([]);
	const [isOpen, setIsOpen] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isNavigate, setIsNavigate] = useState<any>(null);
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [playerData, setPlayerData] = useState<any>([]);
	const [submitCount, setSubmitCount] = useState<any>('');
	const [playersCount, setPlayersCount] = useState<any>(5);

	const addPlayerForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			sportsName: '',
			standardDetailsId: '',
			playersCount: 5,
		},
		validate: (values) => {
			const errors: {
				sportsName?: string;
				playersCount?: string;
			} = {};
			if (!values.sportsName) {
				errors.sportsName = 'Required';
			}
			if (!playersCount) {
				errors.playersCount = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			addPlayersSubmit();
		},
	});

	const selectPlayerCount = (playersCount: any) => {
		setPlayersCount(playersCount);

		let newData: any = [];
		for (let i = 0; i < playersCount; i++) {
			newData.push({
				noOfPlayer: i + 1,
				studentDetailsId: 0,
				isCaptain: '',
				isViceCaptain: '',
				isSubstitute: 0,
				displayOrder: i,
			});
		}
		setNoOfPlayerData(newData);
		setRefreshData(newData);
		setPlayerData([]);
	};

	const [refreshData, setRefreshData] = useState<any>([])

	const addParticipantsDetails = (noOfPlayer: any, studentDetailsId: any, isCaptain: any, isViceCaptain: any, isChecked: any) => {

		let selStu = playerData.filter((item: any) => item.studentDetailsId == studentDetailsId?.value && item.noOfPlayer != noOfPlayer);

		const newData: any = [];

		noOfPlayerData.forEach((item: any) => {
			if (item['noOfPlayer'] == noOfPlayer) {
				item['studentDetailsId'] = studentDetailsId?.value;
				item['studentDetailsIdForShow'] = studentDetailsId;
				item['isCaptain'] = isCaptain;
				item['isViceCaptain'] = isViceCaptain;
				item['isSubstitute'] = isChecked ? 1 : 0;
				item['selectedStudent'] = selStu != '' ? true : false;
			}
			else if (isCaptain != '0') {
				item['studentDetailsId'] = item['studentDetailsId'];
				item['studentDetailsIdForShow'] = item['studentDetailsIdForShow'];
				item['isCaptain'] = '0';
				item['isViceCaptain'] = item['isViceCaptain'];
				item['isSubstitute'] = item['isSubstitute'] ? 1 : 0;
			}
			else if (isViceCaptain != '0') {
				item['studentDetailsId'] = item['studentDetailsId'];
				item['studentDetailsIdForShow'] = item['studentDetailsIdForShow'];
				item['isCaptain'] = item['isCaptain'];
				item['isViceCaptain'] = '0';
				item['isSubstitute'] = item['isSubstitute'] ? 1 : 0;
			}
			newData.push(item);
		});
		setNoOfPlayerData(newData);


		if (playerData.length == 0) {
			var obj;
			obj = {
				noOfPlayer: noOfPlayer,
				studentDetailsId: studentDetailsId?.value,
				studentDetailsIdForShow: studentDetailsId,
				isCaptain: isCaptain == '1' ? '1' : '0',
				isViceCaptain: isViceCaptain == '1' ? '1' : '0',
				isSubstitute: isChecked ? 1 : 0,
				selectedStudent: false,
			};
			playerData.push(obj);
		} else {
			let selectedItem: any;
			playerData.forEach((item: any) => {
				if (item['noOfPlayer'] == noOfPlayer) {
					selectedItem = item;
				} else {
					if (isCaptain != '0') {
						item['studentDetailsId'] = item['studentDetailsId'];
						item['studentDetailsIdForShow'] = item['studentDetailsIdForShow'];
						item['isCaptain'] = '0';
						item['isViceCaptain'] = item['isViceCaptain'] == '1' ? '1' : '0';
						item['isSubstitute'] = item['isSubstitute'] ? 1 : 0;
					} else if (isViceCaptain != '0') {
						item['studentDetailsId'] = item['studentDetailsId'];
						item['studentDetailsIdForShow'] = item['studentDetailsIdForShow'];
						item['isCaptain'] = item['isCaptain'] == '1' ? '1' : '0';
						item['isViceCaptain'] = '0';
						item['isSubstitute'] = item['isSubstitute'] ? 1 : 0;
					}
				}
			});
			if (selectedItem != undefined) {
				selectedItem['studentDetailsId'] = studentDetailsId?.value;
				selectedItem['studentDetailsIdForShow'] = studentDetailsId;
				selectedItem['isCaptain'] = isCaptain == '1' ? '1' : '0';
				selectedItem['isViceCaptain'] = isViceCaptain == '1' ? '1' : '0';
				selectedItem['isSubstitute'] = isChecked ? 1 : 0;
				selectedItem['noOfPlayer'] = noOfPlayer;
				selectedItem['selectedStudent'] = selStu != '' ? true : false;
			} else {
				var obj;
				obj = {
					noOfPlayer: noOfPlayer,
					studentDetailsId: studentDetailsId?.value,
					studentDetailsIdForShow: studentDetailsId,
					isCaptain: isCaptain == '1' ? '1' : '0',
					isViceCaptain: isViceCaptain == '1' ? '1' : '0',
					isSubstitute: isChecked ? 1 : 0,
					selectedStudent: selStu != '' ? true : false,
				};
				playerData.push(obj);
			}
		}
	};

	const [sportsData, setSportsData] = useState<any>([]);

	function getSports() {
		getSportsDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sportsDetails;
					if (data != undefined) {
						let sports = data.filter(
							(Item: any) => Item.sportsDetailsId == sportsDetailsId,
						)[0];
						setSportsData(sports);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSportsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}
	function getAllStudentDetails() {
		getAllStudent(
			userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetails;
					if (data != undefined) {
						setAllStudentData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAllStudentData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function addPlayersSubmit() {
		showLoader(true);
		if (playerData.length > submitCount) {
			let sportsPostData = setPlayersDetails();
			addSportsPlayers(
				sportsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setIsNavigate(`../sportsManagement/addSports`);
						setPlayerData([]);
						closeAndReset();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
						setIsNavigate(null);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
						setIsNavigate(null);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
					setIsNavigate(null);
				},
			);
		} else if (playerData.length == submitCount) {
			showLoader(false);
			setAlertStatus({ message: 'Please Select The Students!.', type: 'error' });
			setIsOpen(true);
			setIsNavigate(null);
		}
	}

	function setPlayersDetails() {
		return {
			licenseKey: getLicenseKey,
			sportsDetailsId: sportsDetailsId,
			staffDetailsId: userAccountId,
			studentDetails: playerData,
		};
	}

	function getSportsTypeList() {
		getSportsType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sportsType;
					if (data != undefined) {
						setSportsTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSportsTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [sportsPlayerData, setSportsPlayerData] = useState<any>([])

	function getSportsPlayersList(sportsDetailsId: any) {
		getSportsPlayers(sportsDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sportsPlayers[0].studentDetails;
					if (data != undefined) {
						setSportsPlayerData(data);
						setPlayersCount(data.length);
						let newData: any = [];
						for (let i = 0; i < data.length; i++) {
							newData.push({
								noOfPlayer: i + 1,
								studentDetailsId: data[i].studentDetailsId,
								studentDetailsIdForShow: { value: data[i].studentDetailsId, label: data[i].studentName },
								isCaptain: data[i].isCaptain ? '1' : '0',
								isViceCaptain: data[i].isViceCaptain ? '1' : '0',
								isSubstitute: data[i].isSubstitute ? 1 : 0,
								displayOrder: i,
							});
						}
						setNoOfPlayerData(newData);
						setPlayerData(newData)
						setRefreshData(newData);

					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSportsPlayerData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function add() {
		for (let i = 0; i < playersCount; i++) {
			setPlayersCount(playersCount + 1)
		}
		setNoOfPlayerData([...noOfPlayerData, { noOfPlayer: playersCount + 1, studentDetailsId: 0, isCaptain: '0', isViceCaptain: '0', isSubstitute: 0, displayOrder: playersCount }])
		setRefreshData([...refreshData, { noOfPlayer: playersCount + 1, studentDetailsId: '', isCaptain: '', isViceCaptain: '', isSubstitute: 0, displayOrder: playersCount }])
	}

	function handleRemove(studentDetailsId: any, index: any) {

		const list = [...noOfPlayerData];
		list.splice(index, 1)

		for (let i = 0; i < list.length; i++) {
			list[i].noOfPlayer = i + 1
		}
		for (let i = 0; i < playersCount; i++) {
			setPlayersCount(playersCount - 1)
		}
		setNoOfPlayerData(list)

		let selectedItem
		playerData.forEach((item: any) => {
			if (item['studentDetailsId'] == studentDetailsId) {
				selectedItem = item
				playerData.splice(playerData.indexOf(selectedItem), 1);
			}
		});
	}

	function closeAndReset() {
		addPlayerForm.resetForm();
		selectPlayerCount(5);
	}

	const navigate = useNavigate();

	return (
		<PageWrapper title='Add Players'>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`../sportsManagement/addSports`)}>
						Back to List
					</Button>
					<SubheaderSeparator />
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<div className='row px-4'>
					<div className='col-xl-4 '>
						<Card stretch>
							<CardHeader borderSize={1}>
								<CardLabel icon='list' iconColor='primary'>
									<CardTitle>Sports Details</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row g-4'>
									<div className='col-12'>
										<FormGroup id='sportsTypeId' label='Sports Name' isFloating>
											<Input disabled value={sportsData?.sportsName} />
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup
											id='playersCount'
											label='Players Count'
											isFloating>
											<Input
												onChange={(e: any) =>
													selectPlayerCount(e.target.value)
												}
												value={playersCount}
												isValid={addPlayerForm.isValid}
												onBlur={addPlayerForm.handleBlur}
												isTouched={addPlayerForm.touched.playersCount}
												invalidFeedback={addPlayerForm.errors.playersCount}
												placeholder='Enter Player'
												required
												disabled={playerData != '' ? true : false}
											/>
										</FormGroup>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-8'>
						<Card stretch>
							<CardHeader borderSize={1}>
								<CardLabel icon='PersonAddAlt' iconColor='primary'>
									<CardTitle>Add Players</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='col-lg-1'></div>
									<div className='col-lg-12'>
										<table className='table table-modern table-hover text-nowrap text-md-nowrap mg-b-0'>
											<thead>
												<tr className='text-center table-primary fs-6'>
													<th>S.no</th>
													<th style={{ width: '400px' }}>Player Name</th>
													<th>Captain</th>
													<th>Vice Captain</th>
													<th>Substitute</th>
													<td />
													<td />
												</tr>
											</thead>
											{noOfPlayerData.map((i: any, index: any) => (
												<tbody key={i.noOfPlayer}>
													<tr>
														<td className='text-center'>
															<Label className='mt-3'>
																{i.noOfPlayer}
															</Label>
														</td>
														<td>
															<div className='col-12'>
																<FormGroup
																	id='studentDetailsId'
																	label='Student Name'
																	isFloating>
																	<SearchableSelect
																		isFloating
																		className={i.selectedStudent || i.studentDetailsId == undefined ? 'border border-danger' : ''}
																		ariaLabel='Select Student'
																		onChange={(e: any) =>
																			addParticipantsDetails(
																				i.noOfPlayer,
																				e,
																				i.isCaptain,
																				i.isViceCaptain,
																				i.isSubstitute,
																			)
																		}
																		value={i.studentDetailsIdForShow}
																		list={allStudentData.map(
																			(data: any) => ({
																				value: data.studentDetailsId,
																				label: data.studentNameAndRollNo,
																			}),
																		)}
																	/>
																</FormGroup>
																<>
																	{i.selectedStudent ? (
																		<div className='mt-2 text-danger'>
																			this student already
																			selected.{' '}
																			<Icon
																				icon='Report'
																				color='danger'
																				className='mx-1'
																				size='lg'
																			/>
																		</div>
																	) : i.studentDetailsId == undefined ? <div className='mt-2 text-danger'>
																		please select student .{' '}
																		<Icon
																			icon='Report'
																			color='danger'
																			className='mx-1'
																			size='lg'
																		/>
																	</div> : null}
																</>
															</div>
														</td>
														<td>
															<div className='col-12 d-flex justify-content-center mt-3'>
																<FormGroup id='isCaptain'>
																	<Checks
																		className='border border-info'
																		type='radio'
																		value={'1'}
																		onChange={(e: any) =>
																			addParticipantsDetails(
																				i.noOfPlayer,
																				i.studentDetailsIdForShow,
																				'1',
																				i.isViceCaptain,
																				i.isSubstitute,
																			)
																		}
																		checked={i.isCaptain}
																		disabled={i.studentDetailsId == 0 || i.isSubstitute == 1 || i.isViceCaptain == '1'}
																	/>
																</FormGroup>
															</div>
														</td>
														<td>
															<div className='col-12 d-flex justify-content-center mt-3'>
																<FormGroup id='isViceCaptain'>
																	<Checks
																		className='border border-info'
																		type='radio'
																		value={'1'}
																		onChange={(e: any) =>
																			addParticipantsDetails(
																				i.noOfPlayer,
																				i.studentDetailsIdForShow,
																				i.isCaptain,
																				'1',
																				i.isSubstitute,
																			)
																		}
																		checked={i.isViceCaptain}
																		disabled={i.studentDetailsId == 0 || i.isSubstitute == 1 || i.isCaptain == '1'}
																	/>
																</FormGroup>
															</div>
														</td>
														<td>
															<div className='col-12 d-flex justify-content-center mt-3'>
																<Checks
																	className='border border-primary'
																	disabled={i.studentDetailsId == 0 || i.isCaptain == '1' || i.isViceCaptain == '1'}
																	name='selectedList'
																	value={i.isSubstitute ? 1 : 0}
																	onChange={(e: any) =>
																		addParticipantsDetails(
																			i.noOfPlayer,
																			i.studentDetailsIdForShow,
																			i.isCaptain,
																			i.isViceCaptain,
																			e.target.checked,
																		)
																	}
																	checked={i.isSubstitute == 1 ? true : false}

																/>
															</div>
														</td>
														<td>
															<Button color='danger' size={'sm'} isLight icon='Close' onClick={() => handleRemove(i.studentDetailsId, index)} />
														</td>
														<td />
													</tr>
												</tbody>
											))}
											{playerData != '' ?
												<tbody>
													<tr>
														<td colSpan={6} className='align-middle'>
															<Button onClick={add} icon='Add' isOutline color='info' size={'sm'} className='w-100'>Add
															</Button>
														</td>
													</tr>
												</tbody> : null
											}
											{playersCount == 0 ? (
												<tbody>
													<tr>
														<td
															colSpan={6}
															className='text-center text-danger'>
															No Data Found
														</td>
													</tr>
												</tbody>
											) : null}
										</table>
									</div>

									<div className='col-lg-12 d-flex justify-content-end'>
										<Button
											icon='Save'
											color='info'
											type='submit'
											isDisable={playerData.length > 0 ? false : true}
											onClick={addPlayersSubmit}>
											Save
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
			<AlertService
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				alertStatus={alertStatus}
				isNavigate={isNavigate}
			/>
		</PageWrapper>
	);
};

export default AddPlayers;
