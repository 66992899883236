import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Collapse from '../../bootstrap/Collapse';
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import SearchableSelect from '../../../common/components/SearchableSelect';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Button from '../../bootstrap/Button';
import Input from '../../bootstrap/forms/Input';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import { getAllStudent } from '../../../services/student.service';
import { toasts } from '../../../services/toast.service';
import { getStaffListByUserTypeId } from '../../../services/staff.service';
import AuthContext from '../../../contexts/authContext';
import { getUserType } from '../../../services/common.service';
import { useFormik } from 'formik';
import { title } from 'process';
import Textarea from '../../bootstrap/forms/Textarea';
import { showLoader } from '../../../services/loader.services';
import { addAwardDetails } from '../../../services/administration.service';
import { getLicenseKey } from '../../../services/application.settings';
import AlertService from '../../../services/AlertService';
import { getAcademicPeriod } from '../../../services/master.service';
import { Label } from '../../icon/material-icons';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Select from '../../bootstrap/forms/Select';

const AddAward = () => {
	useEffect(() => {
		getAcademicPeriodList();
		getAllStudentDetails();
		getUserTypeList();
	}, []);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const [studentDetailsId, setStudentDetailsId] = useState<any>({ value: 0, label: 'Select All' });
	const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
	const [prizeCount, setPrizeCount] = useState<any>('');
	const { userAccountId } = useContext(AuthContext);
	const [userTypeId, setUserTypeId] = useState<any>('');
	const [staffDetailsId, setStaffDetailsId] = useState<any>('');
	const [studentOrStaffDetailsId, setStudentOrStaffDetailsId] = useState<any>('');
	const [userTypeIdForDropdown, setUserTypeIdForDropdown] = useState<any>('')

	const [awardTable, setAwardTable] = useState<any>([]);

	const [academicPeriodData, setAcademicPeriodData] = useState<any>([]);
	const [allStudentData, setAllStudentData] = useState([]);
	const [userTypeData, setUserTypeData] = useState<any>([]);
	const [staffData, setStaffData] = useState<any>([]);
	const [addAwardData, setAddAwardData] = useState<any>([]);

	const awardTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: '',
			noOfPrize: '',
		},
		validate: (values) => {
			const errors: {
				title?: string;
				noOfPrize?: string;
			} = {};
			if (!values.title) {
				errors.title = 'Required';
			}
			if (!prizeCount) {
				errors.noOfPrize = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			addPrizeSubmit();
		},
	});

	const selectAcademicPeriod = (e: any) => {
		setDataSuccess(false);
		let academicPeriodId = e;
		setAcademicPeriodId(academicPeriodId);
		setAddAwardData([])
	};

	const selectNoOfPrize = (prizeCount: any) => {
		setDataSuccess(false);
		setPrizeCount(prizeCount);
		setAddAwardData([]);
	};

	function View() {
		setIsOpenListCard(false);
		setDataSuccess(true);

		let newData: any = [];
		for (let i = 0; i < prizeCount; i++) {
			newData.push({
				prize: '',
				subjectPaperDetailsIdArray: '',
				displayOrder: i,
			});
		}
		setAddAwardData(newData);
		getStaffListByUserType(userTypeIdForDropdown);
	}

	function getAcademicPeriodList() {
		getAcademicPeriod(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.academicPeriod;
					if (data != undefined) {
						setAcademicPeriodData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setAcademicPeriodData([]);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getAllStudentDetails() {
		getAllStudent(userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetails;
					if (data != undefined) {
						setAllStudentData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, 'Error');
					setAllStudentData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						setUserTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffListByUserType(staffTypeId: any) {
		getStaffListByUserTypeId(userAccountId, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffListByUserTypeId;
					if (data != undefined) {
						setStaffData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStaffData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [isDuplicateEntry, setIsDuplicateEntry] = useState<any>([]);

	const addAward = (studentOrStaffDetailsId: any, prize: any, displayOrder: any) => {
		let existingData = addAwardData.filter((data: any) => data.studentOrStaffDetailsId == studentOrStaffDetailsId?.value);
		setIsDuplicateEntry(existingData);

		const newData: any = [];

		addAwardData.forEach((item: any, index: any) => {

			if (item['displayOrder'] == displayOrder) {
				item['studentOrStaffDetailsId'] = studentOrStaffDetailsId?.value;
				item['prize'] = prize;
				item['displayOrder'] = displayOrder;
				item['studentOrStaffDetailsIdArray'] = studentOrStaffDetailsId;

				if (userTypeIdForDropdown && userTypeIdForDropdown != undefined) {
					item['existingData'] = existingData != '' ? true : false;
				} else if (userTypeIdForDropdown == 9 && userTypeIdForDropdown != undefined) {
					item['existingData'] = existingData != '' ? true : false;
				} else {
					item['studentOrStaffDetailsId'] = '';
					item['studentOrStaffDetailsIdArray'] = '';
					item['existingData'] = false;
				}
			}
			newData.push(item);
		});
		setAddAwardData(newData);

		if (awardTable.length == 0 && prize != '') {
			const obj = {
				displayOrder: displayOrder,
				studentOrStaffDetailsId: studentOrStaffDetailsId?.value,
				prize: prize,
			};
			awardTable.push(obj);
		} else {
			let selectedItem: any;
			awardTable.forEach((item: any, index: any) => {
				if (item['displayOrder'] == displayOrder) {
					selectedItem = item;
					selectedItem['studentOrStaffDetailsId'] = studentOrStaffDetailsId?.value;
					selectedItem['prize'] = prize;
				}
			});
			if (selectedItem != undefined) {
				(selectedItem['displayOrder'] = displayOrder),
					(selectedItem['studentOrStaffDetailsId'] = studentOrStaffDetailsId?.value),
					(selectedItem['prize'] = prize);
			} else if (studentOrStaffDetailsId != '' && prize != '') {
				var obj;
				obj = {
					displayOrder: displayOrder,
					studentOrStaffDetailsId: studentOrStaffDetailsId?.value,
					prize: prize,
				};
				awardTable.push(obj);
			}
		}
	};

	function setAwardDetails() {
		return {
			noOfPrize: prizeCount,
			staffDetailsId: userAccountId,
			title: awardTypeForm.values.title,
			userTypeId: userTypeIdForDropdown,
			winnerDetails: awardTable,
			licenseKey: getLicenseKey,
		};
	}

	function addPrizeSubmit() {
		showLoader(true);
		if (prizeCount == awardTable.length) {
			let awardDetailsPostData = setAwardDetails();
			addAwardDetails(
				awardDetailsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setIsOpenListCard(true);
						closeAndReset();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (prizeCount != awardTable.length) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function getOrdinal(index: any) {
		const j = index % 10;
		const k = index % 100;
		if (j === 1 && k !== 11) {
			return `${index}st`;
		}
		if (j === 2 && k !== 12) {
			return `${index}nd`;
		}
		if (j === 3 && k !== 13) {
			return `${index}rd`;
		}
		return `${index}th`;
	}

	function closeAndReset() {
		awardTypeForm.resetForm();
		setAcademicPeriodId('');
		setDataSuccess(false);
		setAddAwardData('');
		setStaffDetailsId('');
		setStudentDetailsId('');
		setStudentOrStaffDetailsId('');
		setPrizeCount('');
	}

	return (
		<PageWrapper title='Add Award'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Add Award
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-2'>
									<FormGroup
										id='academicPeriodId'
										label='Academic Period'
										isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Academic Period'
											placeholder='Academic Period'
											value={academicPeriodId}
											onChange={selectAcademicPeriod}
											list={academicPeriodData.map((data: any) => ({
												value: data.academicPeriodId,
												label: data.period,
											}))}
											required
										/>
									</FormGroup>
								</div>

								<div className='col-3'>
									<FormGroup id='title' label='Title' isFloating>
										<Input
											onChange={awardTypeForm.handleChange}
											value={awardTypeForm.values.title}
											onBlur={awardTypeForm.handleBlur}
											isValid={awardTypeForm.isValid}
											isTouched={awardTypeForm.touched.title}
											invalidFeedback={awardTypeForm.errors.title}
											placeholder='Enter Title'
										/>
									</FormGroup>
								</div>

								<div className='col-2'>
									<FormGroup id='prizeCount' label='No Of Prize' isFloating>
										<Input
											onChange={(e: any) =>
												selectNoOfPrize(e.target.value)
											}
											value={prizeCount}
											placeholder='Enter Section'
											required
										/>
									</FormGroup>
								</div>
								<div className='col-3'>
									<FormGroup id="userTypeId" label="User Type" isFloating>
										<Select
											ariaLabel='User Type'
											onChange={(e: any) => { setUserTypeIdForDropdown(e.target.value); setAddAwardData([]) }}
											value={userTypeIdForDropdown}
										>
											<option value='0'>Staff</option>
											<option value='9'>Student</option>
										</Select>
									</FormGroup>
								</div>
								<div className='col-2 mt-2'>
									<Button
										icon='ArrowDownward'
										color='primary'
										onClick={View}
										isDisable={academicPeriodId?.value == undefined || prizeCount == 0 || awardTypeForm?.values?.title == ''}>
										View
									</Button>
								</div>
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

				{dataSuccess ? (
					<>
						{addAwardData != '' ?
							<SubHeader>
								<SubHeaderLeft>
									<CardLabel icon='PhotoAlbum' iconColor='primary'>
										<CardTitle tag='div' className='h5'>
											{awardTypeForm.values.title}
										</CardTitle>
									</CardLabel>
								</SubHeaderLeft>
								<SubHeaderRight>
									<SubheaderSeparator />
									<Button
										color='info'
										isLight
										onClick={awardTypeForm.handleSubmit}
										icon='Save'
										isDisable={isDuplicateEntry != '' ? true : false}>
										Save
									</Button>
								</SubHeaderRight>
							</SubHeader> : null
						}
						<Page>
							<div className='row g-4'>
								{addAwardData != '' ? (
									<>
										{addAwardData.map((i: any, index: any) => (
											<div className='col-4' key={index}>
												<Card
													stretch
													shadow={'sm'}
													className={`rounded-1 mb-0 border ${i.existingData ? 'border-danger' : 'border-light'}`}>
													<CardHeader borderSize={1}>
														<CardLabel icon='EditNote'>
															<CardTitle>
																{' '}
																{getOrdinal(index + 1)} Prize
															</CardTitle>
														</CardLabel>
													</CardHeader>
													<CardBody>
														<div className='row g-4'>
															{userTypeIdForDropdown == 9 ?
																<div className='col-12'>
																	<FormGroup
																		id='studentDetailsId'
																		label='Student'
																		isFloating>
																		<SearchableSelect
																			isFloating
																			ariaLabel='Student'
																			onChange={(e: any) =>
																				addAward(
																					e,
																					index + 1,
																					index,
																				)
																			}
																			value={
																				i.studentOrStaffDetailsIdArray
																			}
																			list={allStudentData.map(
																				(data: any) => ({
																					value: data.studentDetailsId,
																					label: data.studentNameAndAdmissionNo,
																				}),
																			)}
																			required
																		/>
																	</FormGroup>
																</div> :

																<div className='col-12 mt-3'>
																	<FormGroup
																		id='staffDetailsId'
																		label='Staff'
																		isFloating>
																		<SearchableSelect
																			isFloating
																			ariaLabel='Staff'
																			onChange={(e: any) => addAward(e, index + 1, index)}
																			value={i.studentOrStaffDetailsIdArray}
																			list={staffData.map((data: any) => ({
																				value: data.staffDetailsId,
																				label: data.staffName,
																			}))}
																		/>
																	</FormGroup>
																</div>
															}

															{i.existingData ? (
																<div className='col-12 text-center'>
																	<div className='text-danger fw-bold text-uppercase mt-1'>
																		Already Exist
																	</div>
																</div>
															) : null}

														</div>
													</CardBody>
												</Card>
											</div>
										))}
									</>
								) : null}
							</div>
						</Page>
					</>
				) : null}
			</Page>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default AddAward;
