import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getTournamentDetailsList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTournamentDetailsList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getTournamentDetailsList - " + error.message)
            console.log('error caught in service : getTournamentDetailsList')
        },
    );

export const addTournamentName = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTournamentName`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTournamentName')
        }
    );

export const updateTournamentName = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTournamentName`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTournamentName')
        }
    );

export const deleteTournamentName = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTournamentName`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTournamentName')
        }
    );

export const getEventType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEventType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getEventType - " + error.message)
            console.log('error caught in service : getEventType')
        },
    );

export const getTournamentNameList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTournamentNameList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getTournamentNameList - " + error.message)
            console.log('error caught in service : getTournamentNameList')
        },
    );

export const addTournamentDetails = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTournamentDetails`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTournamentDetails')
        }
    );

export const updateTournamentDetails = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTournamentDetails`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTournamentDetails')
        }
    );

export const deleteTournamentDetails = (deletePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTournamentDetails`, deletePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTournamentDetails')
        }
    );


export const addTournamentParticipants = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTournamentParticipants`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTournamentParticipants')
        }
    );

export const getTournamentDetailsListByTournamentDetailsId = (tournamentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTournamentDetailsList/${getLicenseKey}/${tournamentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getTournamentDetailsList - " + error.message)
            console.log('error caught in service : getTournamentDetailsList')
        },
    );

export const addEventDetails = (tournamentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addEventDetails`, tournamentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addEventDetails')
        }
    );

export const deleteEventImages = (eventPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteEventImages`, eventPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteEventImages')
        }
    );

export const getEventList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEventList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getEventList - " + error.message)
            console.log('error caught in service : getEventList')
        },
    );

export const getSportsDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSportsDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getSportsDetails - " + error.message)
            console.log('error caught in service : getSportsDetails')
        },
    );

export const getSportsPlayers = (sportsDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSportsPlayers/${getLicenseKey}/${sportsDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getSportsPlayers - " + error.message)
            console.log('error caught in service : getSportsPlayers')
        },
    );

export const getSportsPosition = (sportsDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSportsPosition/${getLicenseKey}/${sportsDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getSportsPosition - " + error.message)
            console.log('error caught in service : getSportsPosition')
        },
    );

export const assignPlayersPosition = (positionPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `assignPlayersPosition`, positionPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : assignPlayersPosition')
        }
    );

export const removeSportsPlayers = (positionPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `removeSportsPlayers`, positionPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : removeSportsPlayers')
        }
    );

export const addSportsType = (sportsTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addSportsType`, sportsTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addSportsType')
        }
    );

export const getSportsType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSportsType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getSportsType - " + error.message)
            console.log('error caught in service : getSportsType')
        },
    );

export const updateSportsType = (sportsTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateSportsType`, sportsTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateSportsType')
        }
    );

export const deleteSportsType = (sportsTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteSportsType`, sportsTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteSportsType')
        }
    );

export const addSportsDetails = (sportsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addSportsDetails`, sportsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addSportsDetails')
        }
    );

export const updateSportsDetails = (sportsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateSportsDetails`, sportsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateSportsDetails')
        }
    );

export const deleteSportsDetails = (sportsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteSportsDetails`, sportsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteSportsDetails')
        }
    );

export const addSportsPlayers = (sportsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addSportsPlayers`, sportsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addSportsPlayers')
        }
    );

export const addSportsPosition = (sportsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addSportsPosition`, sportsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addSportsPosition')
        }
    );

export const updateSportsPosition = (sportsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateSportsPosition`, sportsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateSportsPosition')
        }
    );

export const deleteSportsPosition = (sportsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteSportsPosition`, sportsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteSportsPosition')
        }
    );