import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';
import AuthContext from '../../../contexts/authContext';
import useSortableData from '../../../hooks/useSortableData';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import { getLicenseKey } from '../../../services/application.settings';
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable, getUserType } from '../../../services/common.service';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import { showLoader, TableLoader } from '../../../services/loader.services';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from '../../bootstrap/Card';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '../../bootstrap/Modal';
import Icon from '../../icon/Icon';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Input from '../../bootstrap/forms/Input';
import { getStaffListByUserTypeId } from '../../../services/staff.service';
import { getAllStudent } from '../../../services/student.service';
import { addLibraryMember, updateLibraryMember, deleteLibraryMember, getLibraryMember } from '../../../services/library.service';
import Textarea from '../../bootstrap/forms/Textarea';

function LibraryMembership() {

	useEffect(() => {
		getBookDetailsList();
		getStaffListByUserType(0);
		getUserTypeList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [addLibraryBookModal, setAddLibraryBookModal] = useState(false);
	const [editLibraryBookModal, setEditLibraryBookModal] = useState(false);
	const [membershipData, setMembershipData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const componentRef = useRef(null);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(membershipData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [studentOrStaffDetailsId, setStudentOrStaffDetailsId] = useState<any>('');
	const [allStudentData, setAllStudentData] = useState([]);
	const [userTypeData, setUserTypeData] = useState<any>([]);
	const [staffData, setStaffData] = useState<any>([]);

	const addMemberForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			staffUserTypeId: '',
			studentOrStaffDetailsId: '',
			email: '',
			phone: '',
			address: '',
			membershipStartDate: '',
			membershipExpiryDate: '',
			amount: '',
			remarks: '',
		},
		validate: (values) => {
			const errors: {
				staffUserTypeId?: string;
				studentOrStaffDetailsId?: string;
				email?: string;
				phone?: string;
				address?: string;
				membershipStartDate?: string;
				membershipExpiryDate?: string;
				amount?: string;
				remarks?: string;
			} = {};
			if (!studentOrStaffDetailsId) {
				errors.studentOrStaffDetailsId = 'Required';
			}
			if (!values.email) {
				errors.email = 'Required';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && values.email != '') {
				errors.email = 'Invalid email address';
			}
			if (!values.phone) {
				errors.phone = 'Required';
			} else if (values.phone.length < 10 || values.phone.length > 10) {
				errors.phone = 'Must be 10 Numbers'
			} else if (!/^[6-9]\d{9}$/i.test(values.phone)) {
				errors.phone = 'Please enter valid mobile number'
			}
			if (!values.address) {
				errors.address = 'Required';
			}
			if (!values.membershipStartDate) {
				errors.membershipStartDate = 'Required';
			}
			if (!values.membershipExpiryDate) {
				errors.membershipExpiryDate = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			addLibrarySubmit();
		},
	});

	const editMemberForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			staffUserTypeId: '',
			libraryMemberId: '',
			studentOrStaffDetailsId: '',
			email: '',
			phone: '',
			address: '',
			membershipStartDate: '',
			membershipExpiryDate: '',
			amount: '',
			remarks: '',
		},
		validate: (values) => {
			const errors: {
				studentOrStaffDetailsId?: string;
				email?: string;
				phone?: string;
				membershipStartDate?: string;
				membershipExpiryDate?: string;
			} = {};
			// if (!studentOrStaffDetailsId?.value) {
			// 	errors.studentOrStaffDetailsId = 'Required';
			// }
			if (!values.email) {
				errors.email = 'Required';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && values.email != '') {
				errors.email = 'Invalid email address';
			}
			if (!values.phone) {
				errors.phone = 'Required';
			} else if (values.phone.length < 10 || values.phone.length > 10) {
				errors.phone = 'Must be 10 Numbers'
			} else if (!/^[6-9]\d{9}$/i.test(values.phone)) {
				errors.phone = 'Please enter valid mobile number'
			}
			if (!values.membershipStartDate) {
				errors.membershipStartDate = 'Required';
			}
			if (!values.membershipExpiryDate) {
				errors.membershipExpiryDate = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			updateBookDetailsSubmit();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedMembership = i;

			if (selectedMembership.userTypeId == 9) {
				setIsStudent(true)
				getAllStudentDetails()
			} else {
				setIsStudent(false)
			}

			setEditLibraryBookModal(true);
			setStudentOrStaffDetailsId({ value: selectedMembership.studentOrStaffDetailsId, label: selectedMembership.studentOrStaffName });
			setStaffUserTypeId({ value: selectedMembership.userTypeId, label: selectedMembership.userType });

			editMemberForm.setValues({
				staffUserTypeId: selectedMembership.userTypeId,
				libraryMemberId: selectedMembership.libraryMemberId,
				studentOrStaffDetailsId: selectedMembership.studentOrStaffDetailsId,
				email: selectedMembership.email,
				phone: selectedMembership.phone,
				address: selectedMembership.address,
				membershipStartDate: selectedMembership.membershipStartDate,
				membershipExpiryDate: selectedMembership.membershipExpiryDate,
				amount: selectedMembership.amount,
				remarks: selectedMembership.remarks,
			});
		}
	}

	const [staffUserTypeId, setStaffUserTypeId] = useState<any>('');
	const [isStudent, setIsStudent] = useState<any>('');


	function getUserTypeId(e: any) {
		setStudentOrStaffDetailsId('')
		setStaffData([])
		let staffUserTypeId = e
		setStaffUserTypeId(staffUserTypeId)
		if (staffUserTypeId?.value != undefined) {
			getStaffListByUserType(staffUserTypeId?.value)
			if (staffUserTypeId?.value == 9) {
				setIsStudent(true)
				getAllStudentDetails()
			} else {
				setIsStudent(false)
			}
		}
	}

	const selectStaff = (e: any) => {
		let staffDetailsId = e;
		setStudentOrStaffDetailsId(staffDetailsId);
	};

	const selectStudent = (e: any) => {
		let studentDetailsId = e;
		setStudentOrStaffDetailsId(studentDetailsId);
	};

	function setLibraryDetails() {
		return {
			userTypeId: staffUserTypeId?.value,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			studentOrStaffDetailsId: studentOrStaffDetailsId?.value,
			email: addMemberForm.values.email,
			phone: addMemberForm.values.phone,
			address: addMemberForm.values.address,
			membershipStartDate: addMemberForm.values.membershipStartDate,
			membershipExpiryDate: addMemberForm.values.membershipExpiryDate,
			amount: addMemberForm.values.amount,
			remarks: addMemberForm.values.remarks,
		};
	}

	function setEditLibraryDetails() {
		return {
			userTypeId: staffUserTypeId?.value,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			libraryMemberId: editMemberForm.values.libraryMemberId,
			studentOrStaffDetailsId: studentOrStaffDetailsId?.value,
			email: editMemberForm.values.email,
			phone: editMemberForm.values.phone,
			address: editMemberForm.values.address,
			membershipStartDate: editMemberForm.values.membershipStartDate,
			membershipExpiryDate: editMemberForm.values.membershipExpiryDate,
			amount: editMemberForm.values.amount,
			remarks: editMemberForm.values.remarks,
		};
	}

	function setDeleteLibraryDetails() {
		return {
			libraryMemberId: editMemberForm.values.libraryMemberId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
		};
	}

	function addLibrarySubmit() {
		showLoader(true);
		if (addMemberForm.isValid) {
			let libraryPostData = setLibraryDetails();
			addLibraryMember(
				libraryPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeModal();
						getBookDetailsList();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addMemberForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	const updateBookDetailsSubmit = () => {
		showLoader(true);
		if (editMemberForm.isValid) {
			let updateLibraryPostData = setEditLibraryDetails();
			updateLibraryMember(
				updateLibraryPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getBookDetailsList();
						closeModal();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editMemberForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
			closeModal();
		}
	};

	const deleteBookDetailsSubmit = () => {
		showLoader(true);
		if (editMemberForm.values.libraryMemberId > '0') {
			let updateLibraryPostData = setDeleteLibraryDetails();
			deleteLibraryMember(
				updateLibraryPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setIsDeleteOpen(false);
						getBookDetailsList();
						closeModal();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editMemberForm.values.libraryMemberId == '0') {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	};



	function getBookDetailsList() {
		setDataStatus(false);
		getLibraryMember(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.libraryMember;
					if (data != undefined) {
						getColumnsForTable('getLibraryMember', 'get');
						setMembershipData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataStatus(true);
					} else {
						toasts('Undefined Data', 'Error');
						setDataStatus(true);
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					getColumnsForTable('getLibraryMember', 'get');
					setMembershipData([]);
					setNoDataMsg(response.data.message);
					setDataStatus(true);
				} else {
					setDataStatus(true);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getLibraryMember', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function getAllStudentDetails() {
		getAllStudent(
			userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetails;
					if (data != undefined) {
						setAllStudentData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, 'Error');
					setAllStudentData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						setUserTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffListByUserType(staffTypeId: any) {
		getStaffListByUserTypeId(
			userAccountId,
			staffTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffListByUserTypeId;
					if (data != undefined) {
						setStaffData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStaffData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function closeModal() {
		setEditLibraryBookModal(false);
		setAddLibraryBookModal(false);
		setIsDeleteOpen(false);
		addMemberForm.resetForm();
		editMemberForm.resetForm()
		setStaffUserTypeId('')
		setStudentOrStaffDetailsId('');
	}

	return (
		<>
			<PageWrapper title={`Membership List`}>
				<Page container='fluid'>
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-5'>
								<CardTitle tag='div' className='h5'>
									Membership List
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForTable(
																		'getLibraryMember',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div
														className='col-12'
														style={{
															maxHeight: '200px',
															overflowY: 'scroll',
														}}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		id='{i.columnVisibilityId}'
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={
																			i.isDefault == true
																		}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'membership List',
														membershipData,
														columnVisibilityData,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														membershipData,
														columnVisibilityData,
														'Membership List',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'Membership List',
														membershipData,
														columnVisibilityData,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={handlePrint}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Button
									color='primary'
									icon='Add'
									isLight
									onClick={() => setAddLibraryBookModal(true)}>
									Add New
								</Button>
							</CardActions>
						</CardHeader>

						<CardBody className='table-responsive' isScrollable>
							{!dataStatus ? (
								<TableLoader />
							) : (
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No{' '}
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											{columnVisibilityData.map((column: any) => (
												<th
													key={column.keyName}
													scope='col'
													onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon
														size='lg'
														className={getClassNamesFor(column.keyName)}
														icon='FilterList'
													/>
												</th>
											))}
											<th scope='col' className='d-print-none'>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ? (
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.libraryMemberId}>
														<td>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) =>
															column.isDisplay &&
																rowData[column.keyName] ? (
																<td
																	key={`${rowData.libraryMemberId}-${column.keyName}`}>
																	{rowData[column.keyName]}
																</td>
															) : (
																<td
																	key={`empty-${column.keyName}`}
																/>
															),
														)}
														<td className='d-print-none'>
															<Button
																color='dark'
																isLight
																icon='Edit'
																aria-label='Edit'
																onClick={() => {
																	onActivate(rowData);
																}}
															/>
														</td>
													</tr>
												))}
											</>
										) : (
											<NoDataMsg
												columnsCount={columnVisibilityData.length + 2}
												msg={noDataMsg}
											/>
										)}
									</tbody>
								</table>
							)}
						</CardBody>
						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				</Page>

				<Modal
					setIsOpen={setAddLibraryBookModal}
					isOpen={addLibraryBookModal}
					titleId='addBatchMaster'
					isStaticBackdrop
					size='lg'>
					<ModalHeader
						className="'modal-header'"
						setIsOpen={() => {
							setAddLibraryBookModal(false);
						}}
						onClick={closeModal}>
						<ModalTitle id='addBatchMaster'>Membership Creation</ModalTitle>
					</ModalHeader>
					<form noValidate onSubmit={addMemberForm.handleSubmit}>
						<ModalBody>
							<div className='row g-4'>
								<div className='col-md-4'>
									<FormGroup id='staffUserTypeId' label='Role' isFloating>
										<SearchableSelect
											isFloating
											onChange={getUserTypeId}
											value={staffUserTypeId}
											onBlur={addMemberForm.handleBlur}
											isValid={addMemberForm.isValid}
											isTouched={addMemberForm.touched.staffUserTypeId}
											invalidFeedback={addMemberForm.errors.staffUserTypeId}
											list={userTypeData.map((data: any) => (
												{ value: data.userTypeId, label: data.userType }
											))}
											placeholder="Select Role" ariaLabel={""} />
									</FormGroup>
								</div>


								{isStudent ? <>
									<div className='col-md-4'>
										<FormGroup id='studentOrStaffDetailsId' label='Student' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Student'
												onChange={selectStudent}
												value={studentOrStaffDetailsId}
												list={allStudentData.map((data: any) => ({
													value: data.studentDetailsId,
													label: data.studentNameAndRollNo,
												}))}
												required
											/>
										</FormGroup>
									</div></> :

									<div className='col-md-4'>
										<FormGroup id='studentOrStaffDetailsId' label='Staff' isFloating>
											<SearchableSelect isFloating
												onChange={selectStaff}
												value={studentOrStaffDetailsId}
												onBlur={addMemberForm.handleBlur}
												isValid={addMemberForm.isValid}
												isTouched={
													addMemberForm.touched.studentOrStaffDetailsId
												}
												invalidFeedback={
													addMemberForm.errors.studentOrStaffDetailsId
												}
												list={staffData.map((data: any) => ({
													value: data.staffDetailsId,
													label: data.empAndStaffName,
												}))}
												placeholder='Select Staff'
												ariaLabel={''}
											/>
										</FormGroup>
									</div>}

								<div className='col-md-4'>
									<FormGroup id='email' label='email' isFloating>
										<Input
											onChange={addMemberForm.handleChange}
											value={addMemberForm.values.email}
											onBlur={addMemberForm.handleBlur}
											isValid={addMemberForm.isValid}
											isTouched={addMemberForm.touched.email}
											invalidFeedback={addMemberForm.errors.email}
											type='text'
											placeholder='Enter Email'
										/>
									</FormGroup>
								</div>
								<div className='col-md-4'>
									<FormGroup id='phone' label='Phone' isFloating>
										<Input
											onChange={addMemberForm.handleChange}
											value={addMemberForm.values.phone}
											onBlur={addMemberForm.handleBlur}
											isValid={addMemberForm.isValid}
											isTouched={addMemberForm.touched.phone}
											invalidFeedback={addMemberForm.errors.phone}
											type='text'
											placeholder='Enter Phone No'
										/>
									</FormGroup>
								</div>
								<div className='col-md-8'>
									<FormGroup id='address' label='Address' isFloating>
										<Textarea
											onChange={addMemberForm.handleChange}
											value={addMemberForm.values.address}
											onBlur={addMemberForm.handleBlur}
											isValid={addMemberForm.isValid}
											isTouched={addMemberForm.touched.address}
											invalidFeedback={addMemberForm.errors.address}
											placeholder='Enter Address'
										/>
									</FormGroup>
								</div>
								<div className='col-md-4'>
									<FormGroup id='membershipStartDate' label='Membership Start Date' isFloating>
										<Input
											aria-placeholder=''
											onChange={addMemberForm.handleChange}
											onBlur={addMemberForm.handleBlur}
											value={addMemberForm.values.membershipStartDate}
											isValid={addMemberForm.isValid}
											isTouched={addMemberForm.touched.membershipStartDate}
											invalidFeedback={
												addMemberForm.errors.membershipStartDate
											}
											type='date'
										/>
									</FormGroup>
								</div>
								<div className='col-md-4'>
									<FormGroup id='membershipExpiryDate' label='Membership Expiry Date' isFloating	>
										<Input
											aria-placeholder=''
											onChange={addMemberForm.handleChange}
											onBlur={addMemberForm.handleBlur}
											value={addMemberForm.values.membershipExpiryDate}
											isValid={addMemberForm.isValid}
											isTouched={addMemberForm.touched.membershipExpiryDate}
											invalidFeedback={
												addMemberForm.errors.membershipExpiryDate
											}
											type='date'
										/>
									</FormGroup>
								</div>
								<div className='col-md-4'>
									<FormGroup id='amount' label='Amount' isFloating>
										<Input
											onChange={addMemberForm.handleChange}
											value={addMemberForm.values.amount}
											onBlur={addMemberForm.handleBlur}
											isValid={addMemberForm.isValid}
											isTouched={addMemberForm.touched.amount}
											invalidFeedback={addMemberForm.errors.amount}
											type='text'
											placeholder='Enter amount'
										/>
									</FormGroup>
								</div>
								<div className='col-md-8'>
									<FormGroup id='remarks' label='Remarks' isFloating>
										<Input
											onChange={addMemberForm.handleChange}
											value={addMemberForm.values.remarks}
											onBlur={addMemberForm.handleBlur}
											isValid={addMemberForm.isValid}
											isTouched={addMemberForm.touched.remarks}
											invalidFeedback={addMemberForm.errors.remarks}
											type='text'
											placeholder='Enter remarks'
										/>
									</FormGroup>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button
								color='info'
								isOutline
								className='border-0'
								onClick={closeModal}>
								Close
							</Button>
							<Button
								icon='Save'
								color='info'
								type='submit'
								isDisable={!addMemberForm.isValid && !addMemberForm.submitCount}
							>
								Save
							</Button>
						</ModalFooter>
					</form>
				</Modal>

				<Modal
					setIsOpen={setEditLibraryBookModal}
					isOpen={editLibraryBookModal}
					titleId='updateBatchMaster'
					isCentered
					isStaticBackdrop
					size='lg'>
					<ModalHeader className="'modal-header'" setIsOpen={closeModal}>
						<ModalTitle id='updateBatchMaster'>Update Membership </ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='row g-4'>
							<div className='col-md-4'>
								<FormGroup id='staffUserTypeId' label='Role' isFloating>
									<SearchableSelect
										isFloating
										onChange={getUserTypeId}
										value={staffUserTypeId}
										onBlur={editMemberForm.handleBlur}
										isValid={editMemberForm.isValid}
										isTouched={editMemberForm.touched.staffUserTypeId}
										invalidFeedback={editMemberForm.errors.staffUserTypeId}
										list={userTypeData.map((data: any) => (
											{ value: data.userTypeId, label: data.userType }
										))}
										placeholder="Select Role" ariaLabel={""} />
								</FormGroup>
							</div>


							{isStudent ? <>
								<div className='col-md-4'>
									<FormGroup id='studentOrStaffDetailsId' label='Student' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Student'
											onChange={selectStudent}
											value={studentOrStaffDetailsId}
											list={allStudentData.map((data: any) => ({
												value: data.studentDetailsId,
												label: data.studentNameAndRollNo,
											}))}
											required
										/>
									</FormGroup>
								</div></> :

								<div className='col-md-4'>
									<FormGroup id='studentOrStaffDetailsId' label='Staff' isFloating>
										<SearchableSelect isFloating
											onChange={selectStaff}
											value={studentOrStaffDetailsId}
											onBlur={editMemberForm.handleBlur}
											isValid={editMemberForm.isValid}
											isTouched={
												editMemberForm.touched.studentOrStaffDetailsId
											}
											invalidFeedback={
												editMemberForm.errors.studentOrStaffDetailsId
											}
											list={staffData.map((data: any) => ({
												value: data.staffDetailsId,
												label: data.empAndStaffName,
											}))}
											placeholder='Select Staff'
											ariaLabel={''}
										/>
									</FormGroup>
								</div>}

							<div className='col-md-4'>
								<FormGroup id='email' label='Email' isFloating>
									<Input
										onChange={editMemberForm.handleChange}
										value={editMemberForm.values.email}
										onBlur={editMemberForm.handleBlur}
										isValid={editMemberForm.isValid}
										isTouched={editMemberForm.touched.email}
										invalidFeedback={editMemberForm.errors.email}
										type='text'
										placeholder='Enter Email'
									/>
								</FormGroup>
							</div>
							<div className='col-md-4'>
								<FormGroup id='phone' label='Phone' isFloating>
									<Input
										onChange={editMemberForm.handleChange}
										value={editMemberForm.values.phone}
										onBlur={editMemberForm.handleBlur}
										isValid={editMemberForm.isValid}
										isTouched={editMemberForm.touched.phone}
										invalidFeedback={editMemberForm.errors.phone}
										type='text'
										placeholder='Enter Phone No'
									/>
								</FormGroup>
							</div>
							<div className='col-md-8'>
								<FormGroup id='address' label='Address' isFloating>
									<Textarea
										onChange={editMemberForm.handleChange}
										value={editMemberForm.values.address}
										onBlur={editMemberForm.handleBlur}
										isValid={editMemberForm.isValid}
										isTouched={editMemberForm.touched.address}
										invalidFeedback={editMemberForm.errors.address}
										placeholder='Enter Address'
									/>
								</FormGroup>
							</div>

							<div className='col-md-4'>
								<FormGroup id='membershipStartDate' label='Membership Start Date' isFloating>
									<Input
										aria-placeholder=''
										onChange={editMemberForm.handleChange}
										onBlur={editMemberForm.handleBlur}
										value={editMemberForm.values.membershipStartDate}
										isValid={editMemberForm.isValid}
										isTouched={editMemberForm.touched.membershipStartDate}
										invalidFeedback={
											editMemberForm.errors.membershipStartDate
										}
										type='date'
									/>
								</FormGroup>
							</div>
							<div className='col-md-4'>
								<FormGroup id='membershipExpiryDate' label='Membership Expiry Date' isFloating>
									<Input
										aria-placeholder=''
										onChange={editMemberForm.handleChange}
										onBlur={editMemberForm.handleBlur}
										value={editMemberForm.values.membershipExpiryDate}
										isValid={editMemberForm.isValid}
										isTouched={editMemberForm.touched.membershipExpiryDate}
										invalidFeedback={
											editMemberForm.errors.membershipExpiryDate
										}
										type='date'
									/>
								</FormGroup>
							</div>
							<div className='col-md-4'>
								<FormGroup id='amount' label='Amount' isFloating>
									<Input
										onChange={editMemberForm.handleChange}
										value={editMemberForm.values.amount}
										onBlur={editMemberForm.handleBlur}
										isValid={editMemberForm.isValid}
										isTouched={editMemberForm.touched.amount}
										invalidFeedback={editMemberForm.errors.amount}
										type='text'
										placeholder='Enter Amount'
									/>
								</FormGroup>
							</div>
							<div className='col-md-8'>
								<FormGroup id='remarks' label='Remarks' isFloating>
									<Input
										onChange={editMemberForm.handleChange}
										value={editMemberForm.values.remarks}
										onBlur={editMemberForm.handleBlur}
										isValid={editMemberForm.isValid}
										isTouched={editMemberForm.touched.remarks}
										invalidFeedback={editMemberForm.errors.remarks}
										type='text'
										placeholder='Enter Remarks'
									/>
								</FormGroup>
							</div>
						</div>
						<ModalFooter>
							<Button
								color='info'
								isOutline
								className='border-0'
								onClick={closeModal}>
								Close
							</Button>
							<Button
								color='danger'
								icon='Delete'
								type='submit'
								onClick={() => { setIsDeleteOpen(!isDeleteOpen); setEditLibraryBookModal(false) }}>
								Delete
							</Button>
							<Button
								color='info'
								icon='Save'
								type='submit'
								onClick={updateBookDetailsSubmit}>
								Update
							</Button>
						</ModalFooter>
					</ModalBody>
				</Modal>

				<Modal
					setIsOpen={setIsDeleteOpen}
					isOpen={isDeleteOpen}
					titleId='deleteBatchMaster'
					isStaticBackdrop
					size='sm'>
					<ModalHeader>
						<></>
					</ModalHeader>
					<ModalBody>
						<div className='row g-2'>
							<div className='col-12 d-flex justify-content-center'>
								<h5>
									<strong>Are you sure want to delete ?</strong>
								</h5>
							</div>
							<div className='col-10'>
								<div className='d-flex gap-2 justify-content-end'>
									<Button
										color='danger'
										size={'sm'}
										onClick={deleteBookDetailsSubmit}>
										Yes
									</Button>
									<Button
										color='info'
										size={'sm'}
										onClick={() => { setIsDeleteOpen(!isDeleteOpen); setEditLibraryBookModal(true) }}>
										No
									</Button>
								</div>
							</div>
							<div className='col-10'></div>
						</div>
					</ModalBody>
				</Modal>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
}
export default LibraryMembership;
