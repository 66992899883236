import { useFormik } from 'formik';
import { useEffect, useState, useRef, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import DeleteComponents from '../../common/components/DeleteComponents';
import NoDataMsg from '../../common/components/NoDataMsg';
import AuthContext from '../../contexts/authContext';
import useDarkMode from '../../hooks/useDarkMode';
import useSortableData from '../../hooks/useSortableData';
import AlertService from '../../services/AlertService';
import { getLicenseKey } from '../../services/application.settings';
import {
	updateFilter,
	getColumnsForDataTable,
	updateColumnsForDataTable,
} from '../../services/common.service';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../services/ExportService';
import { showLoader, TableLoader } from '../../services/loader.services';
import { toasts } from '../../services/toast.service';
import Button, { ButtonGroup } from '../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from '../bootstrap/Card';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import FormGroup from '../bootstrap/forms/FormGroup';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from '../bootstrap/OffCanvas';
import Icon from '../icon/Icon';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import Input from '../bootstrap/forms/Input';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import {
	addSportsPosition,
	deleteSportsPosition,
	getSportsPosition,
	updateSportsPosition,
} from '../../services/sportsManagement.service';
import { useNavigate, useParams } from 'react-router-dom';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';

function AddPosition() {
	const { sportsDetailsId } = useParams();

	useEffect(() => {
		getPositionDetails(sportsDetailsId);
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [positionData, setPositionData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(positionData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [addPositionOffCanvas, setAddPositionOffCanvas] = useState(false);
	const [editPositionOffCanvas, setEditPositionOffCanvas] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	const addPositionForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			position: '',
		},
		validate: (values) => {
			const errors: {
				position?: string;
			} = {};
			if (!values.position) {
				errors.position = 'Required';
			}
			return errors;
		},
		validateOnChange: true,
		onSubmit: () => {
			addPositionSubmit();
		},
	});

	const editPositionForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			position: '',
			sportsPositionId: '',
		},
		validate: (values) => {
			const errors: {
				position?: string;
				sportsPositionId?: any;
			} = {};
			if (!values.position) {
				errors.position = 'Required';
			}
			return errors;
		},
		validateOnChange: true,
		onSubmit: () => {
			updatePositionSubmit();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => {},

		//validateOnChange: false,
		onSubmit: () => {},
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function getPositionDetails(sportsDetailsId: any) {
		setDataStatus(false);
		getSportsPosition(
			sportsDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sportsPosition[0].position;
					if (data != undefined) {
						getColumnsForTable('getSportsPosition', 'get');
						setPositionData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataStatus(true);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					//toasts(response.data.message, "Error")
					getColumnsForTable('getSportsPosition', 'get');
					setPositionData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedPosition = i;
			setEditPositionOffCanvas(true);

			editPositionForm.setValues({
				position: selectedPosition.position,
				sportsPositionId: selectedPosition.sportsPositionId,
			});
		}
	}

	function setPositionDetails() {
		return {
			position: addPositionForm.values.position,
			sportsDetailsId: sportsDetailsId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
		};
	}

	function setEditPositionDetails() {
		return {
			sportsPositionId: editPositionForm.values.sportsPositionId,
			position: editPositionForm.values.position,
			sportsDetailsId: sportsDetailsId,
			staffDetailsId: userAccountId,
			licenseKey: getLicenseKey,
		};
	}

	function addPositionSubmit() {
		showLoader(true);
		if (addPositionForm.isValid) {
			let sportsPostData = setPositionDetails();
			addSportsPosition(
				sportsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getPositionDetails(sportsDetailsId);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addPositionForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function updatePositionSubmit() {
		showLoader(true);
		if (editPositionForm.isValid) {
			let sportsPostData = setEditPositionDetails();
			updateSportsPosition(
				sportsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getPositionDetails(sportsDetailsId);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editPositionForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteCasteSubmit() {
		showLoader(true);
		// if (editPositionForm.isValid) {
		let sportsPostData = setEditPositionDetails();
		deleteSportsPosition(
			sportsPostData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'success' });
					setIsOpen(true);
					closeAndReset();
					getPositionDetails(sportsDetailsId);
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'error' });
					setIsOpen(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					showLoader(false);
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
		// } else if (editPositionForm.isValid == false) {
		//     showLoader(false)
		//     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
		//     setIsOpen(true);
		// }
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
							{
								columnVisibilityId: columnVisibilityId,
								isDisplay: isDisplay ? 0 : 1,
							},
						],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getSportsPosition', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	function closeAndReset() {
		setAddPositionOffCanvas(false);
		setEditPositionOffCanvas(false);
		setIsDeleteOpen(false);
		addPositionForm.resetForm();
		editPositionForm.resetForm();
	}

	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const navigate = useNavigate();

	return (
		<>
			<PageWrapper title={`Add Position`}>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`../sportsManagement/addSports`)}>
						Back to List
					</Button>
					<SubheaderSeparator />
				</SubHeaderLeft>
			</SubHeader>
				<Page container='fluid'>
					<Card stretch data-tour='list' ref={componentRef}>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-5'>
								<CardTitle tag='div' className='h5'>
									Add Position
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForTable(
																		'getSportsPosition',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div
														className='col-12'
														style={{
															maxHeight: '200px',
															overflowY: 'scroll',
														}}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		id='{i.columnVisibilityId}'
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={
																			i.isDefault == true
																		}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'Sports Position List',
														positionData,
														columnVisibilityData,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														positionData,
														columnVisibilityData,
														'Sports Position List',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'Sports Position List',
														positionData,
														columnVisibilityData,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={handlePrint}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Button
									color='primary'
									icon='Add'
									isLight
									onClick={() => setAddPositionOffCanvas(true)}>
									Add New
								</Button>
							</CardActions>
						</CardHeader>

						<CardBody className='table-responsive' isScrollable>
							{!dataStatus ? (
								<TableLoader />
							) : (
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No{' '}
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											{columnVisibilityData.map((column: any) => (
												<th
													key={column.keyName}
													scope='col'
													onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon
														size='lg'
														className={getClassNamesFor(column.keyName)}
														icon='FilterList'
													/>
												</th>
											))}
											<th scope='col' className='d-print-none'>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ? (
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.sportsPositionId}>
														<td>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) =>
															column.isDisplay &&
															rowData[column.keyName] ? (
																<td
																	key={`${rowData.sportsPositionId}-${column.keyName}`}>
																	{rowData[column.keyName]}
																</td>
															) : (
																<td
																	key={`empty-${column.keyName}`}
																/>
															),
														)}
														<td className='d-print-none'>
															<Button
																color='dark'
																isLight
																icon='Edit'
																aria-label='Edit'
																onClick={() => {
																	onActivate(rowData);
																}}
															/>
														</td>
													</tr>
												))}
											</>
										) : (
											<NoDataMsg
												columnsCount={columnVisibilityData.length + 2}
												msg={noDataMsg}
											/>
										)}
									</tbody>
								</table>
							)}
						</CardBody>
						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>

					<OffCanvas
						setOpen={setAddPositionOffCanvas}
						isOpen={addPositionOffCanvas}
						titleId='addPositionOffCanvas'
						isBodyScroll
						placement='end'
						tag='form'
						noValidate
						onSubmit={addPositionForm.handleSubmit}>
						<OffCanvasHeader setOpen={setAddPositionOffCanvas} onClick={closeAndReset}>
							<OffCanvasTitle id='addPositionOffCanvas'>
								Position Creation
							</OffCanvasTitle>
						</OffCanvasHeader>
						<OffCanvasBody>
							<div className='row g-4'>
								<div className='col-12'>
									<FormGroup id='position' label='Position Name'>
										<Input
											onChange={addPositionForm.handleChange}
											value={addPositionForm.values.position}
											isValid={addPositionForm.isValid}
											onBlur={addPositionForm.handleBlur}
											isTouched={addPositionForm.touched.position}
											invalidFeedback={addPositionForm.errors.position}
											placeholder='Enter Position Name'
										/>
									</FormGroup>
								</div>
							</div>
						</OffCanvasBody>
						<div className='row m-0'>
							<div className='col-12 p-3'>
								<Button
									color='info'
									icon='Save'
									className='w-100'
									type='submit'
									isDisable={
										!addPositionForm.isValid && !!addPositionForm.submitCount
									}>
									Save
								</Button>
							</div>
						</div>
					</OffCanvas>

					<OffCanvas
						setOpen={setEditPositionOffCanvas}
						isOpen={editPositionOffCanvas}
						titleId='editPositionOffCanvas'
						isBodyScroll
						placement='end'
						tag='form'
						noValidate
						onSubmit={editPositionForm.handleSubmit}>
						<OffCanvasHeader setOpen={setEditPositionOffCanvas} onClick={closeAndReset}>
							<OffCanvasTitle id='editPositionOffCanvas'>
								Update Position
							</OffCanvasTitle>
						</OffCanvasHeader>
						<OffCanvasBody>
							<div className='row g-4'>
								<div className='col-12'>
									<FormGroup id='position' label='Position Name'>
										<Input
											onChange={editPositionForm.handleChange}
											value={editPositionForm.values.position}
											isValid={editPositionForm.isValid}
											onBlur={editPositionForm.handleBlur}
											isTouched={editPositionForm.touched.position}
											invalidFeedback={editPositionForm.errors.position}
											placeholder='Enter Position Name'
										/>
									</FormGroup>
								</div>
							</div>
						</OffCanvasBody>

						<div className='row'>
							<div className='d-flex justify-content-center position-absolute top-65 start-50 translate-middle'>
								<DeleteComponents
									isDeleteOpen={isDeleteOpen}
									setIsDeleteOpen={setIsDeleteOpen}
									deleteFunction={deleteCasteSubmit}
								/>
							</div>
							<div className='col-6 position-absolute bottom-0 start-0 p-3'>
								<Button
									color='danger'
									className='w-100'
									icon='Delete'
									onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
									Delete
								</Button>
							</div>
							<div className='col-6 position-absolute bottom-0 end-0 p-3'>
								<Button
									color='info'
									icon='Save'
									type='submit'
									className='w-100'
									isDisable={
										!editPositionForm.isValid && !!editPositionForm.submitCount
									}>
									{' '}
									Update
								</Button>
							</div>
						</div>
					</OffCanvas>
				</Page>
				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
}
export default AddPosition;
