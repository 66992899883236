import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OpenCardComponent from '../../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../../common/components/SearchableSelect';
import AuthContext from '../../../../contexts/authContext';
import useDarkMode from '../../../../hooks/useDarkMode';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../../services/AlertService';
import { getLicenseKey } from '../../../../services/application.settings';
import {
	pictNotLoading,
	profilePic,
	onlyAllowNumber,
	getStandardDetails,
	getMedium,
} from '../../../../services/common.service';
import {
	getStudentFeeDetails,
	addStudentAdvanceFee,
	getNotAssignedTermFees,
	getStudentFeeDetailsHistory,
} from '../../../../services/fee.service';
import { showLoader, TableLoader } from '../../../../services/loader.services';
import {
	getProfileForStudent,
	getAllStudent,
	getStudentDetailsBySectionDetailsId,
} from '../../../../services/student.service';
import { toasts } from '../../../../services/toast.service';
import Button from '../../../bootstrap/Button';
import Card, {
	CardHeader,
	CardLabel,
	CardTitle,
	CardActions,
	CardBody,
} from '../../../bootstrap/Card';
import Collapse from '../../../bootstrap/Collapse';
import OffCanvas, {
	OffCanvasHeader,
	OffCanvasTitle,
	OffCanvasBody,
} from '../../../bootstrap/OffCanvas';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Textarea from '../../../bootstrap/forms/Textarea';
import Icon from '../../../icon/Icon';
import Label from '../../../bootstrap/forms/Label';
import Input from '../../../bootstrap/forms/Input';
import { getBatchMasterDetails, getSectionDetails } from '../../../../services/master.service';
import { addOrUpdStudentFeeConcession, addStudentManualFeeConcession } from '../../../../services/concession.service';
import NoDataMsg from '../../../../common/components/NoDataMsg';
import Modal, { ModalHeader, ModalBody, ModalTitle } from '../../../bootstrap/Modal';

const ManualConcession = () => {
	useEffect(() => {
		getBatchList();
		getAllStudentDetails();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true);
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();

	const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);
	const [dataSuccess, setDataSuccess] = useState(false);

	const [standardName, setStandardName] = useState('')
	const [sectionName, setSectionName] = useState('')
	const [feeType, setFeeType] = useState('')
	const [editConcessionFeesAmount, setEditConcessionFeesAmount] = useState(false)
	const [isDelete, setIsDelete] = useState(false)
	const [viewUpdateHistory, setViewUpdateHistory] = useState(false)
	const [balance, setBAlance] = useState<any>();
	const [editTotal, setEditTotal] = useState(0);
	const [editConcession, setEditConcession] = useState(0);
	const [editFeesReceived, setEditFeesReceived] = useState(0);

	const [studentProfileData, setStudentProfileData] = useState<any>([]);
	const [allStudentData, setAllStudentData] = useState<any>([]);
	const [studentDetailsData, setStudentDetailsData] = useState<any>([]);
	const [studentFeeDetails, setStudentFeeDetails] = useState<any>([]);
	const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([]);
	const [concessionDetails, setConcessionDetails] = useState<any>([]);
	const [sectionDetailsData, setSectionDetailsData] = useState<any>([]);
	const [studentFeeDetailsHistoryData, setStudentFeeDetailsHistoryData] = useState<any>([])

	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [mediumId, setMediumId] = useState<any>('');
	const [standardData, setStandardData] = useState<any>([]);
	const [mediumData, setMediumData] = useState<any>([]);
	const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [isRemove, setIsRemove] = useState(0);
	const [dataStatus, setDataStatus] = useState(false)

	const [termFeesForTutionFeesData, setTermFeesForTutionFeesData] = useState<any>([]);
	const [termFeesForTransportFeesData, setTermFeesForTransportFeesData] = useState<any>([]);
	const [advanceTutionFeesNoData, setAdvanceTutionFeesNoData] = useState('');
	const [advanceTransportFeesNoData, setAdvanceTransportFeesNoData] = useState('');

	const [studentAdvanceFee, setStudentAdvanceFee] = useState<any>([]);
	const [isLoader, setIsLoader] = useState(false);
	const [noDataMsgForStudentFeeDetails, setNoDataMsgForStudentFeeDetails] = useState<any>('');

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const [addAdvanceFeeOffCanvas, setAddAdvanceFeeOffCanvas] = useState(false);

	const approvedConcessionForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			remarks: '',
		},
		onSubmit: (values) => { },
	});

	const [batchMasterId, setBatchMasterId] = useState<any>('');
	const [batchMasterData, setBatchMasterData] = useState<any>([]);

	const editConcessionFeesForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			studentFeeDetailsId: '',
			totalFees: '',
			feesReceived: '',
			concessionFees: '',
			balance: '',
			remarks: ''

		},
		validate: (values) => { },
		//validateOnChange: false,
		onSubmit: (values) => { addOrUpdStudentConcessionFeeDetailsSubmit() },
	});

	function onActivate(i: any) {
		if (i != undefined) {

			let selectedFees = i
			setStandardName(selectedFees.standardName)
			setSectionName(selectedFees.sectionName)
			setFeeType(selectedFees.feeType)
			setEditConcessionFeesAmount(true)
			setBAlance(selectedFees.balanceToBePaid)
			setEditConcession(selectedFees.concession)
			setEditTotal(selectedFees.totalFees)
			setEditFeesReceived(selectedFees.totalFeesReceived)

			editConcessionFeesForm.setValues({
				studentFeeDetailsId: selectedFees.studentFeeDetailsId,
				totalFees: selectedFees.totalFees,
				feesReceived: selectedFees.totalFeesReceived,
				concessionFees: selectedFees.concession,
				balance: selectedFees.balanceToBePaid,
				remarks: selectedFees.remarks
			});
		}
	}

	function viewHistory(i: any) {
		if (i != undefined) {

			let selectedFeesHistory = i

			setFeeType(selectedFeesHistory.feeType)
			getStudentFeeDetailsHistoryList(selectedFeesHistory.studentFeeDetailsId, 1)
			setViewUpdateHistory(true);
		}
	}

	const selectBatch = (e: any) => {
		setStandardDetailsId('');
		setSectionDetailsId('');
		setMediumId('');
		let batchMasterId = e;
		setBatchMasterId(batchMasterId);
		getMediumList();
	};

	const selectMedium = (e: any) => {
		getStandardList();
		setStandardDetailsId('');
		setSectionDetailsId('');
		let mediumId = e;
		setMediumId(mediumId);
	};

	const selectStandard = (e: any) => {
		setSectionDetailsId('');
		let standardDetailsId = e;
		setStandardDetailsId(standardDetailsId);
		if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
			getSectionDetailsList(mediumId?.value, standardDetailsId?.value);
		}
	};

	const selectSection = (e: any) => {
		setStudentDetailsId('');
		let sectionDetailsId = e;
		setSectionDetailsId(sectionDetailsId);
		if (batchMasterId?.value != undefined && sectionDetailsId?.value != undefined) {
			getStudentList(batchMasterId?.value, sectionDetailsId?.value);
		}
	};

	const selectStudent = (e: any) => {
		let studentDetailsId = e;
		setStudentDetailsId(studentDetailsId);
	};

	function viewParticularStudent() {
		setDataSuccess(false);
		setIsLoader(true);
		getStudentProfile(studentDetailsId?.value);
		getStudentFeeList(userTypeId, studentDetailsId?.value);
	}

	function getStudentProfile(studentDetailsId: number) {
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile;
					if (data != undefined) {
						setStudentProfileData(data);
						setStandardName(data[0].standardName)
						setSectionName(data[0].sectionName)
						setDataSuccess(true);
						setIsOpenListCard(false);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
					setDataSuccess(false);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSectionDetailsList(mediumId: any, standardDetailsId: any) {
		getSectionDetails(
			mediumId,
			standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sectionDetails;
					if (data != undefined) {
						setSectionDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setSectionDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStudentList(batchMasterId: any, sectionDetailsId: any) {
		getStudentDetailsBySectionDetailsId(
			batchMasterId,
			sectionDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetailsBySectionDetailsId;
					if (data != undefined) {
						setStudentDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setStudentDetailsData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getAllStudentDetails() {
		getAllStudent(userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetails;
					if (data != undefined) {
						setAllStudentData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAllStudentData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function selectStudentCard(type: any) {
		if (isStudentOrClassWise) {
			getMediumList();
		}
		if (type == 'isSelect') {
			setIsStudentOrClassWise(!isStudentOrClassWise);
		}
		setDataSuccess(false);
		setStudentDetailsId('');
		setMediumId('');
		setStandardDetailsId('');
		setSectionDetailsId('');
		setStudentDetailsId('');
		setStudentDetailsData([]);
	}

	function getStudentFeeList(userTypeId: number, studentDetailsId: number) {
		setIsLoader(true);
		getStudentFeeDetails(
			userTypeId,
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentFeeDetails[0]['feeType'];
					if (data != undefined) {
						setStudentFeeDetails(data);
						setIsLoader(false);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setNoDataMsgForStudentFeeDetails(response.data.message);
					setIsLoader(false);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setIsLoader(false);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setIsLoader(false);
			},
		);
	}

	const updateFeesReceivedValue = (cell: number, amount: any) => {
		studentFeeDetails.forEach((feeType: any) => {
			let feesData = feeType.studentFees;
			const newData: any = [];

			feesData.forEach((item: any) => {
				if (item.studentFeeDetailsId == cell) {
					item.amount = amount;
				}
				newData.push(item);
				setFilteredDataToRefresh(newData);
			});
		});

		if (concessionDetails.length == 0) {
			const obj = {
				studentFeeDetailsId: cell,
				amount: amount,
			};
			concessionDetails.push(obj);
		} else {
			let selectedItem: any;
			concessionDetails.forEach((item: any, index: any) => {
				if (item['studentFeeDetailsId'] == cell) {
					selectedItem = item;
					selectedItem['amount'] = amount;
				}
			});
			if (selectedItem != undefined) {
				selectedItem['amount'] = amount;
			} else {
				var obj;
				obj = {
					studentFeeDetailsId: cell,
					amount: amount,
				};
				concessionDetails.push(obj);
			}
		}
	};

	function setStudentFeeConcessionDetails() {
		return {
			studentDetailsId: studentDetailsId?.value,
			concessionTypeId: 3,
			concessionDetails: concessionDetails,
			remarks: approvedConcessionForm.values.remarks,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function payFeesSubmit() {
		showLoader(true);
		if (approvedConcessionForm.isValid) {
			let approvedConcessionPostData = setStudentFeeConcessionDetails();
			addStudentManualFeeConcession(
				approvedConcessionPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getStudentFeeList(userTypeId, studentDetailsId?.value);
						approvedConcessionForm.resetForm();
						setConcessionDetails([]);
						setDataSuccess(false);
						setIsOpenListCard(true);
						setIsStudentOrClassWise(true);
						selectStudentCard('isSubmit');
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (approvedConcessionForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function selectTermFee() {
		getTermFeesForTutionFees(studentDetailsId?.value, 1);
		getTermFeesForTransportFees(studentDetailsId?.value, 4);
	}

	function addStudentAdvanceFeeForTutionValue(
		cell: any,
		feeMasterId: number,
		feeTypeId: number,
		standardDetailsId: any,
		feeAmount: number,
	) {
		const newData: any = [];

		termFeesForTutionFeesData.forEach((item: any) => {
			if (item.termDetailsId == cell) {
				item.isChecked = true;
			}
			newData.push(item);
			setFilteredDataToRefresh(newData);
		});

		if (studentAdvanceFee.length == 0) {
			var obj;
			obj = {
				termDetailsId: cell,
				standardDetailsId: standardDetailsId,
				fmOrTrasportDetailsId: feeMasterId,
				feeTypeId: feeTypeId,
				amount: feeAmount,
			};
			studentAdvanceFee.push(obj);
		} else {
			let selectedItem: any;
			studentAdvanceFee.forEach((item: any, index: number) => {
				if (item['termDetailsId'] == cell) {
					selectedItem = item;
				}
			});
			if (selectedItem != undefined) {
				const newData: any = [];

				termFeesForTutionFeesData.forEach((item: any) => {
					if (item.termDetailsId == cell) {
						item.isChecked = false;
					}
					newData.push(item);
					setFilteredDataToRefresh(newData);
				});

				studentAdvanceFee.splice(studentAdvanceFee.indexOf(selectedItem), 1);
				selectedItem['termDetailsId'] = cell;
			} else {
				var obj;
				obj = {
					termDetailsId: cell,
					standardDetailsId: standardDetailsId,
					fmOrTrasportDetailsId: feeMasterId,
					feeTypeId: feeTypeId,
					amount: feeAmount,
				};
				studentAdvanceFee.push(obj);
			}
		}
	}

	function addStudentAdvanceFeeForTrasportValue(
		cell: number,
		fmOrTrasportDetailsId: any,
		feeTypeId: number,
		standardDetailsId: any,
		feeAmount: number,
	) {
		const newData: any = [];

		termFeesForTransportFeesData.forEach((item: any) => {
			if (item.termDetailsId == cell) {
				item.fmOrTrasportDetailsId = fmOrTrasportDetailsId;
				item.isChecked = true;
			}
			newData.push(item);
			setFilteredDataToRefresh(newData);
		});

		if (studentAdvanceFee.length == 0) {
			var obj;
			obj = {
				termDetailsId: cell,
				fmOrTrasportDetailsId: fmOrTrasportDetailsId,
				standardDetailsId: standardDetailsId,
				feeTypeId: feeTypeId,
				amount: feeAmount,
			};
			studentAdvanceFee.push(obj);
		} else {
			let selectedItem: any;
			studentAdvanceFee.forEach((item: any, index: number) => {
				if (item['termDetailsId'] == cell) {
					selectedItem = item;
				}
			});
			if (selectedItem != undefined) {
				studentAdvanceFee.splice(studentAdvanceFee.indexOf(selectedItem), 1);

				const newData: any = [];

				termFeesForTransportFeesData.forEach((item: any) => {
					if (item.termDetailsId == cell) {
						item.fmOrTrasportDetailsId = fmOrTrasportDetailsId;
						item.isChecked = false;
					}
					newData.push(item);
					setFilteredDataToRefresh(newData);
				});

				selectedItem['termDetailsId'] = cell;
			} else {
				var obj;
				obj = {
					termDetailsId: cell,
					fmOrTrasportDetailsId: fmOrTrasportDetailsId,
					standardDetailsId: standardDetailsId,
					feeTypeId: feeTypeId,
					amount: feeAmount,
				};
				studentAdvanceFee.push(obj);
			}
		}
	}

	function setEditConcessionFeeDetails() {
		return {
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
			studentFeeDetailsId: editConcessionFeesForm.values.studentFeeDetailsId,
			totalFees: editConcessionFeesForm.values.totalFees,
			feeReceived: editConcessionFeesForm.values.feesReceived,
			concession: editConcessionFeesForm.values.concessionFees != '' ? editConcessionFeesForm.values.concessionFees : 0,
			balanceToBePaid: balance,
			isRemove: isRemove,
			remarks: editConcessionFeesForm.values.remarks
		}
	}

	function addOrUpdStudentConcessionFeeDetailsSubmit() {
		showLoader(true)
		if (editConcessionFeesForm.isValid) {
			let addConcessionPostData = setEditConcessionFeeDetails();
			console.log(addConcessionPostData);
			addOrUpdStudentFeeConcession(addConcessionPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						closeAndReset();
						getStudentFeeList(userTypeId, studentDetailsId?.value);
					}
					else if (data.success == false) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false)
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false)
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (editConcessionFeesForm.isValid == false) {
			showLoader(false)
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function getTermFeesForTutionFees(studentDetailsId: number, feeTypeId: number) {
		getNotAssignedTermFees(
			studentDetailsId,
			feeTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.notAssignedTermFees;
					if (data != undefined) {
						setTermFeesForTutionFeesData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setTermFeesForTutionFeesData([]);
					setAdvanceTutionFeesNoData(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getTermFeesForTransportFees(studentDetailsId: number, feeTypeId: number) {
		getNotAssignedTermFees(
			studentDetailsId,
			feeTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.notAssignedTermFees;
					if (data != undefined) {
						setTermFeesForTransportFeesData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setTermFeesForTransportFeesData([]);
					setAdvanceTransportFeesNoData(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function setCollectAdvanceFeeDetails() {
		return {
			studentAdvanceFee: studentAdvanceFee,
			studentDetailsId: studentDetailsId?.value,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function payAdvanceFeesSubmit() {
		if (studentAdvanceFee.length > 0) {
			showLoader(true);
			let payAdvanceFeesPostData = setCollectAdvanceFeeDetails();
			addStudentAdvanceFee(
				payAdvanceFeesPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getStudentFeeList(userTypeId, studentDetailsId?.value);
						setStudentAdvanceFee([]);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (studentAdvanceFee.length == 0) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function getStudentFeeDetailsHistoryList(studentFeeDetailsId: any, isFeesDetails: any) {
		setDataStatus(false)
		getStudentFeeDetailsHistory(
			studentFeeDetailsId, isFeesDetails,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentFeeDetailsHistory;
					if (data != undefined) {
						setStudentFeeDetailsHistoryData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataStatus(true)

					} else {
						setDataStatus(true)
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, 'Error');
					setStudentFeeDetailsHistoryData([]);
					setNoDataMsg(response.data.message);
					setDataStatus(true)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataStatus(true)
				}
			},
			(error) => {
				setDataStatus(true)
				toasts(error, 'Error');
			},
		);
	}

	function EditConcession(e: any) {
		const c = e.target.value
		setEditConcession(c)
		balanceCalculation(editTotal, editFeesReceived, c)
	}

	function balanceCalculation(count1: any, count2: any, count3: any) {
		const b = count1 - count2 - count3;
		setBAlance(b)
	}

	function closeAndReset() {
		setStudentAdvanceFee([]);
		setAddAdvanceFeeOffCanvas(false);
	}

	return (
		<PageWrapper title={`Approved Concession`}>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-9'>
								<CardTitle tag='div' className='h5'>
									Manual Concession
								</CardTitle>
							</CardLabel>
							<div className='col-lg-3'>
								<CardActions>
									<Button
										icon='Contacts'
										color='info'
										isLight={isStudentOrClassWise ? false : true}
										onClick={() => selectStudentCard('isSelect')}>
										Student
									</Button>

									<Button
										icon='LocalPolice'
										color='info'
										isLight={isStudentOrClassWise ? true : false}
										onClick={() => selectStudentCard('isSelect')}>
										Class Wise
									</Button>
								</CardActions>
							</div>
						</CardHeader>
						{isStudentOrClassWise ? (
							<CardBody>
								<div className='row'>
									<div className='col-4 mt-3'>
										<FormGroup id='studentDetailsId' label='Student' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Student'
												onChange={selectStudent}
												value={studentDetailsId}
												list={allStudentData.map((data: any) => ({
													value: data.studentDetailsId,
													label: data.studentNameAndAdmissionNo,
												}))}
											/>
										</FormGroup>
									</div>

									<div className='col-3 mt-4'>
										<Button
											icon='ArrowDownward'
											color='primary'
											isDisable={studentDetailsId?.value == undefined}
											onClick={viewParticularStudent}>
											View
										</Button>
									</div>
								</div>
							</CardBody>
						) : (
							<CardBody>
								<div className='row'>
									<div className='col-md-2'>
										<FormGroup id='batchMasterId' label='Batch' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Batch'
												placeholder='Batch'
												onChange={selectBatch}
												value={batchMasterId}
												list={batchMasterData.map((data: any) => ({
													value: data.batchMasterId,
													label: data.batchName,
												}))}
												required
											/>
										</FormGroup>
									</div>

									{batchMasterId?.value != undefined ? (
										<div className='col-2'>
											<FormGroup id='mediumId' label='Medium' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Medium'
													placeholder='Select Standard'
													onChange={selectMedium}
													value={mediumId}
													list={mediumData.map((data: any) => ({
														value: data.mediumId,
														label: data.medium,
													}))}
													required
												/>
											</FormGroup>
										</div>
									) : null}
									{mediumId?.value != undefined ? (
										<div className='col-2'>
											<FormGroup
												id='standardDetailsId'
												label='Standard'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Standard'
													placeholder='Select Standard'
													onChange={selectStandard}
													value={standardDetailsId}
													list={standardData.map((data: any) => ({
														value: data.standardDetailsId,
														label: data.standardName,
													}))}
													required
												/>
											</FormGroup>
										</div>
									) : null}
									{standardDetailsId?.value != undefined ? (
										<div className='col-2'>
											<FormGroup
												id='sectionDetailsId'
												label='Section'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Section'
													onChange={selectSection}
													value={sectionDetailsId}
													list={sectionDetailsData.map((data: any) => ({
														value: data.sectionDetailsId,
														label: data.sectionName,
													}))}
												/>
											</FormGroup>
										</div>
									) : null}

									{sectionDetailsId?.value != undefined ? (
										<div className='col-3'>
											<FormGroup
												id='studentDetailsId'
												label='Student'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Student'
													onChange={selectStudent}
													value={studentDetailsId}
													list={studentDetailsData.map((data: any) => ({
														value: data.studentDetailsId,
														label: data.studentNameAndAdmissionNo,
													}))}
												/>
											</FormGroup>
										</div>
									) : null}

									{studentDetailsId?.value != undefined ? (
										<div className='col-1 mt-2'>
											<Button
												icon='ArrowDownward'
												color='primary'
												isDisable={studentDetailsId?.value == undefined}
												onClick={viewParticularStudent}>
												View
											</Button>
										</div>
									) : null}
								</div>
							</CardBody>
						)}
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? (
					<>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info' className='col-lg-10'>
									<CardTitle tag='div' className='h5'>
										Add Manual Concession
									</CardTitle>
								</CardLabel>

								<div className='col-lg-2 d-flex justify-content-end'>
									<CardActions>
										<Button
											color='storybook'
											icon='Add'
											isLight
											onClick={() => {
												setAddAdvanceFeeOffCanvas(true);
												selectTermFee();
											}}>
											Add Advance Fees
										</Button>
									</CardActions>
								</div>
							</CardHeader>
							<CardBody className='table-responsive'>
								<div className="mt-2">
									{studentProfileData.map((student: any) => (
										<div className='row g-4' key={student.studentDetailsId}>
											<div className='col-xl-4'>
												<Card stretch className='border border-light rounded-1 ms-1'>
													<CardBody>
														<div className="row">
															<div className='col-9'>
																<CardLabel>
																	<CardTitle tag='div' className='h5 text-success'>{student.studentName}</CardTitle>
																</CardLabel>
																<div className='fw-bold text-muted fs-6 mb-0'>{student.admissionNo}</div>
															</div>
															<div className='col-2'>
																<img style={{ borderRadius: '50%' }}
																	src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)}
																	height='50' width='50' onError={(e: any) => pictNotLoading(e, student.genderId)}
																/>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
											<div className='col-xl-4'>
												<Card stretch className='border border-light rounded-1'>
													<CardBody>
														<div className="row">
															<div className='col-9'>
																<CardLabel>
																	<CardTitle tag='div' className='h5 text-info'>{student.standardName} - {student.sectionName}</CardTitle>
																</CardLabel>
																<div className='fw-bold text-muted fs-6 mb-0'>Class</div>
															</div>
															<div className='col-2'>
																<Icon icon='school' size='3x' color='info' />
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
											<div className='col-xl-4'>
												<Card stretch className='border border-light rounded-1'>
													<CardBody>
														<div className="row">
															<div className='col-9'>
																<CardLabel>
																	<CardTitle tag='div' className='h5 text-danger'>{student.batchName}</CardTitle>
																</CardLabel>
																<div className='fw-bold text-muted fs-6 mb-0'>Batch</div>
															</div>
															<div className='col-2'>
																<Icon icon='school' size='3x' color='danger' />
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
										</div>))}
								</div>
								{studentFeeDetails != '' ? (
									<>
										{studentFeeDetails.map((feeType: any) => (
											<div key={feeType.feeTypeId}>
												<table className='table table-modern table-hover text-center'>
													<thead>
														<tr className='table-primary'>
															<th
																scope='col'
																style={{ width: '20%' }}>
																{feeType.feeType}
															</th>
															<th
																scope='col'
																style={{ width: '15%' }}>
																TOTAL FEE
															</th>
															<th
																scope='col'
																style={{ width: '15%' }}>
																TOTAL RECEIVED
															</th>
															<th
																scope='col'
																style={{ width: '10%' }}>
																CONCESSION
															</th>
															<th
																scope='col'
																style={{ width: '15%' }}>
																BALANCE
															</th>
															<th
																scope='col'
																style={{ width: '15%' }}>
																AMOUNT
															</th>
															{userTypeId == 1 ? (
																<th
																	scope='col'
																	style={{ width: '15%' }}>
																	Action
																</th>
															) : null}
														</tr>
													</thead>
													<tbody className='align-middle'>
														{feeType.studentFees.filter((item:any) => item.standardDetailsId == studentProfileData[0].standardDetailsId).map(
															(feesData: any) => (
																<tr
																	key={
																		feesData.studentFeeDetailsId
																	}>
																	<td className='fs-6 text-center'>
																		{feesData.termName}
																		{feeType.feeTypeId === 4 ? (
																			<p className='text-info'>
																				({feesData.areaName}
																				)
																			</p>
																		) : feeType.feeTypeId ===
																			14 ? (
																			<p className='text-info'>
																				(
																				{
																					feesData.extraFeeType
																				}
																				)
																			</p>
																		) : (
																			''
																		)}
																	</td>
																	<td className='fs-6'>
																		{feesData.totalFees}
																	</td>
																	<td className='fs-6 text-success'>
																		{feesData.totalFeesReceived}
																	</td>
																	<td className='fs-6 text-info'>
																		{feesData.concession}
																	</td>
																	<td className='fs-6 text-danger'>
																		{feesData.balanceToBePaid}
																	</td>
																	{feesData.balanceToBePaid ==
																		0 ? (
																		<td className='fs-6 fw-bold text-success'>
																			PAID
																		</td>

																	) : (
																		<td>
																			<FormGroup id='amount'>
																				<Input
																					className='text-center w-100'
																					placeholder='0'
																					value={
																						feesData.amount
																					}
																					onInput={(
																						e: any,
																					) =>
																						updateFeesReceivedValue(
																							feesData.studentFeeDetailsId,
																							e.target
																								.value,
																						)
																					}
																					onKeyDown={(
																						e: any,
																					) =>
																						onlyAllowNumber(
																							e,
																						)
																					}
																				/>
																			</FormGroup>
																		</td>

																	)}
																	{userTypeId == 1 ?
																		<td className="d-print-none">
																			<div className='d-flex justify-content-around'>
																				<Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(feesData) }} />
																				<Button color='success' isLight icon='History' aria-label='Edit' onClick={() => { viewHistory(feesData) }} />
																			</div>
																		</td>
																		: null
																	}
																</tr>
															),
														)}
													</tbody>
												</table>
												<br />
											</div>
										))}
									</>
								) : (
									<p className='text-center'>{noDataMsgForStudentFeeDetails}</p>
								)}
							</CardBody>
							<CardBody>
								<div className='row h-100 pb-3'>
									<div className='col-lg-5'>
										<FormGroup id='remarks' label='Narration' isFloating>
											<Textarea
												placeholder='Enter Narration'
												onChange={approvedConcessionForm.handleChange}
												value={approvedConcessionForm.values.remarks}
											/>
										</FormGroup>
									</div>
									<div className='col-lg-3 mt-2'>
										<Button
											color='info'
											icon='Save'
											type='submit'
											isDisable={concessionDetails.length == 0}
											onClick={payFeesSubmit}>
											Submit
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</>
				) : null}
			</Page>

			<OffCanvas
				setOpen={setAddAdvanceFeeOffCanvas}
				isOpen={addAdvanceFeeOffCanvas}
				titleId='addAdvanceFeeOffCanvas'
				placement='end'>
				<OffCanvasHeader setOpen={setAddAdvanceFeeOffCanvas} onClick={closeAndReset}>
					<OffCanvasTitle id='addAdvanceFeeOffCanvas'>Add Advance Fee</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row'>
						<div className='col-12'>
							<Card
								className={`bg-l${darkModeStatus ? 'o25' : '25'}-success bg-l${darkModeStatus ? 'o50' : '10'
									}-success-hover transition-base rounded-2 mb-4`}
								shadow='sm'>
								<CardBody>
									<div className='d-flex align-items-center pb-3'>
										<div className='flex-shrink-0 ms-2'>
											<Icon icon='PushPin' size='3x' color='danger' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<span className='text-muted'>
												If advance fees not listed. <br /> Please add {''}
												<Icon
													icon='East'
													color='danger'
													className='mx-1'
													size='lg'
												/>
												<Button
													color='link'
													tag='a'
													to={`../general/feeMaster`}>
													Click Here
												</Button>
											</span>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>

						{termFeesForTutionFeesData != '' ? (
							<div className='col-md-12 mt-2'>
								<Card shadow='none' borderSize={0} stretch>
									<Label className='col-md-12 p-2 h5'>
										<strong className='text-dark'>
											<Icon
												icon='pending_actions'
												color='success'
												className='mx-1'
												size='2x'
											/>{' '}
											Tution Fees
										</strong>
									</Label>

									<div className='table-responsive'>
										<table className='table table-modern table-hover text-center'>
											<thead>
												<tr className='table-primary text-center'>
													<th></th>
													<th>TERM </th>
													<th>AMOUNT </th>
												</tr>
											</thead>
											<tbody>
												{termFeesForTutionFeesData.map(
													(termFeesForTution: any) => (
														<tr
															className='text-center'
															key={termFeesForTution.termDetailsId}>
															<td width={'20px'}>
																<ChecksGroup>
																	<Checks
																		className='border border-info'
																		checked={
																			termFeesForTution.isChecked
																		}
																		onChange={(e) =>
																			addStudentAdvanceFeeForTutionValue(
																				termFeesForTution.termDetailsId,
																				termFeesForTution.feeMasterId,
																				termFeesForTution.feeTypeId,
																				termFeesForTution.standardDetailsId,
																				termFeesForTution.feeAmount,
																			)
																		}
																	/>
																</ChecksGroup>
															</td>
															<td>{termFeesForTution.termName}</td>
															<td>{termFeesForTution.feeAmount}</td>
														</tr>
													),
												)}
											</tbody>
										</table>
									</div>
								</Card>
							</div>
						) : // <div className='text-danger col-12 d-flex justify-content-center'>
							//     <p className='fw-bold fs-5 mb-0'>{advanceTutionFeesNoData}</p>
							// </div>
							null}

						{termFeesForTransportFeesData != '' ? (
							<div className='col-md-12 mt-2'>
								<Card shadow='none' borderSize={0} stretch>
									<Label className='col-md-12 p-2 h5'>
										<strong className='text-dark'>
											<Icon
												icon='BusAlert'
												color='success'
												className='mx-1'
												size='2x'
											/>{' '}
											Transport Fees
										</strong>
									</Label>

									<div className='table-responsive'>
										<table className='table table-modern table-hover text-center'>
											<thead>
												<tr className='table-primary text-center'>
													<th></th>
													<th>TERM </th>
													<th>AMOUNT </th>
												</tr>
											</thead>
											<tbody>
												{termFeesForTransportFeesData.map(
													(termFeesForTransport: any) => (
														<tr
															className='text-center'
															key={
																termFeesForTransport.termDetailsId
															}>
															<td width={'20px'}>
																<ChecksGroup>
																	<Checks
																		className='border border-info'
																		checked={
																			termFeesForTransport.isChecked
																		}
																		onChange={(e) =>
																			addStudentAdvanceFeeForTrasportValue(
																				termFeesForTransport.termDetailsId,
																				termFeesForTransport.studentTrasportDetailsId,
																				termFeesForTransport.feeTypeId,
																				termFeesForTransport.standardDetailsId,
																				termFeesForTransport.feeAmount,
																			)
																		}
																	/>
																</ChecksGroup>
															</td>
															<td>{termFeesForTransport.termName}</td>
															<td>
																{termFeesForTransport.feeAmount}
															</td>
														</tr>
													),
												)}
											</tbody>
										</table>
									</div>
								</Card>
							</div>
						) : // <div className='text-danger col-12 d-flex justify-content-center'>
							//     <p className='fw-bold fs-5 mb-0'>{advanceTransportFeesNoData}</p>
							// </div>
							null}
					</div>
				</OffCanvasBody>
				{termFeesForTutionFeesData != '' || termFeesForTransportFeesData != '' ? (
					<div className='row m-0'>
						<div className='col-12 p-3'>
							<Button
								color='info'
								className='w-100'
								type='submit'
								icon='Save'
								onClick={payAdvanceFeesSubmit}>
								Save
							</Button>
						</div>
					</div>
				) : null}
			</OffCanvas>

			<OffCanvas
				setOpen={setEditConcessionFeesAmount}
				isOpen={editConcessionFeesAmount}
				isNotClose
				titleId='editConcessionFeesAmount'
				placement='end' tag="form" noValidate onSubmit={editConcessionFeesForm.handleSubmit}>
				<OffCanvasHeader setOpen={setEditConcessionFeesAmount} onClick={() => setEditConcessionFeesAmount(false)}>
					<OffCanvasTitle id="editConcessionFeesAmount">Update Concession<br />
						<span className='text-info'>{feeType}{''}<span className='text-success' style={{ marginLeft: '5px' }}>({standardName} - {sectionName})</span></span>
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup id='totalFees' label='Total Fees'>
								<Input placeholder='Enter Total Fees'
									onChange={editConcessionFeesForm.handleChange}
									value={editConcessionFeesForm.values.totalFees}
									onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='feesReceived' label='Fees Received'>
								<Input placeholder='Enter Fees Received'
									onChange={editConcessionFeesForm.handleChange}
									value={editConcessionFeesForm.values.feesReceived}
									onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='concessionFees' label='Concession Fees'>
								<Input placeholder='Enter Concession Fees'
									onChange={editConcessionFeesForm.handleChange}
									value={editConcessionFeesForm.values.concessionFees}
									onInput={EditConcession}
									onKeyDown={(e: any) => onlyAllowNumber(e)} />
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='balance' label='Balance'>
								<Input placeholder='Enter Balance'
									onChange={editConcessionFeesForm.handleChange}
									value={balance}
									onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='remarks' label='Remarks'>
								<Textarea rows={3} placeholder='Enter Remarks'
									onChange={editConcessionFeesForm.handleChange}
									value={editConcessionFeesForm.values.remarks} />
							</FormGroup>
						</div>
					</div>
				</OffCanvasBody>
				<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
					<div className='row m-0'>
						<div className='col-6 p-3'>
							<Button color='danger' className='w-100' icon="Delete" onClick={() => { setIsDelete(true); setIsRemove(1) }}>
								Delete
							</Button>
						</div>
						<div className='col-6 p-3'>
							<Button color='info' className='w-100' type="submit" icon="Save"> Update</Button>
						</div>
					</div>
				</div>
			</OffCanvas>

			<Modal
				setIsOpen={setIsDelete}
				isOpen={isDelete}
				titleId='isDelete'
				isStaticBackdrop
				size='sm'>
				<ModalHeader setIsOpen={() => { { setIsDelete(false); setIsRemove(0) }; setEditConcessionFeesAmount(true) }}>
					{''}
				</ModalHeader>
				<ModalBody>
					<div className="row g-3">
						<div className="col-12 d-flex justify-content-center">
							<span><b>Are you sure want to Delete ? {''}</b><br />
								<span className='text-info'>{feeType}{''}<span className='text-success' style={{ marginLeft: '5px' }}>({standardName})</span></span>
							</span>
						</div>
						<div className='col-12'>
							<FormGroup id='remarks' label='Remarks'>
								<Textarea rows={3} onChange={editConcessionFeesForm.handleChange}
									value={editConcessionFeesForm.values.remarks}
									placeholder="Enter Remarks" />
							</FormGroup>
						</div>
						<div className='col-12'>
							<div className='d-flex gap-2 justify-content-end'>
								<Button color='danger' size={'sm'} onClick={editConcessionFeesForm.handleSubmit}>
									Yes
								</Button>

								<Button color='info' size={'sm'} onClick={() => { setIsDelete(false); setIsRemove(0); setEditConcessionFeesAmount(true); editConcessionFeesForm.values.remarks == '' }}>
									No
								</Button>
							</div>
						</div>
						<div className='col-10'></div>
					</div>
				</ModalBody>
			</Modal>

			<Modal
				setIsOpen={setViewUpdateHistory}
				isOpen={viewUpdateHistory}
				titleId='viewUpdateHistory'
				isStaticBackdrop
				isScrollable
				size='lg'>
				<ModalHeader className="modal-header" setIsOpen={() => { setViewUpdateHistory(false) }}>
					<ModalTitle id='viewUpdateHistory' >Concession Update History -
						<span >
							<strong className='ms-3 fw-bold fs-5'>{feeType} {''}</strong>
						</span>
						<span className='border border-success border-2 text-success fw-bold px-2 py-1 rounded'>
							{standardName} - {sectionName}
						</span>
					</ModalTitle>
				</ModalHeader>
				<ModalBody >
					<div className='row g-4'>
						<div className='col-lg-12'>
							<table className='table table-modern table-hover text-center scrollable' >
								<thead className='table-info'>
									<tr >
										<th scope='col' style={{ width: '15%' }}>S.NO</th>
										<th scope='col' style={{ width: '15%' }}>TOTAL FEE</th>
										<th scope='col' style={{ width: '15%' }}>TOTAL RECEIVED</th>
										<th scope='col' style={{ width: '10%' }}>CONCESSION</th>
										<th scope='col' style={{ width: '15%' }}>BALANCE</th>
										<th scope='col' style={{ width: '15%' }}>STATUS</th>
										<th scope='col' style={{ width: '15%' }}>DATE</th>
										<th scope='col' style={{ width: '15%' }}>REMARKS</th>
									</tr>
								</thead>
								<tbody className='align-middle'>
									{!dataStatus ?
										<tr>
											<td className='align-self-center' colSpan={8}>
												<TableLoader />
											</td>
										</tr>
										:
										<>
											{studentFeeDetailsHistoryData != '' ? (
												<>
													{studentFeeDetailsHistoryData.map((feesHistoryData: any) => (
														<tr key={feesHistoryData.studentFeeDetailsHistoryId}>
															<td>{feesHistoryData.sno}</td>
															<td className='align-self-center'>{feesHistoryData.totalFees}</td>
															<td className='align-self-center'>{feesHistoryData.feeReceived}</td>
															<td className='align-self-center'>{feesHistoryData.concession}</td>
															<td className='align-self-center'>{feesHistoryData.balanceToBePaid}</td>
															<td className='align-self-center'>{feesHistoryData.status}</td>
															<td className='align-self-center'>{feesHistoryData.date}</td>
															<td className='align-self-center'>{feesHistoryData.remarks}</td>
														</tr>))}
												</>
											) : (
												<NoDataMsg columnsCount={8} msg={noDataMsg} />
											)}
										</>
									}
								</tbody>
							</table>
						</div>
					</div>
				</ModalBody>
			</Modal>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};
export default ManualConcession;
