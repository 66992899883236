import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../../contexts/authContext";
import useDarkMode from "../../../../hooks/useDarkMode";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import Collapse from "../../../bootstrap/Collapse";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import { convertDateToEpoch, getColumnsForDataTable, getCurrentDateAndTime, getMedium, getStandardDetails, updateFilter } from "../../../../services/common.service";
import { toasts } from "../../../../services/toast.service";
import { getBatchMasterDetails, getClassWiseStudentHomeWorkStatus, getSectionDetails } from "../../../../services/master.service";
import Input from "../../../bootstrap/forms/Input";
import { useFormik } from "formik";
import Icon from "../../../icon/Icon";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import useSortableData from "../../../../hooks/useSortableData";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../services/ExportService";
import { useReactToPrint } from "react-to-print";
import NoDataMsg from "../../../../common/components/NoDataMsg";

function ClassWiseHomeWorkStatus() {

    useEffect(() => {
        getBatchList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();

    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [mediumId, setMediumId] = useState<any>('')
    const [mediumData, setMediumData] = useState<any>([])
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [homeWorkDate, setHomeWorkDate] = useState<any>(getCurrentDateAndTime('date'))
    const [dataSuccess, setDataSuccess] = useState(false)
    const [studentHomeWorkStatusData, setStudentHomeWorkStatusData] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentHomeWorkStatusData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false)
    const [noDataMsg, setNoDataMsg] = useState<any>('')

    const componentRef = useRef(null);

    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    const selectBatch = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        setMediumId('')
        setDateOfHomeWork('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        getMediumList();
    }

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        setDateOfHomeWork('')
        let mediumId = e
        setMediumId(mediumId)
        getStandardList()
    }

    const selectStandard = (e: any) => {
        setSectionDetailsId('')
        setDateOfHomeWork('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
            getSectionDetailsList(mediumId?.value, standardDetailsId?.value)
        }
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        setDateOfHomeWork('')
    }

    function viewHomeWork() {
        setDataSuccess(false)
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        let dateInMilliSeconds = convertDateToEpoch(homeWorkDate);
        getStudentHomeWorkStatus(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value, dateInMilliSeconds)
    }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionDetailsList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [subjectData, setSubjectData] = useState<any>([])
    const [homeWorkStatusForExport, setHomeWorkStatusForExport] = useState<any>([])
    const [dateOfHomeWork, setDateOfHomeWork] = useState<any>('')

    function getStudentHomeWorkStatus(batchMasterId: any, standardDetailsId: any, sectionDetailsId: any, homeWorkDate: any) {
        getClassWiseStudentHomeWorkStatus(batchMasterId, standardDetailsId, sectionDetailsId, homeWorkDate,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.classWiseStudentHomeWorkStatus;
                    let dateOfHomeWork = response.data.data.classWiseStudentHomeWorkStatus[0].dateOfHomeWorkForView;
                    if (data != undefined) {

                        const fin = [{ admissionNo: '', studentName: '', dateOfHomeWork: '', statusName1: data[0].paperName1, statusName2: data[0].paperName2, statusName3: data[0].paperName3, statusName4: data[0].paperName4, statusName5: data[0].paperName5, statusName6: data[0].paperName6 }]

                        setHomeWorkStatusForExport([...fin, ...data]);
                        setDateOfHomeWork(dateOfHomeWork)

                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1
                        }
                        setStudentHomeWorkStatusData(data);
                        setSubjectData(data[0]);
                        setDataSuccess(true)
                        setIsOpenListCard(false)
                        setIsLoader(false)
                        getColumnsForTable('getClassWiseStudentHomeWorkStatus')

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setStudentHomeWorkStatusData([]);
                    getColumnsForTable('getClassWiseStudentHomeWorkStatus')
                    setDataSuccess(true)
                    setIsOpenListCard(false)
                    setIsLoader(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                    setIsOpenListCard(false)
                    setIsLoader(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
                setIsOpenListCard(false)
                setIsLoader(false)
            }
        )
    }

    function getColumnsForTable(apiName: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title='Student List'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                <CardTitle tag='div' className='h5'>Class Wise Home Work Status</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Batch' placeholder="Batch"
                                            onChange={selectBatch}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} required />
                                    </FormGroup>
                                </div>
                                {batchMasterId?.value != undefined ?
                                    <div className='col-md-2'>
                                        <FormGroup id='mediumId' label='Medium' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Medium' placeholder="Select Standard"
                                                onChange={selectMedium}
                                                value={mediumId}
                                                list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} required />

                                        </FormGroup>
                                    </div> : null
                                }
                                {mediumId?.value != undefined ? <div className='col-md-2'>
                                    <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Standard' placeholder="Standard"
                                            onChange={selectStandard}
                                            value={standardDetailsId}
                                            list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />
                                    </FormGroup>
                                </div> : null}
                                {standardDetailsId?.value != undefined ? <div className='col-md-2'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                            list={sectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, label: data.sectionName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}
                                {sectionDetailsId?.value != undefined ? <div className="col-md-2">
                                    <FormGroup id='homeWorkDate' label='Date' isFloating>
                                        <Input onChange={(e: any) => { setHomeWorkDate(e.target.value); setDateOfHomeWork(''); setDataSuccess(false) }} value={homeWorkDate}
                                            type='date' />
                                    </FormGroup>
                                </div> : null}
                                {sectionDetailsId?.value != undefined && homeWorkDate != '' ? <div className="col-md-2">
                                    <Button className='mt-2' icon='ArrowDownward' color='primary' isDisable={sectionDetailsId?.value == undefined}
                                        onClick={() => { viewHomeWork(); }} >View</Button>
                                </div> : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card stretch ref={componentRef} data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='list' iconColor='primary'>
                                <CardTitle>
                                    Class Wise Home Work Status {dateOfHomeWork != '' ? <><span> - </span> <span className="text-info">{dateOfHomeWork}</span></> : null}
                                </CardTitle>
                            </CardLabel>
                            <CardActions className='d-print-none'>
                                <ButtonGroup color='primary'>
                                    <Icon
                                        className='mt-1'
                                        icon='Search'
                                        size='2x'
                                        color='primary'
                                    />
                                    <Input
                                        id='searchInput'
                                        type='search'
                                        placeholder='Search...'
                                        onChange={columnVisibilityForm.handleChange}
                                        value={columnVisibilityForm.values.searchInput}
                                    />
                                </ButtonGroup>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'>
                                            Export
                                        </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button
                                                color='primary'
                                                isLight
                                                icon='CloudDownload'
                                                onClick={() =>
                                                    downloadExcel(
                                                        'ClassWiseHomeWorkStatus',
                                                        homeWorkStatusForExport,
                                                        columnVisibilityData,
                                                    )
                                                }>
                                                Excel
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button
                                                color='primary'
                                                isLight
                                                icon='PictureAsPdf'
                                                onClick={() =>
                                                    convertJsonToPdf(
                                                        homeWorkStatusForExport,
                                                        columnVisibilityData,
                                                        'ClassWiseHomeWorkStatus',
                                                    )
                                                }>
                                                PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button
                                                color='primary'
                                                isLight
                                                icon='CloudDownload'
                                                onClick={() =>
                                                    downloadFile(
                                                        'ClassWiseHomeWorkStatus',
                                                        homeWorkStatusForExport,
                                                        columnVisibilityData,
                                                    )
                                                }>
                                                CSV
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button
                                                color='primary'
                                                isLight
                                                icon='Print'
                                                onClick={() => handlePrint()}>
                                                Print
                                            </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Roll No</th>
                                        <th>Student Name</th>
                                        <th className="text-center">Subject 1 {subjectData.paperName1 ? <><br />
                                            <span className="text-primary" style={{ fontSize: '11px' }}>( {subjectData.paperName1} )</span></> : null}</th>
                                        <th className="text-center">Subject 2 {subjectData.paperName2 ? <><br />
                                            <span className="text-primary" style={{ fontSize: '11px' }}>( {subjectData.paperName2} )</span></> : null}</th>
                                        <th className="text-center">Subject 3 {subjectData.paperName3 ? <><br />
                                            <span className="text-primary" style={{ fontSize: '11px' }}>( {subjectData.paperName3} )</span> </> : null}</th>
                                        <th className="text-center">Subject 4 {subjectData.paperName4 ? <><br />
                                            <span className="text-primary" style={{ fontSize: '11px' }}>( {subjectData.paperName4} )</span></> : null}</th>
                                        <th className="text-center">Subject 5 {subjectData.paperName5 ? <><br />
                                            <span className="text-primary" style={{ fontSize: '11px' }}>( {subjectData.paperName5} )</span></> : null}</th>
                                        <th className="text-center">Subject 6 {subjectData.paperName6 ? <><br />
                                            <span className="text-primary" style={{ fontSize: '11px' }}>( {subjectData.paperName6} )</span></> : null}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != '' ? <>
                                        {filteredData.map((rowData: any) => (
                                            <tr key={rowData.studentHomeWorkStatusId}>
                                                <td>{rowData.sno}</td>
                                                <td>{rowData.rollNo}</td>
                                                <td>{rowData.studentName}</td>
                                                <td className="text-center">{rowData.homeWork1Status == 0 ?
                                                    <Icon color="danger" icon='Close' size={'lg'} /> : <Icon color="success" icon='Check' size={'lg'} />}
                                                </td>
                                                <td className="fw-bold text-center">{rowData.homeWork2Status == 0 ?
                                                    <Icon color="danger" icon='Close' size={'lg'} /> : <Icon color="success" icon='Check' size={'lg'} />}
                                                </td>
                                                <td className="fw-bold text-center">{rowData.homeWork3Status == 0 ?
                                                    <Icon color="danger" icon='Close' size={'lg'} /> : <Icon color="success" icon='Check' size={'lg'} />}
                                                </td>
                                                <td className="fw-bold text-center">{rowData.homeWork4Status == 0 ?
                                                    <Icon color="danger" icon='Close' size={'lg'} /> : <Icon color="success" icon='Check' size={'lg'} />}
                                                </td>
                                                <td className="fw-bold text-center">{rowData.homeWork5Status == 0 ?
                                                    <Icon color="danger" icon='Close' size={'lg'} /> : <Icon color="success" icon='Check' size={'lg'} />}
                                                </td>
                                                <td className="fw-bold text-center">{rowData.homeWork6Status == 0 ?
                                                    <Icon color="danger" icon='Close' size={'lg'} /> : <Icon color="success" icon='Check' size={'lg'} />}
                                                </td>
                                            </tr>
                                        ))} </> :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 15} msg={noDataMsg} />
                                    }
                                </tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                    : null
                }
            </Page>
        </PageWrapper>
    );
}

export default ClassWiseHomeWorkStatus;