import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody, CardSubTitle } from '../../../../components/bootstrap/Card';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../../../components/bootstrap/Dropdown';
import { profilePic, getStandardDetails, getClassToperList } from '../../../../services/common.service';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { TableLoader } from '../../../../services/loader.services';

const ClassTopper = () => {
	
	useEffect(() => {
		getStandardList();
		getClassToper(0);
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);

	const [standardDetailsId, setStandardDetailsId] = useState<any>(0);
	const [toperData, setToperData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');
	const componentRef = useRef(null);

	const [standardName, setStandardName] = useState<any>('');

	const selectStandard = (i: any) => {
		setDataSuccess(false);
		setStandardDetailsId(i.standardDetailsId);
		setStandardName(i.standardName);
		getClassToper(i.standardDetailsId);
	};


	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						const standardDetailsData = [{ standardDetailsId: 0, standardName: 'All' }];
						data.forEach((obj: { standardDetailsId: number; standardName: string }) => {
							standardDetailsData.push(obj);
						});
						setStandardData(standardDetailsData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getClassToper(standardDetailsId: any) {
		getClassToperList(userAccountId, 0, standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.classToperList;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setToperData(data);
						setDataSuccess(true);
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(true);
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
					setDataSuccess(true);
					setToperData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(true);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataSuccess(true);
			},
		);
	}

	return (
		<>
			<div className='col-12'>
				<Card stretch ref={componentRef} className='border border-light rounded-1'>
					<CardHeader borderSize={1}>
						<CardLabel icon='Task' iconColor='info' className='col-lg-5'>
							<CardTitle tag='div' className='h5'>
								Toppers List
							</CardTitle>
							<CardSubTitle>
								{toperData != '' ? toperData[0]?.examTypeName : ''}
							</CardSubTitle>
						</CardLabel>
						{userTypeId == 1 || userTypeId == 2 || userTypeId == 3 || userTypeId == 4 || userTypeId == 8 ?
							<CardActions className='d-print-none'>
								<Dropdown isButtonGroup className='mb-0'>
									<Button color='success' isLight icon='Book'>
										{standardDetailsId == 0 ? 'All' : standardName}
									</Button>
									<DropdownToggle>
										<Button color='success' isLight isVisuallyHidden />
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										{standardData.map((i: any) => (
											<DropdownItem>
												<Button onClick={() => selectStandard(i)}>
													{i.standardName}
												</Button>
											</DropdownItem>
										))}
									</DropdownMenu>
								</Dropdown>
							</CardActions> : null
						}
					</CardHeader>
					<CardBody style={{ height: '40vh' }} className='table-responsive'>
						{!dataSuccess ? (
							<TableLoader />
						) : (
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr>
										<th>Standard</th>
										<th><div className="ms-5">Student</div></th>
										<th>Total Scored</th>
									</tr>
								</thead>

								<tbody className='col-12'>
									{toperData != '' ? (
										<>
											{toperData.map((i: any) => (
												<tr key={i.studentDetailsId}>
													<td><div className="ms-4">{i.standardName}</div></td>
													<td>
														<div className='col d-flex'>
															<div className='flex-shrink-0'>
																{i.profilePath != null ? (
																	<Avatar
																		src={i.profilePath}
																		size={36}
																	/>
																) : (
																	<img
																		src={profilePic(i.genderId)}
																		width='36'
																		height='36'
																	/>
																)}
															</div>
															<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
																<div>
																	<span className="fw-bold">{i.studentName}</span>
																	<div className='text-muted mt-n1'>
																		<small>{i.studentRollNo}</small>
																	</div>
																</div>
															</div>
														</div>
													</td>
													<td >
														{i.totalScored} (
														<span className='text-info fw-bold'>
															{i.percentage}
														</span>
														)
													</td>
												</tr>
											))}
										</>
									) : (
										<tr>
											<td colSpan={3} className="text-center">{noDataMsg}</td>
										</tr>
									)}
								</tbody>
							</table>
						)}
					</CardBody>
				</Card>
			</div>
		</>
	);
};

export default ClassTopper;
