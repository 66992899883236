import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';
import AuthContext from '../../../contexts/authContext';
import useSortableData from '../../../hooks/useSortableData';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import { getLicenseKey } from '../../../services/application.settings';
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable } from '../../../services/common.service';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import { getBookTransaction, getBookDetails, getLibraryMember, updateBookTransaction } from '../../../services/library.service';
import { showLoader, TableLoader } from '../../../services/loader.services';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from '../../bootstrap/Card';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Icon from '../../icon/Icon';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Input from '../../bootstrap/forms/Input';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from '../../bootstrap/OffCanvas';

function BookTransaction() {

	useEffect(() => {
		getBookTransactionList();
		getBookAuthorsList();
		getBookGenresList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [addBookTransactionOffCanvas, setAddBookTransactionOffCanvas] = useState(false);
	const [editBookTransactionOffCanvas, setEditBookTransactionOffCanvas] = useState(false);
	const [bookTransactionData, setBookTransactionData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const componentRef = useRef(null);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(bookTransactionData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	const [bookDetailsId, setBookDetailsId] = useState<any>('');
	const [bookTransactionId, setBookTransactionId] = useState<any>(0);
	const [libraryMemberId, setLibraryMemberId] = useState<any>('');
	const [bookDetailsData, setBookDetailsData] = useState<any>([]);
	const [bookGenresData, setBookGenresData] = useState<any>([]);

	const editBookTransactionForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			bookDetailsId: '',
			libraryMemberId: '',
			dueDate: '',
			returnDate: '',
		},
		validate: (values) => {
			const errors: {
				bookDetailsId?: string;
				libraryMemberId?: string;
				dueDate?: string;
				returnDate?: string;
			} = {};
			if (!bookDetailsId) {
				errors.bookDetailsId = 'Required';
			}
			if (!libraryMemberId) {
				errors.libraryMemberId = 'Required';
			}
			if (!values.dueDate) {
				errors.dueDate = 'Required';
			}
			// if (!values.returnDate && bookTransactionId > 0) {
			// 	errors.returnDate = 'Required';
			// }
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { addTransactionSubmit() },
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	function onActivate(i: any) {
		if (i != undefined) {

			let selectedLibraryBook = i;

			setEditBookTransactionOffCanvas(true);
			setBookDetailsId({ value: selectedLibraryBook.bookDetailsId, label: selectedLibraryBook.title });
			setLibraryMemberId({ value: selectedLibraryBook.libraryMemberId, label: selectedLibraryBook.studentOrStaffName });
			setBookTransactionId(selectedLibraryBook.bookTransactionId);

			editBookTransactionForm.setValues({
				bookDetailsId: selectedLibraryBook.bookDetailsId,
				libraryMemberId: selectedLibraryBook.libraryMemberId,
				dueDate: selectedLibraryBook.dueDate,
				returnDate: selectedLibraryBook.returnDate,
			});
		}
	}

	function setTransactionDetails() {
		return {
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			bookTransactionId: bookTransactionId,
			bookDetailsId: bookDetailsId?.value,
			libraryMemberId: libraryMemberId?.value,
			dueDate: editBookTransactionForm.values.dueDate,
			returnDate: null,
		};
	}

	function setEditTransactionDetails() {
		return {
			bookTransactionId: bookTransactionId,
			bookDetailsId: bookDetailsId?.value,
			libraryMemberId: libraryMemberId?.value,
			dueDate: editBookTransactionForm.values.dueDate,
			returnDate: editBookTransactionForm.values.returnDate,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
		};
	}

	function addTransactionSubmit() {
		showLoader(true);
		if (editBookTransactionForm.isValid) {
			let libraryPostData = bookTransactionId == 0 ? setTransactionDetails() : setEditTransactionDetails();
			updateBookTransaction(
				libraryPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getBookTransactionList();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editBookTransactionForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function getBookTransactionList() {
		setDataStatus(false);
		getBookTransaction(0, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.bookTransaction;
					if (data != undefined) {
						getColumnsForTable('getBookTransaction', 'get');
						setBookTransactionData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataStatus(true);
					} else {
						toasts('Undefined Data', 'Error');
						setDataStatus(true);
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					getColumnsForTable('getBookTransaction', 'get');
					setBookTransactionData([]);
					setNoDataMsg(response.data.message);
					setDataStatus(true);
				} else {
					setDataStatus(true);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getBookTransaction', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function getBookAuthorsList() {
		getBookDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.bookDetails;
					if (data != undefined) {
						setBookDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setBookDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBookGenresList() {
		getLibraryMember(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.libraryMember;
					if (data != undefined) {
						setBookGenresData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setBookGenresData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [checkAvailabilityStatus, setCheckAvailabilityStatus] = useState<any>(0)

	const selectBook = (e: any) => {
		setLibraryMemberId('');
		let bookDetailsId = e;
		if (e != undefined) {
			setBookDetailsId(bookDetailsId);
			let notReturnBook = bookDetailsData.filter((item: any) => item.bookDetailsId == e?.value)[0]
			setCheckAvailabilityStatus(notReturnBook.availabilityStatusId)
		}
	};

	const selectMember = (e: any) => {
		let libraryMemberId = e;
		setLibraryMemberId(libraryMemberId);
	};

	function closeAndReset() {
		setEditBookTransactionOffCanvas(false);
		setAddBookTransactionOffCanvas(false);
		setIsDeleteOpen(false);
		editBookTransactionForm.resetForm();
		editBookTransactionForm.resetForm()
		setLibraryMemberId('');
		setBookDetailsId('');
		setBookTransactionId(0);
		setCheckAvailabilityStatus(0)
	}

	return (
		<>
			<PageWrapper title={`Book Transaction Details`}>
				<Page container='fluid'>
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-5'>
								<CardTitle tag='div' className='h5'>
									Book Transaction List
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForTable(
																		'getBookTransaction',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div
														className='col-12'
														style={{
															maxHeight: '200px',
															overflowY: 'scroll',
														}}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		id='{i.columnVisibilityId}'
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={
																			i.isDefault == true
																		}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'book Transaction Details',
														bookTransactionData,
														columnVisibilityData,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														bookTransactionData,
														columnVisibilityData,
														'book Transaction Details',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'book Transaction Details',
														bookTransactionData,
														columnVisibilityData,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={handlePrint}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Button
									color='primary'
									icon='Add'
									isLight
									onClick={() => setAddBookTransactionOffCanvas(true)}>
									Add New
								</Button>
							</CardActions>
						</CardHeader>

						<CardBody className='table-responsive' isScrollable>
							{!dataStatus ? (
								<TableLoader />
							) : (
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No{' '}
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											{columnVisibilityData.map((column: any) => (
												<th
													key={column.keyName}
													scope='col'
													onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon
														size='lg'
														className={getClassNamesFor(column.keyName)}
														icon='FilterList'
													/>
												</th>
											))}
											<th scope='col' className='d-print-none'>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ? (
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.bookTransactionId}>
														<td>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) =>
															column.isDisplay &&
																rowData[column.keyName] ? (
																<td
																	key={`${rowData.bookTransactionId}-${column.keyName}`}>
																	{rowData[column.keyName]}
																</td>
															) : (
																<td
																	key={`empty-${column.keyName}`}
																/>
															),
														)}
														<td className='d-print-none'>
															<Button
																color='dark'
																isLight
																icon='Edit'
																aria-label='Edit'
																onClick={() => {
																	onActivate(rowData);
																}}
															/>
														</td>
													</tr>
												))}
											</>
										) : (
											<NoDataMsg
												columnsCount={columnVisibilityData.length + 2}
												msg={noDataMsg}
											/>
										)}
									</tbody>
								</table>
							)}
						</CardBody>
						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				</Page>

				<OffCanvas
					setOpen={setAddBookTransactionOffCanvas}
					isOpen={addBookTransactionOffCanvas}
					titleId='addBookTransactionOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={editBookTransactionForm.handleSubmit}>
					<OffCanvasHeader setOpen={setAddBookTransactionOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addBookTransactionOffCanvas'>
							Add Book Transaction
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-3'>
							<div className='col-12'>
								<FormGroup id='bookDetailsId' label='Book'>
									<SearchableSelect
										className={checkAvailabilityStatus > 0 ? 'border border-danger' : ''}
										ariaLabel='Book'
										onChange={selectBook}
										onBlur={editBookTransactionForm.handleBlur}
										value={bookDetailsId}
										isValid={editBookTransactionForm.isValid}
										isTouched={editBookTransactionForm.touched.bookDetailsId}
										invalidFeedback={editBookTransactionForm.errors.bookDetailsId}
										list={bookDetailsData.map((data: any) => ({
											value: data.bookDetailsId, label: data.title
										}))}
									/>
									{checkAvailabilityStatus > 0 ? <small className='text-danger'>Book not Returned</small> : <></>}
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='libraryMemberId' label='Library Member'>
									<SearchableSelect
										ariaLabel='Library Member'
										onChange={selectMember}
										onBlur={editBookTransactionForm.handleBlur}
										isValid={editBookTransactionForm.isValid}
										isTouched={editBookTransactionForm.touched.libraryMemberId}
										invalidFeedback={editBookTransactionForm.errors.libraryMemberId}
										value={libraryMemberId}
										list={bookGenresData.map((data: any) => ({
											value: data.libraryMemberId, label: data.studentOrStaffName
										}))}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='dueDate' label='Due Date'>
									<Input
										onChange={editBookTransactionForm.handleChange}
										value={editBookTransactionForm.values.dueDate}
										onBlur={editBookTransactionForm.handleBlur}
										isValid={editBookTransactionForm.isValid}
										isTouched={editBookTransactionForm.touched.dueDate}
										invalidFeedback={editBookTransactionForm.errors.dueDate}
										type='date'
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>

					<div className='row m-0'>
						<div className='col-12 p-3'>
							<Button
								isDisable={checkAvailabilityStatus > 0 ? true : false}
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								onClick={editBookTransactionForm.handleSubmit}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<OffCanvas
					setOpen={setEditBookTransactionOffCanvas}
					isOpen={editBookTransactionOffCanvas}
					titleId='editBookTransactionOffCanvas'
					isBodyScroll
					placement='end'
					tag='form'
					noValidate
					onSubmit={editBookTransactionForm.handleSubmit}>
					<OffCanvasHeader setOpen={setEditBookTransactionOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='editBookTransactionOffCanvas'>
							Update Book Transaction
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-3'>
							<div className='col-12'>
								<FormGroup id='bookDetailsId' label='Book'>
									<SearchableSelect
										className={checkAvailabilityStatus > 0 ? 'border border-danger' : ''}
										ariaLabel='Book'
										disabled
										onChange={selectBook}
										onBlur={editBookTransactionForm.handleBlur}
										isTouched={editBookTransactionForm.touched.bookDetailsId}
										invalidFeedback={editBookTransactionForm.errors.bookDetailsId}
										value={bookDetailsId}
										list={bookDetailsData.map((data: any) => ({
											value: data.bookDetailsId, label: data.title
										}))}
									/>
									{checkAvailabilityStatus > 0 ? <small className='text-danger'>Book not Returned</small> : <></>}
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='libraryMemberId' label='Library Member'>
									<SearchableSelect
										ariaLabel='select Member'
										onChange={selectMember}
										onBlur={editBookTransactionForm.handleBlur}
										isTouched={editBookTransactionForm.touched.libraryMemberId}
										invalidFeedback={editBookTransactionForm.errors.libraryMemberId}
										value={libraryMemberId}
										list={bookGenresData.map((data: any) => ({
											value: data.libraryMemberId, label: data.studentOrStaffName
										}))}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='dueDate' label='DueDate'>
									<Input
										onChange={editBookTransactionForm.handleChange}
										value={editBookTransactionForm.values.dueDate}
										onBlur={editBookTransactionForm.handleBlur}
										isValid={editBookTransactionForm.isValid}
										isTouched={editBookTransactionForm.touched.dueDate}
										invalidFeedback={editBookTransactionForm.errors.dueDate}
										type='date'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='returnDate' label=' Return Date'>
									<Input
										onChange={editBookTransactionForm.handleChange}
										value={editBookTransactionForm.values.returnDate}
										onBlur={editBookTransactionForm.handleBlur}
										isValid={editBookTransactionForm.isValid}
										isTouched={editBookTransactionForm.touched.returnDate}
										invalidFeedback={editBookTransactionForm.errors.returnDate}
										type='date'
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>

					<div className='col-12 position-absolute bottom-0 start-0 p-3'>
						<Button
							isDisable={checkAvailabilityStatus > 0 ? true : false}
							color='info'
							icon='Save'
							type='submit'
							className='w-100'
							onClick={editBookTransactionForm.handleSubmit}>
							Update
						</Button>
					</div>

				</OffCanvas>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
}
export default BookTransaction;
