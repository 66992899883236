import React, { useContext, useEffect, useState } from 'react';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardFooter, CardActions } from '../../bootstrap/Card';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { toasts } from '../../../services/toast.service';
import Icon from '../../icon/Icon';
import { deleteGalleryImages, getGalleryImages, uploadGalleryPic } from '../../../services/complaint.service';
import Modal, { ModalHeader, ModalTitle, ModalBody } from '../../bootstrap/Modal';
import Popovers from '../../bootstrap/Popovers';
import Avatar from '../../Avatar';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Button from '../../bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { profilePic } from '../../../services/common.service';
import Input from '../../bootstrap/forms/Input';
import { getLicenseKey } from '../../../services/application.settings';
import { showLoader, TableLoader } from '../../../services/loader.services';
import AuthContext from '../../../contexts/authContext';
import AlertService from '../../../services/AlertService';

const GalleryList = () => {

	const { userAccountId } = useContext(AuthContext);

	useEffect(() => {
		getGalleryImagesList();
	}, []);

	const [galleryType, setGalleryType] = useState<any>('');
	const [galleryData, setGalleryData] = useState<any>([]);
	const [dataStatus, setDataStatus] = useState<any>(false);
	const [noDataMsg, setNoDataMsg] = useState<any>('');
	const [galleryImagesId, setGalleryImagesId] = useState<any>('');
	const [filePath, setFilePath] = useState<any>('');

	const [selectedFile1, setSelectedFile1] = useState<any>('');
	const [selectedFile2, setSelectedFile2] = useState<any>('');
	const [selectedFile3, setSelectedFile3] = useState<any>('');
	const [selectedFile4, setSelectedFile4] = useState<any>('');

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [isNavigate, setIsNavigate] = useState<any>(null);

	const [noOfImage, setNoOfImage] = useState<any>(0);
	const [galleryImages, setGalleryImages] = useState<any>([]);

	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [isAddOpen, setIsAddOpen] = useState(false);

	const handleFileChange1 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1);
			setSelectedFile1(e.target.files[0]);
		}
	};

	const handleFileChange2 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1);
			setSelectedFile2(e.target.files[0]);
		}
	};

	const handleFileChange3 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1);
			setSelectedFile3(e.target.files[0]);
		}
	};

	const handleFileChange4 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1);
			setSelectedFile4(e.target.files[0]);
		}
	};

	const handleDeleteAvatar1 = () => {
		setNoOfImage(noOfImage - 1);
		setSelectedFile1(null);
		const fileInput = document.getElementById('fileInput1') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar2 = () => {
		setNoOfImage(noOfImage - 1);
		setSelectedFile2(null);
		const fileInput = document.getElementById('fileInput2') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar3 = () => {
		setNoOfImage(noOfImage - 1);
		setSelectedFile3(null);
		const fileInput = document.getElementById('fileInput3') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar4 = () => {
		setSelectedFile4(null);
		setNoOfImage(noOfImage - 1);
		const fileInput = document.getElementById('fileInput4') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const [isLoader, setIsLoader] = useState<any>(true)

	function getGalleryImagesList() {
		setIsLoader(true)
		getGalleryImages(0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.galleryImages;
					if (data != undefined) {
						setIsLoader(false)
						setDataStatus(true);
						for (let i = 0; i < data.length; i++) {
							let imageData = data[i].image;
							for (let j = 0; j < imageData.length; j++) {
								imageData[j].src = imageData[j].imagePath;
							}
						}
						setGalleryData(data);
					} else {
						setDataStatus(true);
						setIsLoader(false)
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false)
					setDataStatus(true);
					//toasts(response.data.message, 'Error');
					setGalleryData([]);
					setNoDataMsg(response.data.message);
				} else {
					setIsLoader(false)
					setDataStatus(true);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false)
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function onUpload() {
		showLoader(true);

		if (selectedFile1 != '' || selectedFile2 != '' || selectedFile3 != '' || selectedFile4 != '') {
			const galleryData = new FormData();
			galleryData.append('licenseKey', getLicenseKey.toString());
			galleryData.append('staffDetailsId', userAccountId);
			galleryData.append('galleryType', galleryType);
			galleryData.append('noOfImage', noOfImage);
			galleryData.append('dataCount', galleryImages != undefined ? galleryImages.length : '0');
			selectedFile1 != '' && galleryData.append('imagePath1', selectedFile1, selectedFile1.name);
			selectedFile2 != '' && galleryData.append('imagePath2', selectedFile2, selectedFile2.name);
			selectedFile3 != '' && galleryData.append('imagePath3', selectedFile3, selectedFile3.name);
			selectedFile4 != '' && galleryData.append('imagePath4', selectedFile4, selectedFile4.name);
			uploadGalleryPic(galleryData,
				(response) => {
					if (response.data.success == true) {
						showLoader(false);
						setAlertStatus({ message: response.data.message, type: 'success' });
						setIsOpen(true);
						getGalleryImagesList();
						closeAndReset();
						closeModal();
					} else {
						showLoader(false);
						setAlertStatus({ message: response.data.message, type: 'warning' });
						setIsOpen(true);
						setIsNavigate(null);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: 'Image not upload', type: 'error' });
					setIsOpen(true);
					setIsNavigate(null);
				},
			);
		} else {
			showLoader(false);
			setAlertStatus({ message: 'Please select image', type: 'error' });
			setIsOpen(true);
			setIsNavigate(null);
		}
	}

	function deleteModal() {
		setIsDeleteOpen(true);
		setShowImage(false);
	}

	function AddModal(i: any) {
		setIsAddOpen(true);
		setShowImage(false);
		setGalleryType(i.galleryType);
		setGalleryImages(i.image)
	}

	const navigate = useNavigate();

	const [selectedImage, setSelectedImage] = useState<any>(undefined);
	const [showImage, setShowImage] = useState<any>(false);

	function closeAndReset() {
		setSelectedFile1('');
		setSelectedFile2('');
		setSelectedFile3('');
		setSelectedFile4('');
		const fileInput1 = document.getElementById('fileInput1') as HTMLInputElement;
		const fileInput2 = document.getElementById('fileInput2') as HTMLInputElement;
		const fileInput3 = document.getElementById('fileInput3') as HTMLInputElement;
		const fileInput4 = document.getElementById('fileInput4') as HTMLInputElement;
		if (fileInput1) {
			fileInput1.value = '';
		}
		if (fileInput2) {
			fileInput2.value = '';
		}
		if (fileInput3) {
			fileInput3.value = '';
		}
		if (fileInput4) {
			fileInput4.value = '';
		}
	}

	function setDeleteGalleryDetails() {
		return {
			licenseKey: 5566,
			galleryImagesId: galleryImagesId,
			imagePath: filePath,
			staffDetailsId: userAccountId,
		};
	}

	function deleteGallerySubmit() {
		showLoader(true);
		let galleryPostData = setDeleteGalleryDetails();
		deleteGalleryImages(
			galleryPostData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'success' });
					setIsOpen(true);
					setIsDeleteOpen(false);
					closeModal();
					closeAndReset();
					getGalleryImagesList();
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'error' });
					setIsOpen(true);
				} else {
					showLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
	}

	function closeModal() {
		setIsAddOpen(false);
		setIsDeleteOpen(false);
	}

	return (
		<PageWrapper title='Gallery'>
			<SubHeader>
				<SubHeaderLeft>
					<CardLabel icon='PhotoAlbum' iconColor='primary'>
						<CardTitle tag='div' className='h5 text-uppercase'>
							Gallery
						</CardTitle>
					</CardLabel>
				</SubHeaderLeft>
				<SubHeaderRight>
					<SubheaderSeparator />
					<Button
						color='info'
						isLight
						icon='Upload'
						onClick={() => navigate(`../management/gallery`)}>
						Upload
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page>
				{!isLoader ? <>
					{galleryData != '' ?
						<div className='row'>
							{galleryData.map((item: any) => (
								<div className='col-6' key={item.galleryType}>
									<Card>
										<CardHeader>
											<CardLabel icon='Image' iconColor='primary'>
												<CardTitle tag='div' className='h5 text-uppercase'>
													{item.galleryType}
												</CardTitle>
											</CardLabel>
											<Button
												color='info'
												isLight
												icon='add'
												onClick={() => AddModal(item)}>
												Add
											</Button>
										</CardHeader>
										<CardBody>
											<div className='col-12'>
												<div className='row'>
													{dataStatus ? (
														<>
															{item.image.map((imageData: any, i: any) => (
																<div key={imageData.galleryImagesId} className={`col-2 ${i > 5 ? ' mt-3' : ''}`}>
																	<Popovers trigger='hover' desc={<b>Preview</b>}>
																		<Avatar
																			onClick={() => {
																				setSelectedImage(imageData.imagePath);
																				setShowImage(true);
																				setGalleryImagesId(imageData.galleryImagesId);
																				setFilePath(imageData.filePath)
																				setGalleryType(item.galleryType);
																			}}
																			srcSet={imageData.imagePath}
																			src={imageData.imagePath}
																			color={'info'}
																			size={80}
																			className='cursor-pointer'
																		/>
																	</Popovers>
																</div>
															))}{' '}
														</>
													) : null}
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							))}
						</div> :
						<div className='row'>
							<div className='col-12' style={{ height: '20vh' }}></div>
							<div className='col-12 d-flex justify-content-center'>
								<Icon icon='HideImage' size={'10x'} />
							</div>
							<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
								{noDataMsg}
							</div>
						</div>
					} </> :
					<div className='row'>
						<div className='col-12' style={{ height: '20vh' }}></div>
						<div className='col-12 d-flex justify-content-center'>
							<TableLoader />
						</div>
					</div>
				}

				<Modal setIsOpen={setShowImage} isOpen={showImage} isCentered>
					<ModalHeader setIsOpen={setShowImage}>
						<CardLabel icon='List' iconColor='info' className="col-lg-10">
							<ModalTitle id='preview'>{galleryType}</ModalTitle>
						</CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<CardActions className="d-print-none">
							<Button
								isOutline
								icon='DeleteOutline'
								size={'sm'}
								color='danger'
								onClick={deleteModal}>
							</Button>
						</CardActions>
					</ModalHeader>
					<ModalBody>
						<img
							src={selectedImage}
							alt={selectedImage}
							width='100%'
							height='auto'
							className='object-fit-contain p-4'
						/>
					</ModalBody>
				</Modal>
				<Modal
					setIsOpen={setIsDeleteOpen}
					isOpen={isDeleteOpen}
					titleId='deleteGalleryImage'
					isStaticBackdrop
					size='sm'>
					<ModalHeader>
						<></>
					</ModalHeader>
					<ModalBody>
						<div className='row g-2'>
							<div className='col-12 d-flex justify-content-center'>
								<h5>
									<strong>Are you sure want to delete ?</strong>
								</h5>
							</div>
							<div className='col-10'>
								<div className='d-flex gap-2 justify-content-end'>
									<Button
										color='danger'
										size={'sm'}
										onClick={deleteGallerySubmit}>
										Yes
									</Button>
									<Button
										color='info'
										size={'sm'}
										onClick={() => {
											setIsDeleteOpen(!isDeleteOpen);
											setShowImage(true);
										}}>
										No
									</Button>
								</div>
							</div>
							<div className='col-10'></div>
						</div>
					</ModalBody>
				</Modal>

				<Modal
					setIsOpen={setIsAddOpen}
					isOpen={isAddOpen}
					titleId='addBatchMaster'
					isStaticBackdrop
					size='xl'>
					<ModalHeader
						className="'modal-header'"
						setIsOpen={() => {
							setIsAddOpen(false);
						}}
						onClick={closeModal}>
						<ModalTitle id='add'>Add</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='row'>
							<div className='col-3'>
								<Card stretch shadow={'md'} className='border border-light'>
									<CardBody>
										{selectedFile1 ? (
											<img
												src={URL.createObjectURL(selectedFile1)}
												alt=''
												width={138}
												height={138}
												className='mx-auto d-block img-fluid mb-3 rounded'
											/>
										) : (
											<img
												src={profilePic(0)}
												alt=''
												width={140}
												height={140}
												className='mx-auto d-block img-fluid rounded'
											/>
										)}
									</CardBody>
									<CardFooter borderSize={1}>
										<div className='col-12'>
											<Input
												className='ms-1'
												id='fileInput1'
												type='file'
												autoComplete='photo'
												ariaLabel='Upload image file'
												onChange={handleFileChange1}
											/>

											<Button
												style={{ marginLeft: '65px' }}
												className='mt-3'
												color='dark'
												isLight
												icon='Delete'
												onClick={handleDeleteAvatar1}>
												Delete
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>

							<div className='col-3'>
								<Card stretch shadow={'md'} className='border border-light'>
									<CardBody>
										{selectedFile2 ? (
											<img
												src={URL.createObjectURL(selectedFile2)}
												alt=''
												width={128}
												height={128}
												className='mx-auto d-block img-fluid mb-3 rounded'
											/>
										) : (
											<img
												src={profilePic(0)}
												alt=''
												width={140}
												height={140}
												className='mx-auto d-block img-fluid rounded'
											/>
										)}
									</CardBody>
									<CardFooter borderSize={1}>
										<div className='col-12'>
											<Input
												className='ms-1'
												id='fileInput2'
												type='file'
												autoComplete='photo'
												ariaLabel='Upload image file'
												onChange={handleFileChange2}
												disabled={selectedFile1 != '' ? false : true}
											/>

											<Button
												style={{ marginLeft: '65px' }}
												className='mt-3'
												color='dark'
												isLight
												icon='Delete'
												onClick={handleDeleteAvatar2}>
												Delete
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>

							<div className='col-3'>
								<Card stretch shadow={'md'} className='border border-light'>
									<CardBody>
										{selectedFile3 ? (
											<img
												src={URL.createObjectURL(selectedFile3)}
												alt=''
												width={128}
												height={128}
												className='mx-auto d-block img-fluid mb-3 rounded'
											/>
										) : (
											<img
												src={profilePic(0)}
												alt=''
												width={140}
												height={140}
												className='mx-auto d-block img-fluid rounded'
											/>
										)}
									</CardBody>
									<CardFooter borderSize={1}>
										<div className='col-12'>
											<Input
												className='ms-1'
												id='fileInput3'
												type='file'
												autoComplete='photo'
												ariaLabel='Upload image file'
												onChange={handleFileChange3}
												disabled={selectedFile2 != '' ? false : true}
											/>

											<Button
												style={{ marginLeft: '65px' }}
												className='mt-3'
												color='dark'
												isLight
												icon='Delete'
												onClick={handleDeleteAvatar3}>
												Delete
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>

							<div className='col-3'>
								<Card stretch shadow={'md'} className='border border-light'>
									<CardBody>
										{selectedFile4 ? (
											<img
												src={URL.createObjectURL(selectedFile4)}
												alt=''
												width={128}
												height={128}
												className='mx-auto d-block img-fluid mb-3 rounded'
											/>
										) : (
											<img
												src={profilePic(0)}
												alt=''
												width={140}
												height={140}
												className='mx-auto d-block img-fluid rounded'
											/>
										)}
									</CardBody>
									<CardFooter borderSize={1}>
										<div className='col-12'>
											<Input
												className='ms-1'
												id='fileInput4'
												type='file'
												autoComplete='photo'
												ariaLabel='Upload image file'
												onChange={handleFileChange4}
												disabled={selectedFile3 != '' ? false : true}
											/>
											<Button
												style={{ marginLeft: '65px' }}
												className='mt-3'
												color='dark'
												isLight
												icon='Delete'
												onClick={handleDeleteAvatar4}>
												Delete
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>
							<br />
						</div>
						<br />
						<div className='col-12 d-flex justify-content-end'>
							<Button icon='Save' color='info' type='submit' onClick={onUpload}>
								Add
							</Button>
						</div>
					</ModalBody>
				</Modal>
			</Page>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default GalleryList;
