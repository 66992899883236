import React, { useContext, useEffect, useRef, useState } from 'react';
import { toasts } from '../../../services/toast.service';
import { useReactToPrint } from 'react-to-print';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import Button from '../../bootstrap/Button';
import Card, { CardHeader, CardActions, CardBody, CardLabel, CardSubTitle, CardTitle } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Icon from '../../icon/Icon';
import { getAllStudent, getProfileForStudent, getStudentDetailsBySectionDetailsId } from '../../../services/student.service';
import { convertDateToEpoch, getColumnsForDataTable, getMedium, getStandardDetails, pictNotLoading, profilePic, updateFilter } from '../../../services/common.service';
import { getBatchMasterDetails, getSectionDetails } from '../../../services/master.service';
import { useNavigate, useParams } from 'react-router-dom';
import { TableLoader } from '../../../services/loader.services';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import CollegeLogo from '../../../assets/logos/logo.jpg';
import { useFormik } from 'formik';
import { addStudentCertificateIssue } from '../../../services/certificate.service';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import dayjs from 'dayjs';
import SearchableSelect from '../../../common/components/SearchableSelect';
import { getSchoolDetails } from '../../../services/school.service';
import AttendanceStatus from '../../charts/AttendanceStatus';
import ExamMarkStatus from '../../charts/ExamMarkStatus';
import Input from '../../bootstrap/forms/Input';
import Timeline, { TimelineItem } from '../../extras/Timeline';
import { getActivityLog } from '../../../services/staff.service';
import { getStudentActivityLog } from '../../../services/dashboard.service';
import Avatar from '../../Avatar';
import { dataPagination } from '../../PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';

const StudentActivityReport = () => {
    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const navigate = useNavigate();
    const { navigatedStudentDetailsId } = useParams();
    const [isNavigatedStudent, setIsNavigatedStudent] = useState(false);

    useEffect(() => {
        getAllStudentDetails();
        getSchool();
        if (navigatedStudentDetailsId != undefined) {
            setIsNavigatedStudent(true);
            getStudentProfile(navigatedStudentDetailsId);
            setStudentDetailsId(navigatedStudentDetailsId);
        }
    }, []);

    const [studentDetailsDataByDropdown, setStudentDetailsDataByDropdown] = useState([])
    const [mediumId, setMediumId] = useState<any>('');
    const [standardDetailsId, setStandardDetailsId] = useState<any>('');
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);
    const componentRef = useRef(null);
    const [mediumData, setMediumData] = useState<any>([]);
    const [standardData, setStandardData] = useState<any>([]);
    const [sectionDetailsData, setSectionDetailsData] = useState([]);
    const [studentDetailsData, setStudentDetailsData] = useState<any>([]);
    const [allStudentData, setAllStudentData] = useState([]);
    const [schoolDetailsData, setSchoolDetailsData] = useState<any>([]);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')
    const [activityData, setActivityData] = useState<any>([]);

    const studentActivityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            fromDate: '',
            toDate: '',
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectBatch = (e: any) => {
        setDataSuccess(false)
        setStudentDetailsId('')
        setStandardDetailsId('')
        setSectionDetailsId('')
        setMediumId('')
        studentActivityForm.resetForm()
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        getMediumList();
    }

    const selectMedium = (e: any) => {
        setDataSuccess(false)
        setStudentDetailsId('')
        setStandardDetailsId('');
        setSectionDetailsId('');
        studentActivityForm.resetForm()
        let mediumId = e;
        setMediumId(mediumId);
        getStandardList();
    };

    const selectStandard = (e: any) => {
        setDataSuccess(false)
        setStudentDetailsId('')
        setSectionDetailsId('');
        studentActivityForm.resetForm()
        let standardDetailsId = e;
        setStandardDetailsId(standardDetailsId);
        if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
            getSectionList(mediumId?.value, standardDetailsId?.value);
        }
    };

    const selectSection = (e: any) => {
        setDataSuccess(false)
        setStudentDetailsId('');
        studentActivityForm.resetForm()
        let sectionDetailsId = e;
        setSectionDetailsId(sectionDetailsId);
        if (batchMasterId?.value != undefined && sectionDetailsId?.value != undefined) {
            getStudentListBySectionId(batchMasterId?.value, sectionDetailsId?.value);
        }
    };

    const selectStudent = (e: any) => {
        setDataSuccess(false)
        studentActivityForm.resetForm()
        let studentDetailsId = e;
        setStudentDetailsId(studentDetailsId);
    };

    function viewParticularStudent() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = studentActivityForm.values.fromDate
        let toDate = studentActivityForm.values.toDate
        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)
        getStudentActivityLogList(studentDetailsId?.value, fromDateInMilliSec, toDateInMilliSec);
        getStudentProfile(studentDetailsId?.value)
    }

    function viewStudentClassWiseList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = studentActivityForm.values.fromDate
        let toDate = studentActivityForm.values.toDate
        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)
        getStudentActivityLogList(studentDetailsId?.value, fromDateInMilliSec, toDateInMilliSec);
        getStudentProfile(studentDetailsId?.value)
    }


    function getStudentListBySectionId(batchMasterId: any, sectionDetailsId: number) {
        getStudentDetailsBySectionDetailsId(batchMasterId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySectionDetailsId;
                    if (data != undefined) {
                        setStudentDetailsDataByDropdown(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    // toasts(response.data.message, 'Error');
                    setStudentDetailsDataByDropdown([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getStudentProfile(studentDetailsId: any) {
        setIsLoader(true);
        getProfileForStudent(
            studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    let studentProfile = response.data.data.studentProfile[0];
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                        setIsLoader(false);
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                    setIsLoader(false);
                    setDataSuccess(false);
                    setIsOpenListCard(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                    setDataSuccess(false);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
                setDataSuccess(false);
            },
        );
    }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getSectionList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(
            mediumId,
            standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getAllStudentDetails() {
        getAllStudent(userAccountId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getSchool() {
        getSchoolDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.schoolDetails;
                    if (data != undefined) {
                        setSchoolDetailsData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    const colors = ['info', 'success', 'warning', 'secondary', 'primary'];
    
    const [fromDate, setFromDate] = useState<any>('')
    const [toDate, setToDate] = useState<any>('')

    function getStudentActivityLogList(studentDetailsId: any, fromDateInMilliSec: any, toDateInMilliSec: any) {
        setIsLoader(true);
        getStudentActivityLog(studentDetailsId, fromDateInMilliSec, toDateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentActivityLog;
                    let fromDate = response.data.data.studentActivityLog[0].fromDateForView;
                    let toDate = response.data.data.studentActivityLog[0].toDateForView;
                    if (data != undefined) {
                        setIsLoader(false);
                        setIsOpenListCard(false)
                        setDataSuccess(true)
                        setFromDate(fromDate)
                        setToDate(toDate)
                        for (let i = 0; i < data.length; i++) {
                            let activities = data[i].activities;
                            for (let j = 0; j < activities.length; j++) {
                                activities[j].color = colors[Math.floor(Math.random() * colors.length)];
                                activities[j].studentName = data[i].studentName;
                                activities[j].studentRollNo = data[i].studentRollNo;
                            }
                        }
                        setActivityData(data);
                        getColumnsForTable('getStudentActivityLog')
                    } else {
                        setIsLoader(false);
                        setDataSuccess(true)
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false)
                    setDataSuccess(true)
                    setNoDataMsg(response.data.message)
                    setActivityData([]);
                    getColumnsForTable('getStudentActivityLog')
                    // toasts(response.data.message, 'Error');
                } else {
                    setIsLoader(false);
                    setDataSuccess(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                setDataSuccess(true)
                toasts(error, 'Error');
            },
        );
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function selectStudentCard() {
        if (isStudentOrClassWise) {
            getBatchList()
        }
        setIsStudentOrClassWise(!isStudentOrClassWise);
        setDataSuccess(false);
        setSectionDetailsId('');
        setStudentDetailsId('');
        setMediumId('')
        setStandardDetailsId('')
        setBatchMasterId('')
        studentActivityForm.resetForm()
    }

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);

    function getColumnsForTable(apiName: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    return (

        <PageWrapper title='StudentActivityReport'>
            {isNavigatedStudent ? (
                <SubHeader className='d-print-none'>
                    <SubHeaderLeft>
                        <Button
                            color='info'
                            isLink
                            icon='ArrowBack'
                            onClick={() => navigate(`../academic/studentList`)}>
                            Back to List
                        </Button>
                        <SubheaderSeparator />
                        {studentDetailsData.map((student: any) => (
                            <div key={student.studentDetailsId}>
                                <img
                                    style={{ borderRadius: '50%' }}
                                    src={
                                        student.profilePath != null
                                            ? student.profilePath
                                            : profilePic(student.genderId)
                                    }
                                    height='40'
                                    width='40'
                                    onError={(e: any) => pictNotLoading(e, student.genderId)}
                                />
                                <span>
                                    <strong className='ms-3'>{student.studentName} </strong>
                                </span>
                                <span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
                                    {student.admissionNo}
                                </span>
                            </div>
                        ))}
                    </SubHeaderLeft>
                    <SubHeaderRight>
                        <Button
                            icon='Print'
                            isLight
                            color='primary'
                            onClick={() => {
                                handlePrint();
                            }}>
                            Print
                        </Button>
                    </SubHeaderRight>
                </SubHeader>
            ) : (
                <></>
            )}
            <Page container='lg'>
                {!isNavigatedStudent ? (
                    <>
                        <Collapse isOpen={isOpenListCard}>
                            <Card>
                                <CardHeader borderSize={1}>
                                    <CardActions>
                                        <Button
                                            icon='PersonSearch'
                                            color='info'
                                            isLight={isStudentOrClassWise ? false : true}
                                            onClick={selectStudentCard}>
                                            Student
                                        </Button>

                                        <Button
                                            icon='PeopleAlt'
                                            color='info'
                                            isLight={isStudentOrClassWise ? true : false}
                                            onClick={selectStudentCard}>
                                            Class Wise
                                        </Button>
                                    </CardActions>
                                </CardHeader>
                                {isStudentOrClassWise ? (
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <FormGroup
                                                    id='studentDetailsId'
                                                    label='Student'
                                                    isFloating>
                                                    <SearchableSelect
                                                        isFloating
                                                        ariaLabel='Student'
                                                        onChange={selectStudent}
                                                        value={studentDetailsId}
                                                        list={allStudentData.map((data: any) => ({
                                                            value: data.studentDetailsId,
                                                            label: data.studentNameAndAdmissionNo,
                                                        }))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {studentDetailsId?.value != undefined ? (
                                                <div className="col-3">
                                                    <FormGroup id="fromDate" label="From Date" isFloating>
                                                        <Input
                                                            type='date'
                                                            ariaLabel='From Date'
                                                            onChange={studentActivityForm.handleChange}
                                                            onBlur={studentActivityForm.handleBlur}
                                                            value={studentActivityForm.values.fromDate}
                                                            isValid={studentActivityForm.isValid}
                                                            isTouched={studentActivityForm.touched.fromDate}
                                                            invalidFeedback={studentActivityForm.errors.fromDate}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}
                                            {studentActivityForm.values.fromDate != '' ? (
                                                <div className="col-3">
                                                    <FormGroup id="toDate" label="To Date" isFloating>
                                                        <Input
                                                            type='date'
                                                            ariaLabel='To Date'
                                                            onChange={studentActivityForm.handleChange}
                                                            onBlur={studentActivityForm.handleBlur}
                                                            value={studentActivityForm.values.toDate}
                                                            isValid={studentActivityForm.isValid}
                                                            isTouched={studentActivityForm.touched.toDate}
                                                            invalidFeedback={studentActivityForm.errors.toDate}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}
                                            {studentActivityForm.values.toDate != '' ? (
                                                <div className='col-2 mt-1'>
                                                    <Button
                                                        icon='ArrowDownward'
                                                        color='primary'
                                                        onClick={viewParticularStudent} >
                                                        View
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                    </CardBody>
                                ) : (
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Batch' placeholder="Batch"
                                                        onChange={selectBatch}
                                                        value={batchMasterId}
                                                        list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))}
                                                        required
                                                    />
                                                </FormGroup>
                                            </div>

                                            {batchMasterId?.value != undefined ?
                                                <div className='col-3'>
                                                    <FormGroup id='mediumId' label='Medium' isFloating>
                                                        <SearchableSelect
                                                            isFloating
                                                            ariaLabel='Medium'
                                                            onChange={selectMedium}
                                                            value={mediumId}
                                                            list={mediumData.map((data: any) => ({
                                                                value: data.mediumId,
                                                                label: data.medium,
                                                            }))}
                                                        />
                                                    </FormGroup>
                                                </div> : null
                                            }

                                            {mediumId?.value != undefined ? (
                                                <div className='col-3'>
                                                    <FormGroup
                                                        id='standardDetailsId'
                                                        label='Standard'
                                                        isFloating>
                                                        <SearchableSelect
                                                            isFloating
                                                            ariaLabel='Standard'
                                                            onChange={selectStandard}
                                                            value={standardDetailsId}
                                                            list={standardData.map((data: any) => ({
                                                                value: data.standardDetailsId,
                                                                label: data.standardName,
                                                            }))}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}

                                            {standardDetailsId?.value != undefined ? (
                                                <div className='col-3'>
                                                    <FormGroup
                                                        id='sectionDetailsId'
                                                        label='Section'
                                                        isFloating>
                                                        <SearchableSelect
                                                            isFloating
                                                            ariaLabel='Section'
                                                            onChange={selectSection}
                                                            value={sectionDetailsId}
                                                            list={sectionDetailsData.map(
                                                                (data: any) => ({
                                                                    value: data.sectionDetailsId,
                                                                    label: data.sectionName,
                                                                }),
                                                            )}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}

                                            {sectionDetailsId?.value != undefined ? (
                                                <div className='col-3 mt-3'>
                                                    <FormGroup
                                                        id='studentDetailsId'
                                                        label='Student'
                                                        isFloating>
                                                        <SearchableSelect
                                                            isFloating
                                                            ariaLabel='Student'
                                                            onChange={selectStudent}
                                                            value={studentDetailsId}
                                                            list={studentDetailsDataByDropdown.map(
                                                                (data: any) => ({
                                                                    value: data.studentDetailsId,
                                                                    label: data.studentNameAndAdmissionNo,
                                                                }),
                                                            )}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}

                                            {studentDetailsId?.value != undefined ? (
                                                <div className="col-3 mt-3">
                                                    <FormGroup id="fromDate" label="From Date" isFloating>
                                                        <Input
                                                            type='date'
                                                            ariaLabel='From Date'
                                                            onChange={studentActivityForm.handleChange}
                                                            onBlur={studentActivityForm.handleBlur}
                                                            value={studentActivityForm.values.fromDate}
                                                            isValid={studentActivityForm.isValid}
                                                            isTouched={studentActivityForm.touched.fromDate}
                                                            invalidFeedback={studentActivityForm.errors.fromDate}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}
                                            {studentActivityForm.values.fromDate != '' ? (
                                                <div className="col-3 mt-3 ">
                                                    <FormGroup id="toDate" label="To Date" isFloating>
                                                        <Input
                                                            type='date'
                                                            ariaLabel='To Date'
                                                            onChange={studentActivityForm.handleChange}
                                                            onBlur={studentActivityForm.handleBlur}
                                                            value={studentActivityForm.values.toDate}
                                                            isValid={studentActivityForm.isValid}
                                                            isTouched={studentActivityForm.touched.toDate}
                                                            invalidFeedback={studentActivityForm.errors.toDate}
                                                        />
                                                    </FormGroup>
                                                </div>) : null
                                            }
                                            {studentActivityForm.values.toDate != '' ?
                                                <div className='col-2 mt-4'>
                                                    <Button
                                                        icon='ArrowDownward'
                                                        color='primary'
                                                        onClick={viewStudentClassWiseList}>
                                                        View
                                                    </Button>
                                                </div> : null
                                            }
                                        </div>
                                    </CardBody>
                                )}
                            </Card>
                        </Collapse>
                        <OpenCardComponent
                            isOpenListCard={isOpenListCard}
                            setIsOpenListCard={setIsOpenListCard}
                            isLoader={isLoader}
                        />
                    </>
                ) : null}
                {dataSuccess ?
                    <div className='row h-100 pb-3'>
                        <div className='col-lg-4'>
                            <Card stretch className='rounded-1 border border-light'>
                                <CardBody>
                                    {studentDetailsData.map((student: any) => (
                                        <div className='row g-3' key={student.studentDetailsId}>
                                            <div className='col-12 d-flex justify-content-center'>
                                                {student.profilePath != null ?
                                                    <img style={{ borderRadius: '50%' }} src={student.profilePath}
                                                        width="100" height="100" onError={(e: any) => pictNotLoading(e, student.genderId)} />
                                                    : <img src={profilePic(student.genderId)}
                                                        width="100" height="100" />}
                                            </div>
                                            <div className='col-12'>
                                                <div className='row g-2'>
                                                    <span className='fs-4 fw-bold me-2  d-flex justify-content-center text-center text-uppercase'>{`${student.studentName}`}</span>
                                                    <div className='h5 text-muted text-lowercase opacity-50 d-flex justify-content-center text-uppercase'>
                                                        {student.admissionNo}
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='HowToReg' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0 '>
                                                                    {student.studentRollNo ? student.studentRollNo : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                </div>
                                                                <div className='text-muted'>
                                                                    Roll No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon={student.genderId == 1 ? 'Male' : 'Female'} size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {student.gender ? student.gender : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Gender
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mt-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='PhoneIphone' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {student.smsToBeSentNumber ? student.smsToBeSentNumber : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Mobile
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mt-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='DateRange' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {student.dateOfBirthForView ? student.dateOfBirthForView : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Date of Birth
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mt-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='Mail' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {student.email ? student.email : <Icon icon='Remove' className='mb-0 text-dark fw-bold h4' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Email Address
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-lg-8'>
                            <Card ref={componentRef} stretch className='rounded-1 border border-light'>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='NotificationsActive' iconColor='warning'>
                                        <CardTitle tag='div' className='h5'>
                                            Recent Activities
                                        </CardTitle>
                                        <CardSubTitle tag='div' className='h6'>
                                            {fromDate} - {toDate}
                                        </CardSubTitle>
                                    </CardLabel>
                                    <CardActions className="d-print-none">
                                        <Dropdown isButtonGroup>
                                            <DropdownToggle>
                                                <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("StudentActivity", activityData[0].activities, columnVisibilityData)}> Excel </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(activityData[0].activities, columnVisibilityData, "StudentActivity")}> PDF
                                                    </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("StudentActivity", activityData[0].activities, columnVisibilityData)}> CSV </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </CardActions>
                                </CardHeader>
                                <CardBody isScrollable>
                                    {activityData != '' ?
                                        <Timeline>
                                            {activityData.map((item: any) => (
                                                <div key={item.studentDetailsId}>
                                                    {item.activities.map((i: any) => (
                                                        <TimelineItem
                                                            key={i.activityLogsId}
                                                            label={i.duration}
                                                            color={i.color}>
                                                            {i.activity}
                                                        </TimelineItem>
                                                    ))}

                                                </div>
                                            ))}
                                        </Timeline> :
                                        <div className='row'>
                                            <div className="col-12" style={{ height: '5vh' }}></div>
                                            <div className='col-12 d-flex justify-content-center'>
                                                <Icon icon='DoNotDisturb' size={'10x'} />
                                            </div>
                                            <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                                {noDataMsg}
                                            </div>
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </div>
                    </div> : null
                }

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper>
    );
};

export default StudentActivityReport;
