import { useContext, useEffect, useRef, useState } from 'react';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Collapse from '../../bootstrap/Collapse';
import Icon from '../../icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Input from '../../bootstrap/forms/Input';
import { useReactToPrint } from 'react-to-print';
import useSortableData from '../../../hooks/useSortableData';
import { useFormik } from 'formik';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService';
import { getClassCapacityReport } from '../../../services/report.service';
import AuthContext from '../../../contexts/authContext';
import {
	getColumnsForDataTable,
	getGraduationType,
	updateColumnsForDataTable,
	updateFilter,
} from '../../../services/common.service';
import { getLicenseKey } from '../../../services/application.settings';
import { getAcademicPeriod } from '../../../services/master.service';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';

function ClassCapacityReport() {

	useEffect(() => {
		getAcademicPeriodList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [courseCapacityReportData, setCourseCapacityReportData] = useState<any>([]);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
	const [academicPeriodData, setAcademicPeriodData] = useState<any>([]);
	const [isOpenListCard, setIsOpenListCard] = useState(true);

	const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(courseCapacityReportData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isLoader, setIsLoader] = useState(false);

	const [noDataMsg, setNoDataMsg] = useState('');

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(courseCapacityReportData, columnVisibilityData, columnVisibilityForm);

	const selectAcademicPeriod = (e: any) => {
		setIsOpenListCard(true);
		setDataSuccess(false);
		let academicPeriodId = e;
		setAcademicPeriodId(academicPeriodId);
	};

	function view() {
		setDataSuccess(false);
		setIsLoader(true);
		columnVisibilityForm.resetForm();
		getClassCapacity(academicPeriodId?.value);
	}

	function getAcademicPeriodList() {
		getAcademicPeriod(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.academicPeriod;
					if (data != undefined) {
						setAcademicPeriodData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAcademicPeriodData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [report, setReport] = useState<any>([])
	const [report1, setReport1] = useState<any>([])
	const [particularDate, setParticularDate] = useState('')
	const [admission, setAdmission] = useState('')

	const [totalAdmissionsPerRow, setTotalAdmissionsPerRow] = useState<any>([]);
	const [totalAdmissionsPerColumn, setTotalAdmissionsPerColumn] = useState<any>([]);
	const [total, setTotal] = useState<any>('')
	const [totalCapacity, setTotalCapacity] = useState<any>(0)

	function getClassCapacity(academicPeriodId: string) {
		setIsLoader(true);
		getClassCapacityReport(academicPeriodId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.classCapacityReport;
					if (data != undefined) {
						setIsLoader(false);
						setIsOpenListCard(false);
						setDataSuccess(true);
						setCourseCapacityReportData(data);

						const transformedData = data.reduce((acc: any, current: any) => {
							const { standardDetailsId, standardName, totalAllocated, dateOfAdmission, admissionCount } = current;

							let entry = acc.find((item: any) => item.standardDetailsId === standardDetailsId);

							if (!entry) {
								entry = { standardDetailsId, standardName, totalAllocated, dateOfAdmission, admissionCount: 0 };
								acc.push(entry);
							}
							entry.admissionCount += admissionCount;

							entry[dateOfAdmission] = (entry[dateOfAdmission] || 0) + admissionCount;

							return acc;
						}, []);

						let capacityData: any[] = [];
						let uniqueData1 = [];
						let courseIds = new Set();
						let dateOfAdmissions = new Set();

						for (let i = 0; i < data.length; i++) {
							let particular = data[i].dateOfAdmission
							let ad = data[i].admissionCount
							setParticularDate(particular)
							setAdmission(ad)
						}

						for (let i = 0; i < data.length; i++) {
							if (!dateOfAdmissions.has(data[i].dateOfAdmission)) {
								dateOfAdmissions.add(data[i].dateOfAdmission);
								capacityData.push(data[i]);
							}
						}

						for (let i = 0; i < data.length; i++) {
							if (!courseIds.has(data[i].standardDetailsId)) {
								courseIds.add(data[i].standardDetailsId);
								uniqueData1.push(data[i]);
							}
						}

						for (let i = 0; i < transformedData.length; i++) {
							transformedData[i].sno = i + 1;
						}

						const totalCapacity = uniqueData1.reduce((sum: any, row: any) => sum + row.totalAllocated, 0);
						setTotalCapacity(totalCapacity)

						let rowTotals: any[] = [];
						let columnTotals: any = {};

						let uniqueData = capacityData.filter((item:any) => item.dateOfAdmission != null)

						uniqueData.forEach((dateOfAdmissionData: any) => { columnTotals[dateOfAdmissionData.dateOfAdmission] = 0 });

						uniqueData1.forEach((rowData: any) => {
							let rowTotal = 0;

							uniqueData.forEach((dateOfAdmissionData: any) => {
								const admissionData = data.find(
									(rowData1: any) => rowData1.standardDetailsId === rowData.standardDetailsId &&
										rowData1.dateOfAdmission === dateOfAdmissionData.dateOfAdmission
								);

								const admissionCount = admissionData ? admissionData.admissionCount : 0;
								rowTotal += admissionCount;
								columnTotals[dateOfAdmissionData.dateOfAdmission] += admissionCount;
							});

							rowTotals.push(rowTotal);
						});

						setTotalAdmissionsPerRow(rowTotals);
						setTotalAdmissionsPerColumn(columnTotals);

						const total: any = Object.values(columnTotals).reduce((a: any, b: any) => a + b, 0);
						setTotal(total)

						let data1: any = []

						uniqueData.forEach((dateOfAdmissionData: any, index: any) => {
							data1.push({ columnVisibilityId: index + 1, columnName: dateOfAdmissionData.dateOfAdmission, keyName: dateOfAdmissionData.dateOfAdmission, isActive: true, isDisplay: true, isDefault: true });
						});

						let finalData = data1.filter((item: any) => item.columnName != null)

						const fin = [{ columnVisibilityId: 1, columnName: 'Standard', keyName: 'standardName', isActive: true, isDisplay: true, isDefault: true }, { columnVisibilityId: 2, columnName: 'Capacity', keyName: 'totalAllocated', isActive: true, isDisplay: true, isDefault: true }]

						const last = [{ columnVisibilityId: 3, columnName: 'Total', keyName: 'admissionCount', isActive: true, isDisplay: true, isDefault: true }]

						setColumnVisibilityData([...fin, ...finalData, ...last])

						console.log('uniqueData', uniqueData);
						console.log('transformedData', transformedData);


						setReport(uniqueData);
						setReport1(transformedData);

					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setCourseCapacityReportData([]);
					setNoDataMsg(response.data.message);
					setIsOpenListCard(false);
					setDataSuccess(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Class Capacity Report'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-6'>
								<CardTitle tag='div' className='h5'>
									Class Capacity Report
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-lg-3'>
									<FormGroup
										id='academicPeriod'
										label='Academic Period'
										isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Academic Period'
											onChange={selectAcademicPeriod}
											value={academicPeriodId}
											list={academicPeriodData.map((data: any) => ({
												value: data.academicPeriodId,
												label: data.period,
											}))}
										/>
									</FormGroup>
								</div>
								{academicPeriodId?.value != undefined ?
									<div className='col-3 align-self-center'>
										<Button icon='ArrowDownward' color='primary' onClick={view}>
											View
										</Button>
									</div>
									: null}
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? (
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-7'>
								<CardTitle tag='div' className='h5'>
									Over All Admission Abstract
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<ButtonGroup
								className='col-lg-3 justify-content-end d-print-none'
								color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>Export</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button color='primary' isLight icon='CloudDownload'
												onClick={() => downloadExcel('CourseCapacityReport', report1, columnVisibilityData,)}
											> Excel
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='PictureAsPdf'
												onClick={() => convertJsonToPdf(report1, columnVisibilityData, 'CourseCapacityReport',)}
											> PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='CloudDownload'
												onClick={() => downloadFile('CourseCapacityReport', report1, columnVisibilityData,)}
											> CSV
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='Print' onClick={handlePrint}> Print</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</CardActions>
						</CardHeader>
						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr className='table-success text-decoration-underline mt-4'>
										<th>S.No</th>
										<th>Standard</th>
										<th>Capacity</th>
										{report.map((rowData2: any) => (
											<th key={rowData2.keyName}>{rowData2.dateOfAdmission}</th>
										))}
										<th>Total</th>
									</tr>
								</thead>
								<tbody>
									{report1 != '' ? (
										<>
											{report1.map((rowData: any, index: number) => (
												<tr key={rowData.standardDetailsId}>
													<td>{rowData.sno}</td>
													<td>{rowData.standardName}</td>
													<td>{rowData.totalAllocated}</td>
													{report.map((dateOfAdmissionData: any) => {
														const admissionData = courseCapacityReportData.find(
															(rowData1: any) => rowData1.standardDetailsId === rowData.standardDetailsId &&
																rowData1.dateOfAdmission === dateOfAdmissionData.dateOfAdmission
														);
														return (
															<td key={dateOfAdmissionData}>
																{admissionData ? admissionData.admissionCount : 0}
															</td>
														);
													})}
													<td>{totalAdmissionsPerRow[index]}</td>
												</tr>
											))}

											<tr className='table-danger'>
												<td colSpan={2}>Total</td>
												<td>{totalCapacity}</td>
												{report.map((dateOfAdmissionData: any) => (
													<td key={dateOfAdmissionData.dateOfAdmission}>
														{totalAdmissionsPerColumn[dateOfAdmissionData.dateOfAdmission]}
													</td>
												))}
												<td>{total}</td>
											</tr>
										</>
									) : (
										<NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />
									)}
								</tbody>
							</table>
						</CardBody>
						{/* <PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/> */}
					</Card>
				) : null}
			</Page>
		</PageWrapper>
	);
}
export default ClassCapacityReport;
