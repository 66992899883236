import { useContext, useEffect, useRef, useState } from "react";
import Page from "../../../layout/Page/Page";
import Button from "../../bootstrap/Button";
import Card, { CardBody, CardLabel, CardTitle } from "../../bootstrap/Card";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { toasts } from "../../../services/toast.service";
import AuthContext from "../../../contexts/authContext";
import { addStudentQuizMarkDetails, getProfileForStudent, getQuizDetailsForStudent } from "../../../services/student.service";
import useDarkMode from "../../../hooks/useDarkMode";
import { showLoader } from "../../../services/loader.services";
import AlertService from "../../../services/AlertService";
import { getLicenseKey, webUrl } from "../../../services/application.settings";
import Icon from "../../icon/Icon";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { getQuizDetails, getSubjectListByStandardDetailsId } from "../../../services/exam.service";
import Spinner from "../../bootstrap/Spinner";

function QuizDetails() {

    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        // getQuiz(userAccountId);
        getStudentProfile(userAccountId);
    }, [])

    const [isVisible, setIsVisible] = useState(false);
    const { darkModeStatus } = useDarkMode();
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentQuizData, setStudentQuizData] = useState<any>([])
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [current, setCurrent] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState<any>('');
    const [selectOption, setSelectOption] = useState<any>([]);
    const [question, setQuestion] = useState<any>([]);
    const [index, setIndex] = useState(1);
    const [report, setReport] = useState<any>(false);
    const [isStart, setIsStart] = useState<any>(false);
    const [startQuiz, setStartQuiz] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [standard, setStandard] = useState('')
    const [batch, setBatch] = useState('')
    const [quiz, setQuiz] = useState('')
    const [quizStatus, setQuizStatus] = useState(false);
    const [subjectName, setSubjectName] = useState<any>('')
    const [titleName, setTitleName] = useState<any>('')

    const [count, setCount] = useState(1);
    const [quizMarkInfo, setQuizMarkInfo] = useState<any>([])

    const timerId = useRef<any>(null);
    const [timeRemaining, setTimeRemaining] = useState<any>('');
    const [isRunning, setIsRunning] = useState(false);

    const startTimer = () => {
        if (!isRunning) {
            setIsRunning(true);
            timerId.current = setInterval(() => {
                setTimeRemaining((prevTime: any) => {
                    if (prevTime == 0) {
                        setAlertStatus({ message: 'Times Out', type: "danger" });
                        setIsOpen(true);
                        setReport(false)
                        closeAndReset()
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
    };

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    const stopTimer = () => {
        if (!timerId.current) return;
        setIsRunning(false);
        if (timerId.current) {
            clearInterval(timerId.current);
            timerId.current = null;
        }
    };

    function onActivate(i: any) {
        setCurrentQuestion(0)
        setSubjectName(i.paperName)
        setTitleName(i.titleName)
        setQuizStatus(i.quizAttendStatus)
        setQuestion(i.questions);
        setStandard(i.studentDetailsId)
        setQuiz(i.quizDetailsId)
        setReport(i.quizAttendStatus)
        setTimeRemaining(i.millisecond)

        if (i.quizAttendStatus == false) {
            setStartQuiz(true)
        } else {
            getQuizBySubject(userAccountId, i.quizDetailsId)
        }
    }

    function start() {
        setIsStart(false)
        setIndex(1)
        setIsVisible(quizStatus == false ? true : false)
        if (quizStatus == false) {
            startTimer();
        }
        else {
            stopTimer();
        }
    }

    const handleNext = () => {
        if (current < question.length - 1) {
            setCurrent(current + 1);
            setCount(count + 1)
        }
    };

    const handlePrevious = () => {
        if (current != 0) {
            setCurrent(current - 1);
            setCount(count - 1)
        }
    };

    const handleNextQuestion = () => {
        if (currentQuestion < question.length - 1) {
            setSelectedOptions('')
            setCurrentQuestion(currentQuestion + 1);
            setIndex(index + 1)
        }
        else {
            addStudentQuizMarkSubmit();
        }
    };

    const handleOptionSelect = (questionId: any, optionId: any) => {
        setSelectedOptions(optionId);
        if (selectOption.length == 0) {
            const obj = {
                quizQuestionsId: questionId,
                selectedOption: optionId
            };
            selectOption.push(obj);
        } else {
            let selectedItem: any;
            selectOption.forEach((item: any, index: any) => {
                if (item['quizQuestionsId'] == questionId) {
                    selectedItem = item
                    selectedItem['quizQuestionsId'] = questionId,
                        selectedItem['selectedOption'] = optionId
                }
            });
            if (selectedItem != undefined) {
                selectedItem['quizQuestionsId'] = questionId,
                    selectedItem['selectedOption'] = optionId
            }
            else {
                var obj
                obj = {
                    quizQuestionsId: questionId,
                    selectedOption: optionId,
                };
                selectOption.push(obj);
            }
        }
    }

    function getStudentProfile(studentDetailsId: any) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                        setBatch(data[0].batchMasterId)
                        setAcademicPeriodId(data[0].academicPeriodId)
                        setMediumId(data[0].mediumId)
                        setStandardDetailsId(data[0].standardDetailsId)
                        getSubjectPaperList(data[0].batchMasterId, data[0].standardDetailsId)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [subjectData, setSubjectData] = useState<any>('')

    function getSubjectPaperList(batchMasterId: any, standardDetailsId: any) {
        getSubjectListByStandardDetailsId(
            batchMasterId,
            standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subjectListByStandardDetailsId;
                    if (data != undefined) {
                        setSubjectData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSubjectData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    const [dataSuccess, setDataSuccess] = useState<any>(true)

    function getQuiz(studentDetailsId: any, subjectPaperDetailsId: any) {
        getQuizDetailsForStudent(studentDetailsId, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.quizDetailsForStudent;
                    if (data != undefined) {
                        let quizData = data.filter((item: any) => item.subjectPaperDetailsId == subjectPaperDetailsId)
                        if (quizData != '') {
                            setDataSuccess(true)
                            setStudentQuizData(quizData);
                        } else {
                            setDataSuccess(false)
                            setStudentQuizData([]);
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setDataSuccess(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getQuizBySubject(studentDetailsId: any, quizDetailsId: any) {
        getQuizDetailsForStudent(studentDetailsId, quizDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.quizDetailsForStudent[0];
                    if (data != undefined) {
                        setQuizMarkInfo(data)
                        setQuestion(data.questions)
                        setReport(true)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setQuizMarkDetails() {
        return ({
            licenseKey: getLicenseKey,
            studentDetailsId: Number(userAccountId),
            quizDetailsId: quiz,
            standardDetailsId: standard,
            batchMasterId: batch,
            questionDetails: selectOption,
        })
    }

    function addStudentQuizMarkSubmit() {
        showLoader(true)
        let addStudentQuizMarkPostData = setQuizMarkDetails()
        addStudentQuizMarkDetails(addStudentQuizMarkPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    // getQuiz(userAccountId)
                    getQuizBySubject(userAccountId, quiz);
                    closeAndReset()
                }
                else if (data.success == false) {
                    showLoader(false)
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                }
            }
            , (error) => {
                showLoader(false)
            }
        )
    }

    function closeAndReset() {
        stopTimer();
        setCurrentQuestion(0)
        setIndex(1);
        setSelectedOptions('')
        setSubjectPaperDetailsId('')
        setSelectOption([])
        setStartQuiz(false)
        setIsVisible(false)
        setIsStart(false)
        setSubjectName('')
        setTitleName('')
        setQuizStatus(false)
        setDataSuccess(true)
        setStandard('')
        setQuiz('')
        setQuestion([])
        setTimeRemaining('')
        setStudentQuizData([])
        setStudentDetailsData([])
    }

    const [subjectPaperDetailsId, setSubjectPaperDetailsId] = useState<any>('')
    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')

    function selectSubject(item: any) {
        getQuiz(userAccountId, item.subjectPaperDetailsId)
        setSubjectPaperDetailsId(item.subjectPaperDetailsId)

    }

    return (
        <PageWrapper title="Quiz Details">
            <SubHeader>
                <SubHeaderLeft>
                    {(subjectPaperDetailsId != '' && isVisible == false) || isStart == true || (isVisible == true && subjectPaperDetailsId == '') ? <>
                        <Button color='primary' isLight icon='ArrowBack' onClick={() => { closeAndReset(); setReport(false) }} isDisable={selectOption.length == 0 ? false : true}>
                            Back
                        </Button>
                        <SubheaderSeparator /> </> : null
                    }
                    <CardLabel icon='EditNote' iconColor='info'>
                        <CardTitle tag='div' className='h5'>
                            Quiz
                        </CardTitle>
                    </CardLabel>
                </SubHeaderLeft>
            </SubHeader>
            <Page container='fluid'>
                <div className="row">
                    <div className="col-lg-4">
                        <Card stretch>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-12' style={{ height: '3vh' }}></div>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <img style={{ borderRadius: '50%' }} src={`${webUrl}assets/logos/quiz.jpg`} width="250" height="200" />
                                    </div>
                                    <div className='col-3'></div>
                                    <div className='col-6'>
                                        <Button
                                            icon='Timer'
                                            color='dark'
                                            isDisable
                                            isOutline
                                            className="w-100 p-3 mt-3 fs-3"
                                            onClick={start}
                                        >
                                            {isVisible ? <b> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</b> : <b>0:00</b>}
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    {isVisible ?
                        <div className="col-lg-8">
                            <Card stretch>
                                <CardBody>
                                    <div className="row mt-2">
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <h6 className='text-grey fw-bold'>Question {index} / {question.length}</h6>
                                        </div>
                                        <br /><br />
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <h5 className='fw-bold'>{question[currentQuestion].question}</h5>
                                        </div>
                                        <div className='col-1 mt-2'> </div>
                                        {question[currentQuestion].optionArray.map((option: any, id: number) => (
                                            <div className='row-col-lg-10 mt-2 ' key={option.optionId}>
                                                <Card style={{ height: '85%' }} className={`${selectedOptions == option.optionId ? 'border border-success' : 'border border-light'} transition-base rounded-2 mb-0`}
                                                    shadow='sm' onClick={() => { handleOptionSelect(question[currentQuestion].quizQuestionsId, option.optionId) }}>
                                                    <CardBody>
                                                        <div className='d-flex align-items-start'>
                                                            <div className={`${selectedOptions == option.optionId ? 'text-success' : 'text-muted'} d-flex justify-content-start`}>
                                                                <Icon icon='TaskAlt' className='fs-4' /> <p className='ms-3 fs-6'>{option.optionValue}</p>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        ))}
                                        <div className='col-1 mt-1'></div>
                                        <div className='col-12 mt-4 d-flex justify-content-center'>
                                            <Button
                                                icon={currentQuestion === question.length - 1 ? 'Save' : 'ArrowForward'}
                                                color='info'
                                                isDisable={!selectedOptions}
                                                onClick={() => {
                                                    if (currentQuestion !== question.length - 1) {
                                                        handleNextQuestion();
                                                    } else {
                                                        handleNextQuestion();
                                                        stopTimer();
                                                    }
                                                }}>
                                                {currentQuestion === question.length - 1 ? "Submit" : "Next Question"}
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div> : null
                    }

                    {report ?
                        <div className="col-lg-8">
                            <Card stretch>
                                <CardBody>
                                    <div className='row'>
                                        <div className='col-12' style={{ height: '8vh' }}></div>
                                        <div className='col-12 d-flex justify-content-center'>
                                            <Icon className='text-success' icon='TaskAlt' size={'9x'} />
                                        </div>

                                        <div className='fs-4 fw-bold text-center text-success'>
                                            Thank you For your Participation!
                                        </div>
                                        <div className='col-3'></div>
                                        <div className='col-3 mt-5'>
                                            <strong className='fs-5'>Your Score </strong>
                                        </div>
                                        <div className='col-1 mt-5 text-start'>
                                            <strong className='fs-5'> : </strong>
                                        </div>
                                        <div className='col-4 mt-5 text-start'>
                                            <strong className='fs-5'><span className='text-success'>{quizMarkInfo.scoredMark}</span> /{quizMarkInfo.totalMark}</strong>
                                        </div>

                                        <div className='col-3'></div>
                                        <div className='col-3 mt-3'>
                                            <strong className='fs-5'>Wrong Answer </strong>
                                        </div>
                                        <div className='col-1 mt-3 text-start'>
                                            <strong className='fs-5'> : </strong>
                                        </div>
                                        <div className='col-4 mt-3 text-start'>
                                            <strong className='fs-5'><span className='text-danger'>{quizMarkInfo.wrongAnswersCount}</span></strong>
                                        </div>
                                        <div className='col-12 d-flex justify-content-center'>
                                            <Button className="mt-4" icon="RemoveRedEye" color="info" onClick={() => { setIsStart(true); setReport(false) }}>
                                                Check Answers
                                            </Button>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </div> : null
                    }

                    {isStart == true ?
                        <div className="col-lg-8">
                            <Card stretch>
                                <CardBody>
                                    <div className="row mt-2">
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <h6 className='text-black fw-bold'>Question {count} / {question.length}</h6>
                                        </div>
                                        <br /><br />
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <h5 className='fw-bold'>{question[current]?.question}</h5>
                                        </div>
                                        <div className='col-1 mt-2'> </div>
                                        {question[current]?.optionArray.map((option: any, id: number) => (
                                            <div className='row-col-lg-10 mt-2' key={option.optionId}>
                                                <Card style={{ height: '85%' }} className={`${question[current]?.correctAnswer === option.optionId ? `bg-l${darkModeStatus ? 'o25' : '25'
                                                    }-success bg-l${darkModeStatus ? 'o50' : '10'
                                                    }-success-hover border border-success` : question[current]?.correctAnswer !== option.selectedOption && option.optionId == question[current]?.selectedOption ? `bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-danger bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-danger-hover border border-danger` : 'border border-light'} transition-base rounded-2 mb-0`}
                                                    shadow='sm' >
                                                    <CardBody>
                                                        <div className='d-flex align-items-start'>
                                                            <div className='text-muted d-flex justify-content-start'>
                                                                <Icon icon={question[current]?.correctAnswer === option.optionId ? 'TaskAlt' : question[current]?.correctAnswer !== option.selectedOption && option.optionId == question[current]?.selectedOption ? 'Close' : 'TaskAlt'} className='fs-4' /> <p className='ms-3 fs-6'>{option.optionValue}</p>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        ))}
                                        <div className='col-1 mt-1'></div>
                                        <div className="row d-flex justify-content-between mt-3">
                                            <div className="col-lg-4" >
                                                <Button
                                                    className="ms-4"
                                                    icon={current === 0 ? 'ArrowForward' : 'ArrowBack'}
                                                    color='info'
                                                    isDisable={current === 0}
                                                    onClick={() => handlePrevious()}>
                                                    {current === 0 ? "FirstQuestion" : "Previous Question"}
                                                </Button>
                                            </div>
                                            <div className="col-lg-3 d-flex justify-content-end">
                                                <Button
                                                    icon={current === question.length - 1 ? 'ArrowBack' : 'ArrowForward'}
                                                    color='info'
                                                    isDisable={current === question.length - 1}
                                                    onClick={() => handleNext()}>
                                                    {current === question.length - 1 ? "Last Question" : "Next Question"}
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div> : null
                    }

                    {!startQuiz && report == false && isStart == false ?
                        <div className="col-lg-8">
                            <Card stretch>
                                {subjectPaperDetailsId == '' ?
                                    <CardBody>
                                        <div className='row g-3'>
                                            {subjectData != '' ? <>
                                                {subjectData.map((rowData: any, index: any) => (
                                                    <div className='col-4' key={index}>
                                                        <Button
                                                            color='info'
                                                            shadow={'sm'}
                                                            className='w-100 p-5 border border-light'
                                                            isDisable={isVisible == true}
                                                            isLight={rowData.quizDetailsId !== quiz}
                                                            onClick={() => { selectSubject(rowData) }}
                                                        >
                                                            {rowData.paperName}
                                                        </Button>
                                                    </div>))
                                                } </> :
                                                <div className='row'>
                                                    <div className='col-12' style={{ height: '20vh' }}></div>
                                                    <div className='col-12 d-flex justify-content-center'>
                                                        <Spinner color='info' size={'xl'} className='fs-2' />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </CardBody> :

                                    <CardBody>
                                        {dataSuccess ? <>
                                            {studentQuizData != '' ?
                                                <div className='row g-3'>
                                                    {studentQuizData.map((rowData: any, index: any) => (
                                                        <div className='col-4' key={index}>
                                                            <Button

                                                                color='info'
                                                                shadow={'sm'}
                                                                className='w-100 p-5 border border-light'
                                                                isDisable={isVisible == true}
                                                                isLight={rowData.quizDetailsId !== quiz}
                                                                onClick={() => { onActivate(rowData) }}
                                                            >
                                                                {rowData.titleName}
                                                            </Button>
                                                        </div>))}
                                                </div> :
                                                <div className='row'>
                                                    <div className='col-12' style={{ height: '18vh' }}></div>
                                                    <div className='col-12 d-flex justify-content-center'>
                                                        <Spinner color='info' size={'xl'} className='fs-2' />
                                                    </div>
                                                </div>

                                            } </> :
                                            <div className='row'>
                                                <div className='col-12' style={{ height: '10vh' }}></div>
                                                <div className='col-12 d-flex justify-content-center'>
                                                    <Icon icon='DoNotDisturb' size={'8x'} />
                                                </div>
                                                <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                                    No Data Found
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                }
                            </Card>

                        </div> : startQuiz == true && isVisible == false && report == false && isStart == false ?

                            <div className="col-lg-8">
                                <Card stretch>
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-12 d-flex justify-content-center text-primary mt-4'>
                                                <h1><b><u>{titleName}</u></b></h1>
                                            </div>

                                            <div className='col-12' style={{ height: '2vh' }}></div>

                                            <div className='col-12 d-flex justify-content-center mt-2'>
                                                <h1><b>{subjectName}</b></h1>
                                            </div>

                                            <div className='col-12' style={{ height: '7vh' }}></div>

                                            <div className='col-12 d-flex justify-content-center mt-2 text-muted'>
                                                <h4>Total No Of Questions : {question.length}</h4>
                                            </div>
                                            <div className='fs-4 fw-bold text-center text-uppercase'>
                                                <Button
                                                    icon='ArrowForward'
                                                    color='info'
                                                    onClick={start}
                                                    className="mt-3"
                                                >
                                                    Start Quiz
                                                </Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div> : null
                    }

                </div>
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    )
}
export default QuizDetails;